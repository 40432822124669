import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { ValidationError } from "../../common/validationError";
import { useMasterDataStepDataMapping } from "./MasterDataStep.hooks";
import StepSegment from "../stepSegment/StepSegment";
import DotLegalMultiSelectWithButton from "../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import PurposeDialog from "../../masterData/purpose/purposeDialog/PurposeDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { dataControllerRecordIcon } from "../../common/icons";

export interface IMasterDataStepProps {
    name: string | undefined;
    description: string | undefined;
    purposes: Array<string> | undefined;
    validationErrors: Array<ValidationError>;
    OnNameChange: (name: string) => void;
    OnDescriptionChange: (description: string) => void;
    OnPurposesChange: (purposes: Array<string>) => void;
    toolTip?: string;
    readOnly?: boolean;
    hasDataCreationPermission: boolean;
    isUserValidated?: boolean;
    processingActivityId: string;
}

function MasterDataStep(props: IMasterDataStepProps) {
    const { translateString } = useTranslation();
    const { isLoading, tableData, showPurposeDialog, setShowPurposeDialog, onSavePurpose, searchValue, setSearchValue } = useMasterDataStepDataMapping(props);
    const { permissions } = useUserContext();

    return (
        <React.Fragment>
            <StepSegment size="small">
                <DotLegalTextField
                    onChange={(e) => props.OnNameChange(e)}
                    label={translateString("processingActivity")}
                    value={props.name!}
                    errorText={props.validationErrors.find((v) => v.field === "processingActivityName")?.error}
                    toolTipText={translateString("processingactivityHelperText")}
                    disabled={props.readOnly}
                    debounce
                />
                <DotLegalTextField
                    label={translateString("description")}
                    onChange={(e) => props.OnDescriptionChange(e)}
                    value={props.description!}
                    multiline
                    rows={12}
                    toolTipText={translateString("descriptionHelperText")}
                    disabled={props.readOnly}
                    debounce
                />
                {permissions.processingActivityMasterDataPermissions.hasPurposePermission && (
                    <DotLegalMultiSelectWithButton
                        isLoading={isLoading}
                        options={tableData!}
                        label={translateString("purpose")}
                        placeholder={translateString("purpose")}
                        chosenOptions={props.purposes!}
                        onChange={(e) => props.OnPurposesChange(e)}
                        errorText={props.validationErrors.find((v) => v.field === "purposes")?.error}
                        toolTipText={translateString("purposeHelperText")}
                        disabled={props.readOnly}
                        onButtonClick={() => setShowPurposeDialog(true)}
                        buttonLabel={translateString("createNew")}
                        hasPermission={props.hasDataCreationPermission}
                        onSearchValueChange={setSearchValue}
                        searchValue={searchValue}
                        additionalIcon={{ icon: dataControllerRecordIcon, tooltipText: translateString("fieldIncludedInRecord") }}
                        noOptionsLabel={translateString("noOptions")}
                    />
                )}
            </StepSegment>

            {showPurposeDialog && (
                <PurposeDialog
                    onHideDialog={() => setShowPurposeDialog(false)}
                    selectedPurpose={{ name: searchValue, description: "", id: "", active: true, isCustom: true }}
                    onSave={onSavePurpose}
                />
            )}
        </React.Fragment>
    );
}

export default MasterDataStep;

import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import React from "react";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../../../common/components/dotLegalTable/DotLegalTable.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { CertificationViewModel } from "./Certifications.types";
import CheckIcon from "@mui/icons-material/Check";
import { useCertificationsHooks } from "./Certifications.hooks";
import CertificationDialog from "../certificationDialog/CertificationDialog";
import DeleteCertificationDialog from "../deleteCertificationDialog/DeleteCertificationDialog";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { getMasterIcon } from "../../MasterData.hooks";

function Certifications() {
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const { translateString } = useTranslation();
    const { customerName } = useUserContext();
    const {
        data,
        isLoading,
        setShowDialog,
        showDialog,
        refetch,
        selectedCertificaion,
        setSelectedCertification,
        setShowDeleteDialog,
        showDeleteDialog,
        onDeleteDialogClose,
        onDialogClose,
    } = useCertificationsHooks();

    const getMenuItems = (row: CertificationViewModel) => {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedCertification(row);
                setShowDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedCertification(row);
                setShowDeleteDialog(true);
            },
        });

        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs(translateString("certifications"))} />
            <DotLegalActionBar
                canCreate
                buttonText={translateString("createCertificaion")}
                onOpenDialog={() => {
                    setShowDialog(true);
                }}
                showDialog={showDialog}
                icon={getMasterIcon()}
            >
                {showDialog && (
                    <CertificationDialog
                        selectedCertification={selectedCertificaion}
                        certificationId={selectedCertificaion?.id}
                        onCertificationSaved={() => {
                            refetch();
                            onDialogClose();
                        }}
                        onDialogClose={() => onDialogClose()}
                    />
                )}
            </DotLegalActionBar>

            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    emptyText={translateString("noData")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    data={data}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                                <Box>{row.isCustom ? "" : <CheckIcon />}</Box>
                            </TableCell>
                            <TableCell align={"right"}>{row.isCustom && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteDialog && (
                <DeleteCertificationDialog
                    onCloseDialog={() => onDeleteDialogClose()}
                    onDeleted={() => {
                        onDeleteDialogClose();
                        refetch();
                    }}
                    selectedCertification={selectedCertificaion!}
                />
            )}
        </React.Fragment>
    );
}

export default Certifications;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<CertificationViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}

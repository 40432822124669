import Typography from "@mui/material/Typography";
import { useTranslation } from "../../../localization/useTranslation";
import { useDotLegalStepperStyles } from "./DotLegalStepper.styles";
import React from "react";
import { StepperGraphicType, StepTypeEnum } from "../../../processingActivity/ProcessingActivity.types";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useIsOnMediumScreen, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import DotLegalCoreStepper from "./DotLegalCoreStepper";
import DotLegalPaper from "../dotLegalPaper/DotLegalPaper";
import { arrow } from "../../icons";
import MobileStepper from "@mui/material/MobileStepper";
import ProcessingActivityValidationBox from "./processingActivityValidationBox/ProcessingActivityValidationBox";
import CompleteValidationDialog from "./completeValidationDialog/CompleteValidationDialog";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";

export interface IDotLegalStepper {
    steps: Array<{
        name: string;
        error: boolean;
        visited: boolean;
        stepType: number;
        stepHover: { hoverContent: any; toolTipMaxWidth: number } | undefined;
        stepValidated: { validationId: string; isValidated: boolean } | undefined;
        helpTextHeader?: string;
        helpTextContent?: string;
        disabled?: boolean;
    }>;
    children: React.ReactNode;
    header: string;
    onNextClick: () => void;
    onPreviousClick: () => void;
    onStepClick: (step: number) => void;
    onSaveClick: () => void;
    activeStep: number;
    graphicType?: StepperGraphicType;
    inProgess?: boolean;
    showValidation?: boolean;
    isExpanded?: boolean;
    isComplete?: boolean;
    showCompleteValidationDialog?: boolean;
    onCompleteValidationDialog?: (open: boolean) => void;
    OnExpandedClick?: () => void;
    OnValidationClick?: (currentStep: StepTypeEnum, isValidated: boolean) => void;
    OnCompleteValidationClick?: () => void;
    completeValidationInProgress?: boolean;
    onSecondaryButtonClick?: () => void;
    secondaryButtonText?: string;
}

function DotLegalStepper(props: IDotLegalStepper) {
    const styles = useDotLegalStepperStyles(props);
    const isMediumScreen = useIsOnMediumScreen();
    const showMobileStepper = useIsOnSmallScreen();
    const { translateString } = useTranslation();
    const currentStep = props.steps.find((s) => s.stepType === props.activeStep)!;
    const stepIndex = props.steps.indexOf(currentStep);

    const showValidationBox = () => {
        return props.showValidation || currentStep.helpTextHeader !== undefined;
    };

    const getCollapseHeader = () => {
        return (
            <Box sx={(theme) => ({ color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold, fontSize: 15, whiteSpace: "nowrap" })}>
                {props.showValidation ? <Trans i18nKey="validationHeader" values={{ step: "Step " + (stepIndex + 1) }} /> : currentStep.helpTextHeader}
            </Box>
        );
    };

    const getCollapseContent = () => {
        return (
            <Box sx={{ margin: 2, textAlign: "center", whiteSpace: "pre-wrap" }}>
                {props.showValidation ? (
                    <Trans i18nKey="validationContent" values={{ step: "Step " + (stepIndex + 1) + " " + props.header }} />
                ) : (
                    currentStep.helpTextContent
                )}
            </Box>
        );
    };

    return (
        <div style={{ height: "100%" }}>
            <Box sx={styles.root}>
                {showMobileStepper ? (
                    <MobileStepper
                        variant="dots"
                        steps={props.steps.length}
                        position="static"
                        activeStep={stepIndex}
                        sx={styles.mobileStepper}
                        nextButton={
                            <React.Fragment>
                                <Box sx={{ "& > *": { marginLeft: 1 } }}>
                                    {props.isComplete && (
                                        <DotLegalButton buttonType="secondary" size="small" onClick={() => props.onCompleteValidationDialog!(true)}>
                                            {translateString("validate")}
                                        </DotLegalButton>
                                    )}
                                    {props.OnCompleteValidationClick === undefined && props.onSecondaryButtonClick && (
                                        <DotLegalButton buttonType="secondary" size="small" onClick={props.onSecondaryButtonClick}>
                                            {props.secondaryButtonText}
                                        </DotLegalButton>
                                    )}
                                    <DotLegalButton
                                        buttonType="primary"
                                        size="small"
                                        onClick={props.onNextClick}
                                        disabled={stepIndex === props.steps.length - 1 || props.steps[stepIndex + 1].disabled}
                                    >
                                        {translateString("next")}
                                    </DotLegalButton>
                                </Box>
                            </React.Fragment>
                        }
                        backButton={
                            <DotLegalButton buttonType="primary" size="small" onClick={props.onPreviousClick} disabled={stepIndex === 0}>
                                {translateString("previous")}
                            </DotLegalButton>
                        }
                    />
                ) : (
                    <Box sx={styles.stepper}>
                        <Box sx={styles.buttonWrapper}>
                            <Box
                                sx={{
                                    "& .MuiButton-root": {
                                        "&:hover": {
                                            "& svg": {
                                                transform: "translateX(10px)",
                                                transition: "0.2s",
                                            },
                                        },
                                    },
                                }}
                            >
                                <DotLegalButton buttonType="primary" disabled={stepIndex === 0} onClick={props.onPreviousClick}>
                                    <Box sx={{ ...styles.whiteArrow, ...styles.leftArrow }}>{arrow}</Box>
                                    &nbsp;
                                    {translateString("previous")}
                                </DotLegalButton>
                            </Box>
                        </Box>
                        <DotLegalCoreStepper steps={props.steps} activeStepIndex={stepIndex} onStepClick={props.onStepClick} />
                        <Box sx={styles.buttonWrapper}>
                            {props.isComplete && (
                                <Box sx={styles.nextDraftWrapper}>
                                    <DotLegalButton buttonType="secondary" onClick={() => props.onCompleteValidationDialog!(true)}>
                                        {translateString("validate")}
                                    </DotLegalButton>
                                </Box>
                            )}
                            {props.OnCompleteValidationClick === undefined && props.onSecondaryButtonClick && (
                                <Box sx={styles.nextDraftWrapper}>
                                    <DotLegalButton buttonType="secondary" onClick={props.onSecondaryButtonClick}>
                                        {props.secondaryButtonText}
                                    </DotLegalButton>
                                </Box>
                            )}
                            <Box sx={styles.nextDraftWrapper}>
                                <Box
                                    sx={{
                                        "& .MuiButton-root": {
                                            "&:hover": {
                                                "& svg": {
                                                    transform: "translateX(10px)",
                                                    transition: "0.2s",
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <DotLegalButton
                                        buttonType="primary"
                                        onClick={props.onNextClick}
                                        disabled={stepIndex === props.steps.length - 1 || props.steps[stepIndex + 1].disabled}
                                    >
                                        {translateString("next")}
                                        &nbsp;
                                        <Box sx={styles.whiteArrow}>{arrow}</Box>
                                    </DotLegalButton>
                                </Box>

                                <DotLegalButton buttonType="secondary" onClick={props.onSaveClick} isLoading={props.inProgess}>
                                    {translateString("overview")}
                                </DotLegalButton>
                            </Box>
                        </Box>
                    </Box>
                )}
                <DotLegalPaper sx={styles.container} background={props.graphicType?.wave}>
                    <div>
                        <Typography sx={styles.instructions}>{props.header}</Typography>
                        <Typography sx={styles.step}>
                            {translateString("step")} {stepIndex + 1}
                        </Typography>
                    </div>
                    <Box sx={styles.contentWrapper}>
                        <Box sx={styles.childWrapper}>
                            {props.children}
                            {!isMediumScreen && <Box sx={styles.stepGraphic} style={{ position: "absolute" }} />}
                        </Box>
                        <Box sx={styles.sticky}>
                            {showValidationBox() && (
                                <ProcessingActivityValidationBox
                                    expanded={props.isExpanded ?? false}
                                    IsValidated={currentStep?.stepValidated?.isValidated!}
                                    stepHeader={getCollapseHeader()}
                                    stepContent={getCollapseContent()}
                                    onExpandClick={() => props.OnExpandedClick!()}
                                    onValidationClick={
                                        props.OnValidationClick ? (selected) => props.OnValidationClick!(currentStep.stepType!, selected) : undefined
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                </DotLegalPaper>
            </Box>
            <CompleteValidationDialog
                open={props.showCompleteValidationDialog!}
                OnCompleteClick={() => props.OnCompleteValidationClick!()}
                onDialogClose={() => props.onCompleteValidationDialog!(false)}
                InProgress={props.completeValidationInProgress!}
            />
        </div>
    );
}

export default DotLegalStepper;

import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { star } from "../../common/icons";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import { useSystemTemplateDialogStyles } from "./SystemTemplatesDialog.styles";
import DotLegalTemplateDialog from "../../common/components/dotLegalTemplateDialog/DotLegalTemplateDialog";
import { useSystemTempaltesDialogHook } from "./SystemTemplatesDialog.hooks";
import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import { DotLegalCheckbox, DotLegalInformationTooltip, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalInformationBox from "../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { Trans } from "react-i18next";
import { SystemTemplateViewModel } from "./SystemTemplatesDialog.types";
import { SystemTemplates } from "../../auth/planProvider/PlanProvider.types";

export interface ISystemTemplasDialogProps {
    onCloseDialog: () => void;
    showCompaniesExistingDialog: (companies: Array<string>) => void;
}

function SystemTemplatesDialog(props: ISystemTemplasDialogProps) {
    const styles = useSystemTemplateDialogStyles();
    const { translateString } = useTranslation();
    const { systemPlan } = usePlanContext();
    const {
        isLoading,
        systemTypesData,
        systemTypeTemplates,
        mostPopularTemplates,
        setSelectedTemplateType,
        selectedTemplates,
        handleTemplateChange,
        addAllTemplatesClick,
        postTemplates,
        isSaving,
        numberOfSystems,
    } = useSystemTempaltesDialogHook(props);

    const getContent = () => {
        var tabContent = Array<TabContent>();
        tabContent.push({ label: translateString("mostPopular"), content: getMostPopularContent(), index: 0, icon: star });
        tabContent.push({
            label: translateString("types"),
            content: getSystemTypeTemplates(),
            index: 1,
        });
        return tabContent;
    };

    const systemLimitReached = systemPlan.maxNumberOfSystems && numberOfSystems !== undefined ? numberOfSystems >= systemPlan.maxNumberOfSystems : false;

    const getTemplateCheckBox = (template: SystemTemplateViewModel | undefined, isDisabled: boolean) => {
        if (template) {
            var templateIndex = selectedTemplates.indexOf(template.id);
            var isChecked = templateIndex >= 0;

            const checkBox = (
                <DotLegalCheckbox
                    label={template.name}
                    checked={isChecked}
                    fontSize={"s"}
                    onChange={() => handleTemplateChange(!isChecked, template.id, templateIndex)}
                    disabled={isDisabled}
                />
            );

            if (isDisabled) {
                return (
                    <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                        <span>{checkBox}</span>
                    </DotLegalTooltip>
                );
            }

            return checkBox;
        }

        return undefined;
    };

    const getTemplateToolTip = (templateDescription?: string) => {
        if (templateDescription)
            return (
                <Box sx={{ marginLeft: 1 }}>
                    <DotLegalInformationTooltip text={templateDescription} />
                </Box>
            );

        return undefined;
    };

    const getSystemTypeTemplates = () => {
        let allTempaltes: Array<string> = [];
        allTempaltes =
            systemTypeTemplates?.map((template) => {
                return template.id;
            }) ?? [];
        return (
            <Box sx={styles.container}>
                <Box sx={styles.gridContainer}>
                    <Typography sx={styles.gridContainerHeader}>{translateString("filterSystemTemplatesBySystemType")}</Typography>
                    <Grid container spacing={2}>
                        {systemTypesData
                            ?.filter((x) => x.hasData)
                            .map((systemType) => {
                                let hasTemplates = systemType.templateCount && systemType.templateCount > 0;
                                return (
                                    <Grid key={systemType.id} item xs={12} md={4} lg={3}>
                                        <DotLegalInformationBox
                                            header={`${systemType.name} ${hasTemplates ? `(${systemType.templateCount})` : ""}`}
                                            isSelected={systemType.isSelected ? "selected" : hasTemplates ? "secondarySelected" : "unselected"}
                                            size="small"
                                            isLoading={isLoading}
                                            onChange={() => setSelectedTemplateType(systemType.id)}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Box>

                {systemTypeTemplates !== undefined && systemTypeTemplates.length > 0 && (
                    <React.Fragment>
                        <Box sx={styles.templateHeaderContainer}>
                            <Typography sx={styles.gridContainerHeader}>{translateString("filterSystemTemplatesBySystemType")}</Typography>
                            {systemPlan.templates !== SystemTemplates.MostPopular &&
                                allTempaltes &&
                                allTempaltes.length !== allTempaltes.filter((x) => selectedTemplates.indexOf(x) >= 0).length && (
                                    <Typography sx={styles.chooseAll} onClick={() => addAllTemplatesClick(allTempaltes)}>
                                        {translateString("chooseAll")}
                                    </Typography>
                                )}
                        </Box>
                        <Grid container spacing={2}>
                            {systemTypeTemplates?.map((template) => {
                                let isDisabled = systemPlan.templates === SystemTemplates.MostPopular;
                                return (
                                    <Grid sx={styles.templateSelectorContainer} key={template.id} item xs={12} md={4} lg={3}>
                                        {getTemplateCheckBox(template, isDisabled)}
                                        {getTemplateToolTip(template?.description)}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </React.Fragment>
                )}
            </Box>
        );
    };

    const getMostPopularContent = () => {
        let allPopularTemplates = mostPopularTemplates?.map((x) => x.id) ?? [];

        return (
            <Box sx={styles.gridContainer}>
                <Box sx={styles.templateHeaderContainer}>
                    <Typography sx={styles.gridContainerHeader}>{translateString("chooseSystemTemplatesMostPopular")}</Typography>
                    {systemPlan.templates !== SystemTemplates.MostPopular &&
                        allPopularTemplates &&
                        allPopularTemplates.length !== allPopularTemplates.filter((x) => selectedTemplates.indexOf(x) >= 0).length && (
                            <Typography onClick={() => addAllTemplatesClick(allPopularTemplates)} sx={styles.chooseAll}>
                                {" "}
                                {translateString("chooseAll")}{" "}
                            </Typography>
                        )}
                </Box>

                <Grid container spacing={1}>
                    {mostPopularTemplates?.map((template) => {
                        const templateIndex = selectedTemplates.indexOf(template!.id);
                        const isChecked = templateIndex >= 0;
                        const disabled = systemLimitReached && !isChecked;
                        return (
                            <Grid sx={styles.templateSelectorContainer} key={template.id} item xs={12} md={4} lg={3}>
                                {getTemplateCheckBox(template, disabled)}
                                {getTemplateToolTip(template?.description)}
                            </Grid>
                        );
                    })}
                </Grid>

                {systemLimitReached && (
                    <Alert severity="error" sx={(theme) => ({ "& .MuiSvgIcon-root": { fill: theme.palette.warning.main }, marginTop: 3 })}>
                        <AlertTitle>{translateString("warning")}</AlertTitle>
                        <Trans i18nKey="systemLimitReached" values={{ count: systemPlan.maxNumberOfSystems }}></Trans>
                    </Alert>
                )}
            </Box>
        );
    };

    return (
        <DotLegalTemplateDialog
            tabContent={getContent()}
            buttonOkText={`${translateString("load")} ${selectedTemplates.length > 0 ? `(${selectedTemplates.length})` : ""}`}
            templateCount={selectedTemplates.length}
            header={translateString("addStandardSystem")}
            open
            isLoading={isLoading}
            inProgress={isSaving}
            onDialogClose={props.onCloseDialog}
            onOkClick={() => postTemplates()}
        />
    );
}

export default SystemTemplatesDialog;

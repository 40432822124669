import React from "react";
import { AnnualWheelBaseInformationFormModel } from "./AnnualWheelBaseInformationForm.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useAnnualWheelDialogStyles } from "../AnnualWheelDialog.styles";
import { useAnnualWheelBaseInformation } from "./AnnualWheelBaseInformationForm.hooks";
import {
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalRadioButton,
    DotLegalSelect,
    DotLegalTextField,
    DotLegalTooltip,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import { Box, RadioGroup } from "@mui/material";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import PurpleLockSvg from "../../../common/svgs/purpleLock.svg?react";

export interface AnnaulWheelBaseInformationProps {
    saveModel: AnnualWheelBaseInformationFormModel | undefined;
    onChange: (saveModel: AnnualWheelBaseInformationFormModel) => void;
    isProcessingActivityValidationActivity: boolean;
    activityId?: string;
}

function AnnaulWheelBaseInformation(props: AnnaulWheelBaseInformationProps) {
    const { translateString } = useTranslation();
    const { taskManagementAndAnnualWheelPlan } = usePlanContext();
    const {
        onPriorityChange,
        onCustomIdChange,
        onDocumentationRequiredChange,
        onAreaChange,
        onRelatedActivitiesChange,
        priorityOptions,
        selectableAreas,
        selectableAreasLoading,
        selectableActivities,
        selectableActivitiesLoading,
    } = useAnnualWheelBaseInformation(props);
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);

    function getRelatedActivities() {
        const multiField = (
            <DotLegalMultiSelect
                options={selectableActivities}
                chosenOptions={props.saveModel?.relatedActivities ?? []}
                isLoading={selectableActivitiesLoading}
                disableClearable
                onChange={(activities) => onRelatedActivitiesChange(activities)}
                placeholder={""}
                label={""}
                noMargin
                noOptionsLabel={translateString("noOptions")}
                disabled={!taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks}
                icon={!taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks ? <PurpleLockSvg /> : undefined}
                alwaysOpenInBottom
                listMaxHeight={190}
            />
        );

        if (taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks) {
            return multiField;
        }

        return (
            <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                <span>{multiField}</span>
            </DotLegalTooltip>
        );
    }

    return (
        <Box sx={styles.formSpacing}>
            <DotLegalHeader headerStyle={"small"} marginTop={2} marginBottom={0.5}>
                {translateString("annualWheelSetPriority")}
            </DotLegalHeader>

            <DotLegalSelect
                options={priorityOptions()}
                selectedItem={props.saveModel?.priority?.toString() ?? ""}
                disableClearable
                onChange={(priority) => onPriorityChange(priority)}
                placeholder={""}
                label={""}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5} toolTip={translateString("customIdToolTip")}>
                {translateString("annualWheelCustomIdHeader")}
            </DotLegalHeader>

            <DotLegalTextField label={""} value={props.saveModel?.customId ?? null} debounce={false} noMargin onChange={(id) => onCustomIdChange(id)} />

            {!props.isProcessingActivityValidationActivity && (
                <>
                    {taskManagementAndAnnualWheelPlan.annualWheelAreas && (
                        <React.Fragment>
                            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5} toolTip={translateString("complianceAreaToolTip")}>
                                {translateString("areasForAnnualWheelTask")}
                            </DotLegalHeader>

                            <DotLegalMultiSelect
                                label={""}
                                options={selectableAreas}
                                isLoading={selectableAreasLoading}
                                chosenOptions={props.saveModel?.areas ?? []}
                                placeholder={""}
                                onChange={(areas) => onAreaChange(areas)}
                                noMargin
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </React.Fragment>
                    )}

                    <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5} toolTip={translateString("relatedActivitiesTooltip")}>
                        {translateString("annualWheelRelatedActivities")}
                    </DotLegalHeader>

                    {getRelatedActivities()}

                    <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                        {translateString("documentationRequiredForTaskCompletion")}
                    </DotLegalHeader>
                    <Box sx={styles.content}>
                        <RadioGroup row value={props.saveModel?.documentationRequired ? 1 : 0} onChange={(e) => onDocumentationRequiredChange(e)}>
                            <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("yes")} />
                            <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("no")} />
                        </RadioGroup>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default AnnaulWheelBaseInformation;

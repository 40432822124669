import { DotLegalDialog, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";
import { QuestionnaireSelectableItem } from "../../legalEntityAuditTab/LegalEntityAudit.types";
import { apiUrls } from "../../../common/api/apiUrls";
import DotLegalMultiSelectApi from "../../../common/components/dotLegalMultiSelectApi/DotLegalMultiSelectApi";
import useLegalEntityClassificationDialogHooks from "./LegalEntityClassificationDialog.hooks";
import React from "react";
import DotLegalSelectApi from "../../../common/components/dotLegalSelectApi/DotLegalSelectApi";

export interface ILegalEntityClassificationDialog {
    legalEntityId: string;
    onDialogClose: () => void;
    onCreated: () => void;
}

function LegalEntityClassificationDialog(props: ILegalEntityClassificationDialog) {
    const { translateString } = useTranslation();
    const { onQuestionnaireChanged, saveModel, validation, onSaveClick, onSystemChange, isSaving } = useLegalEntityClassificationDialogHooks(props);

    validation.check(saveModel);

    return (
        <DotLegalDialog
            size={"sm"}
            header={translateString("addClassification")}
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onSaveClick()}
            inProgress={isSaving}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <React.Fragment>
                <Box ml={2} mr={2}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <DotLegalHeader center fontWeight={400} headerStyle={"small"}>
                            {translateString("addClassificationInfo")}
                        </DotLegalHeader>
                    </Box>

                    <DotLegalMultiSelectApi<QuestionnaireSelectableItem>
                        url={apiUrls().vendorClassifications.selectableItems}
                        onChange={onQuestionnaireChanged}
                        label={translateString("classificationTemplate")}
                        noOptionsLabel={translateString("noOptions")}
                        chosenOptions={saveModel?.questionnaireIds ?? []}
                        errorText={validation.getErrorsForDisplay("questionnaireIds")}
                    />
                    <DotLegalSelectApi
                        url={apiUrls().systems.selectableItemsByLegalEntityId(props.legalEntityId)}
                        onChange={onSystemChange}
                        label={translateString("assetOptional")}
                        noOptionsLabel={translateString("noOptions")}
                        selectedItem={saveModel?.systemId}
                    />
                </Box>
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default LegalEntityClassificationDialog;

import React from "react";
import StepSegment from "../../../../processingActivity/stepSegment/StepSegment";
import { DotLegalMultiSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import useLegalEntityAreaAndCertificationsStepHook from "./LegalEntityAreaAndCertificationsStep.hooks";

export interface ILegalEntityAreaAndCertificationsStep {
    onCertificationChange: (certifications: Array<string>) => void;
    onComplianceAreaChange: (complianceAreas: Array<string>) => void;
    certificationIds: Array<string>;
    complianceAreaIds: Array<string>;
}

function LegalEntityAreaAndCertificationsStep(props: ILegalEntityAreaAndCertificationsStep) {
    const { translateString } = useTranslation();

    const { certifications, certificationsIsLoading, complianceAreas, complianceAreasIsLoading } = useLegalEntityAreaAndCertificationsStepHook();

    return (
        <React.Fragment>
            <StepSegment size="small">
                <DotLegalMultiSelect
                    options={complianceAreas}
                    chosenOptions={props.complianceAreaIds}
                    onChange={props.onComplianceAreaChange}
                    label={translateString("areas")}
                    noOptionsLabel={translateString("noOptions")}
                    isLoading={complianceAreasIsLoading}
                ></DotLegalMultiSelect>
                <DotLegalMultiSelect
                    options={certifications}
                    chosenOptions={props.certificationIds}
                    onChange={props.onCertificationChange}
                    label={translateString("certifications")}
                    noOptionsLabel={translateString("noOptions")}
                    isLoading={certificationsIsLoading}
                ></DotLegalMultiSelect>
            </StepSegment>
        </React.Fragment>
    );
}

export default LegalEntityAreaAndCertificationsStep;

import { Badge, Box, Fab } from "@mui/material";
import { useCommentButtonStyle } from "./CommentButton.styles";
import CommentIcon from "./commentIcon.svg?react";

export interface ICommentButton {
    onButtonClick: () => void;
    id: string;
    unreadComments?: Number;
}

function CommentButton(props: ICommentButton) {
    const styles = useCommentButtonStyle();

    return (
        <Box sx={styles.container}>
            <Fab onClick={() => props.onButtonClick()} color="primary" aria-label="add">
                <Badge badgeContent={props.unreadComments ? <>{props.unreadComments}</> : undefined} color={"error"}>
                    <CommentIcon />
                </Badge>
            </Fab>
        </Box>
    );
}

export default CommentButton;

import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalButton, DotLegalOverflowMenu, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import NoSharings from "../../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ProcessingActivityDocumentViewModel } from "./ProcessingActivityDocumentTab.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import useProcessingActivityDocumentTabHooks from "./ProcessingActivityDocumentTab.hooks";
import ProcessingActivityDocumentDialog from "./processingActivityDocumentDialog/ProcessingActivityDocumentDialog";
import ProcessingActivityDeleteDocumentDialog from "./processingActivityDeleteDocumentDialog/ProcessingActivityDeleteDocumentDialog";

export interface ProcessingActivityDocumentTab {
    processingActivityId?: string;
}

function ProcessingActivityDocumentTab(props: ProcessingActivityDocumentTab) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        downloadDocumentVersion,
        data,
        hasDocuments,
        isLoading,
        setSelectedDocument,
        setShowDeleteDialog,
        setShowDocumentDialog,
        showDocumentDialog,
        refetch,
        selectedDocument,
        showDeleteDialog,
    } = useProcessingActivityDocumentTabHooks(props);

    function getMenuItems(document: ProcessingActivityDocumentViewModel) {
        let menuItems = [];

        if (permissions.processingActivityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDocument(document);
                    setShowDocumentDialog(true);
                },
            });
        }

        if (isNullOrWhitespace(document.fileName)) {
            menuItems.push({
                menuItem: translateString("openLink"),
                onClick: () => window.open(document.link!, "_blank"),
            });
        } else {
            menuItems.push({
                menuItem: translateString("download"),
                onClick: () => downloadDocumentVersion(document.documentId, document.dataId!, document.fileName!),
            });
        }

        if (permissions.processingActivityPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedDocument(document);
                    setShowDeleteDialog(true);
                },
            });
        }

        return menuItems;
    }

    return (
        <React.Fragment>
            <DotLegalPaper>
                {hasDocuments ? (
                    <DotLegalTableWithControls
                        extraControls={undefined}
                        headers={getHeaders()}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        data={data}
                        clickableRows={false}
                        emptyText={translateString("noSharingsMatchesQuery")}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        renderRow={(row, i) => (
                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.fileName ?? row.link}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <DotLegalEmptyState icon={<NoSharings />} text={translateString("noDocumentsAttachedToLegalEntity")} />
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {permissions.processingActivityPermissions.edit && (
                        <DotLegalButton
                            buttonType={"primary"}
                            onClick={() => {
                                setSelectedDocument(undefined);
                                setShowDocumentDialog(true);
                            }}
                        >
                            {translateString("createNew")}
                        </DotLegalButton>
                    )}
                </Box>
            </DotLegalPaper>

            {showDocumentDialog && (
                <ProcessingActivityDocumentDialog
                    onDialogClose={() => setShowDocumentDialog(false)}
                    onSave={() => {
                        refetch();
                        setShowDocumentDialog(false);
                    }}
                    selectedDocument={selectedDocument}
                />
            )}

            {showDeleteDialog && (
                <ProcessingActivityDeleteDocumentDialog
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeletedDocument={() => {
                        refetch();
                        setSelectedDocument(undefined);
                    }}
                    selectedDocument={selectedDocument!}
                    processingActivityId={props.processingActivityId!}
                />
            )}
        </React.Fragment>
    );

    function getHeaders() {
        const headers = Array<ITableHeader<ProcessingActivityDocumentViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
        return headers;
    }
}

export default ProcessingActivityDocumentTab;

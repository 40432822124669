import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { PlanContext } from "./PlanProvider.types";
import { usePlanContext } from "./PlanProvider";

const UserCountUrl = "/plan/usercount";
const ProcessingActivityCountURL = "/plan/processingActivityCount";
const SystemCountUrl = "/plan/systemCount";
const PolicyCountUrl = "/plan/policyCount";
const GdprIncidentLogCountUrl = "/plan/gdprIncidentLogCount";
const DeclarationCountUrl = "/plan/declarationCount";

export function PlanProviderDataMapping() {
    const url = "/plan/";
    let oneHour = 3600000;
    const query = useQuery(url, () => get<PlanContext>(url), { staleTime: oneHour });

    return { data: query.data, isLoading: query.isLoading };
}

//Users
function DoFetchUsersCount() {
    const { technicalPlan } = usePlanContext();

    if (technicalPlan.maxNumberOfUsers) {
        return true;
    }

    return false;
}

export function IsUsersLimitReached() {
    const { technicalPlan } = usePlanContext();

    const fetchData = DoFetchUsersCount();

    const { data } = useQuery(UserCountUrl, () => get<number>(UserCountUrl), { enabled: fetchData });

    if (fetchData) {
        if (data !== undefined) {
            return data >= technicalPlan.maxNumberOfUsers!;
        }

        return undefined;
    }

    return false;
}

export function RefetchUserCountQuery() {
    const fetchData = DoFetchUsersCount();

    const { refetch } = useQuery(UserCountUrl, () => get<number>(UserCountUrl), { enabled: fetchData });

    return refetch;
}

//Processing Activities
function DoFetchProcessingActivityCount() {
    const { processingActivityAndRecordsPlan } = usePlanContext();

    if (processingActivityAndRecordsPlan.maxNumberOfActivities) {
        return true;
    }

    return false;
}

export function GetNumberOfProcessingActivities() {
    const fetchData = DoFetchProcessingActivityCount();

    const { data } = useQuery(ProcessingActivityCountURL, () => get<number>(ProcessingActivityCountURL), { enabled: fetchData });

    return data;
}

export function IsProcessingActivityLimitReached() {
    const { processingActivityAndRecordsPlan } = usePlanContext();

    let fetchData = DoFetchProcessingActivityCount();

    const data = GetNumberOfProcessingActivities();

    if (fetchData) {
        if (data !== undefined) {
            return data >= processingActivityAndRecordsPlan.maxNumberOfActivities!;
        }

        return undefined;
    }

    return false;
}

export function RefetchProcessingActivityCountQuery() {
    const fetchData = DoFetchProcessingActivityCount();

    const { refetch } = useQuery(ProcessingActivityCountURL, () => get<number>(ProcessingActivityCountURL), { enabled: fetchData });

    return refetch;
}

//Systems
function DoFetchSystemCount() {
    const { systemPlan } = usePlanContext();

    if (systemPlan.maxNumberOfSystems) {
        return true;
    }

    return false;
}

export function GetNumberOfSystems() {
    const fetchData = DoFetchSystemCount();

    const { data } = useQuery(SystemCountUrl, () => get<number>(SystemCountUrl), { enabled: fetchData });

    return data;
}

export function IsSystemLimitReached() {
    const { systemPlan } = usePlanContext();

    let fetchData = DoFetchSystemCount();

    const data = GetNumberOfSystems();

    if (fetchData) {
        if (data !== undefined) {
            return data >= systemPlan.maxNumberOfSystems!;
        }

        return undefined;
    }

    return false;
}

export function RefetchSystemCountQuery() {
    const fetchData = DoFetchSystemCount();

    const { refetch } = useQuery(SystemCountUrl, () => get<number>(SystemCountUrl), { enabled: fetchData });

    return refetch;
}

//Policies
function DoFetchPolicyCount() {
    const { proceduresAndPoliciesPlan } = usePlanContext();

    if (proceduresAndPoliciesPlan.maxNumberOfPoliciesAndProcedures) {
        return true;
    }

    return false;
}

export function GetNumberOfPolicies() {
    const fetchData = DoFetchPolicyCount();

    const { data } = useQuery(PolicyCountUrl, () => get<number>(PolicyCountUrl), { enabled: fetchData });

    return data;
}

export function IsPolicyLimitReached() {
    const { proceduresAndPoliciesPlan } = usePlanContext();

    let fetchData = DoFetchPolicyCount();

    const data = GetNumberOfPolicies();

    if (fetchData) {
        if (data !== undefined) {
            return data >= proceduresAndPoliciesPlan.maxNumberOfPoliciesAndProcedures!;
        }

        return undefined;
    }

    return false;
}

export function RefetchPolicyCountQuery() {
    const fetchData = DoFetchPolicyCount();

    const { refetch } = useQuery(PolicyCountUrl, () => get<number>(PolicyCountUrl), { enabled: fetchData });

    return refetch;
}

//Incident logs

function DoFetchGdprIncidentLogCount() {
    const { incidentLogPlan } = usePlanContext();

    if (incidentLogPlan.gdprMaxNumberOfIncidents) {
        return true;
    }

    return false;
}

export function GetNumberOfGdprIncidents(fetchData: boolean) {
    const { data } = GdprIncidentLogCountQuery(fetchData);
    return data;
}

export function IsGdprIncidentLogLimitReached() {
    const { incidentLogPlan } = usePlanContext();

    let fetchData = DoFetchGdprIncidentLogCount();

    if (fetchData) {
        const data = GetNumberOfGdprIncidents(fetchData);
        if (data !== undefined) {
            return data >= incidentLogPlan.gdprMaxNumberOfIncidents!;
        }

        return undefined;
    }

    return false;
}

export function RefetchNumberOfGdprIncidents() {
    let fetchData = DoFetchGdprIncidentLogCount();
    const { refetch } = GdprIncidentLogCountQuery(fetchData);
    return refetch;
}

const GdprIncidentLogCountQuery = (fetchData: boolean) => {
    const { data, refetch } = useQuery(GdprIncidentLogCountUrl, () => get<number>(GdprIncidentLogCountUrl), { enabled: fetchData });
    return { data, refetch };
};

// Declarations

function DoFetchDeclarationsCount() {
    const { declarationPlan } = usePlanContext();
    if (declarationPlan.maxNumberOfDeclaration) {
        return true;
    }

    return false;
}

export function GetNumberOfDeclarations(fetchData: boolean) {
    const { data } = DeclarationsCountQuery(fetchData);
    return data;
}

export function IsNumberOfDeclarationsReached() {
    const { declarationPlan } = usePlanContext();

    let fetchData = DoFetchDeclarationsCount();

    if (fetchData) {
        const data = GetNumberOfDeclarations(fetchData);
        if (data !== undefined) {
            return data >= declarationPlan.maxNumberOfDeclaration!;
        }

        return undefined;
    }

    return false;
}

export function RefetchNumberOfDeclarations() {
    let fetchData = DoFetchDeclarationsCount();
    const { refetch } = DeclarationsCountQuery(fetchData);
    return refetch;
}

const DeclarationsCountQuery = (fetchData: boolean) => {
    const { data, refetch } = useQuery(DeclarationCountUrl, () => get<number>(DeclarationCountUrl), { enabled: fetchData });
    return { data, refetch };
};

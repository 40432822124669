import React from "react";
import { DotLegalPrettyScrollbar, DotLegalTable } from "@dotlegal/dotlegal-ui-components";
import { ITableHeader } from "../../common/components/dotLegalTable/DotLegalTable.types";
import { Box, TableCell, TableRow } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { LegalEntitySelectorViewModel } from "../LegalEntity.types";
import { useTranslation } from "../../localization/useTranslation";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { DotLegalSearchField } from "@dotlegal/dotlegal-ui-components";
import DotLegalWarningTriangle from "../../common/components/dotLegalWarningTriangle/DotLegalWarningTriangle";
import { useLegalEntitySelector } from "./LegalEntitySelector.hooks";

export interface ILegalEntitySelector {
    selectedEntities: Array<string>;
    setSelectedEntities: (selectedEntities: Array<string>) => void;
    legalEntityItems?: Array<LegalEntitySelectorViewModel>;
    isLoading: boolean;
    allColumns: boolean;
    onGridChange?: (gridlength: number | string | null) => void;
    gridlength?: number;
    hideGridSize?: boolean;
    marginTop?: number;
    disableSelectAll?: boolean;
}

export default function LegalEntitySelector(props: ILegalEntitySelector) {
    const { translateString, translateCountry } = useTranslation();

    const { selectOptions, searchString, setSearchString, onSelectAllEntities, onCheckboxClick, setHeaderCheckBoxState, isChecked } =
        useLegalEntitySelector(props);

    const getHeaders = () => {
        let headers = Array<ITableHeader<LegalEntitySelectorViewModel>>();

        if (props.disableSelectAll) {
            headers.push({ property: "id", text: "", align: "left", showOnMobile: true });
        }

        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });

        if (props.allColumns) {
            headers.push({ property: "address", text: translateString("address"), align: "left", showOnMobile: true });
            headers.push({ property: "zipCode", text: translateString("zipCode"), align: "left", showOnMobile: true });
            headers.push({ property: "city", text: translateString("city"), align: "left", showOnMobile: true });
        }
        headers.push({ property: "country", text: translateString("country"), align: "left", showOnMobile: true });

        return headers;
    };

    return (
        <>
            <Box marginBottom={1} display="flex" justifyContent="space-between" alignItems={"center"} flexDirection={"row-reverse"}>
                <DotLegalSearchField labelSearch={translateString("search")} value={searchString} onChange={(e) => setSearchString(e)} />
                {!props.hideGridSize && (
                    <DotLegalSelect
                        width={"100px"}
                        label={translateString("showEntites")}
                        placeholder={translateString("showEntites")}
                        disableClearable
                        options={selectOptions}
                        noMargin
                        onChange={(e) => (props.onGridChange ? props.onGridChange(e!) : {})}
                        selectedItem={props.gridlength ? props.gridlength.toString() : "10"}
                        noOptionsLabel={translateString("noOptions")}
                    />
                )}
            </Box>
            <DotLegalPrettyScrollbar>
                <Box sx={{ maxHeight: 300 }}>
                    <DotLegalTable
                        marginTop={props.marginTop}
                        searchString={searchString}
                        emptyText={translateString("noData")}
                        headers={getHeaders()}
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={props.isLoading}
                        data={props.legalEntityItems}
                        rowsPerPage={props.gridlength}
                        onSelectAllChanged={props.disableSelectAll ? undefined : onSelectAllEntities}
                        headerCheckBoxState={props.disableSelectAll ? undefined : setHeaderCheckBoxState()}
                        paginationLabelOf={translateString("labelOf")}
                        renderRow={(row, i) => (
                            <TableRow key={i} onClick={() => onCheckboxClick(row)}>
                                <TableCell>
                                    <CheckBox checked={isChecked(row.id)} />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex" }}>
                                        <Box sx={{ marginTop: "2px" }}>{row.name}</Box>{" "}
                                        {!row.isActive && <DotLegalWarningTriangle warningText={translateString("companyInActive")} />}
                                    </Box>
                                </TableCell>
                                {props.allColumns && (
                                    <React.Fragment>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell>{row.zipCode}</TableCell>
                                        <TableCell>{row.city}</TableCell>
                                    </React.Fragment>
                                )}
                                <TableCell>{translateCountry(row.country)}</TableCell>
                            </TableRow>
                        )}
                    />
                </Box>
            </DotLegalPrettyScrollbar>
        </>
    );
}

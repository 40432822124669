export interface System {
    // THE system der kommer fra backend og som vælges via dropdown.
    id: string;
    name: string;
    securityMeasureNames: Array<string>;
    systemType: SystemType;
    responsibleName: string;
    dataProcessorName: string;
    dataProcessors: Array<string>;
    generateSharings: boolean;
    dataProcessorId: string;
    subDataProcessors: Array<SubProcessorViewModel>;
    subProcessors: Array<SubProcessorViewModel>;
    transferBasisId: string;
    dataProcessingAgreementIds: Array<string>;
    hasDataProcessingAgreement: boolean;
    transferImpactAssessmentId: string;
    hasTransferImpactAssessment: boolean;
}

export interface SubProcessorViewModel {
    id: string;
    legalEntityId: string;
    transferBasisId: string;
    hasDataProcessorAgreement: boolean;
    transferImpactAssessmentId: string;
    hasTransferImpactAssessment: boolean;
    isLink: boolean;
    link: string | undefined;
}

export enum SystemType {
    GlobalSystem,
    LocalSystem,
    PhysicalArchive,
}

export interface ProcessingActivitySystemUpdateModel {
    systemId: string;
    dataCategories: Array<string>;
    groupEntities: Array<string>;
    processingActivitySystemSubProcessorUpdateModel: Array<ProcessingActivitySystemSubProcessorUpdateModel>;
}

export interface ProcessingActivitySystemSubProcessorUpdateModel {
    systemSubDataProcessorId: string;
    dataCategoryIds: Array<string>;
}

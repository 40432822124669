import { Box, TableCell, TableRow } from "@mui/material";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../../../common/components/dotLegalTable/DotLegalTable.types";
import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { PurposeViewModel } from "./Purposes.types";
import CheckIcon from "@mui/icons-material/Check";
import { usePurposesStyles } from "./Purposes.styles";
import { usePurposesDataMapping } from "./Purposes.hooks";
import PurposeDialog from "../purposeDialog/PurposeDialog";
import { DeletePurposeDialog } from "../deletePurposeDialog/DeletePurposeDialog";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ChangePurposeStatusDialog } from "../changePurposeStatusDialog/ChangePurposeStatusDialog";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";

function Purposes() {
    const styles = usePurposesStyles();
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        data,
        setShowAllPurposes,
        showAllPurposes,
        showPurposeDialog,
        setShowPurposeDialog,
        showDeletePurposeDialog,
        setShowDeletePurposeDialog,
        OnSave,
        selectedPurpose,
        setSelectedPurpose,
        setShowChangeStausDialog,
        showChangeStatusDialog,
    } = usePurposesDataMapping();

    const getMenuItems = (purpose: PurposeViewModel) => {
        let menuItems = [];

        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedPurpose({
                        name: purpose.name,
                        description: purpose.description,
                        id: purpose.id,
                        active: purpose.active,
                        isCustom: purpose.isCustom,
                    });
                    setShowPurposeDialog(true);
                },
            });

            menuItems.push({
                menuItem: purpose.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedPurpose({
                        name: purpose.name,
                        description: purpose.description,
                        id: purpose.id,
                        active: purpose.active,
                        isCustom: purpose.isCustom,
                    });
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && purpose.isCustom) {
            menuItems.push({
                menuItem: translateString("removePurpose"),
                onClick: () => {
                    setSelectedPurpose({
                        name: purpose.name,
                        description: purpose.description,
                        id: purpose.id,
                        active: purpose.active,
                        isCustom: purpose.isCustom,
                    });
                    setShowDeletePurposeDialog(true);
                },
            });
        }

        return menuItems;
    };

    const openCreateDialog = () => {
        setSelectedPurpose(undefined);
        setShowPurposeDialog(true);
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("purposes"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllPurposes}
                        enabled={showAllPurposes}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createPurpose")}
                    onOpenDialog={openCreateDialog}
                    showDialog={showPurposeDialog}
                    icon={getMasterIcon()}
                >
                    {showPurposeDialog && <PurposeDialog onHideDialog={() => setShowPurposeDialog(false)} onSave={OnSave} selectedPurpose={selectedPurpose} />}
                </DotLegalActionBar>
            </div>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </DotLegalPaper>
            </Box>

            {showDeletePurposeDialog && (
                <DeletePurposeDialog selectedPurpose={selectedPurpose!} onSaveCorrect={OnSave} onCloseDialog={() => setShowDeletePurposeDialog(false)} />
            )}

            {showChangeStatusDialog && (
                <ChangePurposeStatusDialog selectedPurpose={selectedPurpose!} refetchPurposes={OnSave} onCloseDialog={() => setShowChangeStausDialog(false)} />
            )}
        </Box>
    );
}

export default Purposes;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<PurposeViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}

import { QuestionCategory, QuestionCategoryQuestions, QuestionType, ScenarioType } from "../RiskAssessment.types";

export interface RiskAssessmentTemplateViewModel {
    questions: Array<RiskAssessmentTemplateItemViewModel>;
    customQuestions: Array<RiskAssessmentTemplateItemViewModel>;
    scenarios: Array<RiskAssessmentTemplateItemViewModel>;
}

export enum RiskAssessmentTemplateItemType {
    Consequence = 1,
    Probability = 2,
    Scenario = 3,
    ProbabilityCustomQuestion = 4,
}

export class RiskAssessmentTemplateItemViewModel {
    constructor(itemType: RiskAssessmentTemplateItemType, name: string, scenarioType?: ScenarioType, questionType?: QuestionType) {
        this.itemType = itemType;
        this.scenarioType = scenarioType;
        this.questionType = questionType;
        this.name = name;
    }
    itemType: RiskAssessmentTemplateItemType;
    scenarioType?: ScenarioType;
    customQuestionId?: string;
    questionType?: QuestionType;
    name: string;
    selected: boolean = false;
    score: number | null = null;
    probabilityScore: number | null = null;
    consequenceScore: number | null = null;
    businessAreas: Array<string> = [];
    inUse: boolean = false;

    itemId(): number {
        return this.itemType === RiskAssessmentTemplateItemType.Scenario ? this.scenarioType! : this.questionType!;
    }
    key(): string {
        if (this.customQuestionId) return this.customQuestionId;
        return (this.itemType === RiskAssessmentTemplateItemType.Scenario ? this.scenarioType! : this.questionType!).toString();
    }
}

export function getQuestionCategoryLists(): Array<QuestionCategoryQuestions> {
    return Array<QuestionCategoryQuestions>(
        new QuestionCategoryQuestions(QuestionCategory.Confidentiality),
        new QuestionCategoryQuestions(QuestionCategory.Integrity),
        new QuestionCategoryQuestions(QuestionCategory.Availability),
        new QuestionCategoryQuestions(QuestionCategory.DataMinimizationAndStorageLimitation),
        new QuestionCategoryQuestions(QuestionCategory.Transparency),
        new QuestionCategoryQuestions(QuestionCategory.CustomQuestion)
    );
}

export interface RiskAssessmentTemplateApiModel {
    isLocked: boolean;
    scenarios: Array<RiskAssessmentTemplateScenarioApiModel>;
    questions: Array<RiskAssessmentTemplateQuestionApiModel>;
}

export interface RiskAssessmentTemplateScenarioApiModel {
    id?: string;
    scenarioType: ScenarioType;
    businessAreas: Array<string>;
    inUse: boolean;
}

export interface RiskAssessmentTemplateQuestionApiModel {
    id?: string;
    questionType: QuestionType;
    customQuestionId?: string;
    probabilityScore: number | null;
    consequenceScore: number | null;
    businessAreas: Array<string>;
    inUse: boolean;
}

export interface RiskAssessmentTemplateSaveModel {
    itemId: number;
    riskAssessmentTemplateItemType: RiskAssessmentTemplateItemType;
    score: number | null;
    customQuestionId?: string;
}

export interface RiskAssessmentTemplateBusinessAreaSaveModel {
    itemId: number;
    riskAssessmentTemplateItemType: RiskAssessmentTemplateItemType;
    businessAreaId: string;
    customQuestionId: string | null;
}

export interface RiskAssessmentTemplateBusinessAreaResetModel {
    itemId: number;
    riskAssessmentTemplateItemType: RiskAssessmentTemplateItemType;
    customQuestionId?: string;
}

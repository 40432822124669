import { DataSubjectDataCategory, ProcessingActivityModel } from "../ProcessingActivity.types";
import { DataCategoryViewModel } from "./DataCategoriesStep.types";
import { ValidationError } from "../../common/validationError";
import { useTranslation } from "../../localization/useTranslation";
import { useSelectableDataCategoriesQuery } from "../hooks/useSelectableDataCategoriesQuery";
import { useMutation } from "react-query";
import { deleteHttp, post } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useUpdateProcessingActivityModel } from "../hooks/useUpdateProcessingActivityModel";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useState } from "react";
import { useDataSubjectsQuery } from "../../common/hooks/useSelectableItemQueries";

export function useDataCategoriesStepHooks(
    processingActivityId: string,
    personDataCategories: Array<string>,
    dataSubjects: Array<string>,
    dataSubjectDataCategories: Array<DataSubjectDataCategory>
) {
    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    let dataSubjectsQuery = useDataSubjectsQuery();
    const addStandardDataCategoryMutation = useMutation(addStandardDataCategory);
    const removeStandardDataCategoryMutation = useMutation(removeStandardDataCategory);
    const updateProcessingActivityModel = useUpdateProcessingActivityModel(processingActivityId);
    const optimisticUpdate = useOptimisticUpdate();
    let dataCategoriesData: Array<DataCategoryViewModel> | undefined;
    let dataSubjectsOptions: Array<DotLegalSelectOption> = [];
    const [dataSubjectId, setDataSubjectId] = useState<string | null>(null);

    const getIsSelected = (id: string, dataSubject: string | null): boolean => {
        if (dataSubject) return dataSubjectDataCategories.filter((x) => x.dataSubjectId === dataSubject).find((x) => x.dataCategoryId === id) ? true : false;
        else return personDataCategories.find((x) => x === id) ? true : false;
    };

    if (dataCategoriesQuery.allDataCategories) {
        dataCategoriesData = dataCategoriesQuery.allDataCategories!.map((d) => {
            return {
                name: d.name,
                id: d.id,
                description: d.description,
                classification: d.classification,
                isSelected: getIsSelected(d.id, dataSubjectId),
            };
        });

        if (dataSubjectsQuery.data) {
            dataSubjectsOptions = dataSubjects.map((d) => {
                return {
                    name: dataSubjectsQuery.data!.find((x) => x.id === d)!.name,
                    id: d,
                };
            });
        }
    }

    async function onAddStandardDataCategory(id: string, dataSubjectId: string | null) {
        const newProcessingActivityModel = updateProcessingActivityModel.update((model) => {
            if (dataSubjectId !== null && dataSubjectId !== "all") model.dataSubjectDataCategories.push(new DataSubjectDataCategory(id, dataSubjectId));

            model.standardDataCategories.push(id);
        });
        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addStandardDataCategoryMutation.mutateAsync, {
            processingActivityId: newProcessingActivityModel.id,
            dataCategoryId: id,
            forDataSubjectId: dataSubjectId,
        });
    }

    async function onRemoveStandardDataCategory(id: string, dataSubjectId: string | null) {
        const newProcessingActivityModel = updateProcessingActivityModel.update((model) => {
            if (dataSubjectId !== null && dataSubjectId !== "all")
                model.dataSubjectDataCategories = model.dataSubjectDataCategories.filter((d) => d.dataCategoryId !== id);

            model.standardDataCategories = model.standardDataCategories.filter((d) => d !== id);
        });
        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeStandardDataCategoryMutation.mutateAsync, {
            processingActivityId: newProcessingActivityModel.id,
            dataCategoryId: id,
            forDataSubjectId: dataSubjectId,
        });
    }

    return {
        dataCategoriesData,
        isLoading: dataCategoriesQuery.isLoading,
        onAddStandardDataCategory,
        onRemoveStandardDataCategory,
        dataSubjectsOptions,
        isLoadingSubjects: dataSubjectsQuery.isLoading,
        dataSubjectId,
        setDataSubjectId,
    };
}

export function useValidateDataCategoriesStep() {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel): Array<ValidationError> => {
        const result: Array<ValidationError> = [];

        if (model.standardDataCategories.length === 0) {
            result.push(new ValidationError("DataCategories", translateString("dataCategoriesRequired")));
        }

        return result;
    };
}

async function addStandardDataCategory(data: { processingActivityId: string; dataCategoryId: string; forDataSubjectId: string | null }) {
    return await post<ProcessingActivityModel>("/processingactivity/" + data.processingActivityId + "/standardDatacategory", {
        dataCategoryId: data.dataCategoryId,
        forDataSubjectId: data.forDataSubjectId,
    });
}

async function removeStandardDataCategory(data: { processingActivityId: string; dataCategoryId: string; forDataSubjectId: string | null }) {
    return await deleteHttp<ProcessingActivityModel>(
        "/processingactivity/" + data.processingActivityId + "/standardDatacategory/" + data.dataCategoryId + "?forDataSubjectId=" + data.forDataSubjectId
    );
}

import { useCertificationsQuery, useComplianceAreaQuery } from "../../../../common/hooks/useSelectableItemQueries";

export default function useLegalEntityAreaAndCertificationsStepHook() {
    const complianceAreaQuery = useComplianceAreaQuery();
    const certificationsQuery = useCertificationsQuery();

    return {
        complianceAreas: complianceAreaQuery.data,
        complianceAreasIsLoading: complianceAreaQuery.isLoading,
        certifications: certificationsQuery.data,
        certificationsIsLoading: certificationsQuery.isLoading,
    };
}

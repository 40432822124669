import { useMutation, useQuery } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { LegalEntityAuditApiModel, LegalEntityAuditRowModel, LegalEntityAuditStatus } from "./LegalEntityAudit.types";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { downloadFile } from "../../common/api/downloadFile";
import { getSettings } from "../../common/settingsProvider";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { useUrlProvider } from "../../useUrlProvider";
import { Trans } from "react-i18next";
import { getQuestionnaireResponseScore } from "../../common/questionnaireResponseScore.ts";
import { setFirstLetterToLowerCase } from "../../common/stringOperations.ts";
import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";

export function useLegalEntityAuditTabDataMapping(legalEntityId: string | undefined, legalEntityName: string) {
    const url = `/legalEntityAudit/getAll/${legalEntityId}`;
    const { language } = useUserContext();
    let { isLoading, data: audits, refetch } = useQuery(url, () => get<Array<LegalEntityAuditApiModel>>(url));
    const [selectedAudit, setSelectedAudit] = useState<LegalEntityAuditApiModel | undefined>(undefined);

    const [showNote, setShowNote] = useState<string | undefined>(undefined);
    const snackbar = useDotLegalSnackbar();
    const { translateString, translateDate, translateLegalEntityAuditStatus } = useTranslation();
    const urlProvider = useUrlProvider();

    const [showDialog, setShowDialog] = useState(false);
    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [showAddToAuditGroup, setShowAddToAuditGroup] = useState<string | undefined>(undefined);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState("");

    const onSendAuditClick = () => {
        setShowDialog(true);
    };

    const saveNoteMutation = useMutation(submitSaveNote);
    function submitSaveNote(data: { note: string; auditId: string }) {
        return put(`/legalEntityAudit/${data.auditId}/note`, {
            note: data.note,
        });
    }

    const saveNote = (note: string, auditId: string) => {
        saveNoteMutation.mutateAsync(
            { note: note, auditId: auditId },
            {
                onSuccess: () => {
                    snackbar.show(translateString("noteUpdated"));
                    setShowNote(undefined);
                    refetch();
                },
            }
        );
    };

    const onAuditSent = () => {
        setShowDialog(false);
        refetch();
    };

    const onAuditClosed = () => {
        refetch();
    };

    const onAuditReopened = () => {
        refetch();
    };

    const onAuditDeleted = () => {
        refetch();
    };

    const onResponsibleChanged = () => {
        refetch();
    };

    const onContactChanged = () => {
        refetch();
    };

    const onReminderSent = () => {
        refetch();
    };

    const onAuditAddedToAuditGroup = () => {
        refetch();
        setShowAddToAuditGroup(undefined);
    };

    const onAuditEvaluated = () => {
        refetch();
    };

    const onRemindersToggled = () => {
        refetch();
    };

    const onAuditCopied = () => {
        refetch();
    };

    const onDownloadReportAsPdf = async (viewModel: LegalEntityAuditApiModel, isSimpleReport: boolean, hasRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = isSimpleReport ? getExternalPdfUrl(viewModel, true, hasRecommendations) : getReportPdfUrl(viewModel, true, hasRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);
        const fileName = `${viewModel.questionnaireName} - ${translateDate(viewModel.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf("");
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getReportPdfUrl(row: LegalEntityAuditApiModel, pdf: boolean, hasRecommendations: boolean) {
        return urlProvider.getQuestionnaireResponseReport(row.questionnaireResponseId, row.deadline, row.sender, legalEntityName, pdf, hasRecommendations);
    }

    function getExternalPdfUrl(row: LegalEntityAuditApiModel, pdf: boolean, hasRecommendations: boolean) {
        return urlProvider.getQuestionnaireResponseExternalreport(
            row.responseExternalId,
            row.deadline,
            row.sender,
            legalEntityName,
            undefined,
            pdf,
            hasRecommendations,
            false
        );
    }

    const auditRowModels: undefined | Array<LegalEntityAuditRowModel> = audits?.map((a) => {
        return {
            ...a,
            systemsString: a.assets.map((asset) => asset.name).join(", "),
            contactName: a.contact.name,
            responsibleName: a.responsible.name,
            auditGroupName: a.auditGroup?.name,
            statusName: translateLegalEntityAuditStatus(a.status.toString()),
            riskLevelName: a.scoreEnabled ? a.riskName : translateString("none"),
            scoreName: getQuestionnaireResponseScore(
                a.status === LegalEntityAuditStatus.Submitted,
                a.scoreEnabled,
                a.score,
                translateString("scoreNotEnabled")
            ),
            evaluationName: a.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[a.evaluationStatus].toString())) : "",
        };
    });

    return {
        onSendAuditClick,
        showDialog,
        setShowDialog,
        audits: auditRowModels,
        isLoading,
        showNote,
        setShowNote,
        saveNote,
        hasAudits: isLoading || (audits && audits.length > 0),
        onAuditSent,
        onAuditClosed,
        onAuditReopened,
        onAuditDeleted,
        onDownloadReportAsPdf,
        isDownloadingPdf,
        getReportPdfUrl,
        onResponsibleChanged,
        onContactChanged,
        onReminderSent,
        showAddToAuditGroup,
        setShowAddToAuditGroup,
        onAuditAddedToAuditGroup,
        onAuditEvaluated,
        onRemindersToggled,
        onAuditCopied,
        showCopyDialog,
        setShowCopyDialog,
        selectedAudit,
        setSelectedAudit,
    };
}

import { DotLegalDocumentUpload, DotLegalDocumentUploadProps } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";

export interface DotLegalDocumentUploadWrapperProps
    extends Omit<
        DotLegalDocumentUploadProps,
        | "dropZoneText"
        | "choseFileText"
        | "numberOfFilesChosenText"
        | "dropFileText"
        | "linkUrlErrorText"
        | "insertLinkLabel"
        | "uploadDocumentLabel"
        | "fileErrorText"
    > {}

function DotLegalDocumentUploadWrapper(props: DotLegalDocumentUploadWrapperProps) {
    const { translateString } = useTranslation();
    return (
        <DotLegalDocumentUpload
            {...props}
            dropZoneText={translateString("dragDrop")}
            choseFileText={translateString("chooseFile")}
            numberOfFilesChosenText={translateString("numberOfFilesChosen")}
            dropFileText={translateString("dropFile")}
            uploadDocumentLabel={translateString("uploadDocument")}
            insertLinkLabel={translateString("insertLink")}
            linkUrlErrorText={translateString("validateUrl")}
            fileErrorText={translateString("validateNotEmpty", { name: translateString("file") })}
        />
    );
}

export default DotLegalDocumentUploadWrapper;

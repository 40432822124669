import { useState } from "react";
import { useResponsibleQuery } from "../hooks/useResponsibleQuery";
import { TransferUserResponsibilityModel } from "./TransferUserResponsibility.types";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { ITransferUserResponsibility } from "./TransferUserResponsibility";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";

function useTransferUserResponsibilityHooks(props: ITransferUserResponsibility) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [transferModel, setTransferModel] = useState<TransferUserResponsibilityModel>({
        fromUser: null,
        toUser: null,
        transferAnnualWheelTasks: false,
        transferProcessingActivities: false,
        transferSystems: false,
        transferDocuments: false,
        transferSharings: false,
        transferLegalEntityAudits: false,
        transferAuditGroups: false,
        transferInternalAssessments: false,
        transferPublicInternalAssessmentTemplates: false,
    });

    const saveMutation = useMutation(save, {
        onSuccess: () => {
            props.onDialogClose();
            snackbar.show(translateString("responsibilityTransfered"));
        },
    });

    const transferResponsible = async () => {
        await saveMutation.mutateAsync(transferModel);
    };

    function save(model: TransferUserResponsibilityModel) {
        return post<{}>("/Users/transferUserResponsibility", model);
    }

    let responsiblesQuery = useResponsibleQuery();

    let fromUsers;
    let toUsers;
    if (responsiblesQuery.userData) {
        fromUsers = responsiblesQuery
            .data(transferModel.fromUser)
            ?.filter((x) => x.id !== transferModel.toUser)
            .map((d) => {
                return d;
            });

        toUsers = responsiblesQuery
            .data(transferModel.toUser)
            ?.filter((x) => x.id !== transferModel.fromUser)
            .map((d) => {
                return d;
            });
    }

    const onFromUserChange = (fromUser: string | null) => {
        let temp = { ...transferModel };
        temp.fromUser = fromUser;
        setTransferModel(temp);
    };

    const onToUserChange = (toUser: string | null) => {
        let temp = { ...transferModel };
        temp.toUser = toUser;
        setTransferModel(temp);
    };

    const onTransferProcessingActivitiesChange = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferProcessingActivities = checked;
        setTransferModel(temp);
    };

    const onTransferSystemsChange = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferSystems = checked;
        setTransferModel(temp);
    };

    const onTransferAnnualWheelTasksChange = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferAnnualWheelTasks = checked;
        setTransferModel(temp);
    };

    const onTransferDocumentsChange = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferDocuments = checked;
        setTransferModel(temp);
    };

    const onTransferSharingsChange = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferSharings = checked;
        setTransferModel(temp);
    };

    const onTransferLegalEntityaudits = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferLegalEntityAudits = checked;
        setTransferModel(temp);
    };

    const onTransferAuditGroups = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferAuditGroups = checked;
        setTransferModel(temp);
    };

    const onTransferInternalAssessments = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferInternalAssessments = checked;
        setTransferModel(temp);
    };

    const onTransferPublicInternalAssessmentTemplates = (checked: boolean) => {
        let temp = { ...transferModel };
        temp.transferPublicInternalAssessmentTemplates = checked;
        setTransferModel(temp);
    };

    const transferButtonEnabled =
        transferModel.fromUser !== null &&
        transferModel.toUser !== null &&
        (transferModel.transferAnnualWheelTasks ||
            transferModel.transferProcessingActivities ||
            transferModel.transferSystems ||
            transferModel.transferDocuments ||
            transferModel.transferSharings ||
            transferModel.transferLegalEntityAudits ||
            transferModel.transferAuditGroups ||
            transferModel.transferInternalAssessments ||
            transferModel.transferPublicInternalAssessmentTemplates);

    return {
        fromUsers,
        toUsers,
        onFromUserChange,
        onToUserChange,
        transferModel,
        onTransferProcessingActivitiesChange,
        onTransferSystemsChange,
        onTransferAnnualWheelTasksChange,
        onTransferDocumentsChange,
        onTransferSharingsChange,
        onTransferLegalEntityaudits,
        transferResponsible,
        onTransferAuditGroups,
        onTransferInternalAssessments,
        onTransferPublicInternalAssessmentTemplates,
        transferButtonEnabled,
        inProgress: saveMutation.isLoading,
    };
}

export default useTransferUserResponsibilityHooks;

import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { ProcessingActivityDocumentTab } from "./ProcessingActivityDocumentTab";
import { ProcessingActivityDocumentViewModel } from "./ProcessingActivityDocumentTab.types";

export default function useProcessingActivityDocumentTabHooks(props: ProcessingActivityDocumentTab) {
    const queryClient = useQueryClient();

    const [selectedDocument, setSelectedDocument] = useState<ProcessingActivityDocumentViewModel>();
    const [showDocumentDialog, setShowDocumentDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const { permissions } = useUserContext();
    const { downloadDocumentVersion } = useDotLegalFileUploadDataMapping();

    const url = `/processingActivity/${props.processingActivityId}/documents`;
    const { isLoading, data, refetch } = useQuery(url, () => get<Array<ProcessingActivityDocumentViewModel>>(url));

    return {
        isLoading,
        data,
        hasDocuments: isLoading || data!.length > 0,
        selectedDocument,
        setSelectedDocument,
        showDocumentDialog,
        setShowDocumentDialog,
        showDeleteDialog,
        setShowDeleteDialog,
        downloadDocumentVersion,
        refetch,
    };
}

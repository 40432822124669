import { Box, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../../../common/components/dotLegalTable/DotLegalTable.types";
import { useTranslation } from "../../../localization/useTranslation";
import { DataSubjectViewModel } from "../DataSubject.types";
import { useDataSubjectsDataMapping } from "./DataSubjects.hooks";
import { useDataSubjectsStyles } from "./DataSubjects.styles";
import CheckIcon from "@mui/icons-material/Check";
import AddEditDataSubjectDialog from "../addEditDataSubjectDialog/AddEditDataSubjectDialog";
import { ChangeDataSubjectStatusDialog } from "../changeDataSubjectStatusDialog/ChangeDataSubjectStatusDialog";
import { DeleteDataSubjectDialog } from "../deleteDataSubjectDialog/DeleteDataSubjectDialog";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";

function DataSubjects() {
    const styles = useDataSubjectsStyles();
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        data,
        OnSave,
        showChangeStatusDialog,
        setShowChangeStausDialog,
        showAllDataSubjects,
        setshowAllDataSubjects,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataSubjectDialog,
        setShowDeleteDataSubjectDialog,
        selectedDataSubject,
        setSelectedDataSubject,
    } = useDataSubjectsDataMapping();

    const getMenuItems = (dataSubject: DataSubjectViewModel) => {
        let menuItems = [];

        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDataSubject({
                        name: dataSubject.name,
                        description: dataSubject.description,
                        id: dataSubject.id,
                        active: dataSubject.active,
                        isCustom: dataSubject.isCustom,
                    });
                    setShowAddEditDialog(true);
                },
            });

            menuItems.push({
                menuItem: dataSubject.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedDataSubject({
                        name: dataSubject.name,
                        description: dataSubject.description,
                        id: dataSubject.id,
                        active: dataSubject.active,
                        isCustom: dataSubject.isCustom,
                    });
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && dataSubject.isCustom) {
            menuItems.push({
                menuItem: translateString("deleteDataSubject"),
                onClick: () => {
                    setSelectedDataSubject({
                        name: dataSubject.name,
                        description: dataSubject.description,
                        id: dataSubject.id,
                        active: dataSubject.active,
                        isCustom: dataSubject.isCustom,
                    });
                    setShowDeleteDataSubjectDialog(true);
                },
            });
        }
        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("dataSubjects"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setshowAllDataSubjects}
                        enabled={showAllDataSubjects}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createDataSubject")}
                    onOpenDialog={() => {
                        setShowAddEditDialog(true);
                        setSelectedDataSubject(undefined);
                    }}
                    showDialog={showAddEditDialog}
                    icon={getMasterIcon()}
                >
                    {showAddEditDialog && (
                        <AddEditDataSubjectDialog
                            isAdd={selectedDataSubject === undefined}
                            onHideDialog={() => setShowAddEditDialog(false)}
                            onSave={OnSave}
                            selectedDataSubject={selectedDataSubject}
                        />
                    )}
                </DotLegalActionBar>
            </div>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </DotLegalPaper>
            </Box>

            {showDeleteDataSubjectDialog && (
                <DeleteDataSubjectDialog
                    selectedDataSubject={selectedDataSubject!}
                    onSaveCorrect={OnSave}
                    onCloseDialog={() => setShowDeleteDataSubjectDialog(false)}
                />
            )}

            {showChangeStatusDialog && (
                <ChangeDataSubjectStatusDialog
                    selectedDataSubject={selectedDataSubject!}
                    refetchDataSubjects={OnSave}
                    onCloseDialog={() => setShowChangeStausDialog(false)}
                />
            )}
        </Box>
    );
}

export default DataSubjects;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<DataSubjectViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}

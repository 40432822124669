import { DotLegalDialog, DotLegalHeader, DotLegalPrettyScrollbar, DotLegalTable } from "@dotlegal/dotlegal-ui-components";
import { Badge, Box, Grid, styled, TableCell, TableRow, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "../localization/useTranslation";
import { AuditLogDialogHooks } from "./AuditLogDialog.hooks";
import { AuditTypeView, EntityType } from "./AuditLogDialog.types";

export interface IAuditLogDialog {
    entityType: EntityType;
    entityId: string | undefined;
    entityName: string | undefined;
    onDialogClose: () => void;
}

const GridItem = styled(Grid)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
}));

const HeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "left",
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(14),
}));

export default function AuditLogDialog(props: IAuditLogDialog) {
    const { translateString, translateauditLogTypes, translateDateWithTime, translatePropertyName, translateEntityType } = useTranslation();
    const theme = useTheme();
    const { isLoading, data, getHeader, selectedLog, setSelectedLog, tableEntryHeader, tablePriorityHeader, translateValue } = AuditLogDialogHooks(props);

    function getBadgeColor(type: AuditTypeView) {
        switch (type) {
            case AuditTypeView.Created:
                return theme.palette.primary.main;
            case AuditTypeView.Updated:
                return theme.palette.grey[400];
            case AuditTypeView.Removed:
                return theme.palette.error.light;
            case AuditTypeView.Deleted:
                return theme.palette.error.dark;
            default:
                return theme.palette.primary.light;
        }
    }

    return (
        <DotLegalDialog
            isLoading={isLoading}
            open
            onDialogClose={props.onDialogClose}
            buttonOkText={""}
            onOkClick={() => undefined}
            hideOkButton
            size="xl"
            fullHeight
        >
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <HeaderBox>
                    <Box component={"span"} sx={{ mr: 1 }}>
                        <DotLegalHeader headerStyle={"medium"} color="lightgrey">
                            {getHeader()}
                        </DotLegalHeader>
                    </Box>
                    <DotLegalHeader headerStyle={"medium"}>{props.entityName}</DotLegalHeader>
                </HeaderBox>
                <Grid container sx={{ flex: 1 }}>
                    <GridItem item sm={6} sx={{ paddingRight: 2 }}>
                        <DotLegalPrettyScrollbar>
                            <DotLegalTable
                                headers={tableEntryHeader}
                                emptyText={translateString("noAuditLogs")}
                                isLoading={isLoading}
                                data={data?.entries}
                                defaultOrder={"desc"}
                                defaultOrderBy={"timeStamp"}
                                paginationLabelOf={translateString("labelOf")}
                                rowsPerPage={19}
                                renderRow={(row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={(theme) => ({ backgroundColor: row.id === selectedLog ? theme.palette.primary.light : undefined })}
                                        onClick={() => {
                                            setSelectedLog(row.id);
                                        }}
                                    >
                                        <TableCell>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Badge
                                                    sx={(theme) => ({
                                                        marginLeft: theme.spacing(0.5),
                                                        marginRight: theme.spacing(1.5),
                                                        "& .MuiBadge-badge": { backgroundColor: getBadgeColor(row.auditType) },
                                                    })}
                                                    variant="dot"
                                                />
                                                <Box>
                                                    <Box component={"span"} sx={{ marginRight: 0.5, fontSize: 12, fontWeight: "bold", color: "primary.dark" }}>
                                                        {translateauditLogTypes(row.auditType.toString())}:
                                                    </Box>
                                                    <Box component={"span"} sx={{ fontSize: 12 }}>
                                                        {translateEntityType(row.entityType)}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={() => ({ color: theme.palette.grey[600] + " !important" })}>
                                            {translateDateWithTime(row.timeStamp)}
                                        </TableCell>
                                        <TableCell
                                            sx={(theme) => ({
                                                color: !!row.name ? theme.palette.primary.main + " !important" : theme.palette.error.main + " !important",
                                            })}
                                        >
                                            {row.name ?? translateString("deletedUser")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            />
                        </DotLegalPrettyScrollbar>
                    </GridItem>
                    <GridItem item sm={6} sx={{ paddingLeft: 2, borderLeft: `1px solid rgb(205, 211, 253)` }}>
                        <DotLegalPrettyScrollbar>
                            {selectedLog && (
                                <DotLegalTable
                                    headers={tablePriorityHeader}
                                    emptyText={translateString("noProperties")}
                                    isLoading={isLoading}
                                    data={data?.entries.find((x) => x.id === selectedLog)?.properties ?? []}
                                    defaultOrderBy={"propertyName"}
                                    defaultOrder={"asc"}
                                    paginationLabelOf={translateString("labelOf")}
                                    rowsPerPage={19}
                                    renderRow={(row, i) => (
                                        <TableRow key={row.propertyName + i}>
                                            {/* Vi skal måske translate værdier forinden vi viser data i frontend? */}
                                            <TableCell>{translatePropertyName(row.propertyName)}</TableCell>
                                            <TableCell>{translateValue(row.from, row.type, row.propertyName)}</TableCell>
                                            <TableCell>{translateValue(row.to, row.type, row.propertyName)}</TableCell>
                                        </TableRow>
                                    )}
                                />
                            )}
                        </DotLegalPrettyScrollbar>
                    </GridItem>
                </Grid>
            </Box>
        </DotLegalDialog>
    );
}

import { ProcessingActivityModel, ProcessingActivityValidationViewModel, StepTypeEnum } from "../ProcessingActivity.types";
import StepDescriptionBox from "../sharingOfDataStep/stepDescriptionBox/StepDescriptionBox";
import React from "react";
import { StepModel } from "../../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { useSteps } from "../../common/components/dotLegalStepper/useSteps";
import { HelpTextViewModel } from "../../masterData/helpText/helpTexts/HelpTexts.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";

export function useProcessingActivitySteps(
    processingActivity: ProcessingActivityModel | undefined,
    processingActivitySteps: Array<StepModel>,
    stepName: "step" | "substep",
    validationData: ProcessingActivityValidationViewModel | undefined,
    helpTexts?: Array<HelpTextViewModel>
) {
    processingActivity = processingActivity ?? new ProcessingActivityModel();

    const { processingActivityAndRecordsPlan, proceduresAndPoliciesPlan } = usePlanContext();

    const steps = useSteps(processingActivity, processingActivitySteps, stepName, HasStepDataAdded, getStepperHover, getIsStepUserValidated, helpTexts);

    function getStepperHover(stepType: number) {
        const toolTipWidth = 465;
        switch (+stepType) {
            case StepTypeEnum.disclosure:
                return {
                    hoverContent: React.createElement(StepDescriptionBox, { stepType: StepTypeEnum.disclosure }),
                    toolTipMaxWidth: toolTipWidth,
                };
            case StepTypeEnum.sources:
                return {
                    hoverContent: React.createElement(StepDescriptionBox, { stepType: StepTypeEnum.sources }),
                    toolTipMaxWidth: toolTipWidth,
                };
            case StepTypeEnum.dataprocessor:
                return {
                    hoverContent: React.createElement(StepDescriptionBox, { stepType: StepTypeEnum.dataprocessor }),
                    toolTipMaxWidth: toolTipWidth,
                };
            case StepTypeEnum.dataController:
                return {
                    hoverContent: React.createElement(StepDescriptionBox, { stepType: StepTypeEnum.dataController }),
                    toolTipMaxWidth: toolTipWidth,
                };
            case StepTypeEnum.jointDataControllers:
                return {
                    hoverContent: React.createElement(StepDescriptionBox, { stepType: StepTypeEnum.jointDataControllers }),
                    toolTipMaxWidth: toolTipWidth,
                };
            default:
                return undefined;
        }
    }

    function getIsStepUserValidated(type: StepTypeEnum) {
        if (!validationData) return undefined;

        let validation = validationData!.validatedSteps.find((x) => x === type);

        if (validation !== undefined) {
            return { validationId: validationData!.id!, isValidated: true };
        }

        return { validationId: validationData!.id!, isValidated: false };
    }

    function HasStepDataAdded(type: StepTypeEnum) {
        switch (type) {
            case StepTypeEnum.association:
                return ContainsAssociationData();
            case StepTypeEnum.dataSubject:
                return ContainsDataSubjectData();
            case StepTypeEnum.documentation:
                return ContainsDocumentation();
            case StepTypeEnum.masterData:
                return ContainsMasterData();
            case StepTypeEnum.legalBases:
                return HasLegalBases();
            case StepTypeEnum.sharings:
                return HasSharings();
            case StepTypeEnum.disclosure:
                return HasDisclosures();
            case StepTypeEnum.sources:
                return HasSources();
            case StepTypeEnum.dataprocessor:
                return HasDataProcessors();
            case StepTypeEnum.dataCategories:
                return HasDataCategories();
            case StepTypeEnum.systems:
                return HasSystems();
            case StepTypeEnum.dataController:
                return HasDataControllers();
            case StepTypeEnum.jointDataControllers:
                return HasJointDataController();
            case StepTypeEnum.policies:
                return proceduresAndPoliciesPlan.processingActivityAndPolicyLink && processingActivity!.policies.length > 0;
            default:
                return true;
        }
    }

    function ContainsAssociationData() {
        return (
            (processingActivity!.owner !== null && processingActivity!.owner !== "") ||
            processingActivity!.processingAreas.length > 0 ||
            processingActivity!.legalEntities.length > 0
        );
    }

    function ContainsDataSubjectData() {
        return (
            processingActivity!.dataSubjects.length > 0 ||
            (processingActivity!.subjects !== undefined && processingActivity!.subjects > 0) ||
            (processingActivity!.processors !== undefined && processingActivity!.processors > 0) ||
            processingActivity!.securityMeasures.length > 0
        );
    }

    function ContainsDocumentation() {
        return processingActivity!.policies.length > 0;
    }

    function ContainsMasterData() {
        return (
            processingActivity?.name !== "" ||
            (processingActivity.description != null && processingActivity.description !== "") ||
            processingActivity.purposes.length > 0
        );
    }

    function HasSystems() {
        return processingActivity?.disableSystems || processingActivity!.systems.length > 0;
    }

    function HasSharings() {
        return (
            HasDataProcessors() &&
            HasDisclosures() &&
            HasSources() &&
            (HasDataControllers() || !processingActivityAndRecordsPlan.registerSelfAsDataProcessor) &&
            HasJointDataController()
        );
    }

    function HasDataCategories() {
        return processingActivity!.standardDataCategories.length > 0;
    }

    function HasLegalBases() {
        return (
            processingActivity!.legalBasesViewModel?.allLegalBases?.some(
                (x) => x.directiveId || x.deletionPeriodId || x.nationalLawId || x.doubleLegalBasisId
            ) ||
            processingActivity!.legalBasesViewModel?.purposeSpecificLegalBases?.some(
                (x) => x.directiveId || x.deletionPeriodId || x.nationalLawId || x.doubleLegalBasisId
            )
        );
    }

    function HasDisclosures() {
        return processingActivity!.hasDisclosures || processingActivity!.disableDisclosures;
    }

    function HasDataProcessors() {
        return processingActivity!.hasDataProcessors || processingActivity!.disableDataProcessors;
    }

    function HasSources() {
        return processingActivity!.hasSources || processingActivity!.disableSources;
    }

    function HasDataControllers() {
        return (
            processingActivityAndRecordsPlan.registerSelfAsDataProcessor &&
            (processingActivity!.hasDataControllers || processingActivity!.disableDataControllers)
        );
    }

    function HasJointDataController() {
        return processingActivity!.hasJointDataControllers || processingActivity!.disableJointDataControllers;
    }

    let newVar = {
        getSteps: steps.getSteps,
        getActiveStep: steps.getActiveStep,
        changeStep: steps.changeStep,
        validationErrors: steps.validationErrors,
    };

    return newVar;
}

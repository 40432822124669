import React from "react";
import { DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { useDataMapping } from "./CustomerDialog.hooks";
import { useTranslation } from "../../localization/useTranslation";
import { List, ListItem, ListItemText } from "@mui/material";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";

export interface ICustomerDialogProps {
    children: JSX.Element;
}

function CustomerDialog(props: ICustomerDialogProps) {
    const { translateString } = useTranslation();

    const { isLoading, customers, onCustomerChosen, onDialogClose, showDialog } = useDataMapping();

    let content = <DotLegalFullScreenSpinner />;
    if (!isLoading) {
        if (showDialog) {
            content = (
                <DotLegalDialog
                    disableOnClose
                    header={translateString("chooseCustomerToLoginWith")}
                    onDialogClose={onDialogClose}
                    buttonOkText={translateString("close")}
                    onOkClick={onDialogClose}
                    open
                    hideOkButton
                    isLoading={isLoading}
                    size={"md"}
                >
                    <List component="nav" aria-label="customers">
                        {customers.map((c) => (
                            <ListItem button key={c.id} onClick={() => onCustomerChosen(c.id)}>
                                <ListItemText primary={c.name} />
                            </ListItem>
                        ))}
                    </List>
                </DotLegalDialog>
            );
        } else {
            content = props.children;
        }
    }

    return <React.Fragment>{content}</React.Fragment>;
}

export default CustomerDialog;

import { DotLegalDatePicker, DotLegalFullScreenSpinner, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import ProcessingActivityReportDialogHeader from "../processingActivityReportDialogHeader/ProcessingActivityReportDialogHeader";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import { useTranslation } from "../../localization/useTranslation";
import { BodyTypography, DateBox, HeaderBodyTypography, HeaderBox, SpanBox, TextBoxWrapper, getDataBasis } from "../ProcessingActivityReport";
import { useProcessingActivityReportHooks } from "../ProcessingActivityReport.hooks";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { ProcessingActivityStatus } from "../../processingActivity/processingActivities/ProcessingActivities.types";

export interface IRegistrationOfResearchProjectReport {
    processingActivityId?: string;
    isPDF: boolean;
    onValidChange: (isValid: boolean) => void;
    showError: boolean;
    processingActivityStatus: ProcessingActivityStatus | undefined;
}

export default function RegistrationOfResearchProjectReport(props: IRegistrationOfResearchProjectReport) {
    const { translateString, translateDate, getDateTimePickerLanguage, translateDataSubjectsEnum } = useTranslation();
    const { isLoading, data, onReportChange, permissions } = useProcessingActivityReportHooks(
        CustomerReportType.RegistrationOfResearchProject,
        props.processingActivityId,
        props.onValidChange
    );

    const displayEndDateError = props.showError && (data?.endDate === null || data?.endDate === undefined);
    const disableMandatoryFields =
        permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft &&
        props.processingActivityStatus !== ProcessingActivityStatus.Draft &&
        props.processingActivityStatus !== ProcessingActivityStatus.AwaitingResearcher;

    return (
        <div>
            {isLoading ? (
                <Box height={"70vh"}>
                    <DotLegalFullScreenSpinner />
                </Box>
            ) : (
                <Box sx={{ paddingLeft: "8px", paddingRigth: "8px" }}>
                    {props.isPDF && <ProcessingActivityReportDialogHeader reportType={CustomerReportType.RegistrationOfResearchProject} isPdf />}
                    <Box sx={{ marginTop: props.isPDF ? 2 : 0 }}>
                        <HeaderBox>
                            <BodyTypography>
                                <HeaderBodyTypography fontSize={16}>{translateString("projectTitel") + ": "}</HeaderBodyTypography>
                                <SpanBox fontSize={16} component="span">
                                    {data?.processingActivityName}
                                </SpanBox>
                            </BodyTypography>
                        </HeaderBox>
                    </Box>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("approvelNumber") + ": "}</HeaderBodyTypography>
                        <BodyTypography>{data?.approvelNumber}</BodyTypography>
                    </HeaderBox>
                    <HeaderBox>
                        <BodyTypography>
                            <HeaderBodyTypography>{translateString("groupEntities") + ": "}</HeaderBodyTypography>
                            <SpanBox component="span">{data?.groupCompanies.join(", ")}</SpanBox>
                        </BodyTypography>
                    </HeaderBox>
                    <div className="page-break-inside">
                        <HeaderBox>
                            <BodyTypography>
                                <HeaderBodyTypography>{translateString("description") + ": "}</HeaderBodyTypography>
                                <SpanBox component="span">{data?.description}</SpanBox>
                            </BodyTypography>
                        </HeaderBox>
                    </div>
                    <HeaderBox>
                        <BodyTypography>
                            <HeaderBodyTypography>{translateString("purposes") + ": "}</HeaderBodyTypography>
                            <SpanBox component="span">{data?.purposes.join(", ")}</SpanBox>
                        </BodyTypography>
                    </HeaderBox>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("NumberOfSubjects") + ": "}</HeaderBodyTypography>
                        <BodyTypography>{data && data?.noOfSubjects ? translateDataSubjectsEnum(data.noOfSubjects.toString()) : undefined}</BodyTypography>
                    </HeaderBox>
                    {props.isPDF ? (
                        <HeaderBox>
                            <HeaderBodyTypography>{translateString("approvelDate") + ": "}</HeaderBodyTypography>
                            <BodyTypography>{data?.approvelDate ? translateDate(data?.approvelDate!) : ""}</BodyTypography>
                        </HeaderBox>
                    ) : (
                        <Box>
                            <HeaderBodyTypography>{translateString("approvelDate") + ": "}</HeaderBodyTypography>
                            <DateBox>
                                <DotLegalDatePicker
                                    label=""
                                    value={data?.approvelDate ?? null}
                                    onChange={(date) => {
                                        const tempData = { ...data! };

                                        if (date === null || isNaN(date!.getTime())) tempData.approvelDate = null;
                                        else {
                                            tempData.approvelDate = date!;
                                        }
                                        onReportChange(tempData);
                                    }}
                                    disabled={permissions.processingActivityStatusManagement! !== ProcessingActivityStatusManagement.Full}
                                    language={getDateTimePickerLanguage()}
                                    noMargin
                                />
                            </DateBox>
                        </Box>
                    )}
                    {props.isPDF ? (
                        <HeaderBox>
                            <HeaderBodyTypography>{translateString("dataprocessorEndDate") + ": "}</HeaderBodyTypography>
                            <BodyTypography>{data?.endDate ? translateDate(data?.endDate!) : ""}</BodyTypography>
                        </HeaderBox>
                    ) : (
                        <Box>
                            <HeaderBodyTypography>{translateString("dataprocessorEndDate") + ": "}</HeaderBodyTypography>
                            <DateBox>
                                <DotLegalDatePicker
                                    label=""
                                    value={data?.endDate ?? null}
                                    onChange={(date) => {
                                        const tempData = { ...data! };

                                        if (date === null || isNaN(date!.getTime())) tempData.endDate = null;
                                        else tempData.endDate = date!;

                                        onReportChange(tempData);
                                    }}
                                    language={getDateTimePickerLanguage()}
                                    noMargin
                                    error={displayEndDateError}
                                    helperMessage={displayEndDateError ? translateString("fieldMandatory") : undefined}
                                    disabled={disableMandatoryFields}
                                />
                            </DateBox>
                        </Box>
                    )}
                    <Box className="avoid-page-break" sx={{ marginBottom: props.isPDF ? 1 : "inherit" }}>
                        {props.isPDF ? (
                            <BodyTypography>
                                <HeaderBodyTypography>{translateString("dataBaseAndSources") + ": "}</HeaderBodyTypography>

                                {getDataBasis(translateString, data)}

                                <SpanBox component="span">{data?.note}</SpanBox>
                            </BodyTypography>
                        ) : (
                            <Fragment>
                                <Box>
                                    <HeaderBodyTypography>{translateString("dataBaseAndSources") + ": "}</HeaderBodyTypography>
                                </Box>

                                {getDataBasis(translateString, data)}

                                <DotLegalTextField
                                    multiline
                                    isLoading={isLoading}
                                    debounce={false}
                                    rows={props.isPDF ? undefined : 8}
                                    label={props.isPDF ? "" : translateString("note")}
                                    value={data?.note ?? ""}
                                    onChange={(value) => {
                                        let tempData = { ...data! };
                                        tempData.note = value;
                                        onReportChange(tempData);
                                    }}
                                />
                            </Fragment>
                        )}
                    </Box>
                    <div className="avoid-page-break">
                        <Grid container spacing={1} marginTop={0}>
                            <Grid item xs={6}>
                                <Fragment>
                                    <Box>
                                        <HeaderBodyTypography>{translateString("primaryResponsible")}</HeaderBodyTypography>
                                    </Box>
                                    {props.isPDF ? (
                                        <BodyTypography>
                                            <SpanBox component="span">{data?.primaryResponsible}</SpanBox>
                                        </BodyTypography>
                                    ) : (
                                        <TextBoxWrapper>
                                            <DotLegalTextField
                                                label={""}
                                                value={data?.primaryResponsible ?? ""}
                                                debounce={false}
                                                multiline
                                                placeholder={translateString("contactPersonPlaceHolder")}
                                                rows={8}
                                                onChange={(text) => {
                                                    let tempModel = { ...data! };
                                                    tempModel.primaryResponsible = text;
                                                    onReportChange(tempModel);
                                                }}
                                                errorText={
                                                    props.showError && isNullOrWhitespace(data?.primaryResponsible ?? undefined)
                                                        ? translateString("fieldMandatory")
                                                        : undefined
                                                }
                                                disabled={disableMandatoryFields}
                                            />
                                        </TextBoxWrapper>
                                    )}
                                </Fragment>
                            </Grid>
                            <Grid item xs={6}>
                                <Fragment>
                                    <Box>
                                        <HeaderBodyTypography>{translateString("contact")}</HeaderBodyTypography>
                                    </Box>
                                    {props.isPDF ? (
                                        <BodyTypography>
                                            <SpanBox component="span">{data?.contact}</SpanBox>
                                        </BodyTypography>
                                    ) : (
                                        <TextBoxWrapper>
                                            <DotLegalTextField
                                                onChange={(text) => {
                                                    let tempModel = { ...data! };
                                                    tempModel.contact = text;
                                                    onReportChange(tempModel);
                                                }}
                                                label={""}
                                                placeholder={translateString("contactPersonPlaceHolder")}
                                                value={data?.contact ?? ""}
                                                debounce={false}
                                                multiline
                                                rows={8}
                                                errorText={
                                                    props.showError && isNullOrWhitespace(data?.contact ?? undefined)
                                                        ? translateString("fieldMandatory")
                                                        : undefined
                                                }
                                                disabled={disableMandatoryFields}
                                            />
                                        </TextBoxWrapper>
                                    )}
                                </Fragment>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            )}
        </div>
    );
}

import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import PlanDialog from "../planDialog/PlanDialog";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import { CustomerPlanViewModel } from "../../trial/Trial.types";
import { useTrialHook } from "../../trial/Trial.hooks";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { Box } from "@mui/material";
import Expired from "./expired.svg?react";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface ITrialExpiredDialog {
    data: CustomerPlanViewModel | undefined;
}

function TrialExpiredDialog(props: ITrialExpiredDialog) {
    const { translateString } = useTranslation();
    const { showExpiredDialog, closeExpiredDialog, dontAskAgainExpiredDialog } = useTrialHook(props.data);
    function getContent() {
        return (
            <React.Fragment>
                <DotLegalHeader marginBottom={3} fontWeight={500} center headerStyle="small">
                    {props.data?.trialPlanType && (
                        <Trans
                            i18nKey={"trialHasExpiredContent"}
                            values={{ plan: translateString(setFirstLetterToLowerCase(PlanType[props.data.trialPlanType])) }}
                        />
                    )}
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    function getButtons() {
        return (
            <React.Fragment>
                <PlanDialogUpgradeButton />
                <DotLegalButton onClick={dontAskAgainExpiredDialog} btnLength={300} buttonType="secondary">
                    {translateString("doNotShowAgain")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    return (
        <PlanDialog
            showDialog={showExpiredDialog}
            onDialogClose={closeExpiredDialog}
            content={getContent()}
            header={
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Box component={"span"}>{translateString("trialHasExpiredHeader")}</Box>
                    <Expired />
                </Box>
            }
            buttons={getButtons()}
        ></PlanDialog>
    );
}

export default TrialExpiredDialog;

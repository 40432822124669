import { createSxStyles } from "../../common/createSxStyles";

export const useLegalEntityClassificationTabStyles = () => {
    return createSxStyles({
        risk: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 1,
        },
    });
};

import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { ITableHeader } from "../dotLegalTable/DotLegalTable.types";
import { DotLegalButton, DotLegalDialog, DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalCompanySelectorStyles } from "./DotLegalCompanySelector.styles";
import { SelectableCompany } from "./DotLegalCompanySelector.types";
import SelectorSvg from "./selector.svg?react";
import { greenArrowRight } from "../../icons";
import { GetCompany, useDotLegalCompanySelectorDataMapping } from "./DotLegalCompanySelector.hooks";

export interface IDotLegalCompanySelector {
    companies: Array<SelectableCompany>;
    selectedCompany: string;
    setSelectedCompany: (id: string) => void;
    isLoading: boolean;
}

function DotLegalCompanySelector(props: IDotLegalCompanySelector) {
    const styles = useDotLegalCompanySelectorStyles();
    const { translateString } = useTranslation();

    const { showDialog, setShowDialog } = useDotLegalCompanySelectorDataMapping();

    let headers: Array<ITableHeader<SelectableCompany>> = [{ property: "name", text: translateString("name"), align: "left", showOnMobile: true }];

    const getSelectedCompany = () => {
        if (isAllSelected()) return translateString("all");

        return GetCompany(props.companies, props.selectedCompany)?.name;
    };

    const setSelectedCompany = (id: string) => {
        setShowDialog(false);
        props.setSelectedCompany(id);
    };

    const getCompanyName = (item: SelectableCompany) => {
        let name = item.name;

        if (props.selectedCompany === item.id) {
            name += setAsSelected();
        }

        return name;
    };

    const getAllCompanies = () => {
        let name = translateString("all");

        if (isAllSelected()) name += setAsSelected();

        return name;
    };

    const isAllSelected = () => {
        return props.selectedCompany === "";
    };

    const setAsSelected = () => {
        return ` (${translateString("selected")})`;
    };

    const getContent = () => {
        if (props.isLoading || props.companies.length > 1) {
            return (
                <Box sx={styles.container}>
                    {props.isLoading ? (
                        <Skeleton variant="text" width={200} height={39} />
                    ) : (
                        <DotLegalButton buttonType="secondary" onClick={() => setShowDialog(true)}>
                            <Box component={"span"} sx={styles.btnContent}>
                                {getSelectedCompany()}
                            </Box>
                            <SelectorSvg />
                        </DotLegalButton>
                    )}

                    <DotLegalDialog
                        header={translateString("changeOrganisation")}
                        size={"md"}
                        hideOkButton
                        buttonOkText={""}
                        open={showDialog}
                        onDialogClose={() => setShowDialog(false)}
                        onOkClick={() => {}}
                    >
                        <DotLegalTableWithControls
                            getUserSpecificPageLength={() => 30}
                            headers={headers}
                            hideRowsPerPage
                            data={props.companies}
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={false}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            emptyText={translateString("noData")}
                            renderRow={(row, i) => (
                                <React.Fragment key={row.id}>
                                    {i === 0 ? (
                                        <TableRow key={i} sx={styles.tableRow}>
                                            <TableCell onClick={() => setSelectedCompany("")} sx={styles.tableCell}>
                                                {getAllCompanies()}
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={styles.greenArrowContainer}>{greenArrowRight}</Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : undefined}
                                    <TableRow key={row.id} sx={styles.tableRow}>
                                        <TableCell onClick={() => setSelectedCompany(row.id)} sx={styles.tableCell}>
                                            {getCompanyName(row)}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={styles.greenArrowContainer}>{greenArrowRight}</Box>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        />
                    </DotLegalDialog>
                </Box>
            );
        }

        return undefined;
    };

    return <React.Fragment>{getContent()}</React.Fragment>;
}

export default DotLegalCompanySelector;

import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import PlanDialog from "../planDialog/PlanDialog";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";
import { useTrialHook } from "../../trial/Trial.hooks";
import { CustomerPlanViewModel } from "../../trial/Trial.types";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import TimeGlass from "./timeglass.svg?react";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface ITrialSoonToExpireDialog {
    data: CustomerPlanViewModel | undefined;
}

function TrialSoonToExpireDialog(props: ITrialSoonToExpireDialog) {
    const { translateString, translateDate } = useTranslation();
    const { data } = useTrialInformationHook();
    const { showSoonDialog, closeExpiresSoonDialog, dontAskAgainExpiresSoonDialog } = useTrialHook(props.data);
    function getContent() {
        return (
            <React.Fragment>
                <DotLegalHeader marginBottom={3} fontWeight={500} center headerStyle="small">
                    {data && data.trialPlanType && (
                        <Trans
                            i18nKey={"trialSoonToExpireContent"}
                            values={{
                                plan: translateString(setFirstLetterToLowerCase(PlanType[data.trialPlanType])),
                                date: data?.trialExpirationDate ? translateDate(data?.trialExpirationDate) : null,
                            }}
                            components={{
                                date: (
                                    <Box
                                        component={"span"}
                                        sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })}
                                    />
                                ),
                            }}
                        />
                    )}
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    function getButtons() {
        return (
            <React.Fragment>
                <PlanDialogUpgradeButton />
                <DotLegalButton onClick={dontAskAgainExpiresSoonDialog} btnLength={300} buttonType="secondary">
                    {translateString("stayOnCurrentPlan")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    return (
        <PlanDialog
            showDialog={showSoonDialog}
            onDialogClose={closeExpiresSoonDialog}
            content={getContent()}
            header={
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Box component={"span"}>{translateString("trialSoonToExpireHeader")}</Box>
                    <TimeGlass />
                </Box>
            }
            buttons={getButtons()}
        ></PlanDialog>
    );
}

export default TrialSoonToExpireDialog;

import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";
import { useTranslation } from "../../localization/useTranslation";
import { useAddSystemDialogDataMapping } from "./AddSystemDialog.hooks";

export interface IAddProcessingActivityDialog {
    showDialog: boolean;
    onHideDialog: () => void;
}

function AddProcessingActivityDialog(props: IAddProcessingActivityDialog) {
    const { createSystem, inProgress, validation, systemModel, setSystemModel, templateTypes } = useAddSystemDialogDataMapping();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    validation.check(systemModel);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={translateString("create")}
            header={translateString("createSystem")}
            onOkClick={async () => {
                createSystem();
            }}
            onDialogClose={props.onHideDialog}
            open={props.showDialog}
            disableBackdropOnCloseIfValueChange={systemModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("system")}
                value={systemModel.name}
                onChange={(x) => {
                    let tempModel = { ...systemModel };
                    tempModel.name = x;
                    setSystemModel(tempModel);
                }}
                errorText={validation?.getErrorsForDisplay("name")}
            />

            {permissions.canManageCustomers && isTemplateCustomer() && (
                <React.Fragment>
                    <DotLegalMultiSelect
                        options={templateTypes}
                        chosenOptions={systemModel.systemTypes.map((x) => {
                            return x.toString();
                        })}
                        placeholder={"Type"}
                        label={"Type"}
                        onChange={(types) => {
                            let tempModel = { ...systemModel };
                            tempModel.systemTypes = types;
                            setSystemModel(tempModel);
                        }}
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <DotLegalCheckbox
                        label={"Mest populær?"}
                        checked={systemModel.mostPopular}
                        onChange={(mostPopular) => {
                            let tempViewModel = { ...systemModel! };
                            tempViewModel.mostPopular = mostPopular;
                            setSystemModel(tempViewModel);
                        }}
                    />
                </React.Fragment>
            )}
        </DotLegalDialog>
    );
}

export default AddProcessingActivityDialog;

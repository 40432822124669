import { GridSize } from "@mui/material";

export interface ITableHeader<T> {
    text: string;
    property: keyof T | null;
    align: "left" | "center" | "right";
    showOnMobile?: boolean;
    width?: string;
    notSortable?: boolean;
}

export interface GridSizes {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
}

export function sortTableData(data: Array<any>, orderBy: string | number | symbol, order: "asc" | "desc") {
    return data.sort((a: any, b: any) => {
        const getElemABiggestOrder = () => (order === "asc" ? 1 : -1);
        const getElemBBiggestOrder = () => (order === "asc" ? -1 : 1);
        let aElement = a[orderBy];
        let bElement = b[orderBy];
        if (!aElement) {
            return getElemBBiggestOrder();
        }
        if (!bElement) {
            return getElemABiggestOrder();
        }
        if (typeof aElement === "string") {
            aElement = aElement.toLowerCase();
            bElement = bElement.toLowerCase();
        }
        if (aElement > bElement) {
            return getElemABiggestOrder();
        }
        if (aElement < bElement) {
            return getElemBBiggestOrder();
        }
        return 0;
    });
}

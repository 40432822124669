import { DataPrivacyClassification } from "../DataCategoriesStep/DataCategoriesStep.types";
import { LevelOfProtectionType } from "../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { SecurityMeasureOverviewModel } from "../dataSubjectStep/DataSubjectStep.types";
import { NumberOfDataSubjectsEnum, SelectableDataCategoryItem, SelectableLegalUnitsItem } from "../ProcessingActivity.types";
import { SelectableCompany } from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector.types";
import { PolicyType } from "../../policy/policies/Policies.types";
import { ProcessingActivityStatus } from "../processingActivities/ProcessingActivities.types";

export interface ProcessingActivityOverviewViewModel {
    processingActivityInfo: ProcessingActivitiesInfoModel;
    sharings: Array<SharingRowModel>;
    dataCategories: Array<SelectableDataCategoryItem>;
    senders: Array<SelectableLegalUnitsItem>;
    recipients: Array<SelectableLegalUnitsItem>;
}

export interface ProcessingActivityProgressViewModel {
    id: string;
    progress: number;
}

export interface ProcessingActivitiesInfoModel {
    id: string;
    userFriendlyId: string;
    associatedCompanies: Array<string>;
    dataSubjects: Array<string>;
    dataprocessAreas: Array<string>;
    purposes: Array<string>;
    name: string;
    description: string;
    note: string;
    responsible: string;
    responsibleActive: boolean | undefined;
    noOfSubjects: NumberOfDataSubjectsEnum | undefined;
    noOfprocessors: number;
    policies: Array<PolicyViewItem>;
    noOfActivities: number;
    noOfSystems: number;
    noOfSensitiveDataCategories: number;
    noOfOrdinaryDataCategories: number;
    noOfNoInfoDataCategories: number;
    noOfEUTransfers: number;
    noOfEEATransfers: number;
    noOfThirdCountryTransfers: number;
    noOfAdequiteTransfers: number;
    noOfDataCategories: number;
    noOfRecipients: number;
    noOfSenders: number;
    status: number;
    securityMeasures: Array<SecurityMeasureOverviewModel>;
    legalBases: Array<LegalBasesDialogViewModel>;
    selectableCompanyItems: Array<SelectableCompany>;
    validation: ValidationViewModel;
    canValidateProcessingActivity: boolean;
    isValidationDue: boolean;
    processingActivityStatus: ProcessingActivityStatus;
    approvelNumber?: string;
}

export interface SharingRowModel {
    name: string;
    description: string;
    senderName: string;
    senderId: string;
    senderRole: string;
    dataCategories: Array<SelectableDataCategoryItem>;
    recipientName: string;
    recipientId: string;
    recipientRole: string;
    legalBasisList: Array<string>;
    transferBasis: string;
    deletionPeriods: Array<string>;
    system: string;
    systemId: string;
    recipientInThirdCountry: boolean;
    thirdCountryOrigin?: ThirdCountryOrigin;
    subStep: number;
    sharingId: string;
    recipientLevelOfProtection: LevelOfProtectionType;
    sharingOrigin: SharingOrigin;
}

export interface SharingOrigin {
    systemId: string;
    dataProcessorId: string;
    dataControllerId: string;
    transferId: string;
    sourceId: string;
    jointDataControllerId: string;
}

export enum SharingTypeEnum {
    dataProcessor,
    disclosure,
    source,
    dataController,
    jointDataController,
}

export enum ThirdCountryOrigin {
    DataProcessor = 1,
    StorageLocation = 2,
}

export interface LegalBasesDialogViewModel {
    name: string;
    id: string;
    classification: DataPrivacyClassification;
    dataCategoryViewItems: Array<DataCategoryViewItem>;
}

export interface DataCategoryViewItem {
    directive: string;
    doubleBasis: string;
    nationalLaw: string;
    deletionPeriod: string;
    purpose: string;
}

export interface SystemViewItem {
    systemName: string;
    systemType: string;
}

export interface ValidationViewModel {
    validatedBy: string;
    validatedDate: Date;
}

export interface ProcessingActivityValidationSaveModel {
    processingActivityId: string | undefined;
}

export interface PolicyViewItem {
    id: string;
    name: string;
    type: PolicyType;
}

export enum ControllerProcess {
    None = -1,
    Controller,
    ProcessorW,
    Datasubject,
    Subprocessor,
    SubsidiaryPerson,
    JointControllers,
}

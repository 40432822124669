import { DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { ITableHeader } from "../../common/components/dotLegalTable/DotLegalTable.types";
import { useTranslation } from "../../localization/useTranslation";
import StepSegment from "../stepSegment/StepSegment";
import { PolicyRow } from "./PoliciesStep.types";
import CloseIcon from "@mui/icons-material/Close";
import { usePoliciesStepDataMapping } from "./PoliciesStep.hooks";
import PolicySelectorDialog from "./policySelectorDialog/PolicySelectorDialog";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import Rocket from "../../processingActivity/processingActivities/rocket.svg?react";

export interface IPoliciesStep {
    stepHeader?: string;
    stepheaderTooltip?: string;
    addPolicyDialogHeader?: string;
    selectedPolicies: Array<string> | undefined;
    onChangePolicies: (policies: Array<string> | undefined) => void;
    isReadOnly: boolean;
}

function PoliciesStep(props: IPoliciesStep) {
    const { translateString, translatePolicyTypes } = useTranslation();
    const { openDialog, setOpenDialog, policies, isLoading, onAddPoliciesClick, onRemovePolicy } = usePoliciesStepDataMapping(props);

    const getSelectedPolicies = () => {
        if (policies) {
            return policies.filter((x) => props.selectedPolicies?.includes(x.id));
        }

        return [];
    };

    const getAvaliablePolices = () => {
        if (policies) {
            return policies.filter((x) => !props.selectedPolicies?.includes(x.id));
        }

        return [];
    };

    return (
        <React.Fragment>
            <StepSegment size="large">
                <DotLegalStepHeader toolTipText={props.stepheaderTooltip} bottomMargin>
                    {props.stepHeader}
                </DotLegalStepHeader>

                {props.selectedPolicies && props.selectedPolicies.length > 0 ? (
                    <DotLegalTableWithControls
                        getUserSpecificPageLength={() => 10}
                        hideRowsPerPage
                        emptyText={translateString("noPoliciesMatchesQuery")}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        headers={getHeaders(translateString)}
                        defaultOrderBy={"name"}
                        data={getSelectedPolicies()}
                        defaultOrder={"asc"}
                        isLoading={false}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{translatePolicyTypes(row.type.toString())}</TableCell>
                                <TableCell align="right">
                                    {!props.isReadOnly && (
                                        <IconButton size="small" onClick={() => onRemovePolicy(row.id)}>
                                            <CloseIcon sx={(theme) => ({ fill: theme.palette.error.main })} />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    ></DotLegalTableWithControls>
                ) : (
                    <DotLegalEmptyState icon={<Rocket />} text={translateString("policiesStepNoPolicies")} />
                )}

                {!props.isReadOnly && (
                    <Box sx={{ marginTop: 2 }}>
                        <DotLegalButton onClick={() => setOpenDialog(true)} buttonType={"secondary"}>
                            {translateString("addPolicy")}
                        </DotLegalButton>
                    </Box>
                )}
            </StepSegment>

            {openDialog && (
                <PolicySelectorDialog
                    header={props.addPolicyDialogHeader ?? ""}
                    onDialogClose={() => setOpenDialog(false)}
                    isLoading={isLoading}
                    policies={getAvaliablePolices()}
                    onAddPoliciesClick={onAddPoliciesClick}
                />
            )}
        </React.Fragment>
    );
}

export default PoliciesStep;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<PolicyRow>>();
    headers.push({ property: "name", text: translateString("policy"), align: "left", showOnMobile: true });
    headers.push({ property: "type", text: translateString("type"), align: "left", showOnMobile: true });

    return headers;
}

import { useState } from "react";
import { SelectableCompany } from "./DotLegalCompanySelector.types";

export function useDotLegalCompanySelectorDataMapping() {
    const [showDialog, setShowDialog] = useState(false);

    return {
        showDialog,
        setShowDialog,
    };
}

export function GetCompany(companies: Array<SelectableCompany>, id: string) {
    return companies.find((x) => x.id === id);
}

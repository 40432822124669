import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DataCategoryViewModel, DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import DotLegalInformationBox from "../dotLegalInformationBox/DotLegalInformationBox";
import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalPersonalDataSelectorStyles } from "./DotLegalPersonalDataSelector.styles";

export interface IDotLegalPersonalDataSelector {
    activityDataCategories: Array<DataCategoryViewModel>;
    chosenCategories: Array<string> | undefined;
    onChosenActivityDataCategories: (chosenCategories: Array<string>) => void;
    errorText?: string;
    disabled?: boolean;
}

export default function DotLegalPersonalDataSelector(props: IDotLegalPersonalDataSelector) {
    const styles = useDotLegalPersonalDataSelectorStyles(props);
    const { translateString } = useTranslation();
    if (props.chosenCategories) {
        props.chosenCategories?.forEach((object) => {
            let dataCategory = props.activityDataCategories.find((r) => r.id === object);
            if (dataCategory) dataCategory.isSelected = true;
        });
    }

    const handleChange = (id: string) => {
        let category = props.activityDataCategories.find((r) => r.id === id);
        props.activityDataCategories.find((r) => r.id === id)!.isSelected = !category!.isSelected;
        props.onChosenActivityDataCategories([...props.activityDataCategories.filter((x) => x.isSelected).map((r) => r.id)]);
    };

    const getDatacategoryContent = (classification: DataPrivacyClassification) => {
        return (
            <Grid container item xs={8} spacing={2}>
                {props.activityDataCategories
                    .filter((x) => x.classification === classification)
                    .map((d) => {
                        return (
                            <Grid item key={d.id}>
                                <div>
                                    <DotLegalInformationBox
                                        isDisabled={props.disabled}
                                        header={d.name}
                                        description={d.description}
                                        isSelected={d.isSelected ? "selected" : "unselected"}
                                        size="small"
                                        onChange={() => handleChange(d.id)}
                                    />
                                </div>
                            </Grid>
                        );
                    })}
            </Grid>
        );
    };

    return (
        <Box sx={styles.root}>
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.Ordinary).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriOrdinary")}</Typography>
                        <DotLegalInformationTooltip text={translateString("dataCategoryOrdinaryHelperText")} />
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.Ordinary)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.NationalIdentificationNumber).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>
                            {translateString("nationalidentificationnumber")}
                        </Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.NationalIdentificationNumber)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.CriminalOffenses).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("criminaloffenses")}</Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.CriminalOffenses)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.Sensitive).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriSensitive")}</Typography>
                        <DotLegalInformationTooltip text={translateString("dataCategorySensitiveHelperText")} />
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.Sensitive)}
                </Grid>
            )}
            {props.activityDataCategories.filter((x) => x.classification === DataPrivacyClassification.None).length > 0 && (
                <Grid container spacing={2} className={"data-category-box"}>
                    <Grid sx={styles.infoHeaderGrid} item xs={4} md={2}>
                        <Typography sx={{ ...styles.infoHeader, ...styles.infoHeaderChildMargin }}>{translateString("dataCategoriNone")}</Typography>
                    </Grid>
                    {getDatacategoryContent(DataPrivacyClassification.None)}
                </Grid>
            )}
            {props.errorText && <Box sx={styles.errorText}>{props.errorText}</Box>}
        </Box>
    );
}

import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../common/api/apiShared";
import { SystemOverviewInfoViewModel } from "./SystemOverview.types";
import { useState } from "react";
import { UserPermissions } from "../../auth/userContextProvider/UserContextProvider.types";
import { useStateUrlParamsNumber } from "../../common/hooks/useStateUrlParams";

export function useSystemOverviewHook() {
    const navigate = useNavigate();
    const { getSystemsUrl, getSystemEditUrl } = useUrlProvider();
    const { translateString } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [showDeleteSystemDialog, setShowDeleteSystemDialog] = useState(false);
    const [showEditSystemTemplateInfo, setShowEditSystemTemplateInfo] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showInternalControlProcedureDialog, setShowInternalControlProcedureDialog] = useState(false);

    const [selectedTab, setSelectedTab] = useStateUrlParamsNumber("tab", 0);
    const [selectedPaperTab, setSelectedPaperTab] = useStateUrlParamsNumber("overviewTab", 0);

    const { id } = useParams<{ id: string }>();

    const queryKey = "systemOverview" + id;

    const infoQuery = useQuery(`${queryKey}`, () => get<SystemOverviewInfoViewModel>(`/SystemOverview/${id}/info`));

    let infoLoading = infoQuery.isLoading;
    let infoData = infoQuery.data;

    const deleteSystemSuccess = () => {
        navigate(getSystemsUrl());
    };

    const getBreadCrumbs = () => {
        return [
            {
                name: translateString("systems"),
                link: getSystemsUrl(),
            },
            {
                name: infoData?.name,
                tooltip: infoData?.description,
            },
        ];
    };

    return {
        infoLoading,
        infoData,
        getBreadCrumbs,
        getSystemsUrl,
        anchorEl,
        setAnchorEl,
        open: Boolean(anchorEl),
        popoverId: Boolean(anchorEl) ? "simple-popover" : undefined,
        editUrl: getSystemEditUrl(id),
        showDeleteSystemDialog,
        setShowDeleteSystemDialog,
        deleteSystemSuccess,
        showEditSystemTemplateInfo,
        setShowEditSystemTemplateInfo,
        id,
        selectedTab,
        setSelectedTab,
        selectedPaperTab,
        setSelectedPaperTab,
        showNoteDialog,
        setShowNoteDialog,
        showInternalControlProcedureDialog,
        setShowInternalControlProcedureDialog,
    };
}

export function hasAccessToSystemSteps(permissions: UserPermissions) {
    return (
        permissions.systemDescriptionPermissions?.permissions.read ||
        permissions.systemAssociationPermissions?.permissions.read ||
        permissions.systemDataProcessorPermissions?.permissions.read ||
        permissions.systemInformationPermissions?.permissions.read ||
        permissions.systemDataAndStoragePermissions?.permissions.read ||
        permissions.systemPolicyPermissions?.permissions.read ||
        permissions.systemPersonalDataPermissions?.permissions.read ||
        permissions.systemAssociatedAssetsPermissions?.permissions.read
    );
}

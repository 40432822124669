import React, { Fragment } from "react";
import { DataCategoryViewModel, DataPrivacyClassification } from "../DataCategoriesStep.types";
import { Box, Grid, Typography } from "@mui/material";
import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import DotLegalInformationBox from "../../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import { useDataCategoriesStepstyles } from "../DataCategoriesStep.styles";

export interface DataCategoryPickerProps {
    isLoading: boolean;
    readOnly: boolean | undefined;
    allDataCategories: Array<DataCategoryViewModel> | undefined;
    onDataCategoryClick: (isSelected: boolean, id: string) => void;
    disabledTooltip?: string;
    onDisabledTooltipClick?: (dataCategoryId: string) => void;
}

function DataCategoryPicker(props: DataCategoryPickerProps) {
    const { translateString } = useTranslation();
    const styles = useDataCategoriesStepstyles();
    const gridSpacing = 3;

    const dataCategoriesLoadingData: Array<DataCategoryViewModel> = [
        { id: "1", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "2", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "3", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "4", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "5", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "6", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "7", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "8", name: "", classification: DataPrivacyClassification.Ordinary, description: "", isSelected: false },
        { id: "9", name: "", classification: DataPrivacyClassification.NationalIdentificationNumber, description: "", isSelected: false },
        { id: "10", name: "", classification: DataPrivacyClassification.CriminalOffenses, description: "", isSelected: false },
        { id: "11", name: "", classification: DataPrivacyClassification.Sensitive, description: "", isSelected: false },
        { id: "12", name: "", classification: DataPrivacyClassification.Sensitive, description: "", isSelected: false },
        { id: "13", name: "", classification: DataPrivacyClassification.Sensitive, description: "", isSelected: false },
        { id: "14", name: "", classification: DataPrivacyClassification.Sensitive, description: "", isSelected: false },
    ];

    const data = props.isLoading ? dataCategoriesLoadingData : (props.allDataCategories ?? []);

    const getDataCategoryGridContainer = (classification: DataPrivacyClassification) => {
        return (
            <Grid container spacing={gridSpacing}>
                {data!
                    .filter((x) => x.classification === classification)
                    .map((d, index) => {
                        return (
                            <Grid key={d.id} item>
                                <DotLegalInformationBox
                                    isDisabled={props.readOnly || d.disabled}
                                    header={d.name}
                                    description={d.description}
                                    isSelected={d.isSelected ? "selected" : "unselected"}
                                    size="large"
                                    isLoading={props.isLoading}
                                    onChange={(isSelected) => props.onDataCategoryClick(isSelected, d.id)}
                                    disabledTooltip={d.disabled ? props.disabledTooltip : undefined}
                                    onDisabledTooltipClick={props.onDisabledTooltipClick ? () => props.onDisabledTooltipClick!(d.id) : undefined}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        );
    };

    return (
        <Fragment>
            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.Ordinary).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriOrdinary")}</Typography>
                            <Box sx={styles.toolTipContainer}>
                                <DotLegalInformationTooltip text={translateString("nonSensitiveHelperText")} />
                            </Box>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.Ordinary)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.NationalIdentificationNumber).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("nationalidentificationnumber")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.NationalIdentificationNumber)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.CriminalOffenses).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("criminaloffenses")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.CriminalOffenses)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.Sensitive).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriSensitive")}</Typography>
                            <Box sx={styles.toolTipContainer}>
                                <DotLegalInformationTooltip text={translateString("sensitiveHelperText")} />
                            </Box>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.Sensitive)}
                    </Fragment>
                )}
            </Fragment>

            <Fragment>
                {data!.filter((x) => x.classification === DataPrivacyClassification.None).length > 0 && (
                    <Fragment>
                        <Box sx={styles.firstTypographyContainer}>
                            <Typography sx={styles.infoHeader}>{translateString("dataCategoriNone")}</Typography>
                        </Box>
                        {getDataCategoryGridContainer(DataPrivacyClassification.None)}
                    </Fragment>
                )}
            </Fragment>
        </Fragment>
    );
}

export default DataCategoryPicker;

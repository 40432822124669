import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { DotLegalBreadCrumbModel, useHistoryWithReferer, useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { useTranslation } from "../../localization/useTranslation";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { useUrlProvider } from "../../useUrlProvider";
import { UsersViewModel, UserViewModel } from "../User.types";
import { IsUsersLimitReached, RefetchUserCountQuery } from "../../auth/planProvider/PlanProvider.hooks";

export function useUsersDataMapping() {
    const queryClient = useQueryClient();
    const historyWithReferer = useHistoryWithReferer();
    const urlProvider = useUrlProvider();
    const { translateString } = useTranslation();
    const refetchUserCountQuery = RefetchUserCountQuery();

    const [showTransferResponsbilityDialog, setShowTransferResponsbilityDialog] = useState(false);
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showAllUsers, setShowAllUsers] = useStateLocalStorage("onlyActiveUsers", false);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("userDataProcessAreas", []);
    const [searchedUserRoles, setSearchedUserRoles] = useStateUrlParamsArray("UserRoles", []);

    const queryPart = `?showAllUsers=${showAllUsers}`;
    const url = `/Users${queryPart}`;
    let { isLoading, data } = useQuery(url, () => get<UsersViewModel>(url));
    var userLimitReached = IsUsersLimitReached();

    let businessAreaFilterOptions: Array<DotLegalSelectOption> = [];
    let userRolesFilterOptions: Array<DotLegalSelectOption> = [];
    let users;
    const hasUsers = isLoading ? true : data!.users.length > 0;

    if (data) {
        data.users.forEach((x) => {
            createBusinessAreaSelectOptions(x.businessAreas);
            createUserRolesSelectOptions(x.userRoles);
        });
        users = getFilteredRows(data.users);
    }

    const handleUserAddSuccess = () => {
        queryClient.refetchQueries(url);
        refetchUserCountQuery();
        setShowAddUserDialog(false);
    };

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("users") });

        return breadCrumbs;
    };

    return {
        getBreadCrumbs,
        isLoading,
        users,
        showAllUsers,
        setShowAllUsers,
        showAddUserDialog,
        setShowAddUserDialog,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedUserRoles,
        setSearchedUserRoles,
        businessAreaFilterOptions,
        userRolesFilterOptions,
        handleUserAddSuccess,
        goToUser,
        hasUsers,
        userLimitReached,
        showTransferResponsbilityDialog,
        setShowTransferResponsbilityDialog,
    };

    function goToUser(userId: string) {
        historyWithReferer.push(urlProvider.getUserUrl(userId));
    }

    function getFilteredRows(overviewRows: Array<UserViewModel>) {
        let tempData = [...overviewRows];
        if (searchedBusinessAreas.length > 0 && searchedBusinessAreas[0] !== "") {
            tempData = tempData.filter((row) => row.businessAreas.filter((x) => searchedBusinessAreas.indexOf(x.id) >= 0).length > 0);
        }

        if (searchedUserRoles.length > 0 && searchedUserRoles[0] !== "") {
            tempData = tempData.filter((row) => row.userRoles.filter((x) => searchedUserRoles.indexOf(x.id) >= 0).length > 0);
        }
        return tempData;
    }

    function createBusinessAreaSelectOptions(businessAreas: Array<SelectableItem>) {
        businessAreas.forEach((x) => {
            if (!businessAreaFilterOptions.some((b) => b.id === x.id)) {
                businessAreaFilterOptions.push({ name: x.name, id: x.id });
            }
        });
    }

    function createUserRolesSelectOptions(userRoles: Array<SelectableItem>) {
        userRoles.forEach((x) => {
            if (!userRolesFilterOptions.some((b) => b.id === x.id)) {
                userRolesFilterOptions.push({ name: x.name, id: x.id });
            }
        });
    }
}

import { SelectableColoredItem, SelectableItem } from "../../processingActivity/ProcessingActivity.types";

export interface RiskAssessmentViewModel {
    users: Array<SelectableItem>;
    businessAreas: Array<SelectableColoredItem>;
    rows: Array<RiskAssessmentRowViewModel>;
    showEmptyState: boolean;
}

export interface RiskAssessmentRowViewModel {
    processingActivityId: string | undefined;
    name: string;
    businessAreas: Array<SelectableColoredItem>;
    businessAreaSorting: string;
    scenariosScore: number | null;
    totalScore: number | null;
    consequenceScore: number | null;
    probabilityScore: number | null;
    owner: string;
    ownerId: string;
    status: number;
    systemId: string | undefined;
}

export enum RiskAssessmentArea {
    ProcessingActivities = 1,
    Systems = 2,
}

export enum RiskComplianceArea {
    GDPR = 1,
    NIS2 = 2,
}

export interface riskAreaAndComplianceAreaLocalStorageModel {
    riskArea: RiskAssessmentArea;
    complianceArea: RiskComplianceArea;
    versionId: string | null;
}

import useDeclarationCategories from "./DeclarationCategories.hooks.ts";
import React from "react";
import {
    DotLegalActionBar,
    DotLegalBreadCrumbModel,
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { TableCell, TableRow } from "@mui/material";
import EmptyState from "../declaration/declarations/DeclarationsEmptyState.svg?react";
import { useTranslation } from "../localization/useTranslation.ts";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { DeclarationCategoryViewModel } from "./DeclarationCategories.types.ts";
import AddEditDeclarationCategoryDialog from "./addEditDialog/AddEditDeclarationCategoryDialog.tsx";
import DeleteDeclarationCategoryDialog from "./deleteDialog/DeleteDeclarationCategoryDialog.tsx";
import { useUrlProvider } from "../useUrlProvider.ts";

function DeclarationCategories() {
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();
    const { customerName, permissions } = useUserContext();
    const {
        isLoading,
        data,
        hasData,
        showAddEditDialog,
        setShowAddEditDialog,
        selectedCategory,
        setSelectedCategory,
        showDeleteDialog,
        setShowDeleteDialog,
        categoryId,
        refetch,
    } = useDeclarationCategories();

    function getHeaders() {
        let headers = Array<ITableHeader<DeclarationCategoryViewModel>>();
        headers.push({ property: "name", text: translateString("category"), align: "left", showOnMobile: true });
        headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
        return headers;
    }

    function getMenuItems(row: DeclarationCategoryViewModel) {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedCategory(row);
                setShowAddEditDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedCategory(row);
                setShowDeleteDialog(true);
            },
            disabled: row.inUse,
        });

        return menuItems;
    }

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        if (data) {
            breadCrumbs.push({
                name: translateString("declarations"),
                link: urlProvider.getDeclarationsUrl(),
            });
            breadCrumbs.push({
                name: data.declarationName,
                link: urlProvider.getDeclarationUrl(data.declarationId),
            });
            breadCrumbs.push({
                name: translateString("categories"),
            });
        }

        return breadCrumbs;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />

            {permissions.declarationPermissions.edit && (
                <DotLegalActionBar
                    primaryButton={
                        permissions.declarationPermissions.edit ? (
                            <DotLegalButton buttonType="primary" size="medium" onClick={() => setShowAddEditDialog(true)}>
                                {`${translateString("create")} ${translateString("category").toLowerCase()}`}
                            </DotLegalButton>
                        ) : (
                            <></>
                        )
                    }
                    canCreate
                    showDialog={showAddEditDialog}
                >
                    {showAddEditDialog && categoryId && permissions.declarationPermissions.edit && (
                        <AddEditDeclarationCategoryDialog
                            declarationId={categoryId!}
                            model={selectedCategory}
                            onDialogClose={() => {
                                setShowAddEditDialog(false);
                                setSelectedCategory(undefined);
                                refetch();
                            }}
                        />
                    )}
                </DotLegalActionBar>
            )}

            <DotLegalPaper>
                {hasData ? (
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={permissions.declarationPermissions.edit}
                        paginationLabelOf={translateString("labelOf")}
                        getUserSpecificPageLength={() => 30}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        data={data?.rows}
                        emptyText={translateString("noData")}
                        renderRow={(row) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    {permissions.declarationPermissions.edit && (
                                        <TableCell align={"right"}>
                                            <DotLegalOverflowMenu items={getMenuItems(row)} />
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        }}
                    />
                ) : (
                    <DotLegalEmptyState icon={<EmptyState />} text={translateString("declarationCategoryEmptyState")} />
                )}
            </DotLegalPaper>

            {showDeleteDialog && selectedCategory && (
                <DeleteDeclarationCategoryDialog
                    id={selectedCategory.id}
                    name={selectedCategory.name}
                    onCloseDialog={() => {
                        setShowDeleteDialog(false);
                        setSelectedCategory(undefined);
                        refetch();
                    }}
                    declarationId={categoryId!}
                />
            )}
        </React.Fragment>
    );
}

export default DeclarationCategories;

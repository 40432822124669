import { useQueryClient } from "react-query";
import { ProcessingActivityModel, ProcessingActivityValidationViewModel } from "../../processingActivity/ProcessingActivity.types";
import { SystemModel } from "../../system/systemEdit/SystemEdit.types";
import { ajaxQueue } from "../api/ajaxQueue";
import { Result } from "../api/result";

export function useOptimisticUpdate() {
    const queryClient = useQueryClient();

    async function updateQueryData<T>(queryKey: string, object: T, updateCallback: (object: T) => void): Promise<T> {
        const o = { ...object };
        updateCallback(o);
        await setQueryData(queryKey, o);
        return o;
    }

    async function setQueryData<T>(queryKey: string, data: T) {
        /* Sørger for at cancel refetch queries ved window focus. Queries der hentes første gang (isLoading) cancles ikke */
        await queryClient.cancelQueries({
            predicate: (q) => {
                return q.state.status === "success";
            },
        });

        queryClient.setQueryData(queryKey, data);
    }

    async function addToQueue<TVariables, TResponse>(req: (vars: TVariables) => Promise<Result<TResponse | unknown>>, vars: TVariables, queryKey?: string) {
        const queue = ajaxQueue(queryKey);
        const response = await queue.addRequest(req, vars);
        if (!queue.isItemsOnQueue()) {
            if (!!response && queryKey) {
                queryClient.setQueryData(queryKey, response.value());
            }
        }
    }

    return {
        updateWithProcessingActivityResponse: async function update<TVariables>(
            processingActivity: ProcessingActivityModel,
            req: (vars: TVariables) => Promise<Result<ProcessingActivityModel | unknown>>,
            vars: TVariables
        ) {
            const queryKey = "processingActivity" + processingActivity.id;
            await setQueryData(queryKey, processingActivity);
            await addToQueue(req, vars, queryKey);
        },
        updateWithSystemResponse: async function update<TVariables>(
            system: SystemModel,
            req: (vars: TVariables) => Promise<Result<SystemModel | unknown>>,
            vars: TVariables
        ) {
            const queryKey = "system" + system.id;
            await setQueryData(queryKey, system);
            await addToQueue(req, vars, queryKey);
        },
        updateWithProcessingActivityValidationReponse: async function update<TVariables>(
            validation: ProcessingActivityValidationViewModel,
            processingActivityId: string | undefined,
            req: (vars: TVariables) => Promise<Result<boolean>>,
            vars: TVariables
        ) {
            const queryKey = "processingActivity" + processingActivityId + "validation";
            await setQueryData(queryKey, validation);

            const queue = ajaxQueue(queryKey);
            const response = await queue.addRequest(req, vars);
            if (!queue.isItemsOnQueue()) {
                if (!!response && queryKey) {
                    return response.value();
                }
            }

            return false;
        },
        putOnQueueAndSetQueryData: async function update<TData, TVariables>(
            tasks: TData,
            queryKey: string,
            req: (vars: TVariables) => Promise<Result<unknown>>,
            vars: TVariables
        ) {
            await setQueryData(queryKey, tasks);
            await addToQueue(req, vars, queryKey);
        },
        setQueryData: setQueryData,
        updateQueryData,
    };
}

import { Box, Grid, TableCell, TableRow } from "@mui/material";
import React from "react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { DotLegalMultiSelect, DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { BaseSharingRowsModel, SharingBaseTableViewModel } from "./SharingsTable.types";
import NoSharings from "../../processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { useUrlProvider } from "../../../useUrlProvider";
import { GridSizes, ITableHeader } from "../../../common/components/dotLegalTable/DotLegalTable.types";
import { StepTypeEnum } from "../../ProcessingActivity.types";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useSharingsBaseTableStyles } from "./SharingsTable.styles";
import { useSharingsBaseTableHook } from "./SharingsTable.hooks";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { informationIcon } from "../../../common/icons";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { SharingOrigin } from "../../processingActivityOverview/ProcessingActivityOverview.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export interface ISharingsBaseTableProps {
    data: SharingBaseTableViewModel | undefined;
    isLoading: boolean;
    emptyStateText: string;
}

function SharingsBaseTable(props: ISharingsBaseTableProps) {
    const { translateString } = useTranslation();
    const { getEditProcessingActivityUrl, getProcessingActivityOverviewUrl } = useUrlProvider();
    const {
        searchedSenders,
        setSearchedSenders,
        searchedRecipients,
        setSearchedRecipients,
        searchedSharingType,
        setSearchedSharingType,
        searchedLevelOfProtecetion,
        setsearchedLevelOfProtecetion,
        searchedDataCategories,
        setSearchedDataCategories,
        sharingsDataRows,
        datacategoriesData,
        legalEntitiesData,
        recipientsEntitiesData,
        sharingsTypeOptions,
        sharingsLevelOfProtectionData,
        hasSharings,
    } = useSharingsBaseTableHook(props);

    const styles = useSharingsBaseTableStyles();
    const { permissions } = useUserContext();

    function getProcessingActivityLink(row: BaseSharingRowsModel) {
        if (permissions?.processingActivityPermissions.read) {
            return (
                <DotLegalLink linkColor={"primary"} to={getProcessingActivityOverviewUrl(row.id)}>
                    {row.name}
                </DotLegalLink>
            );
        }

        return row.name;
    }

    function getSharingLink(row: BaseSharingRowsModel, role: string) {
        if (hasSharingAccess(row.sharingOrigin)) {
            return (
                <DotLegalLink linkColor={"primary"} to={getEditProcessingActivityUrl(row.id, StepTypeEnum.sharings, row.subStep, row.sharingId)}>
                    {role}
                </DotLegalLink>
            );
        }

        return role;
    }

    function hasSharingAccess(sharingOrigin: SharingOrigin) {
        if (sharingOrigin.dataControllerId) {
            return permissions?.processingActivityDataControllersPermissions?.permissions?.read;
        } else if (sharingOrigin.sourceId) {
            return permissions?.processingActivitySourcesPermissions?.permissions?.read;
        } else if (sharingOrigin.transferId) {
            return permissions?.processingActivityDisclosuresPermissions?.permissions?.read;
        } else if (sharingOrigin.dataProcessorId) {
            return permissions?.processingActivityDataProcessorsPermissions?.permissions?.read;
        } else {
            return permissions?.processingActivityJointDataControllerPermissions?.permissions?.read;
        }
    }

    const tableGridProps: GridSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };

    function getAdditionalFilters() {
        return (
            <React.Fragment>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSharingType}
                        chosenOptions={searchedSharingType}
                        label={translateString("sharingType")}
                        placeholder={translateString("sharingType")}
                        isLoading={props.isLoading}
                        options={sharingsTypeOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSenders}
                        chosenOptions={searchedSenders}
                        label={translateString("Senders")}
                        placeholder={translateString("Senders")}
                        isLoading={props.isLoading}
                        options={legalEntitiesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataCategories}
                        chosenOptions={searchedDataCategories}
                        label={translateString("dataCategoryHeader")}
                        placeholder={translateString("dataCategoryHeader")}
                        isLoading={props.isLoading}
                        options={datacategoriesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRecipients}
                        chosenOptions={searchedRecipients}
                        label={translateString("Recipients")}
                        placeholder={translateString("Recipients")}
                        isLoading={props.isLoading}
                        options={recipientsEntitiesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setsearchedLevelOfProtecetion}
                        chosenOptions={searchedLevelOfProtecetion}
                        label={translateString("sharingsTo")}
                        placeholder={translateString("sharingsTo")}
                        isLoading={props.isLoading}
                        options={sharingsLevelOfProtectionData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    return hasSharings ? (
        <DotLegalTableWithControls
            searchFieldGridSizes={tableGridProps}
            searchFieldFullWidth
            extraControls={getAdditionalFilters()}
            headers={GetSharingsHeader(translateString)}
            getUserSpecificPageLength={() => 30}
            hideRowsPerPage
            defaultOrderBy={"senderName"}
            clickableRows={false}
            defaultOrder={"asc"}
            isLoading={props.isLoading}
            data={sharingsDataRows}
            emptyText={translateString("noSharingsMatchesQuery")}
            labelRowsPerPage={translateString("showEntites")}
            labelSearch={translateString("search")}
            noOptionsLabel={translateString("noOptions")}
            paginationLabelOf={translateString("labelOf")}
            renderRow={(row, i) => (
                <TableRow key={i}>
                    <TableCell>
                        <div>
                            <Box sx={styles.legalEntity}>{row.senderName}</Box>
                            {row.senderId !== null && <Box sx={styles.entityRole}>{getSharingLink(row, row.senderRole)}</Box>}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {row.dataCategories.map((x, i) => {
                                return (
                                    <div key={x.id} style={{ marginRight: 8, display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "inline-block", marginRight: 3 }}>{x.name}</div>
                                        {!isNullOrWhitespace(x.description) && (
                                            <DotLegalTooltip text={x.description}>
                                                <Box sx={styles.tooltipIcon}>{informationIcon}</Box>
                                            </DotLegalTooltip>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div>
                            <Box sx={styles.legalEntity}>
                                {row.recipientName}
                                {row.recipientInThirdCountry && (
                                    <DotLegalTooltip text={translateString("recipientFromInsecureThirdCountry")}>
                                        {<ReportProblemOutlinedIcon fontSize={"small"} />}
                                    </DotLegalTooltip>
                                )}
                            </Box>
                            {row.recipientId !== null && <Box sx={styles.entityRole}>{getSharingLink(row, row.recipientRole)}</Box>}
                        </div>
                    </TableCell>
                    <TableCell>
                        <Box sx={styles.entityRole}>{getProcessingActivityLink(row)}</Box>
                    </TableCell>
                </TableRow>
            )}
        />
    ) : (
        <DotLegalEmptyState icon={<NoSharings />} text={props.emptyStateText} />
    );
}

export default SharingsBaseTable;

function GetSharingsHeader(translateString: any) {
    const headers = Array<ITableHeader<BaseSharingRowsModel>>();
    headers.push({ property: "senderName", text: translateString("Sender"), align: "left", showOnMobile: true });
    headers.push({ property: "dataCategories", text: translateString("dataCategoryHeader"), align: "left", showOnMobile: true });
    headers.push({ property: "recipientName", text: translateString("recipient"), align: "left", showOnMobile: true });
    headers.push({ property: "name", text: translateString("processingActivity"), align: "left", showOnMobile: true });
    return headers;
}

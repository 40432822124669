import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { DataCategorySaveModel, DataCategoryViewModel } from "./DataCategories.types";

export function useDataCategoriesHooks() {
    const queryClient = useQueryClient();
    const [showAllDataCategories, setShowAllDataCategories] = useStateLocalStorage("onlyActiveDataCategorys", false);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showDeleteDataCategoryDialog, setShowDeleteDataCategoryDialog] = useState(false);
    const [selectedDataCategory, setSelectedDataCategory] = useState<DataCategorySaveModel | undefined>(undefined);
    const [showChangeStatusDialog, setShowChangeStausDialog] = useState(false);

    const queryPart = `?showAllDataCategories=${showAllDataCategories}`;
    const url = `/DataCategories${queryPart}`;
    const { isLoading, data } = useQuery(url, () => get<Array<DataCategoryViewModel>>(url));

    let dataCategories;
    if (data) {
        dataCategories = data.map((dc) => {
            return {
                id: dc.id,
                name: dc.name,
                description: dc.description,
                classification: dc.classification,
                confidential: dc.confidential,
                isCustom: dc.isCustom,
                active: dc.active,
            };
        });
    }

    const refetchDataCategories = () => {
        queryClient.refetchQueries(url);
    };

    return {
        isLoading,
        dataCategories,
        showAllDataCategories,
        setShowAllDataCategories,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataCategoryDialog,
        setShowDeleteDataCategoryDialog,
        selectedDataCategory,
        setSelectedDataCategory,
        refetchDataCategories,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    };
}

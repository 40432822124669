import { useQuery } from "react-query";
import { get, postGet } from "../../../common/api/apiShared";
import {
    AuditGroupLegalEntityRowApiModel,
    AuditGroupLegalEntityRowViewModel,
    LegalEntityAuditGroupSelectableSystem,
    LegalEntityAuditInformation,
    LegalEntityAuditInformationViewModel,
    LegalEntityClassificationInformationViewModel,
} from "./AuditGroupLegalEntitySelector.types";
import { useState } from "react";
import { IAuditGroupLegalEntitySelector } from "./AuditGroupLegalEntitySelector";
import { LegalEntityAuditGroupDraft } from "../LegalEntityAuditGroupOverview.types";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParams, useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams";
import { LegalEntityAuditInformationsDto, LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { RiskLevel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { useLocation, useNavigate } from "react-router-dom";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../../legalEntity/legalEntityManagement/LegalEntityManagement.types";

export default function useAuditGroupLegalEntitySelectorHooks(props: IAuditGroupLegalEntitySelector) {
    const navigate = useNavigate();
    const location = useLocation();
    const { translateCountry, translateString, translateDate } = useTranslation();
    const [showAddContactDialog, setShowAddContactDialog] = useState<string | undefined>(undefined);
    const [showSendAuditsDialog, setShowSendAuditsDialog] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);

    const [searchedCountry, setSearchedCountry] = useStateUrlParamsArray("countryCodes", []);
    const [searchedType, setSearchedType] = useStateUrlParamsArray("type", []);
    const [searchedRiskLevel, setSearchedRisklevel] = useStateUrlParamsArray("riskLevel", []);
    const [searchedClassification, setSearchedClassification] = useStateUrlParams("classification", "");
    const [searchedLatestAudits, setSearchedLatestAudits] = useStateUrlParamsArray("latestAudit", []);
    const [searchedLatestAuditSubmission, setSearchedLatestAuditSubmission] = useStateUrlParamsArray("latestAuditSubmission", []);
    const [searchedSupervisionType, setSearchedSupervisionType] = useStateUrlParamsArray("supervisionType", []);
    const [searchedSupervisionFrequency, setSearchedSupervisionFrequency] = useStateUrlParamsArray("supervisionFrequency", []);

    const auditInformationsUrl = `/legalEntityAudit/auditInformations`;
    let auditInformationsQuery = useQuery(
        auditInformationsUrl,
        () =>
            postGet<Array<LegalEntityAuditInformation>>(auditInformationsUrl, {
                legalEntityIds: props.rows.map((x) => x.id),
                fetchLatestAudit: true,
            } as LegalEntityAuditInformationsDto),
        {
            enabled: !props.selectedEntititesIsLoading,
        }
    );

    if (auditInformationsQuery.data) {
        auditInformationsQuery.data.forEach((s) => {
            var entity = props.rows.find((x) => x.id === s.legalEntityId);

            if (entity) {
                entity.classifications = s.classifications;
                entity.latestAudit = s.latestAudit;
            }
        });
    }

    const selectableSystemsUrl = `/legalEntityAuditGroups/systems`;
    let selectableSystemsQuery = useQuery(selectableSystemsUrl, () => get<Array<LegalEntityAuditGroupSelectableSystem>>(selectableSystemsUrl), {
        enabled: !props.selectedEntititesIsLoading,
    });

    if (selectableSystemsQuery.data) {
        selectableSystemsQuery.data.forEach((s) => {
            var entity = props.rows.find((x) => x.id === s.legalEntityId);

            if (entity) {
                entity.assets = s.selectableSystems;
            }
        });
    }

    const filters = {
        countrySelectOptions: Array<DotLegalSelectOption>(),
        typeSelectOptions: Array<DotLegalSelectOption>(),
        latestAuditFilterOptions: Array<DotLegalSelectOption>(),
        latestAuditSubmissionFilterOptions: Array<DotLegalSelectOption>(),
        superVisionTypeOptions: Array<DotLegalSelectOption>(),
        superVisionFrequencyOptions: Array<DotLegalSelectOption>(),
        riskLevelOptions: Array<DotLegalSelectOption>(),
        classificationOptions: Array<DotLegalSelectOption>(),
    };

    createRiskLevelSelectOptions();
    onSelectClassification(searchedClassification);

    let rows: Array<AuditGroupLegalEntityRowApiModel> | undefined = undefined;
    if (props.rows.length > 0) {
        rows = [...props.rows];

        rows.forEach((x) => {
            createCountrySelectOptions(x.countryCode);
            createTypeOptions(x.legalEntityTypes);
            createLatestAuditFilterOptions(x.latestAudit);
            createLatestAuditSubmissionFilterOptions(x.latestAudit);
            createAuditTypeFilterOptions(x.auditType);
            createAuditFrequencyFilterOptions(x.auditFrequency);
            createClassificationFilterOptions(x.classifications ?? []);
        });

        sortSelectOptions();

        rows = getFilteredRows(rows);
    }

    const onCheckBoxClick = (row: AuditGroupLegalEntityRowApiModel) => {
        let temp = [...props.selectedEntities];
        if (temp.some((x) => x.legalEntityId === row.id)) {
            temp = temp.filter((x) => x.legalEntityId !== row.id);
        } else {
            var contact = row.contacts.find((x) => x.isPrimaryContact);
            temp.push(new LegalEntityAuditGroupDraft(row.id, contact?.id, []));
        }

        props.onSelectedEntitiesUpdated(temp);
    };

    const onSelectAllEntities = (selected: Array<AuditGroupLegalEntityRowViewModel>) => {
        let selectedDrafts = selected.map((s) => {
            const contact = props.rows.find((r) => r.id === s.id)?.contacts.find((x) => x.isPrimaryContact);
            return new LegalEntityAuditGroupDraft(s.id, contact?.id, []);
        });

        props.onSelectedEntitiesUpdated(selectedDrafts);
    };

    const onContactChange = (rowId: string, contactId: string) => {
        let temp = [...props.selectedEntities];

        let entity = temp.find((x) => x.legalEntityId == rowId);

        if (entity) {
            entity.contactId = contactId;
        }

        props.onSelectedEntitiesUpdated(temp);
    };

    const onSystemChange = (rowId: string, systemIds: Array<string>) => {
        let temp = [...props.selectedEntities];

        let entity = temp.find((x) => x.legalEntityId == rowId);

        if (entity) {
            entity.systemIds = systemIds;
        }

        props.onSelectedEntitiesUpdated(temp);
    };

    const setHeaderCheckBoxState = () => {
        let allSelected = rows?.every(function (item) {
            return props.selectedEntities.some((x) => x.legalEntityId === item.id);
        });

        return props.selectedEntities?.length === 0 ? "false" : allSelected ? "true" : "indeterminate";
    };

    const onUpdateClassificationFilter = (classification: string | null) => {
        const urlSearchParams = new URLSearchParams(location.search);

        if (classification === null) {
            urlSearchParams.delete("classification");
            urlSearchParams.delete("riskLevel");
        } else {
            urlSearchParams.set("classification", classification);
        }

        navigate({
            pathname: location.pathname,
            search: urlSearchParams.toString(),
        });
    };

    return {
        systemsIsLoading: selectableSystemsQuery.isLoading,
        auditIsLoading: auditInformationsQuery.isLoading,
        showAddContactDialog,
        setShowAddContactDialog,
        onCheckBoxClick,
        setHeaderCheckBoxState,
        onSelectAllEntities,
        onContactChange,
        onSystemChange,
        showSendAuditsDialog,
        setShowSendAuditsDialog,
        sendClicked,
        setSendClicked,
        filters,
        searchedCountry,
        setSearchedCountry,
        searchedType,
        setSearchedType,
        searchedLatestAudits,
        setSearchedLatestAudits,
        searchedLatestAuditSubmission,
        setSearchedLatestAuditSubmission,
        searchedSupervisionType,
        setSearchedSupervisionType,
        searchedSupervisionFrequency,
        setSearchedSupervisionFrequency,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassification,
        setSearchedClassification,
        onUpdateClassificationFilter,
        rows,
    };

    function getFilteredRows(rows: Array<AuditGroupLegalEntityRowApiModel>): Array<AuditGroupLegalEntityRowApiModel> {
        let tempData = [...rows];

        if (searchedType.length > 0 && searchedType[0] !== "") {
            tempData = tempData.filter((row) => row.legalEntityTypes.filter((type) => searchedType.indexOf(type.toString()) >= 0).length > 0);
        }

        if (searchedCountry.length > 0 && searchedCountry[0] !== "") {
            tempData = tempData.filter((row) => searchedCountry.indexOf(row.countryCode.toLowerCase()) >= 0);
        }

        if (searchedClassification) {
            tempData = tempData.filter(
                (row) => row.classifications?.filter((classification) => searchedClassification.indexOf(classification.questionnaireId) >= 0).length > 0
            );
        }

        if (searchedRiskLevel.length > 0 && searchedRiskLevel[0] !== "") {
            tempData.forEach(
                (row) =>
                    (row.classifications = row.classifications.filter(
                        (classification) =>
                            (classification && searchedRiskLevel.indexOf(classification.riskLevel?.toString() ?? "") >= 0) ||
                            (searchedRiskLevel.includes("null") && classification.riskLevel === null)
                    ))
            );

            tempData = tempData.filter((x) => x.classifications.length > 0);
        }

        if (searchedLatestAudits.length > 0 && searchedLatestAudits[0] !== "") {
            tempData = tempData.filter((row) => searchedLatestAudits.indexOf(row.latestAudit?.questionnaireId ?? "") >= 0);
        }

        if (searchedLatestAuditSubmission.length > 0 && searchedLatestAuditSubmission[0] !== "") {
            tempData = tempData.filter((row) => {
                if (row.latestAudit) {
                    if (row.latestAudit.isClosed) {
                        return searchedLatestAuditSubmission.indexOf("isclosed") >= 0;
                    }

                    if (row.latestAudit.submissionDate) {
                        return searchedLatestAuditSubmission.indexOf(new Date(row.latestAudit.submissionDate).getTime().toString()) >= 0;
                    }

                    return searchedLatestAuditSubmission.indexOf("notsubmitted") >= 0;
                }

                return false;
            });
        }

        if (searchedSupervisionType.length > 0 && searchedSupervisionType[0] !== "") {
            tempData = tempData.filter((row) => row.auditType && searchedSupervisionType.indexOf(row.auditType.toString()) >= 0);
        }

        if (searchedSupervisionFrequency.length > 0 && searchedSupervisionFrequency[0] !== "") {
            tempData = tempData.filter((row) => row.auditFrequency && searchedSupervisionFrequency.indexOf(row.auditFrequency.toString()) >= 0);
        }

        return tempData;
    }

    function createCountrySelectOptions(countryCode: string) {
        if (!filters.countrySelectOptions.some((x) => x.id === countryCode.toLowerCase())) {
            filters.countrySelectOptions.push({ name: translateCountry(countryCode), id: countryCode.toLowerCase() });
        }
    }

    function createTypeOptions(types: Array<LegalEntityMasterType>) {
        for (let type of types) {
            if (!filters.typeSelectOptions.some((x) => x.id === type.toString())) {
                filters.typeSelectOptions.push({
                    id: type.toString(),
                    name: translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())),
                });
            }
        }
    }

    function createClassificationFilterOptions(classifications: Array<LegalEntityClassificationInformationViewModel>) {
        classifications.forEach((classification) => {
            if (!filters.classificationOptions.some((x) => x.id == classification.questionnaireId)) {
                filters.classificationOptions.push({ id: classification.questionnaireId, name: classification.name });
            }
        });
    }

    function createRiskLevelSelectOptions() {
        if (auditInformationsQuery.data) {
            let riskLevels = props.rows
                .flatMap((x) => x.classifications)
                .filter((x) => x && x.questionnaireId === searchedClassification)
                .map((x) => x.riskLevel);

            riskLevels.forEach((level) => {
                if (level !== null && level !== undefined && !filters.riskLevelOptions.some((x) => x.id === level?.toString())) {
                    !filters.riskLevelOptions.push({
                        id: level.toString(),
                        name: translateString(setFirstLetterToLowerCase(RiskLevel[level])),
                    });
                }
            });

            if (riskLevels.some((x) => x === null)) {
                !filters.riskLevelOptions.push({ id: "null", name: translateString("notAnswered") });
            }
        }

        filters.riskLevelOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    function createLatestAuditFilterOptions(legalEntitiesAuditViewModel: LegalEntityAuditInformationViewModel | null) {
        if (legalEntitiesAuditViewModel && !filters.latestAuditFilterOptions.some((x) => x.id === legalEntitiesAuditViewModel.questionnaireId)) {
            filters.latestAuditFilterOptions.push({ id: legalEntitiesAuditViewModel.questionnaireId, name: legalEntitiesAuditViewModel.name });
        }
    }

    function createLatestAuditSubmissionFilterOptions(legalEntitiesAuditViewModel: LegalEntityAuditInformationViewModel | null) {
        if (legalEntitiesAuditViewModel) {
            if (legalEntitiesAuditViewModel.isClosed && !filters.latestAuditSubmissionFilterOptions.some((x) => x.id === "isclosed")) {
                filters.latestAuditSubmissionFilterOptions.push({ id: "isclosed", name: translateString("excluded") });
            } else if (legalEntitiesAuditViewModel.submissionDate) {
                const submissionDate = new Date(legalEntitiesAuditViewModel.submissionDate);

                if (!filters.latestAuditSubmissionFilterOptions.some((x) => x.id === submissionDate.getTime().toString())) {
                    filters.latestAuditSubmissionFilterOptions.push({
                        id: submissionDate.getTime().toString(),
                        name: translateDate(submissionDate),
                    });
                }
            } else if (!filters.latestAuditSubmissionFilterOptions.some((x) => x.id === "notsubmitted")) {
                filters.latestAuditSubmissionFilterOptions.push({
                    id: "notsubmitted",
                    name: translateString("notSubmitted"),
                });
            }
        }
    }

    function createAuditTypeFilterOptions(auditType?: LegalEntityAuditType) {
        if (auditType && !filters.superVisionTypeOptions.some((x) => x.id === auditType.toString())) {
            filters.superVisionTypeOptions.push({
                name: translateString(setFirstLetterToLowerCase(LegalEntityAuditType[auditType].toString())),
                id: auditType.toString(),
            });
        }
    }

    function createAuditFrequencyFilterOptions(auditFrequency?: LegalEntityAuditFrequency) {
        if (auditFrequency && !filters.superVisionFrequencyOptions.some((x) => x.id === auditFrequency.toString())) {
            filters.superVisionFrequencyOptions.push({
                name: translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[auditFrequency].toString())),
                id: auditFrequency.toString(),
            });
        }
    }

    function onSelectClassification(classificationId: string | null) {
        if (auditInformationsQuery.data) {
            if (classificationId) {
                props.rows.forEach((x) => (x.classifications = x.classifications.filter((x) => x.questionnaireId === classificationId)));
            }
        }
    }

    function sortSelectOptions() {
        filters.countrySelectOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        filters.typeSelectOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        filters.classificationOptions.sort((a, b) => (a.name > b.name ? 1 : -1));

        filters.latestAuditFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        filters.latestAuditSubmissionFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        filters.superVisionFrequencyOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        filters.superVisionTypeOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
}

import { useState } from "react";
import { LegalEntityContactSaveModel, LegalEntityContactType, LegalEntityContactViewModel } from "../LegalEntityContactTab.types";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../../localization/useTranslation";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { useValidator } from "../../../common/hooks/validation";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { ILegalEntityContactDialog } from "./LegalEntityContactDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { getEnumValues } from "../../../common/enumOperations";

export function useLegalEntityContactDialogHook(props: ILegalEntityContactDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const legalEntityIdToUse = props.forLegalEntityId ?? id;

    const [contact, setContact] = useState<LegalEntityContactSaveModel>(getLegalEntityContactSaveModel(props.selectedContact));

    const validation = useValidator<LegalEntityContactSaveModel>((validators) => ({
        name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        email: validators.validateEmail((item) => item.email, translateString("email")),
        countryCode: validators.validateCountryCode(
            (item) => item.countryCode,
            translateString("countryCode"),
            (item) => isNullOrWhitespace(item.phoneNumber)
        ),
        phoneNumber: validators.validateNotEmpty(
            (item) => item.phoneNumber,
            translateString("phone"),
            (item) => isNullOrWhitespace(item.countryCode)
        ),
    }));

    const createMutation = useMutation(createContactMutation);

    function createContactMutation(doc: LegalEntityContactSaveModel) {
        return post(`/LegalEntityContacts/${legalEntityIdToUse}/`, doc);
    }

    const createContact = () => {
        createMutation.mutateAsync(contact!, {
            onSuccess: (response: any) => {
                props.onSave(response.value().contactId);
                snackbar.show(translateString("contactCreated"));
            },
        });
    };

    const editMutation = useMutation(editContactMutation);

    function editContactMutation(doc: LegalEntityContactSaveModel) {
        return put(`/LegalEntityContacts/${legalEntityIdToUse}/${props.selectedContact?.id}`, doc);
    }

    const editContact = () => {
        editMutation.mutateAsync(contact!, {
            onSuccess: (response: any) => {
                props.onSave(props.selectedContact!.id);
                snackbar.show(translateString("contactUpdated"));
            },
        });
    };

    const onSaveClick = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (props.isEdit) {
            editContact();
        } else {
            createContact();
        }
    };

    const onNameChange = (name: string) => {
        let temp = { ...contact };
        temp.name = name;
        setContact(temp);
    };

    const onEmailChange = (email: string) => {
        let temp = { ...contact };
        temp.email = email;
        setContact(temp);
    };

    const onCountryCodeChange = (countryCode: string) => {
        let temp = { ...contact };

        temp.countryCode = countryCode;
        setContact(temp);
    };

    const onPhoneNumberChange = (phoneNumber: string) => {
        let temp = { ...contact };
        temp.phoneNumber = phoneNumber;
        setContact(temp);
    };

    const onPrimaryContactChange = (primaryContact: boolean) => {
        let temp = { ...contact };
        temp.primaryContact = primaryContact;
        setContact(temp);
    };

    const onContactTypeChange = (types: Array<string>) => {
        let temp = { ...contact };
        temp.contactTypes = types.map((x) => Number(x));
        setContact(temp);
    };

    return {
        onSaveClick,
        onNameChange,
        contact,
        onEmailChange,
        onPhoneNumberChange,
        onCountryCodeChange,
        onPrimaryContactChange,
        validation,
        isSaving: createMutation.isLoading || editMutation.isLoading,
        onContactTypeChange,
    };
}

function getLegalEntityContactSaveModel(model?: LegalEntityContactViewModel) {
    return {
        name: model?.name ?? "",
        email: model?.email ?? "",
        countryCode: model?.countryCode ?? "",
        phoneNumber: model?.phoneNumber ?? "",
        primaryContact: model?.primaryContact ?? false,
        contactTypes: model?.contactTypes ?? [],
    };
}

export const contactTypeOptions = (translateString: any) => {
    return getEnumValues(LegalEntityContactType).map((x) => {
        var name = translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()));
        return { name: name, id: x.toString() };
    });
};

import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalInformationTooltip,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
    useDotLegalSnackbar,
} from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../localization/useTranslation";
import { InternalAssessmentApiRow, InternalAssessmentViewRow } from "./InternalAssessments.types";
import { Box, Grid, GridSize, IconButton, TableCell, TableRow } from "@mui/material";
import useInternalAssessmentsHooks from "./InternalAssessment.hooks";
import EmptyState from "../common/svgs/emptyState.svg?react";
import SendInternalAssessmentDialog from "./sendInternalAssessmentDialog/SendInternalAssessmentDialog";
import { NoteSVG } from "../common/icons";
import ComplianceAreaWrapper from "../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import QuestionnaireReportButtons from "../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import QuestionnaireStatus from "../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import { LegalEntityAuditStatus } from "../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { getQuestionnaireResponseScore } from "../common/questionnaireResponseScore";
import QuestionnaireRisk from "../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import DeleteInternalAssessmentDialog from "./deleteInternalAssessmentDialog/DeleteInternalAssessmentDialog";
import CloseInternalAssessmentDialog from "./closeInternalAssessmentDialog/CloseInternalAssessmentDialog";
import ReopenInternalAssessmentDialog from "./reopenInternalAssessmentDialog/ReopenInternalAssessmentDialog";
import DotLegalNoteDialog from "../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import ChangeAssigneeDialog from "./changeAssigneeDialog/ChangeAssigneeDialog";
import ChangeInternalAssessmentResponsibleDialog from "./changeInternalAssessmentResponsibleDialog/ChangeInternalAssessmentResponsibleDialog";
import InternalAssessmentsIcon from "./internalAssessments.svg?react";
import { useUrlProvider } from "../useUrlProvider.ts";
import { useNavigate } from "react-router-dom";
import { getSettings } from "../common/settingsProvider.ts";
import SendInternalAssessmentReminderDialog from "./sendInternalAssessmentReminderDialog/SendInternalAssessmentReminderDialog.tsx";
import QuestionnaireReportOverflowButtons from "../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons.tsx";
import QuestionnaireTemplateLink from "../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import PublicAssessment from "../common/svgs/Public assessment.svg?react";
import QuestionnaireEvaluation from "../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import EvaluateInternalAssessmentDialog from "./evaluateInternalAssessmentDialog/EvaluateInternalAssessmentDialog.tsx";
import ToggleInternalAssessmentRemindersDialog from "./toggleInternalAssessmentRemindersDialog/ToggleInternalAssessmentRemindersDialog.tsx";

function InternalAssessments() {
    const { customerName } = useUserContext();
    const { translateString, translateDate } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const snackbar = useDotLegalSnackbar();
    const {
        audits,
        isLoading,
        hasData,
        setShowDialog,
        showDialog,
        onInternalAssessmentSend,
        selectableTemplates,
        selectedTemplates,
        setSelectedTemplates,
        selectableAreas,
        selectedAreas,
        setSelectedAreas,
        selectableResponsibles,
        selectedResponsibles,
        setSelectedResponsibles,
        selectableStatus,
        selectedStatus,
        setSelectedStatus,
        onInternalAssessmentDeleted,
        selectedInternalAssessment,
        setSelectedInternalAssessment,
        setShowDeleteDialog,
        showDeleteDialog,
        setShowCloseDialog,
        showCloseDialog,
        onInternalAssessmentClosed,
        setShowReopenDialog,
        showReopenDialog,
        onInternalAssessmentReopened,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        saveNote,
        setShowNote,
        showNote,
        savingNote,
        setShowChangeAssigneeDialog,
        showChangeAssigneeDialog,
        onAssigneeChanged,
        onResponsibleChanged,
        setShowChangeResponsibleDialog,
        showChangeResponsibleDialog,
        showReminderDialog,
        setShowReminderDialog,
        onReminderSent,
        onEvaluated,
        setShowEvaluationDialog,
        showEvaluationDialog,
        selectableEvaluationStatus,
        selectedEvaluations,
        setSelectedEvaluations,
        setShowToggleRemindersDialog,
        showToggleRemindersDialog,
        onRemindersToggled,
    } = useInternalAssessmentsHooks();

    function getHeaders() {
        let headers = Array<ITableHeader<InternalAssessmentViewRow>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "uniqueIdentifier", text: "Id", align: "left", showOnMobile: true });
        headers.push({ property: "sent", text: translateString("assessmentSent"), align: "left", showOnMobile: true });
        headers.push({ property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true });
        headers.push({ property: "responsibleName", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "assigneeName", text: translateString("assigned"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireName", text: translateString("assessmentTemplate"), align: "left", showOnMobile: true });
        headers.push({ property: "complianceAreasString", text: translateString("complianceAreas"), align: "left", showOnMobile: true });
        headers.push({ property: "sender", text: translateString("sentFrom"), align: "left", showOnMobile: true });
        headers.push({ property: "statusString", text: translateString("status"), align: "left", showOnMobile: true });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "scoreName", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireId", text: translateString("assessmentReport"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        return headers;
    }

    function getMenuItems(row: InternalAssessmentApiRow) {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("note"),
            onClick: () => {
                setSelectedInternalAssessment(row);
                setShowNote(true);
            },
        });

        if (row.isClosed || row.status === LegalEntityAuditStatus.Submitted) {
            menuItems.push({
                menuItem: row.isClosed ? translateString("reopenInternalAssessment") : translateString("reopenCompletedInternalAssessment"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowReopenDialog(true);
                },
            });
        }

        if (!row.isClosed) {
            menuItems.push({
                menuItem: translateString("closeInternalAssessment"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowCloseDialog(true);
                },
            });
        }

        if (row.status !== LegalEntityAuditStatus.Submitted && !row.isClosed) {
            menuItems.push({
                menuItem: translateString("sendReminder"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowReminderDialog(true);
                },
                disabled: row.remindersPaused,
                tooltip: row.remindersPaused ? translateString("remindersIsPaused") : "",
            });
        }

        if (row.status !== LegalEntityAuditStatus.Submitted && !row.isClosed) {
            menuItems.push({
                menuItem: row.remindersPaused ? translateString("resumeReminders") : translateString("pauseReminders"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowToggleRemindersDialog(true);
                },
            });
        }

        if (row.canBeDeleted) {
            menuItems.push({
                menuItem: translateString("deleteInternalAssessment"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowDeleteDialog(true);
                },
            });
        }

        menuItems.push({
            menuItem: translateString("changeResponsible"),
            onClick: () => {
                setSelectedInternalAssessment(row);
                setShowChangeResponsibleDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("changeAssignee"),
            onClick: () => {
                setSelectedInternalAssessment(row);
                setShowChangeAssigneeDialog(true);
            },
        });

        if (!row.isClosed) {
            menuItems.push({
                menuItem: translateString("CopyLinkToAssessment"),
                onClick: () => {
                    const url = getSettings().mainSiteBaseUrl + urlProvider.getExternalQuestionnaireResponseUrl(row.responseExternalId);
                    navigator.clipboard.writeText(url);
                    snackbar.show(translateString("linkCopied", { link: url }));
                },
            });
        }

        if (row.status === LegalEntityAuditStatus.Submitted) {
            menuItems.push({
                menuItem: translateString("evaluateInternalAssessmentHeader"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowEvaluationDialog(true);
                },
            });
        }

        return menuItems;
    }

    function formatNote(row: InternalAssessmentApiRow) {
        return (
            row.note && (
                <IconButton
                    onClick={() => {
                        setSelectedInternalAssessment(row);
                        setShowNote(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getName(row: InternalAssessmentApiRow) {
        const publicAssessmentTooltip = `${translateString("publicInternalAssessment")} ${row.publicTemplateIsDeleted ? `(${translateString("deleted")})` : ""}`;
        const publicAssessment = (
            <Box>
                {row.createdFromPublicTemplate && (
                    <DotLegalTooltip text={publicAssessmentTooltip}>
                        <Box sx={{ marginLeft: 0.5 }}>
                            <PublicAssessment />
                        </Box>
                    </DotLegalTooltip>
                )}
            </Box>
        );
        if (row.description) {
            return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DotLegalTooltip text={row.description}>
                        <Box>{row.name}</Box>
                    </DotLegalTooltip>
                    {publicAssessment}
                </Box>
            );
        }

        return (
            <Box sx={{ display: "flex" }}>
                {row.name} {publicAssessment}
            </Box>
        );
    }

    function getFilters() {
        const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
        };

        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableTemplates}
                        noMargin
                        chosenOptions={selectedTemplates}
                        onChange={setSelectedTemplates}
                        label={translateString("assessmentTemplate")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableAreas}
                        noMargin
                        chosenOptions={selectedAreas}
                        onChange={setSelectedAreas}
                        label={translateString("complianceAreas")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableStatus}
                        noMargin
                        chosenOptions={selectedStatus}
                        onChange={setSelectedStatus}
                        label={translateString("status")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableResponsibles}
                        noMargin
                        chosenOptions={selectedResponsibles}
                        onChange={setSelectedResponsibles}
                        label={translateString("responsible")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableEvaluationStatus}
                        noMargin
                        chosenOptions={selectedEvaluations}
                        onChange={setSelectedEvaluations}
                        label={translateString("evaluation")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
            </React.Fragment>
        );
    }

    function getComplianceAreas(row: InternalAssessmentApiRow) {
        return (
            <Box sx={{ display: "flex", gap: 1 }}>
                {row.complianceAreas.map((c) => (
                    <ComplianceAreaWrapper key={c.id} complianceArea={c.name} />
                ))}
            </Box>
        );
    }

    function getStatus(row: InternalAssessmentApiRow) {
        return (
            <QuestionnaireStatus
                showQuestionsStatistics={row.showQuestionsStatistics}
                numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                totalNumberOfQuestions={row.totalNumberOfQuestions}
                status={row.status}
                lastestRecipientUpdate={row.lastestRecipientUpdate}
            />
        );
    }

    function getRisk(row: InternalAssessmentApiRow) {
        return <QuestionnaireRisk riskLevel={row.riskLevel} riskName={row.riskName} scoreEnabled={row.scoreEnabled} status={row.status} />;
    }

    function getReportButtons(row: InternalAssessmentApiRow) {
        let showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.isClosed;
        let isDownloading = isDownloadingPdf === row.id;
        let reportUrl = getReportPdfUrl(row, false, row.hasRecommendations);

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row)}
                    showButtons={showButtons}
                    isDownloadingPdf={isDownloading}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={isDownloading}
                onDownloadReportPdf={() => onDownloadReportAsPdf(row, true, false)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(row: InternalAssessmentApiRow) {
        if (row.scoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(row, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(row, true, false),
                },
            ];
        } else {
            return [
                { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, false, true) },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(row, false, false),
                },
            ];
        }
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("internalAssessments") }]} />
            <DotLegalActionBar
                primaryButton={
                    <DotLegalButton buttonType="primary" size="medium" onClick={() => setShowDialog(true)}>
                        {translateString("sendAssessment")}
                    </DotLegalButton>
                }
                additionalButtons={[
                    <Box key={"publicaudit"} sx={{ display: "flex", alignItems: "center" }}>
                        <DotLegalButton
                            buttonType={"secondary"}
                            size={"medium"}
                            onClick={() => {
                                navigate(urlProvider.getPublicInternalAssessmentTemplateUrl());
                            }}
                        >
                            {translateString("publicInternalAssessmentCreateHeader")}
                        </DotLegalButton>
                        <Box sx={{ display: "flex", ml: 1 }}>
                            <DotLegalInformationTooltip text={translateString("publicInternalAssessmentTooltip")} />
                        </Box>
                    </Box>,
                ]}
                showDialog={showDialog}
                canCreate
                icon={<InternalAssessmentsIcon />}
            >
                <SendInternalAssessmentDialog onInternalAssessmentSend={onInternalAssessmentSend} onDialogClose={() => setShowDialog(false)} />
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasData ? (
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        extraControls={getFilters()}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"sent"}
                        defaultOrder={"desc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={audits}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>{getName(row)}</TableCell>
                                <TableCell>{row.uniqueIdentifier}</TableCell>
                                <TableCell>{translateDate(row.sent)}</TableCell>
                                <TableCell>{translateDate(row.deadline)}</TableCell>
                                <TableCell>{row.responsibleName}</TableCell>
                                <TableCell>{row.assigneeName}</TableCell>
                                <TableCell>
                                    <QuestionnaireTemplateLink type={"audit"} id={row.assessmentTemplate.id} name={row.assessmentTemplate.name} />
                                </TableCell>
                                <TableCell>{getComplianceAreas(row)}</TableCell>
                                <TableCell>{row.sender}</TableCell>
                                <TableCell>{getStatus(row)}</TableCell>
                                <TableCell>{getRisk(row)}</TableCell>
                                <TableCell>{row.scoreName}</TableCell>
                                <TableCell>{getReportButtons(row)}</TableCell>
                                <TableCell>
                                    <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                                </TableCell>
                                <TableCell>
                                    {row.remindersPaused ? translateString("remindersPaused") : row.reminderSent ? translateDate(row.reminderSent) : ""}
                                </TableCell>
                                <TableCell>{formatNote(row)}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <Box
                        sx={{
                            img: {
                                width: "25%",
                            },
                        }}
                    >
                        <DotLegalEmptyState icon={<EmptyState />} text={translateString("noInternalAssessments")} />
                    </Box>
                )}
            </DotLegalPaper>

            {showDeleteDialog && (
                <DeleteInternalAssessmentDialog
                    onDialogClose={() => setShowDeleteDialog(false)}
                    selectedAssessment={selectedInternalAssessment!}
                    onSuccessDelete={onInternalAssessmentDeleted}
                />
            )}
            {showCloseDialog && (
                <CloseInternalAssessmentDialog
                    onDialogClose={() => setShowCloseDialog(false)}
                    onInternalAssessmentClosed={onInternalAssessmentClosed}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}
            {showReopenDialog && (
                <ReopenInternalAssessmentDialog
                    onDialogClose={() => setShowReopenDialog(false)}
                    onInternalAssessmentReopened={onInternalAssessmentReopened}
                    internalAssessmentId={selectedInternalAssessment!.id}
                    deadline={selectedInternalAssessment!.deadline}
                    name={selectedInternalAssessment!.name}
                    isSubmitted={selectedInternalAssessment?.status === LegalEntityAuditStatus.Submitted}
                />
            )}

            {showChangeAssigneeDialog && (
                <ChangeAssigneeDialog
                    internalAssessmentViewModel={selectedInternalAssessment!}
                    onDialogClose={() => setShowChangeAssigneeDialog(false)}
                    onChangedAssignee={onAssigneeChanged}
                />
            )}

            {showChangeResponsibleDialog && (
                <ChangeInternalAssessmentResponsibleDialog
                    responsible={selectedInternalAssessment!.responsible.id}
                    internalAssessmentId={selectedInternalAssessment!.id}
                    onDialogClose={() => setShowChangeResponsibleDialog(false)}
                    onChangedResponsible={onResponsibleChanged}
                />
            )}

            {showNote && (
                <DotLegalNoteDialog
                    showDialog={showNote}
                    noteText={selectedInternalAssessment?.note}
                    onDialogClose={() => {
                        setShowNote(false);
                    }}
                    onOkClick={(text) => {
                        saveNote(text);
                    }}
                    isSaving={savingNote}
                />
            )}

            {showReminderDialog && (
                <SendInternalAssessmentReminderDialog
                    onCloseDialog={() => setShowReminderDialog(false)}
                    onReminderSent={onReminderSent}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}

            {showEvaluationDialog && (
                <EvaluateInternalAssessmentDialog
                    onEvaluated={onEvaluated}
                    onDialogClose={() => setShowEvaluationDialog(false)}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}

            {showToggleRemindersDialog && (
                <ToggleInternalAssessmentRemindersDialog
                    onDialogClose={() => setShowToggleRemindersDialog(false)}
                    onInternalAssessmentPaused={onRemindersToggled}
                    internalAssessment={selectedInternalAssessment!}
                />
            )}
        </React.Fragment>
    );
}

export default InternalAssessments;

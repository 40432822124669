import { Alert, AlertTitle, Box, Grid, GridSize, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import {
    DotLegalButton,
    DotLegalChip,
    DotLegalCompletedPercentage,
    DotLegalHeader,
    DotLegalInformationTooltip,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalSelect,
    DotLegalTableWithControls,
    DotLegalTooltip,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../../common/components/dotLegalTable/DotLegalTable.types";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useRiskAssessmentsDataMapping } from "./RIskAssessments.hooks";
import { useRiskAssessmentsStyles } from "./RiskAssessments.styles";
import { RiskAssessmentArea, RiskAssessmentRowViewModel, RiskComplianceArea } from "./RiskAssessments.types";
import NoRiskTemplates from "../noRiskAssessmentTemplate.svg?react";
import NoRiskTemplatesArrow from "../noRiskTemplateArrow.svg?react";
import RiskIndicator from "../riskIndicator/RiskIndicator";
import { RiskAssessmentType } from "../RiskAssessment.types";
import ProcessingActivityRiskMatrix from "../riskAssessmentMatrix/RiskAssessmentMatrix";
import { useUrlProvider } from "../../useUrlProvider";
import { useNavigate } from "react-router-dom";
import { PurpleLockIcon } from "../../common/icons";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import DotLegalAllOrMySwitchBox from "../../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox";
import DotLegalSelectWithButton from "../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import RiskAssessmentVersionDialog from "../../masterData/riskAssessmentVersion/riskAssessmentVersionDialog/RiskAssessmentVersionDialog";
import NoSharings from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";

function RiskAssessments() {
    const { getProcessingActivityOverviewUrl, getSystemOverviewUrl, getProcessingActivityRiskScenariosDialog } = useUrlProvider();
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const { permissions, customerName } = useUserContext();
    const { riskAndClassificationPlan } = usePlanContext();

    const {
        getBreadCrumbs,
        riskData,
        allData,
        versions,
        questionsScoreItems,
        isLoading,
        searchedQuestionsScore,
        setSearchedQuestionsScore,
        handleMatrixClick,
        gridClicked,
        setGridClicked,
        onlyUserSpecific,
        setOnlyUserSpecific,
        ownerFilterData,
        searchedOwner,
        setSearchedOwner,
        searchedScenariosScore,
        setSearchedScenariosScore,
        scenarioScoreItems,
        onDownloadPdfClick,
        isDownloadingPdf,
        isPdf,
        businessAreaFilterItems,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        onDownloadPDFForAssessmentRisk,
        overallStatus,
        selectedRiskArea,
        isProcessingActivityRiskAssessment,
        selectedComplianceArea,
        riskAssessmentArea,
        riskComplianceArea,
        selectedVersion,
        showCreateVersion,
        setShowCreateVersion,
        refetchVersions,
        setSearchParams,
    } = useRiskAssessmentsDataMapping();

    const styles = useRiskAssessmentsStyles(isPdf);

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
    };

    const setSearchedQuestionsScores = (values: Array<string>) => {
        setGridClicked(false);
        setSearchedQuestionsScore(values);
    };

    //TODO: Skal udvides med mulighed for at tilgå overview siden, Klassificering (åbner klassificering popup), Risikovurdering  (åbner risiko popup)
    const getMenuItems = (riskAssessment: RiskAssessmentRowViewModel) => {
        const id = isProcessingActivityRiskAssessment ? riskAssessment.processingActivityId : riskAssessment.systemId;
        let menuItems = [];
        menuItems.push({
            menuItem: translateString("openRiskAssessment"),
            onClick: () => {
                navigate(urlProvider.getRiskAssessmentQuestionsDialog(id!, riskAssessmentArea, riskComplianceArea, selectedVersion!));
            },
        });

        if (riskAssessment.scenariosScore && riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
            menuItems.push({
                menuItem: translateString("openClassification"),
                onClick: () => {
                    navigate(urlProvider.getProcessingActivityRiskScenariosDialog(id!, selectedVersion ?? undefined));
                },
            });
        }

        if (riskAndClassificationPlan.riskReport) {
            menuItems.push({
                menuItem: translateString("exportRiskAssessmentPdf"),
                onClick: () => {
                    onDownloadPDFForAssessmentRisk(id!, riskAssessment.name);
                },
            });
        } else {
            menuItems.push({
                menuItem: (
                    <Box sx={{ display: "flex" }}>
                        <span>{translateString("exportRiskAssessmentPdf")}</span>
                        <Box sx={{ marginLeft: 0.5 }}>
                            <PurpleLockSvg />
                        </Box>
                    </Box>
                ),
                tooltip: translateString("upgradePrivacyPlanToday"),
                disabled: true,
            });
        }

        return menuItems;
    };

    const noOptionLabel = translateString("noOptions");
    const getSearchFields = () => {
        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedBusinessAreas}
                        chosenOptions={searchedBusinessAreas}
                        label={translateString("businessArea")}
                        placeholder={translateString("businessArea")}
                        isLoading={isLoading}
                        options={businessAreaFilterItems}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionLabel}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={(values: string[]) => setSearchedOwner(values)}
                        chosenOptions={searchedOwner}
                        label={translateString("owner")}
                        placeholder={translateString("owner")}
                        isLoading={isLoading}
                        options={ownerFilterData}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionLabel}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedQuestionsScores}
                        chosenOptions={searchedQuestionsScore}
                        label={translateString("risk")}
                        placeholder={translateString("risk")}
                        isLoading={isLoading}
                        options={questionsScoreItems}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionLabel}
                    />
                </Grid>
                {selectedRiskArea === RiskAssessmentArea.ProcessingActivities && selectedComplianceArea === RiskComplianceArea.GDPR && (
                    <Grid {...gridProps}>
                        <DotLegalMultiSelect
                            onChange={setSearchedScenariosScore}
                            chosenOptions={searchedScenariosScore}
                            label={translateString("riskClassification")}
                            placeholder={translateString("riskClassification")}
                            isLoading={isLoading}
                            options={scenarioScoreItems}
                            noMargin
                            limit={1}
                            noOptionsLabel={noOptionLabel}
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    const getRiskTemplateBtn = () => {
        if (permissions.riskTemplatePermissions.read) {
            return (
                <DotLegalButton buttonType="primary" href={urlProvider.getRiskAssessmentTemplateUrl()}>
                    {translateString("riskTemplate")}
                </DotLegalButton>
            );
        }

        return <div></div>;
    };
    const getDownloadButton = () => {
        const btn = (
            <DotLegalButton
                isLoading={isDownloadingPdf}
                disabled={isDownloadingPdf || !riskAndClassificationPlan.riskReport}
                buttonType={"secondary"}
                onClick={onDownloadPdfClick}
                endIcon={!riskAndClassificationPlan.riskReport && PurpleLockIcon}
                toolTip={!riskAndClassificationPlan.riskReport ? translateString("upgradePrivacyPlanToday") : undefined}
            >
                {translateString("download")}
            </DotLegalButton>
        );

        if (!riskAndClassificationPlan.riskReport) {
            return <PlanDialogButtonWrapper enabled>{btn}</PlanDialogButtonWrapper>;
        }

        return btn;
    };

    const getRiskVersionSelector = () => {
        const version = selectedVersion === undefined ? versions?.find((x) => x.isCurrentVersion) : versions?.find((x) => x.id === selectedVersion);

        let selector = (
            <Box sx={{ "& .MuiBox-root": { margin: 0, width: 250, "& .MuiOutlinedInput-notchedOutline": { borderWidth: 2 } } }}>
                <DotLegalSelectWithButton
                    isLoading={isLoading}
                    onButtonClick={() => setShowCreateVersion(true)}
                    buttonLabel={translateString("createNewVersion")}
                    noOptionsLabel={translateString("noOptions")}
                    options={versions ?? []}
                    onChange={(versionId) => {
                        setSearchParams(selectedRiskArea, selectedComplianceArea, versionId);
                    }}
                    placeholder={""}
                    label={translateString("version")}
                    disableClearable
                    selectedItem={selectedVersion}
                    hasPermission={permissions.riskVersionPermissions.create}
                    disabled={!riskAndClassificationPlan.riskVersions}
                    icon={!riskAndClassificationPlan.riskVersions ? PurpleLockIcon : undefined}
                />
            </Box>
        );

        if (!riskAndClassificationPlan.riskVersions) {
            selector = (
                <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                    <span>{selector}</span>
                </DotLegalTooltip>
            );
        }

        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {selector}
                {!isLoading && version?.description && (
                    <Box sx={{ marginLeft: 1 }}>
                        <DotLegalInformationTooltip text={version.description ?? ""}></DotLegalInformationTooltip>
                    </Box>
                )}
            </Box>
        );
    };

    const hasMultipleRiskAssessmentOptions = riskAndClassificationPlan.systemRiskAssessments && permissions.systemPermissions.read;
    const getRiskAssessmentOptions = () => {
        let options = [{ id: RiskAssessmentArea.ProcessingActivities.toString(), name: translateString("processingActivities") }];

        if (riskAndClassificationPlan.systemRiskAssessments && permissions.systemPermissions.read) {
            options.push({
                id: RiskAssessmentArea.Systems.toString(),
                name: translateString("systems"),
            });
        }

        return options;
    };

    const getRiskComplianceAreaOptions = () => {
        return [
            { id: RiskComplianceArea.GDPR.toString(), name: translateString("gdpr") },
            { id: RiskComplianceArea.NIS2.toString(), name: translateString("nis2") },
        ];
    };

    function isTextLongerThan120(text: string | undefined): boolean {
        if (text) {
            return text!.length > 120;
        }
        return false;
    }

    const getComplianceAreaSelect = () => {
        return riskAndClassificationPlan.niS2RiskTemplates ? (
            <DotLegalSelect
                options={getRiskComplianceAreaOptions()}
                selectedItem={riskComplianceArea!.toString()}
                onChange={(value) => {
                    setSearchParams(selectedRiskArea, Number(value!), null);
                }}
                placeholder={""}
                label={translateString("area")}
                noOptionsLabel={""}
                disableClearable
                width="300px"
                noMargin
                backgroundColor="white"
            />
        ) : undefined;
    };

    const getRiskAssessmentAreaSelect = () => {
        return hasMultipleRiskAssessmentOptions ? (
            <Box sx={{ marginRight: 1 }}>
                <DotLegalSelect
                    options={getRiskAssessmentOptions()}
                    selectedItem={riskAssessmentArea.toString()}
                    onChange={(value) => {
                        setSearchParams(Number(value!), selectedComplianceArea, null);
                    }}
                    placeholder={""}
                    label={translateString("type")}
                    noOptionsLabel={""}
                    disableClearable
                    width="300px"
                    noMargin
                    backgroundColor="white"
                />
            </Box>
        ) : undefined;
    };

    const getEmptyState = () => {
        if (allData?.showEmptyState) {
            return (
                <DotLegalEmptyState
                    icon={<NoRiskTemplates />}
                    containerIsRelative
                    text={
                        <React.Fragment>
                            <Box component={"span"} sx={styles.noDataInfoHeader}>
                                {translateString("noRiskTemplates")}
                            </Box>
                            <Typography sx={styles.noDataInfoBody}>{translateString("noRiskTemplateDescription")}</Typography>
                            <Box component={"span"} sx={styles.noDataInfoBtn}>
                                {" "}
                                {getRiskTemplateBtn()}{" "}
                            </Box>
                            {}
                            <Box sx={styles.noRiskTemplatesArrow}>
                                <NoRiskTemplatesArrow />
                            </Box>
                        </React.Fragment>
                    }
                />
            );
        }

        return (
            <DotLegalEmptyState
                icon={<NoSharings />}
                containerIsRelative
                text={
                    <React.Fragment>
                        <Typography sx={styles.noDataInfoBody}>{getEmptyDataText()}</Typography>
                    </React.Fragment>
                }
            />
        );
    };

    const getEmptyDataText = () => {
        if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities) {
            if (riskComplianceArea === RiskComplianceArea.GDPR) {
                return translateString("noProcessingActivityHeader");
            }
            return translateString("noProcessingActivitiesWithCriticalAssets");
        }

        if (riskComplianceArea === RiskComplianceArea.GDPR) {
            return translateString("noSystems");
        }

        return translateString("noOperationCriticalAssets");
    };

    return (
        <React.Fragment>
            {isPdf ? (
                <Box>
                    <DotLegalHeader headerStyle={isTextLongerThan120(getBreadCrumbs()[0].name) ? "small" : "medium"} marginBottom={0}>
                        {getBreadCrumbs()[0].name}
                    </DotLegalHeader>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} />
                        <Box sx={{ display: "flex", gap: 1, marginBottom: 1, marginTop: 1 }}>
                            {getRiskAssessmentAreaSelect()}

                            {getComplianceAreaSelect()}
                        </Box>
                    </Box>
                </Box>
            ) : (
                <DotLegalPageHeader
                    userContext={{ customerName }}
                    breadCrumbs={getBreadCrumbs()}
                    leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} />}
                    rightComponent={
                        <>
                            {getRiskAssessmentAreaSelect()}

                            {getComplianceAreaSelect()}
                        </>
                    }
                />
            )}

            <Box className="riskAssessment-top-paper">
                <DotLegalPaper sx={styles.paperHeader} background="blueWave" backgroundCover>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Box sx={(theme) => ({ display: "flex", "& > *": { marginRight: `${theme.spacing(2)} !Important` } })}>
                            {isLoading ? (
                                <Skeleton variant="rectangular" width="100&" sx={{ height: 40, borderRadius: "10px" }}>
                                    {getRiskTemplateBtn()}
                                </Skeleton>
                            ) : (
                                getRiskTemplateBtn()
                            )}

                            {getRiskVersionSelector()}

                            {isLoading ? (
                                <Skeleton variant="rectangular" width="100&" sx={{ height: 40, borderRadius: "10px" }}>
                                    {getDownloadButton()}
                                </Skeleton>
                            ) : (
                                getDownloadButton()
                            )}
                        </Box>
                        <Box>
                            <DotLegalCompletedPercentage label={translateString("overallStatus")} completed={overallStatus} />
                        </Box>
                    </Box>
                </DotLegalPaper>
            </Box>
            {!allData?.showEmptyState && (
                <Box className="page-break-after">
                    <DotLegalPaper sx={styles.topPaper}>
                        {!isPdf && versions?.find((x) => x.id === selectedVersion)?.isLocked && (
                            <Alert
                                sx={(theme) => ({ marginTop: -2, marginBottom: 2, "& .MuiSvgIcon-root": { fill: theme.palette.warning.main } })}
                                severity="warning"
                            >
                                <AlertTitle>{translateString("lockedVersion")}</AlertTitle>
                                {translateString("lockedVersionInfo")}
                            </Alert>
                        )}
                        <ProcessingActivityRiskMatrix
                            isLoading={isLoading}
                            gridClicked={gridClicked}
                            onMatrixClick={(xCord, yCord) => handleMatrixClick(xCord, yCord)}
                            data={allData?.rows}
                            isPdf={isPdf}
                        />
                    </DotLegalPaper>
                </Box>
            )}
            <DotLegalPaper removePadding={isPdf}>
                <Box sx={{ paddingLeft: isPdf ? 2 : 0, paddingRight: isPdf ? 2 : 0, paddingBottom: isPdf ? 2 : 0 }}>
                    {(!allData?.showEmptyState && riskData && riskData.length > 0) || isLoading ? (
                        <DotLegalTableWithControls
                            extraControls={getSearchFields()}
                            getUserSpecificPageLength={() => 30}
                            hideRowsPerPage
                            headers={getHeaders(translateString, isPdf, selectedRiskArea!, selectedComplianceArea!)}
                            data={riskData!}
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            clickableRows={false}
                            isPdf={isPdf}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            emptyText={translateString("noData")}
                            renderRow={(row: RiskAssessmentRowViewModel, i: number) => (
                                <TableRow key={row.name + i}>
                                    <TableCell sx={styles.bold}>
                                        <DotLegalLink
                                            to={
                                                isProcessingActivityRiskAssessment
                                                    ? getProcessingActivityOverviewUrl(row.processingActivityId!)
                                                    : getSystemOverviewUrl(row.systemId!)
                                            }
                                        >
                                            {row.name}
                                        </DotLegalLink>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={styles.chipContainer}>
                                            {row.businessAreas.map((x) => {
                                                return (
                                                    <Box key={x.id} sx={styles.chip}>
                                                        <DotLegalChip value={x.name} color={x.color} size="small" isSelected />
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row.owner}</TableCell>
                                    <TableCell
                                        sx={{
                                            display:
                                                selectedRiskArea! === RiskAssessmentArea.ProcessingActivities &&
                                                selectedComplianceArea === RiskComplianceArea.GDPR
                                                    ? "table-cell"
                                                    : "none",
                                        }}
                                    >
                                        <RiskIndicator
                                            riskAssessmentType={RiskAssessmentType.None}
                                            isClassification
                                            riskScoreTextColor={"darkblue"}
                                            hideRiskType
                                            score={row.scenariosScore}
                                            removeLeftMargin
                                            linkHref={getProcessingActivityRiskScenariosDialog(row.processingActivityId!, selectedVersion ?? undefined)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RiskIndicator
                                            riskAssessmentType={RiskAssessmentType.None}
                                            hideRiskType
                                            removeLeftMargin
                                            riskScoreTextColor={"darkblue"}
                                            fixedWidthLeftColumn={false}
                                            textSize="sm"
                                            score={row.totalScore}
                                            linkHref={urlProvider.getRiskAssessmentQuestionsDialog(
                                                row.processingActivityId ?? row.systemId!,
                                                riskAssessmentArea,
                                                riskComplianceArea,
                                                selectedVersion!
                                            )}
                                            showHighRiskWarning
                                            highRiskWarningTooltip={
                                                selectedRiskArea! === RiskAssessmentArea.ProcessingActivities &&
                                                selectedComplianceArea === RiskComplianceArea.GDPR
                                                    ? translateString("highRiskWarning")
                                                    : ""
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={styles.percentageContainer}>
                                            <DotLegalCompletedPercentage completed={row.status} />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <DotLegalOverflowMenu items={getMenuItems(row)} />
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    ) : (
                        getEmptyState()
                    )}
                </Box>
            </DotLegalPaper>

            {showCreateVersion && (
                <RiskAssessmentVersionDialog
                    onDialogClose={() => setShowCreateVersion(false)}
                    onVersionSaved={(versionId) => {
                        setShowCreateVersion(false);
                        setSearchParams(selectedRiskArea, selectedComplianceArea, versionId);
                        refetchVersions();
                    }}
                    versionOptions={
                        versions!.map((x) => {
                            return {
                                id: x.id,
                                name: `${x.name} (${translateString("copy")})`,
                                isLocked: x.isLocked,
                                isCurrentVersion: x.isCurrentVersion,
                                description: x.description,
                            };
                        }) ?? []
                    }
                    riskAssessmentArea={riskAssessmentArea}
                    riskComplianceArea={riskComplianceArea}
                />
            )}
        </React.Fragment>
    );
}

export default RiskAssessments;

function getHeaders(translateString: any, isPdf: boolean, riskAssessmentArea: number, riskComplianceArea: number) {
    let headers = Array<ITableHeader<RiskAssessmentRowViewModel>>();

    if (riskAssessmentArea === RiskAssessmentArea.Systems) {
        headers.push({ property: "name", text: translateString("system"), align: "left", showOnMobile: true });
    } else {
        headers.push({ property: "name", text: translateString("processingActivity"), align: "left", showOnMobile: true });
    }

    headers.push({
        property: "businessAreaSorting",
        text: translateString("businessAreas"),
        align: "left",
        showOnMobile: true,
        width: isPdf ? "400px" : undefined,
    });

    if (riskAssessmentArea === RiskAssessmentArea.Systems) {
        headers.push({ property: "owner", text: translateString("responsible"), align: "left", showOnMobile: true });
    } else {
        headers.push({ property: "owner", text: translateString("owner"), align: "left", showOnMobile: true });
    }

    if (riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR) {
        headers.push({ property: "scenariosScore", text: translateString("riskClassification"), align: "left", showOnMobile: true });
    }

    headers.push({ property: "totalScore", text: translateString("risk"), align: "left", showOnMobile: true });
    if (!isPdf) headers.push({ property: "status", text: translateString("creationStatus"), align: "left", showOnMobile: true });

    return headers;
}

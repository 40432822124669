import {
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { LegalEntityClassificationApiRow, LegalEntityClassificationViewRow, RiskLevel } from "./LegalEntityClassificationTab.types";
import useLegalEntityClassificationTabHooks from "./LegalEntityClassificationTab.hooks";
import LegalEntityClassificationDialog from "./legalEntityClassificationDialog/LegalEntityClassificationDialog";
import EmptyState from "../../common/svgs/emptyState.svg?react";
import { NoteSVG, warning, notSatisfied, partlySatisfied, satisfied } from "../../common/icons";
import DotLegalNoteDialog from "../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import { useRiskIndicator } from "../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { useLegalEntityClassificationTabStyles } from "./LegalEntityClassificationTab.styles";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../useUrlProvider";
import DeleteClassificationDialog from "./deleteClassificationDialog/DeleteClassificationDialog";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { getLegalEntityPermissions } from "../LegalEntity.hooks";
import QuestionnaireReportButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import { LegalEntityPageEnum } from "../legalEntities/LegalEntities.types.ts";
import QuestionnaireTemplateLink from "../legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import QuestionnaireReportOverflowButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons.tsx";
import EvaluateClassificationDialog from "./evaluateClassificationDialog/EvaluateClassificationDialog.tsx";
import QuestionnaireEvaluation from "../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import ReopenClassificationDialog from "./reopenClassificationDialog/ReopenClassificationDialog.tsx";

export interface ILegalEntityClassificationTab {
    legalEntityId: string;
    legalEntityName: string | undefined;
    isGroupEntity: boolean;
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntityClassificationTab(props: ILegalEntityClassificationTab) {
    const { translateString, translateDate } = useTranslation();
    const { getSystemOverviewUrl, getQuestionnaireVendorClassificationOverview, getLegalEntityClassificationUrl } = useUrlProvider();
    const {
        isLoading,
        hasData,
        setShowClassificationDialog,
        showClassificationDialog,
        selectedSystems,
        setSelectedSystems,
        classifications,
        selectableSystems,
        onClassificationCreated,
        selectedClassification,
        setSelectedClassification,
        setShowNoteDialog,
        showNoteDialog,
        saveNote,
        isSavingNote,
        setShowDeleteDialog,
        showDeleteDialog,
        onClassificationDeleted,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showEvaluateDialog,
        setShowEvaluateDialog,
        onClassificationEvaluated,
        setShowReopenDialog,
        showReopenDialog,
    } = useLegalEntityClassificationTabHooks(props);

    const styles = useLegalEntityClassificationTabStyles();
    const { getRiskIcon } = useRiskIndicator();
    const { vendorManagementPlan } = usePlanContext();
    const { permissions } = useUserContext();

    const legalEntityPermissions = getLegalEntityPermissions(permissions, props.isGroupEntity);

    function getMenuItems(row: LegalEntityClassificationApiRow) {
        let menuItems = [];

        if (legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("note"),
                onClick: () => {
                    setShowNoteDialog(true);
                    setSelectedClassification(row);
                },
            });
        }

        if (vendorManagementPlan.hasAccess && legalEntityPermissions.delete) {
            menuItems.push({
                menuItem: translateString("deleteClassification"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowDeleteDialog(true);
                },
            });
        }

        if (row.submitted && legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("evaluateClassificationHeader"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowEvaluateDialog(true);
                },
            });
        }

        if (row.submitted && legalEntityPermissions.edit) {
            menuItems.push({
                menuItem: translateString("reopenCompletedClassification"),
                onClick: () => {
                    setSelectedClassification(row);
                    setShowReopenDialog(true);
                },
            });
        }

        return menuItems;
    }

    function formatNote(row: LegalEntityClassificationApiRow) {
        return (
            row.note && (
                <IconButton
                    onClick={() => {
                        setSelectedClassification(row);
                        setShowNoteDialog(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getSystemsUrl(system?: BaseViewModel) {
        if (system) {
            return formatUrl(system.id, system.name, getSystemOverviewUrl(system.id));
        }

        return undefined;
    }

    function formatUrl(id: string, name: string, url: string) {
        return (
            <Box key={id} component={"span"} sx={{ fontWeight: "bold" }}>
                <DotLegalLink linkColor={"primary"} to={url}>
                    {name}
                </DotLegalLink>
            </Box>
        );
    }

    function getRisk(row: LegalEntityClassificationApiRow) {
        var icon = getIcon(row);
        var name = getRiskName(row);

        if (!row.submitted) {
            return (
                <Box sx={styles.risk}>
                    {icon}
                    <Box sx={{ fontWeight: 700 }}>
                        <DotLegalLink
                            linkColor={"primary"}
                            to={getLegalEntityClassificationUrl(props.legalEntityPage, props.legalEntityId, row.questionnaireResponseId)}
                        >
                            {name}
                        </DotLegalLink>
                    </Box>
                </Box>
            );
        }
        return (
            <Box sx={styles.risk}>
                {icon}
                <Box>{name}</Box>
            </Box>
        );
    }

    function getIcon(row: LegalEntityClassificationApiRow) {
        if (row.riskLevel || row.riskLevel === 0) {
            return getRiskIcon(row.riskLevel);
        }

        if (!row.scoreEnabled && row.submitted) return getRiskIcon(RiskLevel.None);

        return warning;
    }

    function getRiskName(row: LegalEntityClassificationApiRow) {
        if (row.riskName) {
            return row.riskName;
        }

        if (!row.scoreEnabled && row.submitted) return translateString("none");

        return translateString("answerClassification");
    }

    function getReportButtons(row: LegalEntityClassificationApiRow) {
        if (row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={getReportPdfUrl(row.questionnaireResponseId, false, true)}
                    reportPdfMenuItems={[
                        { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, true) },
                        {
                            menuItem: translateString("downloadWithoutRecommendations"),
                            onClick: () => onDownloadReportAsPdf(row, false),
                        },
                    ]}
                    showButtons
                    isDownloadingPdf={isDownloadingPdf === row.id}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                showButtons
                reportPdfUrl={getReportPdfUrl(row.questionnaireResponseId, false, false)}
                onDownloadReportPdf={() => onDownloadReportAsPdf(row, false)}
                isDownloadingPdf={isDownloadingPdf === row.id}
            />
        );
    }

    function getHeaders() {
        const headers = Array<ITableHeader<LegalEntityClassificationViewRow>>();
        headers.push({ property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: false });
        headers.push({ property: "systemName", text: translateString("system"), align: "left", showOnMobile: true });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "score", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "submitted", text: translateString("submitted"), align: "left", showOnMobile: true });
        headers.push({ property: "submittedBy", text: translateString("submittedBy"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "report", text: translateString("classificationReport"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });
        return headers;
    }

    return (
        <DotLegalPaper>
            <React.Fragment>
                {hasData ? (
                    <DotLegalTableWithControls
                        extraControls={
                            <Grid item xs={2}>
                                <DotLegalMultiSelect
                                    options={selectableSystems}
                                    chosenOptions={selectedSystems}
                                    onChange={setSelectedSystems}
                                    label={translateString("assets")}
                                    noOptionsLabel={translateString("noOptions")}
                                ></DotLegalMultiSelect>
                            </Grid>
                        }
                        headers={getHeaders()}
                        defaultOrderBy={"created"}
                        data={classifications}
                        isLoading={isLoading}
                        defaultOrder={"desc"}
                        labelRowsPerPage={""}
                        labelSearch={translateString("search")}
                        noOptionsLabel={""}
                        hideRowsPerPage
                        clickableRows={false}
                        hidePagination
                        emptyText={translateString("noData")}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <QuestionnaireTemplateLink type={"classification"} id={row.questionnaireId} name={row.questionnaireName} />
                                </TableCell>
                                <TableCell>{row.created ? translateDate(row.created) : ""}</TableCell>
                                <TableCell>{getSystemsUrl(row.system)}</TableCell>
                                <TableCell>{getRisk(row)}</TableCell>
                                <TableCell>{row.scoreName}</TableCell>
                                <TableCell>{row.submitted ? translateDate(row.submitted) : ""}</TableCell>
                                <TableCell>{row.submittedBy}</TableCell>
                                <TableCell>
                                    <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                                </TableCell>
                                <TableCell>{getReportButtons(row)}</TableCell>
                                <TableCell>{formatNote(row)}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                        paginationLabelOf={""}
                        getUserSpecificPageLength={() => 30}
                    ></DotLegalTableWithControls>
                ) : (
                    <Box
                        sx={{
                            img: {
                                width: "15%",
                            },
                        }}
                    >
                        <DotLegalEmptyState icon={<EmptyState />} text={translateString("legalEntityClassificationEmptyState")} />
                    </Box>
                )}
                {vendorManagementPlan.hasAccess && legalEntityPermissions.create && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                        <DotLegalButton onClick={() => setShowClassificationDialog(true)} buttonType={"primary"}>
                            {translateString("addClassification")}
                        </DotLegalButton>
                    </Box>
                )}

                {showDeleteDialog && (
                    <DeleteClassificationDialog
                        onDialogClose={() => setShowDeleteDialog(false)}
                        selectedClassification={selectedClassification!}
                        onSuccessDelete={onClassificationDeleted}
                    />
                )}

                {showClassificationDialog && (
                    <LegalEntityClassificationDialog
                        onCreated={onClassificationCreated}
                        legalEntityId={props.legalEntityId}
                        onDialogClose={() => setShowClassificationDialog(false)}
                    />
                )}

                {showNoteDialog && (
                    <DotLegalNoteDialog
                        isSaving={isSavingNote}
                        showDialog
                        disabled={!vendorManagementPlan.hasAccess || !legalEntityPermissions.edit}
                        noteText={selectedClassification?.note}
                        onDialogClose={() => setShowNoteDialog(false)}
                        onOkClick={(text) => {
                            saveNote(text);
                        }}
                    />
                )}

                {showEvaluateDialog && (
                    <EvaluateClassificationDialog
                        onEvaluated={onClassificationEvaluated}
                        onDialogClose={() => {
                            setShowEvaluateDialog(false);
                            setSelectedClassification(undefined);
                        }}
                        selectedClassification={selectedClassification!}
                    />
                )}

                {showReopenDialog && (
                    <ReopenClassificationDialog
                        classificationId={selectedClassification!.id}
                        name={selectedClassification!.questionnaireName}
                        onDialogClose={() => setShowReopenDialog(false)}
                        onInternalAssessmentReopened={() => setShowReopenDialog(false)}
                    />
                )}
            </React.Fragment>
        </DotLegalPaper>
    );
}

export default LegalEntityClassificationTab;

import { useQuery, useQueryClient } from "react-query";
import { SecurityTabContentProps } from "./SecurityTabContent";
import { get } from "../../../common/api/apiShared";
import { SystemSecurityTabContentViewModel } from "./SecurityTabContent.types";
import { useState } from "react";

export function useSecurityTabContent(props: SecurityTabContentProps) {
    const [showInternalControlProcedureDialog, setShowInternalControlProcedureDialog] = useState(false);

    const queryClient = useQueryClient();

    const queryKey = "systemOverview" + props.systemId + "securityTab";
    const infoQuery = useQuery(`${queryKey}`, () => get<SystemSecurityTabContentViewModel>(`/SystemOverview/${props.systemId}/securitytab`));

    const refetch = () => {
        queryClient.refetchQueries(queryKey);
    };

    return {
        isLoading: infoQuery.isLoading,
        infoData: infoQuery.data,
        showInternalControlProcedureDialog,
        setShowInternalControlProcedureDialog,
        refetch: refetch,
    };
}

import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";

export interface LegalEntityClassificationApiModel {
    rows: Array<LegalEntityClassificationApiRow>;
    selectableSystems: Array<SelectableItem>;
}

export interface LegalEntityClassificationApiRow {
    id: string;
    created: Date;
    questionnaireId: string;
    questionnaireName: string;
    questionnaireResponseId: string;
    system?: BaseViewModel;
    riskLevel?: RiskLevel;
    score?: number;
    riskName?: string;
    submitted?: Date;
    submittedBy?: string;
    note?: string;
    report?: string;
    scoreEnabled: boolean;
    hasRecommendations: boolean;
    evaluationStatus?: EvaluationStatus;
    evaluation: string;
}

export interface LegalEntityClassificationViewRow extends LegalEntityClassificationApiRow {
    systemName?: string;
    scoreName?: string;
    evaluationName?: string;
}

export enum RiskLevel {
    None = 0,
    VeryLow = 1,
    Low = 2,
    Medium = 3,
    High = 4,
    VeryHigh = 5,
}

import React, { useRef, useState } from "react";
import DotLegalStepper from "../common/components/dotLegalStepper/DotLegalStepper";
import { DataSubjectDataCategory, StepperChild, StepTypeEnum } from "./ProcessingActivity.types";
import MasterDataStep from "./masterDataStep/MasterDataStep";
import DataSubjectStep from "./dataSubjectStep/DataSubjectStep";
import AssociationStep from "./associationStep/AssociationStep";
import DataCategoriesStep from "./DataCategoriesStep/DataCategoriesStep";
import LegalBasesStep from "./legalBasesStep/LegalBasesStep";
import SystemStep from "./systemsStep/SystemStep";
import SharingOfDataStep from "./sharingOfDataStep/SharingOfDataStep";
import { shouldDisableComments, useProcessingActivityHook } from "./ProcessingActivity.hooks";
import { useTranslation } from "../localization/useTranslation";
import DotLegalFullScreenSpinner from "../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import { reactPlugin } from "../AppInsights";
import { useAppInsightsContext, useTrackMetric, withAITracking } from "@microsoft/applicationinsights-react-js";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { DotLegalPageHeader, useHistoryWithReferer } from "@dotlegal/dotlegal-ui-components";
import { useUrlProvider } from "../useUrlProvider";
import { Permissions, ProcessingActivityStatusManagement } from "../auth/userContextProvider/UserContextProvider.types";
import { ProcessingActivityStatus } from "./processingActivities/ProcessingActivities.types";
import ProcessingActivtyReportDialog from "../processingActivityReport/processingActivityReportDialog/ProcessingActivityReportDialog";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import ProcessingActivityPolicyStep from "./policiesStep/ProcessingActivityPolicyStep";
import { Box } from "@mui/system";
import CommentDialog from "../comment/commentDialog/CommentDialog";

export interface IProcessingActivityProps {
    processsingActivityValidation: boolean;
}

function ProcessingActivity(props: IProcessingActivityProps) {
    const historyWithReferer = useHistoryWithReferer();
    const urlProvider = useUrlProvider();
    const [saveClicked, setSaveClicked] = useState(false);
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "ProcessingActivity");
    const hasTracked = useRef(false);
    const { permissions, customerName } = useUserContext();
    const { processingActivityAndRecordsPlan, planType } = usePlanContext();

    if (!hasTracked.current) {
        trackComponent();
        hasTracked.current = true;
    }

    const {
        isLoading,
        processingActivity,
        setProcessingActivity,
        onSave,
        onLegalEntityChange,
        getActiveStep,
        changeStep,
        validationErrors,
        getSteps,
        setProcessingActivityWithoutSaving,
        subSteps,
        expanded,
        completeValidationDialog,
        setCompleteValidationDialog,
        validateStep,
        completeValidation,
        completeInProgress,
        handleCollapseClick,
        isAllStepsValid,
        showProcessingActivityReportDialog,
        setShowProcessingActivityReportDialog,
    } = useProcessingActivityHook(props.processsingActivityValidation);

    const activeStep = getActiveStep();
    const steps = getSteps();
    const currentStep = steps.find((s) => s.stepType === activeStep.stepType);
    const { translateString } = useTranslation();

    let hasDataCreationPermission = () => {
        return permissions.canManageMasterData;
    };

    function isStepReadOnly(currentStep: any, permission: Permissions) {
        return (
            (currentStep?.stepValidated?.isValidated ?? false) ||
            !permission.edit ||
            (permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft &&
                processingActivity.status !== ProcessingActivityStatus.Draft &&
                processingActivity.status !== ProcessingActivityStatus.AwaitingResearcher)
        );
    }

    function getStepContent(stepType: StepTypeEnum): StepperChild {
        switch (stepType) {
            case StepTypeEnum.masterData:
                return {
                    childElement: (
                        <MasterDataStep
                            hasDataCreationPermission={hasDataCreationPermission()}
                            description={processingActivity.description}
                            name={processingActivity.name}
                            processingActivityId={processingActivity.id}
                            purposes={processingActivity.purposes}
                            validationErrors={validationErrors}
                            readOnly={isStepReadOnly(currentStep, permissions.processingActivityMasterDataPermissions.permissions)}
                            OnDescriptionChange={async (description) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.description = description;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            OnNameChange={async (name) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.name = name;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            OnPurposesChange={async (purposes) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.purposes = purposes;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                        />
                    ),
                    graphicType: { wave: "purpleWave", graphic: "stepOne", position: "right" },
                };
            case StepTypeEnum.dataSubject:
                return {
                    childElement: (
                        <DataSubjectStep
                            hasDataCreationPermission={hasDataCreationPermission()}
                            processingActivityId={processingActivity.id}
                            name={processingActivity.name}
                            dataSubjects={processingActivity.dataSubjects}
                            dataSubjectDataCategories={processingActivity.dataSubjectDataCategories}
                            subjects={processingActivity.subjects}
                            dataSources={processingActivity.dataSources}
                            validationErrors={validationErrors}
                            processors={processingActivity.processors}
                            securityMeasures={processingActivity.securityMeasures}
                            readOnly={isStepReadOnly(currentStep, permissions.processingActivityDataSubjectPermissions.permissions)}
                            onDataSubjectsChange={async (dataSubjects) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.dataSubjects = dataSubjects;
                                if (dataSubjects.length > processingActivity.dataSubjects.length) {
                                    let addedDataSubject = dataSubjects.filter((x) => !processingActivity.dataSubjects.includes(x))[0];
                                    processingActivity.standardDataCategories.forEach((d) => {
                                        newProcessingActivityModel.dataSubjectDataCategories = [
                                            ...newProcessingActivityModel.dataSubjectDataCategories,
                                            new DataSubjectDataCategory(d, addedDataSubject),
                                        ];
                                    });
                                }
                                if (dataSubjects.length < processingActivity.dataSubjects.length) {
                                    let removedDataSubject = processingActivity.dataSubjects.filter((x) => !dataSubjects.includes(x))[0];
                                    let dataSubjectDataCategories = processingActivity.dataSubjectDataCategories.filter(
                                        (x) => x.dataSubjectId === removedDataSubject
                                    );
                                    newProcessingActivityModel.dataSubjectDataCategories = processingActivity.dataSubjectDataCategories.filter(
                                        (l) => l.dataSubjectId !== removedDataSubject
                                    );
                                    let newStandardDataCategories = newProcessingActivityModel.standardDataCategories;
                                    dataSubjectDataCategories.forEach((d) => {
                                        if (!newProcessingActivityModel.dataSubjectDataCategories.find((y) => y.dataCategoryId === d.dataCategoryId)) {
                                            let index = newStandardDataCategories.indexOf(newStandardDataCategories.find((y) => y === d.dataCategoryId)!);
                                            newStandardDataCategories.splice(index, 1);
                                        }
                                    });
                                    newProcessingActivityModel.standardDataCategories = newStandardDataCategories;
                                }
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onSubjectsChange={async (subjects) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.subjects = subjects === 0 ? undefined : subjects;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onProccessorsChange={async (processers) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.processors = processers === 0 ? undefined : processers;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onSecurityMeasureChange={async (securityMeasures) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.securityMeasures = securityMeasures;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onDataSourceChange={async (dataSources) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.dataSources = dataSources;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                        />
                    ),
                    graphicType: { wave: "purpleWave", graphic: "stepTwo", position: "right" },
                };
            case StepTypeEnum.association:
                return {
                    childElement: (
                        <AssociationStep
                            processingActivityId={processingActivity.id}
                            name={processingActivity.name}
                            hasDataCreationPermission={hasDataCreationPermission()}
                            owner={processingActivity.owner}
                            processingAreas={processingActivity.processingAreas}
                            selectedSharedResponsibles={processingActivity.sharedResponsibles}
                            validationErrors={validationErrors}
                            selectedEntities={processingActivity.legalEntities}
                            onLegalEntitiesChange={(legalEntities) => onLegalEntityChange(legalEntities)}
                            onOwnerChange={async (owner) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.owner = owner;
                                newProcessingActivityModel.dataProcessors.forEach((processing) => {
                                    if (processing.isSystemGenerated) processing.responsible = newProcessingActivityModel.owner;
                                });
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onProcessingAreaChange={async (areas) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.processingAreas = areas;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            onSharedReponsiblesChange={async (responsibles) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.sharedResponsibles = responsibles;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            isReadOnly={isStepReadOnly(currentStep, permissions.processingActivityAssociationPermissions.permissions)}
                            hasMultipleGroupCompanies={processingActivity.hasMultipleGroupCompanies}
                        />
                    ),
                    graphicType: processingActivity.legalEntities.length > 0 ? { wave: "greenWave" } : undefined,
                };
            case StepTypeEnum.dataCategories:
                return {
                    childElement: (
                        <DataCategoriesStep
                            dataSubjects={processingActivity.dataSubjects}
                            dataSubjectDataCategories={processingActivity.dataSubjectDataCategories}
                            processingActivityId={processingActivity.id}
                            name={processingActivity.name}
                            dataCategories={processingActivity.standardDataCategories}
                            legalEntities={processingActivity.legalEntities}
                            processings={processingActivity.dataProcessors}
                            validationErrors={validationErrors}
                            sources={processingActivity.sources}
                            transfers={processingActivity.transfers}
                            readOnly={isStepReadOnly(currentStep, permissions.processingActivityDataCategoryPermissions.permissions)}
                        />
                    ),
                    graphicType: { wave: "greenWave" },
                };
            case StepTypeEnum.legalBases:
                return {
                    childElement: (
                        <LegalBasesStep
                            hasError={steps.find((x) => x.stepType === StepTypeEnum.legalBases)?.error}
                            hasDataCreationPermission={hasDataCreationPermission()}
                            processingActivityId={processingActivity.id}
                            name={processingActivity.name}
                            readOnly={isStepReadOnly(currentStep, permissions.processingActivityLegalBasesPermissions.permissions)}
                            validationErrors={validationErrors}
                            legalBasesViewModel={processingActivity.legalBasesViewModel}
                            purposes={processingActivity.purposes}
                        />
                    ),
                    graphicType: { wave: "purpleWave" },
                };
            case StepTypeEnum.systems:
                return {
                    childElement: (
                        <SystemStep
                            processingActivityModel={processingActivity}
                            onDisableSystemsChange={async (checked) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.disableSystems = checked;
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            disableSystems={processingActivity.disableSystems}
                            isReadOnly={isStepReadOnly(currentStep, permissions.processingActivitySystemPermissions.permissions)}
                            validationErrors={validationErrors}
                        />
                    ),
                    graphicType: undefined,
                };
            case StepTypeEnum.sharings:
                return {
                    childElement: (
                        <SharingOfDataStep
                            hasDataCreationPermission={hasDataCreationPermission()}
                            processingActivityModel={processingActivity}
                            onChange={async (activityObject) => {
                                let newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel = activityObject;
                                await setProcessingActivityWithoutSaving(newProcessingActivityModel);
                            }}
                            disableDataProcessors={processingActivity.disableDataProcessors}
                            disableDisclosures={processingActivity.disableDisclosures}
                            disableSources={processingActivity.disableSources}
                            disableDataControllers={processingActivity.disableDataControllers}
                            disableJointDataControllers={processingActivity.disableJointDataControllers}
                            onDisableSharingsChange={async (checked, stepType) => {
                                const newProcessingActivityModel = { ...processingActivity };

                                switch (stepType) {
                                    case StepTypeEnum.dataprocessor:
                                        newProcessingActivityModel.disableDataProcessors = checked;
                                        break;
                                    case StepTypeEnum.dataController:
                                        newProcessingActivityModel.disableDataControllers = checked;
                                        break;
                                    case StepTypeEnum.disclosure:
                                        newProcessingActivityModel.disableDisclosures = checked;
                                        break;
                                    case StepTypeEnum.sources:
                                        newProcessingActivityModel.disableSources = checked;
                                        break;
                                    case StepTypeEnum.jointDataControllers:
                                        newProcessingActivityModel.disableJointDataControllers = checked;
                                        break;
                                }
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                            validationErrors={subSteps.validationErrors}
                            getSubSteps={subSteps.getSteps}
                            changeSubStep={subSteps.changeStep}
                            getActiveSubStep={() => subSteps.getActiveStep().stepType}
                            isValidated={currentStep?.stepValidated?.isValidated ?? false}
                            processingActivityStatus={processingActivity.status}
                        />
                    ),
                    graphicType: undefined,
                };
            case StepTypeEnum.policies:
                return {
                    childElement: (
                        <ProcessingActivityPolicyStep
                            processingActivityId={processingActivity.id}
                            name={processingActivity.name}
                            isReadOnly={isStepReadOnly(currentStep, permissions.processingActivityPolicyPermissions.permissions)}
                            selectedPolicies={processingActivity.policies}
                            onChangePolicies={async (policies) => {
                                const newProcessingActivityModel = { ...processingActivity };
                                newProcessingActivityModel.policies = policies !== undefined ? policies : [];
                                await setProcessingActivity(newProcessingActivityModel);
                            }}
                        />
                    ),
                    graphicType: { wave: "blueWave", graphic: "stepEight", position: "right" },
                };
            default:
                return {
                    childElement: <div>Unknown stepIndex</div>,
                    graphicType: undefined,
                };
        }
    }

    function getLoadingState() {
        return <DotLegalFullScreenSpinner />;
    }

    const currentIndex = steps.indexOf(steps.find((s) => s.stepType === activeStep.stepType)!);

    async function save() {
        setSaveClicked(true);
        await onSave();
        setSaveClicked(false);
        historyWithReferer.push(urlProvider.getProcessingActivityOverviewUrl(processingActivity.id));
    }

    return (
        <Box sx={(_) => ({ display: "flex", flexDirection: "column", height: "100%" })}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[
                    { name: translateString("processingActivities"), link: "/privacy/processingactivities" },
                    { name: processingActivity?.name, tooltip: processingActivity?.description },
                ]}
            />
            {isLoading ? (
                <React.Fragment> {getLoadingState()} </React.Fragment>
            ) : (
                <React.Fragment>
                    {props.processsingActivityValidation ? (
                        <DotLegalStepper
                            steps={steps}
                            activeStep={activeStep.stepType}
                            header={activeStep.name}
                            onNextClick={() => changeStep(steps[currentIndex + 1].stepType)}
                            onPreviousClick={() => changeStep(steps[currentIndex - 1].stepType)}
                            graphicType={getStepContent(activeStep.stepType).graphicType}
                            showValidation={props.processsingActivityValidation}
                            inProgess={saveClicked}
                            onStepClick={(step) => changeStep(step)}
                            onSaveClick={async () => await save()}
                            isExpanded={expanded}
                            isComplete={isAllStepsValid}
                            showCompleteValidationDialog={completeValidationDialog}
                            onCompleteValidationDialog={(open) => setCompleteValidationDialog(open)}
                            OnExpandedClick={() => handleCollapseClick()}
                            completeValidationInProgress={completeInProgress}
                            OnValidationClick={async (currentStep, IsValidated) => await validateStep(currentStep, IsValidated, currentIndex)}
                            OnCompleteValidationClick={async () => {
                                await completeValidation(processingActivity.name!);
                                historyWithReferer.push(urlProvider.getProcessingActivityOverviewUrl(processingActivity.id));
                            }}
                        >
                            {getStepContent(activeStep.stepType).childElement}
                        </DotLegalStepper>
                    ) : (
                        <DotLegalStepper
                            steps={steps}
                            activeStep={activeStep.stepType}
                            header={activeStep.name}
                            onNextClick={() => changeStep(steps[currentIndex + 1].stepType)}
                            onPreviousClick={() => changeStep(steps[currentIndex - 1].stepType)}
                            graphicType={getStepContent(activeStep.stepType).graphicType}
                            showValidation={props.processsingActivityValidation}
                            inProgess={saveClicked}
                            onStepClick={(step) => changeStep(step)}
                            OnExpandedClick={() => handleCollapseClick()}
                            isExpanded={expanded}
                            onSaveClick={async () => await save()}
                            onSecondaryButtonClick={
                                processingActivityAndRecordsPlan.caseHandlerModule &&
                                permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Draft &&
                                (processingActivity.status === ProcessingActivityStatus.Draft ||
                                    processingActivity.status === ProcessingActivityStatus.AwaitingResearcher)
                                    ? () => setShowProcessingActivityReportDialog(true)
                                    : undefined
                            }
                            secondaryButtonText={translateString("submit")}
                        >
                            {getStepContent(activeStep.stepType).childElement}
                        </DotLegalStepper>
                    )}
                </React.Fragment>
            )}

            {showProcessingActivityReportDialog && (
                <ProcessingActivtyReportDialog
                    processingActivityId={processingActivity.id}
                    processingActivityName={processingActivity.name}
                    onDialogClose={() => setShowProcessingActivityReportDialog(false)}
                    processingActivityStatus={processingActivity.status}
                    onSubmitReport={() => {}}
                />
            )}

            {permissions.canManageProcessingActivtyComments && processingActivityAndRecordsPlan.commentsModule && processingActivity && (
                <CommentDialog
                    processingActivityId={processingActivity.id}
                    header={processingActivity.name}
                    disabled={shouldDisableComments(planType, processingActivity.status)}
                />
            )}
        </Box>
    );
}

export default withAITracking(reactPlugin, ProcessingActivity, "Processing activity overiew", "tracking");

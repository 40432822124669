import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import PlanDialog, { planDialogBottomMargin } from "../planDialog/PlanDialog";
import { DotLegalButton, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { arrow } from "../../common/icons";
import { useUpgradeOrTrialDialogStyles } from "./UpgradeOrTrialDialog.styles";
import { useUpgradeOrTrialDialog } from "./UpgradeOrTrialDialog.hooks";
import { getPriceListUrl } from "../upgradePlanDialog/UpgradePlanDialog";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface IUpgradePlanDialog {
    showDialog: boolean;
    onDialogClose: () => void;
}

function UpgradeOrTrialDialog(props: IUpgradePlanDialog) {
    const styles = useUpgradeOrTrialDialogStyles();
    const { translateString } = useTranslation();
    const { language, permissions } = useUserContext();
    const { tryTrialClicked, setTryTrialClicked, beginTrial, isBeginningTrial, getNextTrialPlan } = useUpgradeOrTrialDialog(props);

    function getContent() {
        return (
            <>
                {/* Used to lock the screen while we updates BO */}
                <Backdrop
                    sx={(theme) => ({ color: theme.customPalette.white, zIndex: (theme) => theme.zIndex.drawer + 1 })}
                    open={isBeginningTrial}
                    onClick={() => {}}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {tryTrialClicked ? getTrialContent() : getUpgradePlanContent()}
            </>
        );
        /*         if (tryTrialClicked) {
            return getTrialContent();
        } else {
            return getUpgradePlanContent();
        } */
    }

    function getTrialContent() {
        return (
            <>
                <DotLegalHeader marginBottom={3} marginTop={-planDialogBottomMargin} center headerStyle="extraSmall" color="secondary">
                    {translateString("tryTrialPlanSubHeader")}
                </DotLegalHeader>
                <DotLegalHeader marginBottom={4} fontWeight={500} center headerStyle="small">
                    <Trans
                        i18nKey={"tryTrialPlanContent"}
                        values={{ plan: translateString(setFirstLetterToLowerCase(getNextTrialPlan())) }}
                        components={{
                            featureList: (
                                <Box
                                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })}
                                    component={"a"}
                                    href={getPriceListUrl(language)}
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </DotLegalHeader>
            </>
        );
    }

    function getUpgradePlanContent() {
        return (
            <React.Fragment>
                <DotLegalHeader marginBottom={3} fontWeight={500} center headerStyle="medium">
                    {translateString("tryOrUpgradePrivacy")}
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    function getButtons() {
        return (
            <>
                {tryTrialClicked ? (
                    <>
                        <Box
                            sx={{
                                "& .MuiButton-root": {
                                    "&:hover": {
                                        "& svg": {
                                            transform: "translateX(10px)",
                                            transition: "0.2s",
                                        },
                                    },
                                },
                            }}
                        >
                            <DotLegalButton onClick={() => setTryTrialClicked(false)} btnLength={300} buttonType="primary">
                                <Box sx={[styles.whiteArrow, styles.leftArrow]}>{arrow}</Box>
                                &nbsp;
                                {translateString("previous")}
                            </DotLegalButton>
                        </Box>

                        <DotLegalButton onClick={beginTrial} btnLength={300} buttonType="secondary">
                            {translateString("startFreeTrial")}
                        </DotLegalButton>
                    </>
                ) : (
                    <>
                        <PlanDialogUpgradeButton />

                        <DotLegalButton
                            onClick={() => setTryTrialClicked(true)}
                            btnLength={300}
                            buttonType="secondary"
                            disabled={!permissions.canPurchasePrivacy}
                            toolTip={!permissions.canPurchasePrivacy ? translateString("contactAdministratorToRequestTrial") : undefined}
                        >
                            {translateString("try30DaysTrial")}
                        </DotLegalButton>
                    </>
                )}
            </>
        );
    }

    return (
        <PlanDialog
            showDialog={props.showDialog}
            onDialogClose={() => props.onDialogClose()}
            content={getContent()}
            header={
                tryTrialClicked
                    ? translateString("tryTrialPlanHeader", { plan: translateString(setFirstLetterToLowerCase(getNextTrialPlan())) })
                    : translateString("upgradePrivacyPlanHeader")
            }
            buttons={getButtons()}
        />
    );
}

export default UpgradeOrTrialDialog;

import React, { useCallback } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Fade, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigationLinkStyles } from "./NavigationLink.styles";
import { DotLegalTooltip, ToDto, UrlToPathObject } from "@dotlegal/dotlegal-ui-components";
import PrettyScrollbar from "../../common/PrettyScrollbar";
import { SubMenuItem } from "./NavigationLink.types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { hasSettingsAccess } from "../../privacySettings/Settings";

export interface INavigationLinkProps {
    text: string;
    linkTo: ToDto | string;
    subMenuItems?: Array<SubMenuItem>;
    icon: any;
    isExpanded: boolean;
    onSubMenuExpanded?: (menuItem: string | false) => void;
    subMenuExpanded?: string | false;
    isInGroup?: boolean;
    openNewTab?: boolean;
    externalLink?: boolean;
    tooltip?: string;
    extraPaths: Array<string>;
    onClick: () => void;
}

function hasSubMenuItems(props: INavigationLinkProps) {
    return props.subMenuItems && props.subMenuItems.length > 0;
}

function NavigationLink(props: INavigationLinkProps) {
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
    const [hoveringOnMenu, setHoveringOnMenu] = React.useState(false);
    const location = useLocation();
    const urlProvider = useUrlProvider();
    const userContext = useUserContext();

    const isRouteLink = useCallback(() => {
        if (userContext.permissions.processingActivityPermissions.read) return urlProvider.getProcessingActivitiesOverviewUrl();
        else if (userContext.permissions.canAccessRecord301 || userContext.permissions.canAccessRecord302) return urlProvider.getRecordUrl();
        else if (userContext.permissions.systemPermissions.read) return urlProvider.getSystemsUrl();
        else if (userContext.permissions.riskPermissions.read) return urlProvider.getRiskAssessmentsUrl();
        else if (userContext.permissions.annualWheelPermission.read) return urlProvider.getAnnualWheelUrl();
        else if (userContext.permissions.taskPermissions.read) return urlProvider.getTaskManagementUrl();
        else if (userContext.permissions.companyPermissions.read) return urlProvider.getLegalEntitiesUrl();
        else if (hasSettingsAccess(userContext.permissions)) return urlProvider.getSettingsUrl();

        return "";
    }, [urlProvider, userContext.permissions]);

    const isActive = useCallback(
        (link: ToDto | string, extraPaths: Array<string>) => {
            const toDtoLink = UrlToPathObject(link);
            let pathname = location.pathname;
            if (toDtoLink.pathname === isRouteLink() && pathname === "/") {
                return true;
            }

            return pathname.indexOf(toDtoLink.pathname ?? "") > -1 || extraPaths.some((m) => pathname.indexOf(m) > -1);
        },
        [isRouteLink, location.pathname]
    );

    const isSubMenuItemActive = useCallback(
        (link: string, extraPaths: Array<string>) => {
            const toDtoLink = UrlToPathObject(link);
            let pathname = location.pathname;
            if (toDtoLink.pathname === isRouteLink() && pathname === "/") {
                return true;
            }

            return location.pathname.includes(link) || extraPaths.some((m) => pathname.indexOf(m) > -1);
        },
        [isRouteLink, location.pathname]
    );

    const styles = useNavigationLinkStyles(props.isExpanded, isActive(props.linkTo, props.extraPaths), props.isInGroup);

    function onAccordionChange(expanded: boolean) {
        if (props.onSubMenuExpanded) {
            props.onSubMenuExpanded(expanded ? props.text : false);
        }
    }

    function accordion(styles: any, props: INavigationLinkProps, content: JSX.Element) {
        let accordionExpanded = props.subMenuExpanded === props.text;
        let isMainMenuItemActive = isActive(props.linkTo, props.extraPaths);
        return (
            <Accordion
                expanded={accordionExpanded}
                onChange={(e, expanded) => {
                    if (accordionExpanded) {
                        onAccordionChange(expanded);
                    }
                    if (props.isExpanded) {
                        onAccordionChange(expanded);
                    }
                }}
                sx={(theme) => ({
                    padding: 0,
                    textDecoration: "none",
                    color: isMainMenuItemActive ? "inherit" : theme.palette.grey["700"],
                    fontWeight: theme.typography.fontWeightBold,
                    borderRadius: 15 + "px",
                    textTransform: "uppercase",
                    textOverflow: "elipsis",
                    "&:hover": {
                        color: props.isInGroup ? theme.palette.text.secondary : isMainMenuItemActive ? "inherit" : theme.palette.grey["700"],
                    },
                    "& .MuiAccordionDetails-root": {
                        margin: 0,
                    },
                    "& .MuiAccordionSummary-root": {
                        minHeight: "0 !important",
                    },
                })}
                className={"couldBeActive" + isMainMenuItemActive ? " active" : ""}
            >
                <DotLegalTooltip text={props.tooltip} position="right" tooltipStyle="primary" useBoldText>
                    <AccordionSummary
                        sx={{
                            paddingLeft: "8px !important",
                            paddingRight: "8px !important",
                            paddingTop: 1.5,
                            paddingBottom: 1.5,
                            borderRadius: "15px",
                            textAlign: accordionExpanded ? "left" : "center",
                            backgroundColor: isMainMenuItemActive && !accordionExpanded ? "primary.light" : "inherit",
                            // marginBottom: 1,
                            color: isMainMenuItemActive && !accordionExpanded ? "primary.main" : "black",
                            "&:hover": { backgroundColor: "primary.light", color: "primary.main" },
                            "& .MuiAccordionSummary-content": {
                                margin: 0,
                                overflow: "hidden",
                                minHeight: 0,
                                "&.Mui-expanded": {
                                    margin: 0,
                                },
                            },
                        }}
                        expandIcon={props.isExpanded ? <ExpandMoreIcon /> : undefined}
                    >
                        {content}
                    </AccordionSummary>
                </DotLegalTooltip>
                <AccordionDetails>
                    {props.subMenuItems?.map((s) => {
                        let isActive = isSubMenuItemActive(s.link, s.extraPaths ?? []);
                        return (
                            <DotLegalTooltip key={s.link} text={s.tooltip} position="right" tooltipStyle="primary" useBoldText>
                                <Box
                                    component={Link}
                                    sx={[
                                        styles.link,
                                        styles.subMenuLink,
                                        { backgroundColor: isActive ? "primary.light" : "inherit", color: isActive ? "primary.main" : "inherit" },
                                    ]}
                                    to={s.link}
                                >
                                    <Box sx={{ marginLeft: "45px" }}>{s.name}</Box>
                                </Box>
                            </DotLegalTooltip>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        );
    }

    let content = (
        <React.Fragment>
            {!props.isExpanded && hasSubMenuItems(props) ? (
                <Box sx={styles.menuIcon} onMouseEnter={(_) => setHoveringOnMenu(true)} onMouseLeave={(_) => setHoveringOnMenu(false)}>
                    {props.icon}
                </Box>
            ) : (
                <Box sx={styles.menuIcon}>{props.icon}</Box>
            )}

            <Box component={"span"} sx={[styles.text, props.isExpanded ? {} : styles.isMinimized]}>
                {props.text}
            </Box>
        </React.Fragment>
    );

    if (hasSubMenuItems(props) && props.isExpanded) {
        content = accordion(styles, props, content);
    } else if (props.linkTo) {
        content = (
            <Box
                component={Link}
                sx={{ ...styles.link, ...styles.menuItem }}
                target={props.openNewTab ? "_blank" : "_self"}
                rel={props.openNewTab ? "noopener noreferrer" : ""}
                onMouseEnter={(e: any) => setAnchorElement(e.currentTarget)}
                onMouseLeave={(_: any) => setAnchorElement(null)}
                onClick={props.onClick}
                to={props.linkTo}
            >
                {content}
            </Box>
        );
    }

    if (!props.isExpanded && !hasSubMenuItems(props)) {
        content = (
            <DotLegalTooltip text={`${props.text} ${props.tooltip ? `\n\n ${props.tooltip}` : ""}`} position="right" tooltipStyle="primary" useBoldText>
                {content}
            </DotLegalTooltip>
        );
    } else if (props.tooltip && !hasSubMenuItems(props)) {
        content = (
            <DotLegalTooltip text={props.tooltip} position="right" tooltipStyle="primary" useBoldText>
                {content}
            </DotLegalTooltip>
        );
    }

    let open = ((Boolean(anchorElement) || hoveringOnMenu) && hasSubMenuItems(props)) ?? false;

    return (
        <React.Fragment>
            <Box component={"li"} sx={styles.wrapper}>
                {content}
            </Box>

            <Popper
                sx={styles.popOverSubMenu}
                open={open}
                anchorEl={anchorElement}
                placement={"right-start"}
                transition
                onMouseEnter={(_) => {
                    setHoveringOnMenu(true);
                }}
                onMouseLeave={(_) => {
                    setHoveringOnMenu(false);
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ padding: 1 }}>
                            <MenuList>
                                <DotLegalTooltip text={props.tooltip} position="right" tooltipStyle="primary" useBoldText>
                                    <Box component={"h4"} sx={(theme) => ({ fontSize: theme.typography.pxToRem(14), textTransform: "uppercase", height: 40 })}>
                                        {props.text}
                                    </Box>
                                </DotLegalTooltip>
                                <PrettyScrollbar maxHeight={350}>
                                    {props.subMenuItems?.map((s) => {
                                        let isActive = isSubMenuItemActive(s.link, s.extraPaths ?? []);
                                        const menuItem = (
                                            <DotLegalTooltip text={s.tooltip} position="right" tooltipStyle="primary" useBoldText>
                                                <MenuItem
                                                    onClick={() => {
                                                        setHoveringOnMenu(false);
                                                        setAnchorElement(null);
                                                    }}
                                                    sx={(theme) => ({
                                                        ...styles.collapsedSubMenuItem,
                                                        backgroundColor: isActive ? "primary.light" : "inherit",
                                                        color: isActive ? "primary.main" : theme.palette.grey["700"],
                                                        marginBottom: 0.5,
                                                    })}
                                                >
                                                    {s.name}
                                                </MenuItem>
                                            </DotLegalTooltip>
                                        );
                                        return (
                                            <Box component={Link} key={s.link} sx={styles.subMenuPopperLink} to={s.link} color={"inherit"}>
                                                <Box>{menuItem}</Box>
                                            </Box>
                                        );
                                    })}
                                </PrettyScrollbar>
                            </MenuList>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </React.Fragment>
    );
}

export default NavigationLink;

import { Box, Grid } from "@mui/material";
import React from "react";
import DotLegalStepHeader from "../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { useTranslation } from "../../localization/useTranslation";
import { DataProcessor, DataSubjectDataCategory, Source, Transfer } from "../ProcessingActivity.types";
import StepSegment from "../stepSegment/StepSegment";
import { useDataCategoriesStepHooks } from "./DataCategoriesStep.hooks";
import { useDataCategoriesStepstyles } from "./DataCategoriesStep.styles";
import { ValidationError } from "../../common/validationError";
import { DataSubjectSelect } from "./dataSubjectSelect/DataSubjectSelect";
import DataCategoryPicker from "./dataCategoryPicker/DataCategoryPicker";

export interface IDataCategoriesStep {
    name?: string;
    dataSubjects: Array<string>;
    dataSubjectDataCategories: Array<DataSubjectDataCategory>;
    processingActivityId: string;
    dataCategories: Array<string>;
    legalEntities: Array<string>;
    processings: Array<DataProcessor>;
    transfers: Array<Transfer>;
    sources: Array<Source>;
    readOnly?: boolean;
    validationErrors: Array<ValidationError>;
}

function DataCategoriesStep(props: IDataCategoriesStep) {
    const { translateString } = useTranslation();
    const {
        dataCategoriesData,
        isLoading,
        onAddStandardDataCategory,
        onRemoveStandardDataCategory,
        dataSubjectsOptions,
        isLoadingSubjects,
        dataSubjectId,
        setDataSubjectId,
    } = useDataCategoriesStepHooks(props.processingActivityId, props.dataCategories, props.dataSubjects, props.dataSubjectDataCategories);
    const styles = useDataCategoriesStepstyles();
    const gridSpacing = 3;

    if (dataCategoriesData && props.dataSubjectDataCategories && props.dataCategories && dataSubjectId !== "all" && dataSubjectId !== null) {
        if (props.dataSubjectDataCategories.length === 0 && props.dataCategories.length !== 0) {
            props.dataCategories.forEach((id) => {
                var dataCategory = dataCategoriesData!.find((r) => r.id === id);

                if (dataCategory) dataCategory.isSelected = true;
            });
        }
        props.dataSubjectDataCategories.forEach((obj) => {
            if (obj.dataSubjectId === dataSubjectId) {
                var dataSubjectCategory = dataCategoriesData!.find((x) => x.id === obj.dataCategoryId);

                if (dataSubjectCategory) {
                    dataSubjectCategory.isSelected = true;
                }
            }
        });
    } else if (dataCategoriesData && props.dataCategories) {
        props.dataCategories.forEach((id) => {
            var dataCategory = dataCategoriesData!.find((r) => r.id === id);

            if (dataCategory) dataCategory.isSelected = true;
        });
    }

    function handleChange(isSelected: boolean, id: string) {
        let subjectId = dataSubjectId === "all" ? null : dataSubjectId;
        isSelected ? onAddStandardDataCategory(id, subjectId) : onRemoveStandardDataCategory(id, subjectId);
    }

    return (
        <StepSegment size="large">
            <DotLegalStepHeader
                toolTipText={translateString("dataCategoryHelperText")}
                record
                subHeader={translateString("dataCategoriesStepHeaderSecond")}
                bottomMargin
            >
                {translateString("dataCategoriesStepHeaderPrime")}
            </DotLegalStepHeader>
            <Grid container justifyContent="flex-start" spacing={gridSpacing}>
                <Grid item>
                    <DataSubjectSelect
                        dataSubjects={props.dataSubjects}
                        options={dataSubjectsOptions}
                        selectedItem={dataSubjectId}
                        onChange={(dataSubject) => setDataSubjectId(dataSubject)}
                        label={translateString("dataSubjects")}
                        placeholder={translateString("dataSubjects")}
                        disabled={props.readOnly}
                        isLoading={isLoadingSubjects}
                        dataSubjectTooltip={translateString("selectDataSubjectsHelperText")}
                        disableClearable
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </Grid>
            {props.validationErrors.length > 0 && <Box sx={styles.error}>{translateString("dataCategoriesRequired")}</Box>}
            <DataCategoryPicker isLoading={isLoading} readOnly={props.readOnly} allDataCategories={dataCategoriesData} onDataCategoryClick={handleChange} />
        </StepSegment>
    );
}

export default DataCategoriesStep;

import { useTranslation } from "../../localization/useTranslation";
import { useEditTaskDialogDataMapping } from "./EditTaskDialog.hooks";
import { useEditTaskDialogStyles } from "./EditTaskDialog.styles";
import React, { useEffect, useState } from "react";
import TaskStatus from "../taskStatus/TaskStatus";
import SubTasks from "../subTasks/SubTasks";
import {
    DotLegalFullScreenSpinner,
    DotLegalHeader,
    DotLegalOverflowMenu,
    DotLegalPrettyScrollbar,
    DotLegalTooltip,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import TaskDocuments from "./taskDocuments/TaskDocuments";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { Box, Button, darken, Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import MasterDataSection from "./masterDataSection/MasterDataSection";
import SubtasksSvg from "./SubTask.svg?react";
import Documentation from "./Documentation.svg?react";
import LinkTaskSvg from "./LinkTask.svg?react";
import TaskComments from "./taskComments/TaskComments";
import { AddRelatedTaskScenario, AddTaskClickEnum } from "./EditTaskDialog.types";
import { closeDialogXIcon } from "../../common/icons";
import TaskRelations from "./taskRelations/TaskRelations";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { AnnualWheelActivityType } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";

export interface IEditTaskDialog {
    taskId: string;
    setSelectedTask: (value: string | null) => void;
    refetchTasks: () => void;
    setShowDeleteDialog: () => void;
    setYearByTaskYear: (year: number) => void;
}

export const buttonColor = "#F5F5F5";
export const TopButton = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    lineHeight: 1.5,
    borderRadius: "5px",
    backgroundColor: buttonColor,
    borderColor: buttonColor,
    color: theme.palette.primary.dark,
    "&:hover": {
        backgroundColor: darken(buttonColor, 0.1),
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: darken(buttonColor, 0.1),
    },
    "& img": {
        marginRight: 8,
    },
    "&.Mui-disabled": {
        color: theme.palette.primary.dark,
    },
}));

function EditTaskDialog(props: IEditTaskDialog) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const { translateString } = useTranslation();
    const { task, updateTaskState, onTaskDocumentChange, addTask, setAddTask, addDocument, setAddDocument, addRelatedTask, setAddRelatedTask } =
        useEditTaskDialogDataMapping(props);

    const styles = useEditTaskDialogStyles();
    const { permissions } = useUserContext();
    const { taskManagementAndAnnualWheelPlan } = usePlanContext();

    const showTaskDocuments = !task?.isSeparateTask;
    const showTaskComments = !!task;
    const showSubTasks = !!task;
    const showTaskRelations = task && !task.isSeparateTask && task.type !== AnnualWheelActivityType.ProcessingActivityValidation;

    const [taskCommentsIsDoneLoading, setTaskCommentsIsDoneLoading] = useState<boolean>();
    const [subTasksIsDoneLoading, setSubTasksIsDoneLoading] = useState<boolean>();
    const [taskRelationsIsDoneLoading, setTaskRelationsIsDoneLoading] = useState<boolean>();
    const [taskDocumentsIsDoneLoading, setTaskDocumentsIsDoneLoading] = useState<boolean>();
    const [masterDataSectionIsDoneLoading, setMasterDataSectionIsDoneLoading] = useState<boolean>();
    const [showDialogData, setShowDialogData] = useState<boolean>(false);

    useEffect(() => {
        if (
            !showDialogData &&
            (showTaskComments ? taskCommentsIsDoneLoading : true) &&
            (showSubTasks ? subTasksIsDoneLoading : true) &&
            (showTaskRelations ? taskRelationsIsDoneLoading : true) &&
            (showTaskDocuments ? taskDocumentsIsDoneLoading : true) &&
            masterDataSectionIsDoneLoading &&
            task
        ) {
            setShowDialogData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskCommentsIsDoneLoading, subTasksIsDoneLoading, taskRelationsIsDoneLoading, taskDocumentsIsDoneLoading, masterDataSectionIsDoneLoading]);

    const getValue = (value: string) => {
        return <Box sx={styles.text}>{value}</Box>;
    };

    let taskStatus = <Box></Box>;
    if (showDialogData) {
        taskStatus = (
            <TaskStatus
                activityName={task?.name ?? ""}
                taskName={task?.processingActivityName}
                customTaskId={task?.customId}
                processingActivtiyId={task?.processingActivityId ?? null}
            />
        );
    }

    function resetStates() {
        setShowDialogData(false);
        setSubTasksIsDoneLoading(undefined);
        setTaskCommentsIsDoneLoading(undefined);
        setTaskRelationsIsDoneLoading(undefined);
        setTaskDocumentsIsDoneLoading(undefined);
    }

    function onDialogClose() {
        props.setSelectedTask("");
        props.refetchTasks();
        resetStates();
    }

    function getRelatedTaskTopButton() {
        if (!showTaskRelations) {
            return;
        }

        const button = (
            <TopButton
                disabled={!taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks || !permissions.taskPermissions.edit}
                onClick={() => setAddRelatedTask(AddRelatedTaskScenario.ShowContentAndOpenSuggenstions)}
            >
                <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                    <LinkTaskSvg />
                </Box>
                {translateString("addRelatedTasks")}
            </TopButton>
        );

        if (taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks) {
            return button;
        }
        return (
            <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                <span>{button}</span>
            </DotLegalTooltip>
        );
    }

    let borderPadding = 4;
    return (
        <Dialog open fullWidth maxWidth={"xl"} onClose={onDialogClose} fullScreen={isOnSmallScreen}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: borderPadding,
                    paddingLeft: borderPadding,
                    paddingRight: borderPadding,
                    fontSize: "18px !important",
                }}
            >
                <Box
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                    }}
                >
                    {taskStatus}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {showDialogData && (
                        <Box sx={{ marginRight: 1 }}>
                            {permissions.taskPermissions.delete && (
                                <DotLegalOverflowMenu
                                    setHorizontally
                                    color={"secondary"}
                                    items={[
                                        {
                                            menuItem: translateString("delete"),
                                            onClick: () => props.setShowDeleteDialog(),
                                        },
                                    ]}
                                />
                            )}
                        </Box>
                    )}
                    <Box>
                        <IconButton onClick={onDialogClose}>{closeDialogXIcon}</IconButton>
                    </Box>
                </Box>
            </Box>
            <DialogContent sx={{ paddingTop: 0, paddingRight: borderPadding, paddingBottom: borderPadding, paddingLeft: borderPadding }}>
                {!showDialogData && (
                    <Box sx={(_) => ({ height: window.innerHeight / 2 })}>
                        <DotLegalFullScreenSpinner />
                    </Box>
                )}

                <Grid container>
                    <Grid item xs={12} sm={12} md={7}>
                        <DotLegalPrettyScrollbar>
                            <Box
                                sx={{
                                    paddingRight: 2,
                                    paddingTop: 1,
                                }}
                            >
                                {showDialogData && (
                                    <Box sx={{ "& > *:not(:last-child) ": { marginRight: 1 }, marginBottom: 2 }}>
                                        <TopButton
                                            disabled={!permissions.taskPermissions.edit}
                                            onClick={() => {
                                                setAddTask(true);
                                            }}
                                        >
                                            <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                                                <SubtasksSvg />
                                            </Box>
                                            {translateString("addSubTask")}
                                        </TopButton>
                                        {getRelatedTaskTopButton()}
                                        <TopButton
                                            disabled={!permissions.taskPermissions.edit}
                                            onClick={() => {
                                                switch (addDocument) {
                                                    case AddTaskClickEnum.None:
                                                        setAddDocument(AddTaskClickEnum.ShowSection);
                                                        break;
                                                    case AddTaskClickEnum.ShowSection:
                                                        setAddDocument(AddTaskClickEnum.ShowFileExplorer);
                                                        break;
                                                }
                                            }}
                                        >
                                            <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                                                <Documentation />
                                            </Box>
                                            {translateString("addDocumentation")}
                                        </TopButton>
                                    </Box>
                                )}
                                {showDialogData && task?.description && (
                                    <Box sx={{ marginBottom: 3 }}>
                                        <DotLegalHeader marginBottom={0.5} marginTop={0} headerStyle="small">
                                            {translateString("description")}
                                        </DotLegalHeader>
                                        {getValue(task!.description)}
                                    </Box>
                                )}
                                {showSubTasks && (
                                    <SubTasks
                                        passIsDoneLoading={(newValue: boolean) => {
                                            if (!subTasksIsDoneLoading) {
                                                setSubTasksIsDoneLoading(newValue);
                                            }
                                        }}
                                        disabled={!permissions.taskPermissions.edit}
                                        taskId={props.taskId}
                                        updateTaskState={() => updateTaskState()}
                                        isSeparateTask={task.isSeparateTask}
                                        shouldAddTask={addTask}
                                        onSuccessAddTask={() => setAddTask(false)}
                                        allDataIsDoneLoading={showDialogData}
                                    />
                                )}

                                {showTaskRelations && (
                                    <TaskRelations
                                        passIsDoneLoading={(newValue: boolean) => {
                                            if (!taskRelationsIsDoneLoading) {
                                                setTaskRelationsIsDoneLoading(newValue);
                                            }
                                        }}
                                        shouldAddRelatedTask={addRelatedTask === AddRelatedTaskScenario.ShowContentAndOpenSuggenstions}
                                        showRelatedTasks={addRelatedTask >= AddRelatedTaskScenario.ShowContent}
                                        updateRelatedTaskScenario={() => {
                                            if (addRelatedTask === AddRelatedTaskScenario.ShowContentAndOpenSuggenstions) {
                                                setAddRelatedTask(AddRelatedTaskScenario.ShowContent);
                                            }
                                        }}
                                        taskId={props.taskId}
                                        onHideRelatedTasks={() => setAddRelatedTask(AddRelatedTaskScenario.None)}
                                        allDataIsDoneLoading={showDialogData}
                                        readOnly={!taskManagementAndAnnualWheelPlan.relatedActivitiesAndTasks || !permissions.taskPermissions.edit}
                                    />
                                )}

                                {showTaskDocuments && (
                                    <TaskDocuments
                                        passIsDoneLoading={(newValue: boolean) => {
                                            if (!taskDocumentsIsDoneLoading) {
                                                setTaskDocumentsIsDoneLoading(newValue);
                                            }
                                        }}
                                        disabled={!permissions.taskPermissions.edit}
                                        taskId={props.taskId}
                                        updateTaskState={() => updateTaskState()}
                                        updateDocumentState={(hasDocuments) => onTaskDocumentChange(hasDocuments)}
                                        shouldAddDocument={addDocument === AddTaskClickEnum.ShowFileExplorer}
                                        show={addDocument >= AddTaskClickEnum.ShowSection}
                                        isDocumentationRequired={task?.documentationRequired}
                                        onSuccessAddDocument={() => {
                                            if (addDocument === AddTaskClickEnum.ShowFileExplorer) {
                                                setAddDocument(AddTaskClickEnum.ShowSection);
                                            }
                                        }}
                                        onClose={() => setAddDocument(AddTaskClickEnum.None)}
                                        allDataIsDoneLoading={showDialogData}
                                    />
                                )}
                                {showTaskComments && (
                                    <TaskComments
                                        passIsDoneLoading={(newValue: boolean) => {
                                            if (!taskCommentsIsDoneLoading) {
                                                setTaskCommentsIsDoneLoading(newValue);
                                            }
                                        }}
                                        taskId={props.taskId}
                                        taskName={task.name}
                                        customId={task.customId}
                                        deadline={task.deadline}
                                        allDataIsDoneLoading={showDialogData}
                                    />
                                )}
                            </Box>
                        </DotLegalPrettyScrollbar>
                    </Grid>
                    <Grid hidden={!showDialogData} item xs={12} sm={12} md={5} paddingLeft={2} paddingTop={1}>
                        <MasterDataSection
                            passIsDoneLoading={(newValue: boolean) => {
                                if (!masterDataSectionIsDoneLoading) {
                                    setMasterDataSectionIsDoneLoading(newValue);
                                }
                            }}
                            taskId={task?.id}
                            masterData={
                                task
                                    ? {
                                          customId: task.customId,
                                          deadline: task.deadline,
                                          documentationRequired: task.documentationRequired,
                                          hasDocument: task.hasDocument,
                                          isOverdue: task.isOverdue,
                                          isSeparateTask: task.isSeparateTask,
                                          legalEntityName: task.legalEntityName,
                                          name: task.name,
                                          type: task.type,
                                      }
                                    : undefined
                            }
                            isLoading={!task}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EditTaskDialog;

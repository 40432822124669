import { DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import DotLegalSecurityMeasure from "../../../common/components/dotLegalSecurityMeasure/DotLegalSecurityMeasure";
import { SecurityMeasureModel } from "../../../common/components/dotLegalSecurityMeasure/DotLegalSecurityMeasure.types";
import DotLegalSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalSelectWithButton";
import { Guid } from "../../../common/guid";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import { AddEditDeletionPeriodDialog } from "../../../masterData/deletionPeriod/addEditDeletionPeriodDialog/AddEditDeletionPeriodDialog";
import AddEditSecurityMeasureDialog from "../../../masterData/securityMeasure/addEditSecurityMeasureDialog/AddEditSecurityMeasureDialog";
import SegmentStep from "../../../processingActivity/stepSegment/StepSegment";
import StepSegment from "../../../processingActivity/stepSegment/StepSegment";
import { useSystemInfomationStepHooks } from "./SystemInformationStep.hooks";
import { Grid, RadioGroup, Typography } from "@mui/material";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import DotLegalMultiSelectWithButton from "../../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import CertificationDialog from "../../../masterData/certification/certificationDialog/CertificationDialog";

export interface ISystemInformationStep {
    canManageMasterData: boolean;
    deletionPeriod: string | null;
    deletionPeriodReason: string | undefined;
    securityMeasures: Array<SecurityMeasureModel>;
    validationErrors: Array<ValidationError>;
    certifications: Array<string>;
    isCriticalForOperation: boolean;
    readOnly?: boolean;
    onDeletionPeriodChange: (deletionPeriod: string | null) => void;
    onSecurityMeasureChange: (securityMeasures: Array<SecurityMeasureModel>) => void;
    onDeletionPeriodReasonChange: (value: string | undefined) => void;
    onCertificationChange: (certifications: Array<string>) => void;
    onIsCriticalOperationChange: (isCritical: boolean) => void;
}

export function SystemInformationStep(props: ISystemInformationStep) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { systemPlan } = usePlanContext();
    const {
        securityMeasureLoading,
        securityMeasureData,
        deletionPeriodsData,
        deletionPeriodsLoading,
        typedSecurityMeasure,
        setTypedSecurityMeasure,
        showAddNewSecurityMeasureDialog,
        setShowAddNewSecurityMeasureDialog,
        onSaveSecurityMeasure,
        onSaveDeletionPeriod,
        showAddNewDeletionPeriod,
        setShowAddNewDeletionPeriod,
        typedDeletionPeriod,
        setTypedDeletionPeriod,
        certificationData,
        certificationLoading,
        setShowAddCertificationDialog,
        setTypedCertification,
        showAddCertificationDialog,
        typedCertification,
        onSaveCertification,
    } = useSystemInfomationStepHooks(props);

    return (
        <React.Fragment>
            <StepSegment size="small">
                <React.Fragment>
                    <DotLegalSelectWithButton
                        label={translateString("deletionPeriod")}
                        placeholder={translateString("deletionPeriod")}
                        selectedItem={props.deletionPeriod}
                        options={deletionPeriodsData}
                        disabled={props.readOnly}
                        onChange={(deletionPeriod) => props.onDeletionPeriodChange(deletionPeriod)}
                        isLoading={deletionPeriodsLoading}
                        onButtonClick={() => setShowAddNewDeletionPeriod(true)}
                        buttonLabel={translateString("createNew")}
                        onSearchValueChange={setTypedDeletionPeriod}
                        searchValue={typedDeletionPeriod}
                        hasPermission={props.canManageMasterData && permissions.systemInformationPermissions.permissions.edit}
                        toolTipText={translateString("systemDeletionPeriodTooltip")}
                        noOptionsLabel={translateString("noOptions")}
                    />
                    {props.deletionPeriod && (
                        <DotLegalTextField
                            onChange={(e) => props.onDeletionPeriodReasonChange(e)}
                            label={translateString("deletionPeriodReason")}
                            value={props.deletionPeriodReason!}
                            disabled={props.readOnly}
                            debounce
                        />
                    )}

                    {systemPlan.operationalCriticalSystem && (
                        <React.Fragment>
                            <DotLegalStepHeader record={false} topMargin>
                                {translateString("isTheSystemCriticalForOperation")}
                            </DotLegalStepHeader>

                            <Grid
                                container
                                sx={(theme) => ({ display: "inline-block", marginTop: theme.spacing(1) })}
                                alignContent="center"
                                justifyContent="center"
                            >
                                <RadioGroup
                                    row
                                    value={props.isCriticalForOperation}
                                    onChange={(event) => {
                                        const value = (event.target as HTMLInputElement).value === "true";
                                        props.onIsCriticalOperationChange(value);
                                    }}
                                >
                                    <Grid sx={{ alignSelf: "center" }} item xs={4}>
                                        <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(12), color: theme.palette.primary.dark })}>
                                            {translateString("operationCriticalSystem")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <DotLegalRadioButton value label={translateString("yes")} disabled={props.readOnly} />
                                    </Grid>
                                    <Grid item>
                                        <DotLegalRadioButton value={false} label={translateString("no")} disabled={props.readOnly} />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </StepSegment>
            {permissions.systemInformationPermissions.hasSecurityMeasurePermission && (
                <SegmentStep size="small">
                    <DotLegalSecurityMeasure
                        tooltip={translateString("systemSecurityMeasuresTooltip")}
                        header={translateString("whichSecurityMeasuresOnSystem")}
                        canManageMasterData={props.canManageMasterData}
                        disabled={props.readOnly}
                        isLoading={securityMeasureLoading}
                        securityMeasureOptions={securityMeasureData!}
                        securityMeasures={props.securityMeasures}
                        onSecurityMeasureChange={props.onSecurityMeasureChange}
                        setShowAddNewSecurityMeasure={setShowAddNewSecurityMeasureDialog}
                        setTypedSecurityMeasure={setTypedSecurityMeasure}
                        typedSecurityMeasure={typedSecurityMeasure}
                    />
                </SegmentStep>
            )}

            {systemPlan.certifications && (
                <React.Fragment>
                    <DotLegalStepHeader record={false} topMargin>
                        {translateString("systemCertificationHeader")}
                    </DotLegalStepHeader>
                    <StepSegment size="small">
                        <DotLegalMultiSelectWithButton
                            onButtonClick={() => setShowAddCertificationDialog(true)}
                            isLoading={certificationLoading}
                            buttonLabel={translateString("createNew")}
                            options={certificationData}
                            chosenOptions={props.certifications}
                            onChange={(certifications) => props.onCertificationChange(certifications)}
                            placeholder={translateString("certifications")}
                            label={translateString("certifications")}
                            noOptionsLabel={translateString("noOptions")}
                            hasPermission={systemPlan.certifications && permissions.canManageMasterData}
                            searchValue={typedCertification}
                            onSearchValueChange={setTypedCertification}
                            disabled={props.readOnly}
                        />
                    </StepSegment>
                </React.Fragment>
            )}

            {showAddNewSecurityMeasureDialog && permissions.systemInformationPermissions.hasSecurityMeasurePermission && !props.readOnly && (
                <AddEditSecurityMeasureDialog
                    open
                    isAdd
                    selectSecurityMeasure={{
                        id: Guid.newGuid(),
                        name: typedSecurityMeasure,
                        description: "",
                        securityMeasureType: undefined,
                        isGlobal: false,
                        active: true,
                        isCustom: true,
                        businessAreaIds: [],
                        groupEntitiesIds: [],
                    }}
                    onDialogClose={() => setShowAddNewSecurityMeasureDialog(false)}
                    onOkClick={onSaveSecurityMeasure}
                    refetch={() => {}}
                />
            )}
            {showAddNewDeletionPeriod && !props.readOnly && (
                <AddEditDeletionPeriodDialog
                    open
                    isAdd
                    deletionPeriod={{
                        id: Guid.newGuid(),
                        name: typedDeletionPeriod!,
                        description: "",
                        deletionPeriodLength: undefined,
                        deletionPeriodType: undefined,
                        active: true,
                        isCustom: true,
                    }}
                    onDialogClose={() => setShowAddNewDeletionPeriod(false)}
                    onOkClick={(reponse, name) => onSaveDeletionPeriod(reponse, name)}
                    refetch={() => {}}
                />
            )}

            {showAddCertificationDialog && (
                <CertificationDialog
                    onDialogClose={() => setShowAddCertificationDialog(false)}
                    onCertificationSaved={onSaveCertification}
                    selectedCertification={{ name: typedCertification, description: "" }}
                />
            )}
        </React.Fragment>
    );
}

import { Box, TableCell, TableRow } from "@mui/material";
import React, { Fragment } from "react";
import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useRolesHooks } from "./Roles.hooks";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../common/components/dotLegalTable/DotLegalTable.types";
import { RoleMasterDataViewModel } from "./Roles.types";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../useUrlProvider";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import Rocket from "../processingActivity/processingActivities/rocket.svg?react";
import { AddEditRolesMasterData } from "./addEditRolesMasterData/AddEditRolesMasterData";
import CheckIcon from "@mui/icons-material/Check";
import AddEditRoles from "./addEditRoles/AddEditRoles";
import RolesIcon from "../roles/roles.svg?react";
import DeleteRoleDialog from "./deleteRoleDialog/DeleteRoleDialog";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";

export default function Roles() {
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const { customerName } = useUserContext();

    const {
        isLoading,
        data,
        save,
        selectedRoleViewModel,
        setSelectedRoleViewModel,
        showRolesDialog,
        setShowRolesDialog,
        createNewUserRolesProps,
        hasRoles,
        id,
        roleid,
        getBreadCrumbs,
        showDeleteRoleDialog,
        setShowDeleteRoleDialog,
    } = useRolesHooks();

    const getMenuItems = (roleViewModel: RoleMasterDataViewModel) => {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                navigate(urlProvider.gerRolesEditUrl(roleViewModel.id));
            },
        });

        menuItems.push({
            menuItem: translateString("editRoles"),
            onClick: () => {
                navigate(urlProvider.getEditRolesUrl(roleViewModel.id));
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => setShowDeleteRoleDialog({ showDialog: true, name: roleViewModel.name, id: roleViewModel.id }),
        });

        return menuItems;
    };

    return (
        <Fragment>
            <DotLegalPageHeader userContext={{ customerName: customerName }} breadCrumbs={getBreadCrumbs()} />

            <DotLegalActionBar
                canCreate
                buttonText={translateString("createRoles")}
                showDialog={showRolesDialog}
                onOpenDialog={() => {
                    setSelectedRoleViewModel(createNewUserRolesProps);
                    setShowRolesDialog(true);
                }}
                icon={<RolesIcon />}
            >
                <AddEditRolesMasterData
                    id={undefined}
                    onDialogClose={() => setShowRolesDialog(false)}
                    onSave={() => save()}
                    selectedRoleViewModel={selectedRoleViewModel}
                />
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasRoles ? (
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => undefined}
                        defaultOrderBy={"name"}
                        defaultOrder={"desc"}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        isLoading={isLoading}
                        renderRow={(row, i) => (
                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex" }}>
                                        {!row.canOnlyAccessDataWhereResponsible ? <CheckIcon sx={{ height: "20px !important" }} /> : ""}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex" }}>{row.canBeChosenAsResponsible ? <CheckIcon sx={{ height: "20px !important" }} /> : ""}</Box>
                                </TableCell>
                                <TableCell>{row.isStandardRole && <CheckIcon sx={{ height: "20px !important" }} />}</TableCell>
                                <TableCell align={"right"}>{!row.isStandardRole && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                            </TableRow>
                        )}
                    ></DotLegalTableWithControls>
                ) : (
                    <DotLegalEmptyState
                        icon={<Rocket />}
                        text={
                            <React.Fragment>
                                <span>{translateString("noRoles")}</span>
                            </React.Fragment>
                        }
                    />
                )}
            </DotLegalPaper>

            {id && <AddEditRolesMasterData onDialogClose={() => navigate(-1)} onSave={() => save()} selectedRoleViewModel={undefined} id={id} />}
            {roleid && <AddEditRoles id={roleid} onDialogClose={() => navigate(-1)} onSave={() => save()} />}

            {showDeleteRoleDialog.showDialog && (
                <DeleteRoleDialog
                    id={showDeleteRoleDialog.id}
                    name={showDeleteRoleDialog.name}
                    onCloseDialog={() => setShowDeleteRoleDialog({ showDialog: false, name: "", id: "" })}
                    onDeleteSuccess={save}
                />
            )}
        </Fragment>
    );

    function getHeaders(translateString: any) {
        let headers = Array<ITableHeader<RoleMasterDataViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
        headers.push({
            property: "canOnlyAccessDataWhereResponsible",
            text: translateString("canOnlyAccessDataWhereResponsible"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "canBeChosenAsResponsible",
            text: translateString("canBeChosenAsResponsible"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "isStandardRole",
            text: translateString("standardRole"),
            align: "left",
            showOnMobile: true,
        });
        return headers;
    }
}

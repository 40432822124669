import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { ProcessingActivityModel, SelectableDataCategoryItem } from "../ProcessingActivity.types";

export function useSelectableDataCategoriesQuery(isEnabled: boolean = true) {
    let query = useQuery("personDataCategories", () => get<Array<SelectableDataCategoryItem>>("/processingactivity/datacategories"), {
        notifyOnChangePropsExclusions: ["isStale"],
        staleTime: 15000,
        enabled: isEnabled,
    });

    const getDataCategoriesForProcessingActivity = (procesingActivity: ProcessingActivityModel) => {
        const dataCategoryIds = procesingActivity.standardDataCategories.map((d) => d!);

        let dataCategoriesData;
        if (query.data) {
            dataCategoriesData = query.data.filter(function (item) {
                return dataCategoryIds.includes(item.id);
            });
        }

        return dataCategoriesData;
    };

    return {
        isLoading: query.isLoading,
        allDataCategories: query.data,
        getDataCategoriesForProcessingActivity,
    };
}

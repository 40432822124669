import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { get, put } from "../../common/api/apiShared";
import { StepModel } from "../../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useSelectableLegalEntityQuery } from "../../legalEntity/useSelectableLegalEntityQuery";
import { useTranslation } from "../../localization/useTranslation";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { useSystemSteps } from "../hooks/useSystemSteps";
import { useValidationSystemAssociationStep } from "./systemAssociationStep/SystemAssociationStep.hooks";
import { SystemModel, SystemUpdateModel } from "./SystemEdit.types";
import { useValidateSystemStep } from "./systemStep/SystemStep.hooks";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useValidateSystemSupplierStep } from "./systemSupplierStep/SystemSupplierStep.hooks";

export function useSystemHook() {
    const { updateWithSystemResponse } = useOptimisticUpdate();
    const { id } = useParams<{ id: string }>();
    const { translateString } = useTranslation();
    const [saveClicked, setSaveClicked] = useState(false);
    const { permissions } = useUserContext();
    const { systemPlan } = usePlanContext();

    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    const { userData } = useResponsibleQuery();

    const defaultSteps: Array<StepModel> = [];

    let queryKey = getSystemEditQueryKey(id);
    let query = useQuery(queryKey, () => get<SystemModel>("/system/" + id));
    let relationQuery = useQuery("systemRelation" + id, () => get<Array<string>>("/systemRelation/" + id), { enabled: systemPlan.associatedAssets });

    const validateSystemStep = useValidateSystemStep();
    const validateSystemAssociastionStep = useValidationSystemAssociationStep(permissions.systemAssociationPermissions?.hasSystemTypePermission);
    const validateSystemSupplierStep = useValidateSystemSupplierStep(permissions.systemDataProcessorPermissions);

    // Håndter role enums
    if (permissions.systemDescriptionPermissions) {
        defaultSteps.push(new StepModel(translateString("description"), 0, validateSystemStep));
    }

    if (permissions.systemAssociationPermissions) {
        defaultSteps.push(new StepModel(translateString("affiliationHeader"), 1, (model) => validateSystemAssociastionStep(model, userData)));
    }

    if (permissions.systemDataAndStoragePermissions) {
        defaultSteps.push(new StepModel(translateString("dataAndStorage"), 2));
    }

    if (systemPlan.personalData && permissions.systemPersonalDataPermissions) {
        defaultSteps.push(new StepModel(translateString("personalData"), 7));
    }

    if (permissions.systemDataProcessorPermissions) {
        defaultSteps.push(new StepModel(translateString("supplier"), 3, (model) => validateSystemSupplierStep(model, legalEntitiesQuery.data ?? [])));
    }

    if (permissions.systemInformationPermissions) {
        defaultSteps.push(new StepModel(translateString("systemInformation"), 4));
    }

    if (systemPlan.associatedAssets && permissions.systemAssociatedAssetsPermissions) {
        defaultSteps.push(new StepModel(translateString("associatedAssets"), 5));
    }

    if (permissions.systemPolicyPermissions) {
        defaultSteps.push(new StepModel(translateString("policies"), 6));
    }

    const steps = useSystemSteps(query.data, defaultSteps, relationQuery.data ?? []);

    async function onSystemChange(system: SystemModel, delay: boolean = false) {
        await updateWithSystemResponse(system, saveSystem, system);
    }

    async function onSaveSystem() {
        await saveSystem(query.data!);
    }

    function changeStep(step: number) {
        steps.changeStep(step);
    }

    return {
        isLoading: query.isLoading,
        system: query.data!,
        saveClicked,
        setSaveClicked,
        getSteps: steps.getSteps,
        getActiveStep: steps.getActiveStep,
        changeStep: changeStep,
        onSaveSystem,
        validationErrors: steps.validationErrors,
        onSystemChange,
        relations: relationQuery.data,
        relationsIsLoading: relationQuery.isLoading,
    };

    async function saveSystem(viewModel: SystemModel) {
        const updateModel: SystemUpdateModel = {
            name: viewModel.name,
            description: viewModel.description,
            id: viewModel.id,
            businessAreas: viewModel.businessAreas,
            type: viewModel.type,
            deletionPeriodId: viewModel.deletionPeriodId,
            securityMeasures: viewModel.securityMeasures,
            dataStorageLocations: viewModel.dataStorageLocations,
            supportAccessLocations: viewModel.supportAccessLocations,
            responsibleId: viewModel.responsibleId,
            deletionPeriodReason: viewModel.deletionPeriodReason,
            dataTypes: viewModel.dataTypes,
            entityType: viewModel.entityType,
            storageType: viewModel.storageType,
            policies: viewModel.policies,
            status: viewModel.status,
            certificationIds: viewModel.certifications,
            isCriticalForOperation: viewModel.isCriticalForOperation,
            network: viewModel.network,
            disableSystemRelations: viewModel.disableSystemRelations,
        };

        return await put<SystemUpdateModel>("/system/" + viewModel.id, updateModel);
    }
}

export function getSystemEditQueryKey(systemId: string | undefined) {
    return "system" + systemId;
}

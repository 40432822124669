import React from "react";
import { ITableHeader } from "../../common/components/dotLegalTable/DotLegalTable.types";
import { CustomerListModel, CustomerType } from "./Customers.types";
import { Box, IconButton, MenuItem, Popover, TableCell, TableRow } from "@mui/material";
import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useDataMapping } from "./Customers.hooks";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import CustomerIcon from "../customer.svg?react";
import AddEditCustomerDialog from "../addEditCustomerDialog/AddEditCustomerDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { DeleteCustomerDialog } from "../deleteCustomerDialog/DeleteCustomerDialog";
import CheckIcon from "@mui/icons-material/Check";
import { useCustomersStyle } from "./Customers.styles";
import { getLoginProviderName } from "../../user/User.types";
import { CustomerSaveModel } from "../addEditCustomerDialog/AddEditCustomer.types";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { editIcon } from "../../common/icons";
import AddNotificationsDialog from "../../notifications/addNotificationsDialog/AddNotificationsDialog";

export interface ICustomersProps {}

function Customers(props: ICustomersProps) {
    const styles = useCustomersStyle();
    const { permissions, customerName } = useUserContext();
    const { translateString, translateLanguage, translateDate } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();

    const {
        customers,
        isLoading,
        refetch,
        showDeleteCustomerDialog,
        setShowDeleteCustomerDialog,
        selectedCustomer,
        setSelectedCustomer,
        showAddEditDialog,
        setShowAddEditDialog,
        createNewCustomerProps,
        getBreadCrumbs,
        anchorEl,
        setAnchorEl,
        showAddNotificationsDialog,
        setShowAddNotificationsDialog,
        open,
        popoverId,
    } = useDataMapping();

    function mapToSaveModel(customer: CustomerListModel): CustomerSaveModel {
        return {
            id: customer.id,
            name: customer.name,
            language: customer.language,
            active: customer.active,
            loginProvider: customer.loginProvider,
            customerType: customer.customerType,
            helpLink: customer.helpLink,
            planType: customer.planType,
            reportTypes: customer.reportTypes,
            germanEnabled: customer.germanEnabled,
        };
    }

    function getCustomerTypeName(customerType: CustomerType) {
        switch (customerType) {
            case CustomerType.Trial:
                return "Trial";
            case CustomerType.Live:
                return "Live";
            case CustomerType.Demo:
                return "Demo";
            default:
                return "";
        }
    }

    function getPlanTypeName(planType: PlanType) {
        switch (planType) {
            case PlanType.GdprStarter:
                return "Starter";
            case PlanType.GdprEssential:
                return "Essential";
            case PlanType.GdprStandard:
                return "Standard";
            case PlanType.GdprPro:
                return "Pro";
            case PlanType.GdprResearch:
                return "Research";
            default:
                return "";
        }
    }

    const getMenuItems = (customer: CustomerListModel) => {
        let menuItems = [];
        if (permissions.canManageCustomers) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    navigate(urlProvider.getAddEditCustomerDialog(customer.id));
                },
            });

            menuItems.push({
                menuItem: translateString("removeCustomer"),
                onClick: () => {
                    setSelectedCustomer(mapToSaveModel(customer));
                    setShowDeleteCustomerDialog(true);
                },
            });
        }
        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={
                    <div>
                        <React.Fragment>
                            <IconButton
                                color={"primary"}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                aria-haspopup="true"
                            >
                                {editIcon}
                            </IconButton>
                            <Popover
                                id={popoverId}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <React.Fragment>
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorEl(null);
                                            setShowAddNotificationsDialog(true);
                                        }}
                                    >
                                        {translateString("addNotifications")}
                                    </MenuItem>
                                </React.Fragment>
                            </Popover>
                        </React.Fragment>
                    </div>
                }
            />
            <DotLegalActionBar
                canCreate
                buttonText={translateString("createCustomer")}
                onOpenDialog={() => {
                    setSelectedCustomer({ ...createNewCustomerProps });
                    setShowAddEditDialog(true);
                }}
                showDialog={showAddEditDialog}
                icon={<CustomerIcon />}
            >
                <AddEditCustomerDialog
                    onCloseDialog={() => setShowAddEditDialog(false)}
                    onSave={async () => {
                        setShowAddEditDialog(false);
                        await refetch();
                    }}
                    selectedCustomer={selectedCustomer}
                />
            </DotLegalActionBar>

            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    clickableRows={false}
                    isLoading={isLoading}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={customers}
                    renderRow={(row, i) => (
                        <TableRow key={i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{translateLanguage(row.language)}</TableCell>
                            <TableCell>{getLoginProviderName(row.loginProvider)}</TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.additionalProcessingActivityReports ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>{getCustomerTypeName(row.customerType)}</TableCell>
                            <TableCell>{getPlanTypeName(row.planType)}</TableCell>
                            <TableCell>{translateDate(row.created)}</TableCell>
                            <TableCell>{row.endDate ? translateDate(row.endDate!) : ""}</TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteCustomerDialog && (
                <DeleteCustomerDialog
                    selectedCustomer={selectedCustomer!}
                    onDeletion={async () => {
                        await refetch();
                    }}
                    onCloseDialog={() => setShowDeleteCustomerDialog(false)}
                />
            )}

            {showAddNotificationsDialog && <AddNotificationsDialog onCloseDialog={() => setShowAddNotificationsDialog(false)} />}
        </React.Fragment>
    );

    function getHeaders(translateString: any) {
        let headers = Array<ITableHeader<CustomerListModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "language", text: translateString("language"), align: "left", showOnMobile: true });
        headers.push({ property: "loginProvider", text: translateString("loginMethod"), align: "left", showOnMobile: true });
        headers.push({
            property: "additionalProcessingActivityReports",
            text: translateString("additionalProcessingActivityReports"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
        headers.push({ property: "customerType", text: translateString("customerType"), align: "left", showOnMobile: true });
        headers.push({ property: "planType", text: "Plan", align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: true });
        headers.push({ property: "endDate", text: translateString("endDate"), align: "left", showOnMobile: true });

        return headers;
    }
}

export default Customers;

import { Tab } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import React from "react";
import DotLegalTabPanel from "../dotLegalTabPanel/DotLegalTabPanel";
import { dotLegalTabStyles, useDotLegalTabsStyles } from "./DotLegalTabs.styles";
import { TabContent } from "./DotLegalTabs.types";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";

export interface IDotLegalTabs {
    tabStyle: dotLegalTabStyles;
    selectedTab: number;
    setSelectedTab: (index: number) => void;
    content: Array<TabContent>;
    fullWidth: boolean;
    ignoreTextTransformation?: boolean;
    noPadding?: boolean;
}

function DotLegalTabs(props: IDotLegalTabs) {
    const styles = useDotLegalTabsStyles(props);

    const getTabLabels = () => {
        const lastIndex = props.content.length - 1;

        return props.content.map((x, i) => {
            const className = i === 0 ? "topLeft" : lastIndex === i ? "topRight" : "";

            return <Tab className={className} key={x.index} label={getTabLabel(x.label, x.tooltip)} icon={x.icon} />;
        });
    };

    const getTabLabel = (label: string, tooltip?: string | undefined) => {
        if (tooltip)
            return (
                <DotLegalTooltip text={tooltip}>
                    <span>{label}</span>
                </DotLegalTooltip>
            );

        return label;
    };

    const getTabContent = () => {
        return props.content.map((x) => {
            return (
                <DotLegalTabPanel key={x.index} index={x.index} value={props.selectedTab} noPadding={props.noPadding}>
                    {x.content}
                </DotLegalTabPanel>
            );
        });
    };

    return (
        <React.Fragment>
            <Tabs
                value={props.selectedTab}
                onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                    const tabContent = props.content.filter((c) => c.index === newValue);

                    if (tabContent && !tabContent[0].disabled) {
                        props.setSelectedTab(newValue);
                    }
                }}
                indicatorColor="primary"
                textColor="primary"
                variant={props.fullWidth ? "fullWidth" : "standard"}
                sx={styles.selectedTab}
            >
                {getTabLabels()}
            </Tabs>
            {getTabContent()}
        </React.Fragment>
    );
}

export default DotLegalTabs;

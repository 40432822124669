export enum PropertyType {
    ProcessingActivities = 0,
    Sharings = 1,
    Systems = 2,
    LegalEntities = 3,
    Description = 4,
    Purposes = 5,
    DataSubjects = 6,
    SecurityMeasures = 7,
    NumberOfDataSubjects = 8,
    NumberOfProcessors = 9,
    Owners = 10,
    BusinessAreas = 11,
    Risk = 12,
    ProcessingActivityClassification = 13,
    Status = 14,
    NumberOfSharings = 15,
    NumberOfSenders = 16,
    NumberOfDataCategories = 17,
    NumberOfDataSubjectObjects = 18,
    NumberOfRecipientsInEU = 19,
    NumberOfRecipientsInEEA = 20,
    NumberOfRecipientsInInsecureThirdCountry = 21,
    NumberOfRecipientsWithAdequateProtection = 22,
    NumberOfRecipients = 23,
    NumberOfSystems = 24,
    DataCategories = 25,
    LegalBases = 26,
    NationalLaws = 27,
    DeletionPeriods = 28,
    TransferBases = 29,
    TIA = 30,
    NumberOfDataProcessorSharings = 31,
    DataProcessors = 32,
    NumberOfDisclosureSharings = 33,
    Disclosures = 34,
    NumberOfSourceSharings = 35,
    Sources = 36,
    NumberOfDataControllerSharings = 37,
    DataControllers = 38,
    NumberOfGroupEntityDataControllers = 39,
    GroupEntityDataControllers = 40,
    ValidationDate = 41,
    ValidatedBy = 42,
    SharingTypes = 43,
    Senders = 44,
    SenderRoles = 45,
    SenderCountries = 46,
    SenderAddress = 47,
    Recipients = 48,
    RecipientCountries = 49,
    RecipientRoles = 50,
    RecipientAddress = 51,
    DataProcessorAgreement = 52,
    Agreement = 53, //(Videregivelse of kilde)
    DisclosureLegalBases = 54,
    DisclosureDoubleLegalBases = 55,
    DisclosureNationalLaws = 56,
    Responsibles = 57,
    NumberOfProcessingActivities = 58,
    StorageLocations = 59,
    SupportAccessLocations = 60,
    DataProcessorNames = 61,
    DataProcessorCountries = 62,
    NumberOfSecondarySuppliers = 63,
    SecondarySuppliers = 64,
    GroupCompanies = 65,
    SystemTypes = 66,
    Address = 67,
    ZipCode = 68,
    City = 69,
    Country = 70,
    TIANotNecessary = 71,
    CompanyNumber = 72,
    ReferenceNumber = 73,
    LegalEntityRisk = 74,
    Type = 75, //(vi skal både have eksterne typer og koncernselskaber med)
    Active = 76,
    Roles = 77,
    Documents = 78,
    ReceivingLocation = 79,
    DataProcessorLocations = 80,
    IsDataProcessor = 81,
    IsSubProcessor = 82,
    IsCompanyRecipientOnDislosure = 83,
    IsCompanySenderOnSource = 84,
    IsCompanySenderOnDataProcessor = 85,
    Location = 86,
    IsGroupCompany = 87,
    ProcessingActivityStatus = 88,
    NumberOfJointDataControllerSharings = 89,
    JointDataControllers = 90,
    DocumentTypes = 91,
    DocumentCount = 92,
    ReportApprovalDate = 93,
    ReportDataProcessorEndDate = 94,
    TotalProcessingTime = 95,
    TotalCaseMangerProcessingTime = 96,
    TotalResearcherTime = 97,
    AverageCaseManagerProcessingTime = 98,
    AverageResearcherProcessingTime = 99,
    ReportApprovalNumber = 100,
    Policies = 101,
    IsSupplierADataProcessor = 102,
    LegalEntityAuditType = 103,
    LegalEntityAuditFrequency = 104,
    SystemStatus = 107,
    SystemDocuments = 108,
    SystemStorageType = 110,
    SystemEntityType = 111,
    SystemDataType = 112,
    IsCriticalForOperation = 113,
    Certifications = 114,
    CompanyHasDocuments = 115,
    GroupEntitiesOnDocuments = 116,
    DataSource = 117,
    DocumentOwners = 118,
}

import { DotLegalHeader, DotLegalMultiSelect, DotLegalTableWithControls, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import { LegalEntityAuditGroupAuditModel, LegalEntityAuditGroupAuditTableModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { useManagementReportExcludedAuditsHooks } from "./ManagementReportExcludedAudits.hooks";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../../useUrlProvider";
import SystemLinks from "../../../system/SystemLinks";
import QuestionnaireStatus from "../../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";

export interface IManagementReportExcludedAudits {
    isLoading: boolean;
    audits: Array<LegalEntityAuditGroupAuditModel>;
}

function ManagementReportExcludedAudits(props: IManagementReportExcludedAudits) {
    const { getLegalEntityUrl } = useUrlProvider();
    const { translateString, translateLegalEntityAuditStatus } = useTranslation();
    const { rows, searchedStatus, setSearchedStatus, exclusionFilterOptions } = useManagementReportExcludedAuditsHooks(props);

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupAuditTableModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "contact", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "status", text: translateString("exclusionReason"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        // Hidden headers - allowing search
        headers.push({ property: "assetsString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "auditStatusString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "contactTypesString", text: "", align: "left", showOnMobile: true, hideHeader: true });

        return headers;
    }

    function getSearchFields() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3,
        };

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedStatus}
                        chosenOptions={searchedStatus}
                        label={translateString("exclusionReason")}
                        placeholder={translateString("exclusionReason")}
                        isLoading={props.isLoading}
                        options={exclusionFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    return rows.length === 0 ? undefined : (
        <Box sx={{ marginTop: 3 }} className="avoid-page-break">
            <DotLegalHeader headerStyle={"medium"}>
                {translateString("excluded").toUpperCase()}{" "}
                <Box sx={(theme) => ({ color: theme.palette.grey[500] })} component="span">
                    ({props.audits.length})
                </Box>
            </DotLegalHeader>
            <DotLegalTableWithControls
                extraControls={getSearchFields()}
                getUserSpecificPageLength={() => 1000}
                labelRowsPerPage={""}
                labelSearch={translateString("search")}
                noOptionsLabel={""}
                headers={getHeaders()}
                defaultOrderBy={"legalEntityName"}
                defaultOrder={"asc"}
                emptyText={translateString("noData")}
                renderRow={(row) => {
                    return (
                        <TableRow key={row.legalEntityId}>
                            <TableCell>
                                <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                    <DotLegalLink linkColor={"primary"} to={`${getLegalEntityUrl(row.legalEntityId)}?tab=3`}>
                                        {row.legalEntityName}
                                    </DotLegalLink>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <SystemLinks systems={row.assets} />
                            </TableCell>
                            <TableCell>
                                <DotLegalTooltip text={row.contactEmail}>
                                    <Box>
                                        {row.contact} {row.contactTypes.length > 0 && <>({row.contactTypesString})</>}
                                    </Box>
                                </DotLegalTooltip>
                            </TableCell>
                            <TableCell>
                                <QuestionnaireStatus
                                    showQuestionsStatistics={row.showQuestionsStatistics}
                                    numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                                    totalNumberOfQuestions={row.totalNumberOfQuestions}
                                    status={row.status}
                                    lastestRecipientUpdate={row.lastestResponseChange}
                                />
                            </TableCell>
                            <TableCell>{row.note}</TableCell>
                        </TableRow>
                    );
                }}
                paginationLabelOf={""}
                data={mapToTableData(rows)}
                hideRowsPerPage
                hidePagination
            />
        </Box>
    );

    function mapToTableData(audits: Array<LegalEntityAuditGroupAuditModel> | undefined): Array<LegalEntityAuditGroupAuditTableModel> {
        if (audits) {
            return audits.map((x) => {
                return {
                    ...x,
                    assetsString: x.assets.flatMap((x) => x.name).join(", "),
                    auditStatusString: translateLegalEntityAuditStatus(x.status.toString()),
                    contactTypesString: x.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", "),
                };
            });
        }

        return [];
    }
}

export default ManagementReportExcludedAudits;

import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useTransfersSubStepStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        addNewTransfer: {
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
        },
        errorBorder: {
            borderColor: theme.palette.error.main,
        },
        collapse: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        collapseArrow: {
            fill: theme.palette.secondary.main,
            transform: "rotate(180deg)",
            marginRight: theme.spacing(1.5),
            marginLeft: theme.spacing(1.5),
        },
        exclamation: {
            position: "absolute",
            right: theme.spacing(-8.125),
            top: theme.spacing(3),
        },
        exclamationIcon: {
            fill: theme.palette.error.main,
        },
        divider: {
            backgroundColor: "#B6D5EF",
        },
        systemImage: {
            "& .collapseSVG svg": {
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
        },
        disableDisclosures: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(2),
            flexDirection: "column",
            alignItems: "center",
        },
        error: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
    });
};

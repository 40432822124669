import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalDialog, DotLegalSearchField, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useChangeContact } from "./ChangeContact.hooks";
import { LegalEntityContactType, LegalEntityContactViewModel } from "../../legalEntityContactTab/LegalEntityContactTab.types";
import { Alert, Box, Radio, RadioGroup, TableCell, TableRow, Typography } from "@mui/material";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { Trans } from "react-i18next";

export interface ChangeContactProps {
    legalEntityId: string | undefined;
    auditId: string;
    contactId: string;
    questionnaireName: string;
    deadline: Date;
    onDialogClose: () => void;
    onChangedContact: () => void;
}

const ChangeContact: FunctionComponent<ChangeContactProps> = (props) => {
    const { translateString, translateDate } = useTranslation();
    const { data, isLoading, contact, isChanging, setContact, onChangeContactClick } = useChangeContact(props);
    const [contactSearch, setContactSearch] = useState<string>("");

    const getHeaders = () => {
        const headers = new Array<ITableHeader<LegalEntityContactViewModel>>();
        headers.push({ property: "id", text: "", align: "center", width: "50px" });
        headers.push({ property: "name", text: translateString("contact") });
        headers.push({ property: "contactTypes", text: translateString("role") });
        headers.push({ property: "email", text: translateString("email") });
        return headers;
    };

    return (
        <DotLegalDialog
            size="md"
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onChangeContactClick}
            isLoading={isLoading}
            header={translateString("changeContactHeader")}
            inProgress={isChanging}
        >
            <Box display="flex" gap={1} flexDirection={"column"}>
                <Typography component={"div"}>
                    <Alert severity={"info"}>
                        <Trans
                            i18nKey={"changeContactDialogDescription"}
                            values={{ audit: props.questionnaireName, deadline: translateDate(props.deadline) }}
                            components={{
                                strong: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.secondary.main })}></Box>,
                            }}
                        />
                    </Alert>
                </Typography>

                <Box display="flex" justifyContent="space-between" alignItems={"center"} flexDirection={"row-reverse"}>
                    <DotLegalSearchField labelSearch={translateString("search")} value={contactSearch} onChange={(value) => setContactSearch(value)} />
                </Box>

                <RadioGroup value={contact} onChange={(event, value) => setContact(value)}>
                    <DotLegalTable
                        data={data}
                        headers={getHeaders()}
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows
                        searchString={contactSearch}
                        renderRow={(row) => (
                            <TableRow key={row.id} onClick={() => setContact(row.id)}>
                                <TableCell>
                                    <Radio value={row.id} />
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    {row.contactTypes &&
                                        row.contactTypes
                                            .map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString())))
                                            .join(", ")}
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                            </TableRow>
                        )}
                        paginationLabelOf={translateString("labelOf")}
                    />
                </RadioGroup>
            </Box>
        </DotLegalDialog>
    );
};

export default ChangeContact;

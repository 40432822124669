import { Box, Divider, Grid, GridSize, IconButton, Skeleton, styled } from "@mui/material";
import React from "react";
import SystemIcon from "../../systemIcon/SystemIcon";
import { useSystemOverviewStyles } from "../SystemOverview.styles";
import { useTranslation } from "../../../localization/useTranslation";
import { useGeneralTabContent } from "./GeneralTabContent.hooks";
import ProcessingActivityOverviewDialog from "../../../processingActivity/processingActivityOverviewDialog/ProcessingActivityOverviewDialog";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { DotLegalDialog, DotLegalFixedHeightText, DotLegalHeader, DotLegalHeading } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { downloadFileOrOpenLink } from "../../../common/downloadFileOrOpenLink";
import PolicyOverviewDialog from "../../../processingActivity/processingActivityOverview/policyOverviewDialog/PolicyOverviewDialog";
import { DotLegalDocumentType } from "../../../documents/Documents.types";
import { SystemStatus } from "../../systemEdit/SystemEdit.types";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { NoteSVG } from "../../../common/icons";
import SystemOverviewLegalEntityDialog from "../systemOverviewLegalEntityDialog/SystemOverviewLegalEntityDialog";
import SystemOverviewNoteDialog from "../systemOverviewNoteDialog/SystemOverviewNoteDialog";

export interface GeneralTabContentProps {
    systemId?: string;
    systemName: string;
}

function GeneralTabContent(props: GeneralTabContentProps) {
    const styles = useSystemOverviewStyles();

    const propertiesGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: 2,
    };
    const { translateString } = useTranslation();
    const { downloadDocument } = useDotLegalFileUploadDataMapping();
    const {
        isLoading,
        infoData,
        showProcessingActivitiesDialog,
        setShowProcessingActivitiesDialog,
        showPoliciesDialog,
        setShowPoliciesDialog,
        showDataProcessorAgreementDialog,
        setShowDataProcessorAgreementDialog,
        showSubSuppliers,
        setShowSubSuppliers,

        getDocument,
        permissions,
        showNoteDialog,
        setShowNoteDialog,
        showSuppliers,
        setShowSuppliers,
    } = useGeneralTabContent(props);
    const { systemPlan } = usePlanContext();

    const hasIsmsModule =
        systemPlan.systemStatus ||
        systemPlan.storageType ||
        systemPlan.unitType ||
        systemPlan.dataTypes ||
        systemPlan.operationalCriticalSystem ||
        systemPlan.certifications;

    const numberOfProcessingActivities = (infoData?.processingActivities?.length ?? 0) + (infoData?.noOfProcessingActivitiesWithNoAccess ?? 0);
    const numberOfSuppliers = infoData?.suppliers?.length ?? 0;
    const numberOfSubProcessors = infoData?.subProcessors?.length ?? 0;
    const numberOfPolicies = infoData?.policies?.length ?? 0;

    const getDocuments = () => {
        if (infoData?.dataProcessingAgreements.length === 1 && !infoData?.dataProcessingAgreements[0].id) {
            return (
                <DotLegalFixedHeightText
                    isLoading={isLoading}
                    content={translateString(infoData?.dataProcessingAgreements[0].name.toLowerCase() === "false" ? "no" : "yes")}
                />
            );
        } else {
            return (
                <DotLegalFixedHeightText
                    onClick={() => setShowDataProcessorAgreementDialog(true)}
                    isLoading={isLoading}
                    content={infoData?.dataProcessingAgreements.length}
                    fontSize="xl"
                />
            );
        }
    };

    const getNumberStyle = (disableCursor: boolean) => {
        return {
            "& .MuiTypography-root": { "&:hover": { cursor: disableCursor ? "default" : "pointer" } },
        };
    };

    return (
        <>
            <Grid sx={styles.gridRow} container spacing={3}>
                <Grid {...propertiesGridProps}>
                    <SystemIcon systemName={props.systemName} size={"medium"} />
                </Grid>

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("responsible")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={infoData?.responsible} />
                    </DotLegalHeading>
                </Grid>

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("businessAreas")}>
                        <DotLegalFixedHeightText isLoading={isLoading} content={infoData?.businessAreas?.join(", ")} />
                    </DotLegalHeading>
                </Grid>

                {permissions.processingActivityPermissions.read && (
                    <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfProcessingActivities === 0)}>
                        <DotLegalHeading name={translateString("processingActivities")}>
                            <DotLegalFixedHeightText
                                onClick={() => {
                                    if (numberOfProcessingActivities > 0) setShowProcessingActivitiesDialog(true);
                                }}
                                isLoading={isLoading}
                                content={numberOfProcessingActivities}
                                fontSize="xl"
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfSuppliers === 0)}>
                    <DotLegalHeading name={translateString("suppliers")}>
                        <DotLegalFixedHeightText
                            onClick={() => {
                                if (numberOfSuppliers > 0) setShowSuppliers(true);
                            }}
                            isLoading={isLoading}
                            content={numberOfSuppliers === 1 ? infoData!.suppliers[0].name : numberOfSuppliers}
                            fontSize={numberOfSuppliers === 1 ? undefined : "xl"}
                        />
                    </DotLegalHeading>
                </Grid>

                <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfSubProcessors === 0)}>
                    <DotLegalHeading name={translateString("secondarySuppliers")}>
                        <DotLegalFixedHeightText
                            onClick={() => {
                                if (numberOfSubProcessors > 0) setShowSubSuppliers(true);
                            }}
                            isLoading={isLoading}
                            content={numberOfSubProcessors}
                            fontSize="xl"
                        />
                    </DotLegalHeading>
                </Grid>
            </Grid>
            <Divider sx={styles.divider} />
            <Grid sx={styles.gridRow} container spacing={3}>
                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("agreements")}>
                        {isLoading ? <DotLegalFixedHeightText isLoading={isLoading} content={""} /> : getDocuments()}
                    </DotLegalHeading>
                </Grid>

                {permissions.policyPermissions && permissions.systemPolicyPermissions?.permissions.read && (
                    <Grid {...propertiesGridProps} sx={getNumberStyle(numberOfPolicies === 0)}>
                        <DotLegalHeading name={translateString("policies")}>
                            <DotLegalFixedHeightText
                                onClick={() => {
                                    if (numberOfPolicies > 0) setShowPoliciesDialog(true);
                                }}
                                isLoading={isLoading}
                                content={numberOfPolicies}
                                fontSize="xl"
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                {hasIsmsModule && systemPlan.systemStatus && (
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("systemStatus")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={infoData?.status ? translateString(setFirstLetterToLowerCase(SystemStatus[infoData?.status].toString())) : ""}
                            />
                        </DotLegalHeading>
                    </Grid>
                )}

                <Grid {...propertiesGridProps}>
                    <DotLegalHeading name={translateString("note")}>
                        {isLoading ? <Skeleton variant="text" width={"100%"} /> : <IconButton onClick={() => setShowNoteDialog(true)}>{NoteSVG}</IconButton>}
                    </DotLegalHeading>
                </Grid>
            </Grid>
            {showProcessingActivitiesDialog && (
                <ProcessingActivityOverviewDialog
                    onCloseDialog={() => setShowProcessingActivitiesDialog(false)}
                    processingActivities={infoData?.processingActivities}
                    header={translateString("systemProcessingsDialogDescription")}
                    numberOfProcessingWithoutaccess={infoData?.noOfProcessingActivitiesWithNoAccess}
                />
            )}
            {showDataProcessorAgreementDialog && (
                <DotLegalDialog
                    header={translateString("dataProcessingAgreement")}
                    size="sm"
                    hideOkButton
                    buttonOkText={""}
                    open
                    onDialogClose={() => setShowDataProcessorAgreementDialog(false)}
                    onOkClick={() => {}}
                >
                    <Box
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            "& *": {
                                color: `${theme.palette.secondary.main} !Important`,
                                "&:hover": {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                },
                            },
                        })}
                    >
                        {infoData?.dataProcessingAgreements.map((agreement, index) => {
                            const document = getDocument(agreement.id);
                            return (
                                <DotLegalHeader
                                    fontWeight={500}
                                    onClick={
                                        !isNullOrWhitespace(agreement.name)
                                            ? undefined
                                            : () => {
                                                  if (agreement?.id) {
                                                      downloadFileOrOpenLink(
                                                          agreement?.id,
                                                          [
                                                              {
                                                                  id: agreement?.id,
                                                                  fileDownloadName: document!.fileName,
                                                                  link: document.link!,
                                                                  name: "",
                                                                  type: DotLegalDocumentType.DataProcessorAgreement,
                                                                  legalEntityId: "",
                                                                  status: undefined,
                                                              },
                                                          ],
                                                          (id: string, fileName: string) => downloadDocument(id, fileName)
                                                      );
                                                  }
                                              }
                                    }
                                    key={index}
                                    headerStyle={"small"}
                                >
                                    {document.name}
                                </DotLegalHeader>
                            );
                        })}
                    </Box>
                </DotLegalDialog>
            )}

            {showPoliciesDialog && <PolicyOverviewDialog onDialogClose={() => setShowPoliciesDialog(false)} policies={infoData?.policies ?? []} />}

            {showNoteDialog && (
                <SystemOverviewNoteDialog
                    disabled={!permissions.systemPermissions.edit}
                    systemId={infoData!.id}
                    onCloseDialog={() => setShowNoteDialog(false)}
                    onSuccess={() => setShowNoteDialog(false)}
                />
            )}
            {showSubSuppliers && (
                <SystemOverviewLegalEntityDialog onDialogClose={() => setShowSubSuppliers(false)} legalEntities={infoData?.subProcessors} hideTransferBasis />
            )}

            {showSuppliers && (
                <SystemOverviewLegalEntityDialog onDialogClose={() => setShowSuppliers(false)} legalEntities={infoData?.suppliers} hideTransferBasis />
            )}
        </>
    );
}

export default GeneralTabContent;

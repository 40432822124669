export interface PlanContext {
    planType: PlanType;
    ismsPlanType?: PlanType;
    customListsAccess: boolean;
    processingActivityAndRecordsPlan: ProcessingActivityAndRecordsPlan;
    unitsPlan: UnitsPlanViewModel;
    supportPlan: SupportPlanViewModel;
    technicalPlan: TechnicalPlanViewModel;
    systemPlan: SystemPlanViewModel;
    riskAndClassificationPlan: RiskAndClassificationPlanViewModel;
    taskManagementAndAnnualWheelPlan: TaskManagerAndAnnualWheelPlanViewModel;
    proceduresAndPoliciesPlan: ProceduresAndPoliciesPlanVIewModel;
    incidentLogPlan: IncidentLogPlanViewModel;
    vendorManagementPlan: VendorManagementPlanViewModel;
    declarationPlan: DeclarationPlanViewModel;
}

export interface ProcessingActivityAndRecordsPlan {
    maxNumberOfActivities?: number;
    templates: ProcessingActivityTemplates;
    registerSelfAsDataProcessor: boolean;
    validationFlow: boolean;
    recordDataProcessor: boolean;
    commentsModule: boolean;
    processingActivityStatus: boolean;
    caseHandlerModule: boolean;
    standardListExport: boolean;
    customLists: boolean;
    customListExport: boolean;
    recordsExport: boolean;
    sharedResponsibility: boolean;
}

export interface UnitsPlanViewModel {
    maxNumberOfGroupEntities?: number;
    retentionOfDataProcessingAgreement: boolean;
    standardListExport: boolean;
    customLists: boolean;
    customListExport: boolean;
    contacts: boolean;
    companyAreas: boolean;
}

export interface SupportPlanViewModel {
    onboardingType: OnboardingType;
    importOfData: ImportOfData;
    supportChannel: SupportChannel;
}

export interface TechnicalPlanViewModel {
    maxNumberOfUsers?: number;
    userAdministration: boolean;
    customMasterData: boolean;
    azureSSO: boolean;
    customRolesAndPermissions: boolean;
    notificationCenter: boolean;
    emailNotifications: boolean;
    auditLog: boolean;
    transferUserResponsibility: boolean;
    fullDataExport: boolean;
}

export interface SystemPlanViewModel {
    maxNumberOfSystems?: number;
    automaticRegistrationOfDataProcessorTransfers: boolean;
    templates: SystemTemplates;
    linkSystemsAndProcessingActivities: boolean;
    standardLists: boolean;
    standardListsExport: boolean;
    customLists: boolean;
    customListExport: boolean;
    systemStatus: boolean;
    storageType: boolean;
    unitType: boolean;
    dataTypes: boolean;
    operationalCriticalSystem: boolean;
    certifications: boolean;
    documents: boolean;
    unitTypeFilter: boolean;
    associatedAssets: boolean;
    internalControlProcedures: boolean;
    personalData: boolean;
}

export interface RiskAndClassificationPlanViewModel {
    maxNumberOfTemplates?: number;
    gdprRiskAssessments: boolean;
    createOwnRiskScenarios: boolean;
    consequenceCatalog: boolean;
    automaticClassificationOfProcessingActivities: boolean;
    riskClassificationOfDataProcessors: boolean;
    riskReport: boolean;
    systemRiskAssessments: boolean;
    systemRiskHandling: boolean;
    niS2RiskTemplates: boolean;
    riskVersions: boolean;
}

export interface TaskManagerAndAnnualWheelPlanViewModel {
    maxNumberOfActivities?: number;
    gdprPlanningWheel: boolean;
    maxNumberOfStandardControlsInAnnualWheel?: number;
    createOwnAnnualWheelActivities: boolean;
    taskManagement: boolean;
    processingActivityValidation: boolean;
    addToOutlook: boolean;
    emailNotifications: boolean;
    annualWheelReport: boolean;
    niS2Framework: boolean;
    annualWheelAreas: boolean;
    relatedActivitiesAndTasks: boolean;
    iso27001Framework: boolean;
    iso27002Framework: boolean;
}

export interface ProceduresAndPoliciesPlanVIewModel {
    processingActivityAndPolicyLink: boolean;
    maxNumberOfPoliciesAndProcedures?: number;
}

export interface IncidentLogPlanViewModel {
    gdprMaxNumberOfIncidents?: number;
    gdprCanSetPermissionsOnRole: boolean;
}

export interface VendorManagementPlanViewModel {
    hasAccess: boolean;
    legalEntityClassification: boolean;
}

export interface DeclarationPlanViewModel {
    hasAccess: boolean;
    maxNumberOfDeclaration?: number;
}

export enum ProcessingActivityTemplates {
    MostPopular,
    All,
}

export enum OnboardingType {
    InApp,
    InAppAndSupport,
}

export enum ImportOfData {
    ContactForInfo,
    Included,
}

export enum SupportChannel {
    Mail,
    MailAndPhone,
}

export enum SystemTemplates {
    MostPopular,
    All,
}

export enum PlanType {
    GdprStarter = 0,
    GdprEssential = 1,
    GdprStandard = 2,
    GdprPro = 3,
    GdprResearch = 4,
    IsmsStarter = 100,
    IsmsStandard = 101,
    IsmsPro = 102,
}

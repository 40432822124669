import React, { FunctionComponent } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "../../../../localization/useTranslation";
import { DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";

export interface DotLegalAllOrMySwitchBoxProps {
    hidden?: boolean;
    onChange?: (enabled: boolean) => void;
    enabled?: boolean;
}

const DotLegalAllOrMySwitchBox: FunctionComponent<DotLegalAllOrMySwitchBoxProps> = ({ onChange, enabled, hidden }) => {
    const { translateString } = useTranslation();
    return (
        <Box sx={(theme) => ({ visibility: hidden ? "hidden" : "visible" })}>
            <DotLegalSwitch onChange={onChange} enabled={enabled} leftLabel={translateString("all")} rightLabel={translateString("my")} />
        </Box>
    );
};

export default DotLegalAllOrMySwitchBox;

import { useMutation, useQueryClient } from "react-query";
import { useSelectableDataCategoriesQuery } from "../../../../processingActivity/hooks/useSelectableDataCategoriesQuery";
import { DataCategoriesProps } from "./DataCategories";
import { getSystemEditQueryKey } from "../../SystemEdit.hooks";
import { deleteHttp, post } from "../../../../common/api/apiShared";
import { useState } from "react";

export function useSystemDataCategories(props: DataCategoriesProps) {
    const dataCategories = props.systemViewModel?.systemPersonalData?.dataCategories ?? [];

    const queryClient = useQueryClient();
    const queryKey = getSystemEditQueryKey(props.systemId);

    const [showDataCategoryDialog, setShowDataCategoryDialog] = useState<string | undefined>(undefined);

    let { isLoading, allDataCategories } = useSelectableDataCategoriesQuery();

    function handleChange(isSelected: boolean, id: string) {
        if (isSelected) {
            addDataCategory(id);
        } else {
            removeDataCategory(id);
        }
    }

    const addDataCategory = (dataCategoryId: string) => {
        const tempDataCategories = [...dataCategories];
        const tempViewModel = { ...props.systemViewModel };
        tempDataCategories.push({ dataCategoryId: dataCategoryId, isSystemSelected: true, processingActivities: [], processingActivitiesWithoutAccess: null });
        tempViewModel.systemPersonalData!.dataCategories = tempDataCategories;
        queryClient.setQueryData(queryKey, props.systemViewModel);
        saveMutation.mutateAsync(dataCategoryId);
    };

    const removeDataCategory = (dataCategoryId: string) => {
        let tempDataCategories = [...dataCategories];
        const tempViewModel = { ...props.systemViewModel };
        tempDataCategories = dataCategories.filter((x) => x.dataCategoryId !== dataCategoryId);
        tempViewModel!.systemPersonalData!.dataCategories = tempDataCategories;
        queryClient.setQueryData(queryKey, props.systemViewModel);
        deleteMutation.mutateAsync(dataCategoryId);
    };

    const saveMutation = useMutation(save, {
        onSuccess: () => {},
    });

    function save(dataCategoryId: string) {
        return post("/system/dataCategory", { systemId: props.systemId, dataCategoryId: dataCategoryId });
    }

    const deleteMutation = useMutation(remove, {
        onSuccess: () => {},
    });

    function remove(dataCategoryId: string) {
        return deleteHttp(`/system/${props.systemId}/${dataCategoryId}/dataCategory`);
    }

    return { isLoading, allDataCategories, dataCategories, handleChange, showDataCategoryDialog, setShowDataCategoryDialog };
}

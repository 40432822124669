import React, { createContext, ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { jwtDecode } from "jwt-decode";
import { UserContext, UserPermissions } from "./UserContextProvider.types";
import { useTranslation } from "../../localization/useTranslation";
import { get, getJwtToken } from "../../common/api/apiShared";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";

export const defaultPermissions = () => {
    return {
        read: false,
        create: false,
        edit: false,
        delete: false,
    };
};

export const UserContextContext = createContext<UserContext | undefined>({
    emailAddress: "",
    userProfileId: "",
    customerName: "",
    language: "en",
    fullName: "",
    customerId: "",
    isFirstLogin: false,
    helpLink: "",
    gdpo: false,
    germanEnabled: false,
    created: new Date(),
    permissions: {
        canManageCustomers: false,
        canManageUsers: false,
        canManageGroupEntities: false,
        canManageMasterData: false,
        canManageRiskAssessment: false,
        canChangeUserLoginProvider: false,
        canAccessAllData: false,
        canValidateProcessingActivities: false,
        canManageProcessingActivityStandardTemplates: false,
        canManageSystemStandardTemplates: false,
        companyDocumentationPermissions: defaultPermissions(),
        processingActivityAssociationPermissions: {
            permissions: defaultPermissions(),
            hasGroupEntitiesPermission: false,
            hasBusinessAreaPermission: false,
            hasOwnershipPermission: false,
            hasAnySubPermissionAccess: false,
        },
        processingActivityDataCategoryPermissions: {
            permissions: defaultPermissions(),
        },
        processingActivityDataSubjectPermissions: {
            hasProcessorsPermission: false,
            hasDataSubjectsPermission: false,
            hasSecurityMeasurePermission: false,
            hasDataSourcePermission: false,
            permissions: defaultPermissions(),
        },
        processingActivityLegalBasesPermissions: {
            hasDeletionPeriodPermission: false,
            hasLegalBasisPermission: false,
            permissions: defaultPermissions(),
        },
        processingActivityMasterDataPermissions: {
            hasPurposePermission: false,
            permissions: defaultPermissions(),
        },
        processingActivitySystemPermissions: {
            permissions: defaultPermissions(),
        },
        processingActivityPolicyPermissions: {
            permissions: defaultPermissions(),
        },
        processingActivityDataControllersPermissions: {
            hasAgreementPermission: false,
            hasResponsiblePermission: false,
            hasSubProcessorPermission: false,
            hasTiaAndTransferBasisPermission: false,
            hasDataCategoriesPermission: false,
            permissions: defaultPermissions(),
        },
        processingActivityDataProcessorsPermissions: {
            hasAgreementPermission: false,
            hasResponsiblePermission: false,
            hasSubProcessorPermission: false,
            hasTiaAndTransferBasisPermission: false,
            hasDataCategoriesPermission: false,
            permissions: defaultPermissions(),
        },
        processingActivityDisclosuresPermissions: {
            hasAgreementPermission: false,
            hasBasisOfDisclosurePermission: false,
            hasResponsiblePermission: false,
            hasTransferBasisPermission: false,
            hasDataCategoriesPermission: false,
            permissions: defaultPermissions(),
        },
        processingActivitySourcesPermissions: {
            hasAgreementPermission: false,
            hasResponsiblePermission: false,
            hasDataCategoriesPermission: false,
            permissions: defaultPermissions(),
        },
        processingActivityJointDataControllerPermissions: {
            hasAgreementPermission: false,
            hasResponsiblePermission: false,
            hasDataCategoriesPermission: false,
            permissions: defaultPermissions(),
        },
        systemAssociationPermissions: {
            hasSystemTypePermission: false,
            permissions: defaultPermissions(),
        },
        systemDataProcessorPermissions: {
            hasDataProcessorAgreementPermission: false,
            hasStorageLocationPermission: false,
            hasSubProcessorPermission: false,
            hasSupportAccessPermission: false,
            hasTiaAndTransferBasis: false,
            permissions: defaultPermissions(),
        },
        systemDescriptionPermissions: {
            permissions: defaultPermissions(),
        },
        systemInformationPermissions: {
            hasSecurityMeasurePermission: false,
            permissions: defaultPermissions(),
        },
        annualWheelPermission: defaultPermissions(),
        taskPermissions: defaultPermissions(),
        processingActivityPermissions: defaultPermissions(),
        systemPermissions: defaultPermissions(),
        canManageRoles: false,
        canPurchasePrivacy: false,
        canAccessRecord301: false,
        canAccessRecord302: false,
        riskPermissions: defaultPermissions(),
        riskTemplatePermissions: defaultPermissions(),
        customListPermissions: defaultPermissions(),
        companyPermissions: defaultPermissions(),
        policyPermissions: defaultPermissions(),
        canManageProcessingActivtyComments: false,
        processingActivityStatusManagement: undefined,
        canManageProcessingActivityNote: false,
        canAccessAuditLog: false,
        canAccessProcessingActivityStatistic: false,
        canDeleteDoocumentVersion: false,
        canSetDocumentStatus: false,
        systemPolicyPermissions: {
            permissions: defaultPermissions(),
        },
        systemDataAndStoragePermissions: {
            permissions: defaultPermissions(),
            hasStorageLocationPermission: false,
            hasSupportAccessPermission: false,
        },
        riskVersionPermissions: defaultPermissions(),
        gdprIncidentLogPermissions: defaultPermissions(),
        reportTypes: [],
        canManageTaskMasterData: false,
        systemPersonalDataPermissions: {
            permissions: defaultPermissions(),
        },
        systemAssociatedAssetsPermissions: {
            permissions: defaultPermissions(),
        },
        canCopyProcessingActivity: false,
        canManageInternalAssessment: false,
        declarationPermissions: defaultPermissions(),
        hasDeclarationAuditorApproval: false,
        canManageAuditGroups: false,
    },
});

export interface IUserContextProviderProps {
    children: ReactNode;
}

function UserContextProvider(props: IUserContextProviderProps) {
    const { changeLanguage } = useTranslation();

    const url = "/userPermissions";
    let oneHour = 3600000;

    const query = useQuery(url, () => get<UserPermissions>(url), { staleTime: oneHour });

    let userContext: UserContext | undefined = undefined;

    if (query.data) {
        const claims = decodeClaims(getJwtToken());

        userContext = {
            language: claims.Language,
            userProfileId: claims.UserProfileId,
            fullName: claims.FullName,
            emailAddress: claims.EmailAddress,
            customerName: claims.CustomerName,
            permissions: query.data,
            customerId: claims.CustomerId,
            isFirstLogin: claims.LastLogOnDate === "null",
            helpLink: claims.HelpLink,
            gdpo: claims.GDPO === "True",
            germanEnabled: claims.GermanEnabled === "True",
            created: claims.Created,
        };

        if (!localStorage.getItem("language")) {
            changeLanguage(userContext.language);
        }
    }

    return (
        <UserContextContext.Provider value={userContext}>
            {query.isLoading ? <DotLegalFullScreenSpinner /> : <React.Fragment>{props.children}</React.Fragment>}
        </UserContextContext.Provider>
    );
}

export function useUserContext(): UserContext {
    return useContext(UserContextContext)!;
}

interface UserClaims {
    Language: string;
    CustomerName: string;
    UserProfileId: string;
    EmailAddress: string;
    FullName: string;
    CustomerId: string;
    LastLogOnDate: string;
    HelpLink: string;
    GDPO: string;
    GermanEnabled: string;
    Created: Date;
}

export function decodeClaims(token: string): UserClaims {
    let object = jwtDecode<UserClaims>(token);
    return {
        Language: object.Language,
        CustomerName: object.CustomerName,
        UserProfileId: object.UserProfileId,
        EmailAddress: object.EmailAddress,
        FullName: object.FullName,
        CustomerId: object.CustomerId,
        LastLogOnDate: object.LastLogOnDate,
        HelpLink: object.HelpLink,
        GDPO: object.GDPO,
        GermanEnabled: object.GermanEnabled,
        Created: object.Created,
    };
}

export default UserContextProvider;

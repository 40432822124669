import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get, postGet, put } from "../../common/api/apiShared";
import {
    LegalEntityAuditGroupAuditModel,
    LegalEntityAuditGroupAuditTableModel,
    LegalEntityAuditGroupOverviewModel,
} from "./LegalEntityAuditGroupOverview.types";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { downloadFile } from "../../common/api/downloadFile";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { getSettings } from "../../common/settingsProvider";
import { Trans } from "react-i18next";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../common/stringOperations";
import { RiskLevel } from "../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import { LegalEntityAuditStatus } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import {
    LegalEntityAuditInformation,
    LegalEntityClassificationInformationViewModel,
} from "../legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";
import { LegalEntityAuditInformationsDto, LegalEntityMasterType } from "../../legalEntity/legalEntities/LegalEntities.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";

export function useLegalEntityAuditGroupDialog() {
    const { id } = useParams<{ id: string }>();
    const { translateString, translateDate, translateLegalEntityAuditStatus } = useTranslation();
    const { language } = useUserContext();
    const snackbar = useDotLegalSnackbar();
    const urlProvider = useUrlProvider();

    const [searchedType, setSearchedType] = useStateUrlParamsArray("type", []);
    const [searchedRiskLevel, setSearchedRisklevel] = useStateUrlParamsArray("riskLevel", []);
    const [searchedClassifications, setSearchedClassifications] = useStateUrlParamsArray("classifications", []);
    const [searchedAssets, setSearchedAssets] = useStateUrlParamsArray("assets", []);
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("status", []);
    const [searchedEvaluations, setSearchedEvaluations] = useStateUrlParamsArray("evaluations", []);

    const [showNote, setShowNote] = useState<string | undefined>(undefined);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState<string | undefined>(undefined);
    const [isDownloadingManagementReport, setIsDownloadingManagementReport] = useState(false);

    const queryKey = `/legalEntityAuditGroups/${id}/overview`;
    let { isLoading, data, refetch } = useQuery(queryKey, () => get<LegalEntityAuditGroupOverviewModel>(queryKey));

    const auditInformationsUrl = `/legalEntityAudit/auditInformations`;
    let auditInformationsQuery = useQuery(
        auditInformationsUrl,
        () =>
            postGet<Array<LegalEntityAuditInformation>>(auditInformationsUrl, {
                legalEntityIds: data?.audits.map((x) => x.legalEntityId),
                fetchLatestAudit: false,
            } as LegalEntityAuditInformationsDto),
        {
            enabled: data !== undefined,
        }
    );

    if (auditInformationsQuery.data) {
        auditInformationsQuery.data.forEach((s) => {
            var entity = data?.audits.find((x) => x.legalEntityId === s.legalEntityId);

            if (entity) {
                entity.classifications = s.classifications;
            }
        });
    }

    function onAuditGroupUpdated() {
        refetch();
    }

    const saveNoteMutation = useMutation(submitSaveNoteAPI);
    const saveNote = (note: string, auditId: string) => {
        saveNoteMutation.mutateAsync(
            { note: note, auditId: auditId },
            {
                onSuccess: () => {
                    snackbar.show(translateString("noteUpdated"));
                    setShowNote(undefined);
                    refetch();
                },
            }
        );
    };

    const onDownloadReportAsPdf = async (viewModel: LegalEntityAuditGroupAuditTableModel, isSimpleReport: boolean, showRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = isSimpleReport ? getExternalPdfUrl(viewModel, true, showRecommendations) : getReportPdfUrl(viewModel, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);
        const fileName = `${viewModel.questionnaireName} - ${translateDate(viewModel.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf(undefined);
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getReportPdfUrl(row: LegalEntityAuditGroupAuditTableModel, pdf: boolean, showRecommendations: boolean) {
        return urlProvider.getQuestionnaireResponseReport(row.questionnaireResponseId, row.deadline, row.sender, row.legalEntityName, pdf, showRecommendations);
    }

    function getExternalPdfUrl(row: LegalEntityAuditGroupAuditTableModel, pdf: boolean, showRecommendations: boolean) {
        return urlProvider.getQuestionnaireResponseExternalreport(
            row.responseExternalId,
            row.deadline,
            row.sender,
            row.legalEntityName,
            undefined,
            pdf,
            showRecommendations,
            false
        );
    }

    const onDownloadManagementReport = async () => {
        setIsDownloadingManagementReport(true);
        let url = getSettings().mainSiteBaseUrl + urlProvider.getAuditGroupManagementReportUrl(id!) + "?isPdf=true";
        url = createPdfUrl(url, language);

        const fileName = `${data!.auditTemplate} - ${translateDate(data!.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingManagementReport(false);
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    const filters = {
        typeSelectOptions: Array<DotLegalSelectOption>(),
        riskLevelOptions: Array<DotLegalSelectOption>(),
        assetsOptions: Array<DotLegalSelectOption>(),
        statusOptions: Array<DotLegalSelectOption>(),
        classificationOptions: Array<DotLegalSelectOption>(),
        evaluationOptions: Array<DotLegalSelectOption>(),
    };

    let rows: Array<LegalEntityAuditGroupAuditModel> = [];
    if (data && data.audits.length > 0) {
        createTypeOptions(data.audits.flatMap((x) => x.legalEntityTypes.flatMap((x) => x)));
        createAssetOptions(data.audits.flatMap((x) => x.assets.flatMap((x) => x)));
        createStatusOptinos(data.audits.flatMap((x) => x.status));
        createEvalutionOptions(data.audits.filter((x) => x.evaluationStatus).map((x) => x.evaluationStatus!));
        createAuditRiskLevelOptions(data.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).flatMap((x) => x.riskLevel));

        createClassificationAndRiskLevelFilterOptions(auditInformationsQuery.data ? data.audits.flatMap((x) => x.classifications) : []);

        sortSelectOptions();
        rows = getFilteredRows(data.audits);
    }

    return {
        isLoading,
        data,
        rows,
        onAuditGroupUpdated,
        showNote,
        setShowNote,
        saveNote,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        refetch,
        filters,
        searchedType,
        setSearchedType,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassifications,
        setSearchedClassifications,
        searchedAssets,
        setSearchedAssets,
        searchedStatus,
        setSearchedStatus,
        auditIsLoading: auditInformationsQuery.isLoading,
        isDownloadingManagementReport,
        setIsDownloadingManagementReport,
        onDownloadManagementReport,
        searchedEvaluations,
        setSearchedEvaluations,
    };

    function submitSaveNoteAPI(data: { note: string; auditId: string }) {
        return put(`/legalEntityAudit/${data.auditId}/note`, {
            note: data.note,
        });
    }

    function getFilteredRows(rows: Array<LegalEntityAuditGroupAuditModel>) {
        let tempData = [...rows];

        if (hasFilterData(searchedType)) {
            tempData = tempData.filter((row) => row.legalEntityTypes.filter((type) => searchedType.indexOf(type.toString()) >= 0).length > 0);
        }

        if (hasFilterData(searchedClassifications)) {
            tempData = tempData.filter(
                (row) =>
                    row.classifications.filter(
                        (classification) => classification?.questionnaireId && searchedClassifications.indexOf(classification.questionnaireId) >= 0
                    ).length > 0
            );
        }

        if (hasFilterData(searchedRiskLevel)) {
            tempData = tempData.filter((row) => row.riskLevel && searchedRiskLevel.includes(row.riskLevel.toString()));
        }

        if (hasFilterData(searchedAssets)) {
            tempData = tempData.filter((row) => row.assets.filter((asset) => searchedAssets.indexOf(asset.id) >= 0).length > 0);
        }

        if (hasFilterData(searchedStatus)) {
            tempData = tempData.filter((row) => searchedStatus.indexOf(row.status.toString()) >= 0);
        }

        if (hasFilterData(searchedEvaluations)) {
            tempData = tempData.filter((row) => row.evaluationStatus && searchedEvaluations.includes(row.evaluationStatus.toString()));
        }

        return tempData;
    }

    function hasFilterData(filter: Array<string>) {
        return filter.length > 0 && !isNullOrWhitespace(filter[0]);
    }

    function createTypeOptions(types: Array<LegalEntityMasterType>) {
        for (let type of types) {
            if (!filters.typeSelectOptions.some((x) => x.id === type.toString())) {
                filters.typeSelectOptions.push({
                    id: type.toString(),
                    name: translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())),
                });
            }
        }
    }

    function createAssetOptions(assets: Array<BaseViewModel>) {
        assets.forEach((asset) => {
            if (!filters.assetsOptions.some((x) => x.id == asset.id)) {
                filters.assetsOptions.push({ id: asset.id, name: asset.name });
            }
        });
    }

    function createStatusOptinos(allAuditStatus: Array<LegalEntityAuditStatus>) {
        allAuditStatus.forEach((status) => {
            if (!filters.statusOptions.some((x) => x.id == status.toString())) {
                filters.statusOptions.push({
                    id: status.toString(),
                    name: translateLegalEntityAuditStatus(status.toString()),
                });
            }
        });
    }

    function createAuditRiskLevelOptions(riskLevels: Array<RiskLevel | undefined>) {
        riskLevels.forEach((level) => {
            if (level && !filters.riskLevelOptions.some((x) => x.id == level.toString())) {
                filters.riskLevelOptions.push({
                    id: level.toString(),
                    name: translateString(setFirstLetterToLowerCase(RiskLevel[level])),
                });
            }
        });
    }

    function createClassificationAndRiskLevelFilterOptions(classifications: Array<LegalEntityClassificationInformationViewModel>) {
        classifications.forEach((classification) => {
            if (classification && !filters.classificationOptions.some((x) => x.id == classification.questionnaireId)) {
                filters.classificationOptions.push({ id: classification.questionnaireId, name: classification.name });
            }
        });
    }

    function createEvalutionOptions(allEvaluationStatus: Array<EvaluationStatus>) {
        allEvaluationStatus.forEach((status) => {
            if (!filters.evaluationOptions.some((x) => x.id == status.toString())) {
                filters.evaluationOptions.push({
                    id: status.toString(),
                    name: translateString(setFirstLetterToLowerCase(EvaluationStatus[status].toString())),
                });
            }
        });
    }

    function sortSelectOptions() {
        filters.typeSelectOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        filters.classificationOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        filters.riskLevelOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
}

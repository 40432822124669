import { useTheme } from "@mui/material";
import { createSxStyles } from "../../../common/createSxStyles";

export const useAddEditDataCategoryDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        globalContainer: {
            display: "flex",
            alignItems: "center",
            width: 115,
        },
        globalText: {
            color: theme.palette.primary.dark,
            marginRight: theme.spacing(1),
        },
        globalToolTip: {
            marginBottom: -4 + "px",
        },
    });
};

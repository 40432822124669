import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { useState } from "react";

export function useContentDataMapping(isFirstLogin: boolean) {
    let { data } = useQuery("introVideoLink", () => get<string>("/videolink/intro"), { enabled: isFirstLogin });

    const [showIntroVideo, setShowIntroVideo] = useState(isFirstLogin);

    return {
        videoLink: data,
        showIntroVideo,
        setShowIntroVideo,
    };
}

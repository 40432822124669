import { useQuery } from "react-query";
import { LegalBasisSelectorViewModel } from "../../processingActivity/legalBasesStep/LegalBasesStep.types";
import { SelectableDocumentItem, SelectableItem, SelectableTransferBasisItem } from "../../processingActivity/ProcessingActivity.types";
import { get } from "../api/apiShared";
import { SelectablePolicyItem } from "../../policy/policies/Policies.types";
import { CountryViewModel } from "./useCountryList";
import { SelectableAreaItem } from "../../annualWheel/annualWheelDialog/annualWheelResponsibleForm/AnnualWheelResponsibleForm.types";

export function useSelectableDeletionPeriodsQuery(isEnabled: boolean = true) {
    return useQuery("deletionperiods", () => get<Array<SelectableItem>>("/processingactivity/deletionperiods"), { enabled: isEnabled });
}

export function useSelectableAllLegalBasesAllQuery(isEnabled: boolean = true) {
    return useQuery("legalbasis", () => get<LegalBasisSelectorViewModel>("/Legalbasis/getall/"), { enabled: isEnabled });
}

export function useSelectableLegalBasesQuery(isEnabled: boolean = true) {
    return useQuery("selectableLegalbases", () => get<Array<SelectableItem>>("/Legalbasis/getSelectableLegalBases/"), { enabled: isEnabled });
}

export function useSelectablePurposesQuery(isEnabled: boolean = true) {
    return useQuery("purposes", () => get<Array<SelectableItem>>("/purposes/selectablePurposes"), { enabled: isEnabled });
}

export function useSelectableProcessingActivitiesQuery(isEnabled: boolean = true) {
    return useQuery("selectableProcessingActivities", () => get<Array<SelectableItem>>("/processingactivity/selectableItems"), { enabled: isEnabled });
}

export function useSelectableSystemsQuery(isEnabled: boolean = true) {
    return useQuery("selectableSystems", () => get<Array<SelectableItem>>("/system/selectableItems"), { enabled: isEnabled });
}

export function useSelectableBusinessAreaQuery(isEnabled: boolean = true) {
    return useQuery("selectableBusinessAreas", () => get<Array<SelectableItem>>("/businessAreas/businessareas"), { enabled: isEnabled });
}

export function useSelectableUsersQuery(isEnabled: boolean = true) {
    return useQuery("selectableUsers", () => get<Array<SelectableItem>>("/users/responsibles"), { enabled: isEnabled });
}

export function useAllSelectableUsersQuery(isEnabled: boolean = true) {
    return useQuery("allSelectableUsers", () => get<Array<SelectableItem>>("/users/allUsers"), { enabled: isEnabled });
}

export function useDataCategoriesQuery(isEnabled: boolean = true) {
    return useQuery("selectableDataCategories", () => get<Array<SelectableItem>>("/processingActivity/datacategories"), { enabled: isEnabled });
}

export function useDataSubjectsQuery(isEnabled: boolean = true) {
    return useQuery("selectableDataSubjects", () => get<Array<SelectableItem>>("/processingActivity/datasubjects"), { enabled: isEnabled });
}

export function useNationalLawsQuery(isEnabled: boolean = true) {
    return useQuery("selectableNationalLaws", () => get<Array<SelectableItem>>("/legalbasis/selectableNationalLaws"), { enabled: isEnabled });
}

export function useTransferBasesQuery(isEnabled: boolean = true) {
    return useQuery("selectableTransferBases", () => get<Array<SelectableTransferBasisItem>>("/legalbasis/getTransferBasis"), { enabled: isEnabled });
}

export function useSecurityMeasuresQuery(isEnabled: boolean = true) {
    return useQuery("selectableSecurityMeasures", () => get<Array<SelectableItem>>("/SecurityMeasures/selectableSecurityMeasures"), { enabled: isEnabled });
}

export function useCompaniesQuery(isEnabled: boolean = true) {
    return useQuery("selectableCompanies", () => get<Array<SelectableItem>>("/LegalEntity/selectableentities"), { enabled: isEnabled });
}

export function useGroupCompaniesQuery(isEnabled: boolean = true) {
    return useQuery("selectableGroupCompanies", () => get<Array<SelectableItem>>("/LegalEntity/selectablegroupentities"), { enabled: isEnabled });
}

export function useCountriesQuery(isEnabled: boolean = true) {
    return useQuery("countries", () => get<Array<CountryViewModel>>("/Countries"), { enabled: isEnabled });
}

export function useDocumentQuery(isEnabled: boolean = true) {
    return useQuery("selectableLegalEntityDocuments", () => get<Array<SelectableDocumentItem>>("/LegalEntity/selectableDocuments"), { enabled: isEnabled });
}

export function usePoliciesQuery(isEnabled: boolean = true) {
    return useQuery("selectablePolicies", () => get<Array<SelectablePolicyItem>>("/policies/selectablePolicies"), { enabled: isEnabled });
}

export function useSystemDocumentQuery(isEnabled: boolean = true) {
    return useQuery("selectableSystemDocuments", () => get<Array<SelectablePolicyItem>>("/system/getSelectableDocuments"), { enabled: isEnabled });
}

export function useCertificationsQuery(isEnabled: boolean = true) {
    return useQuery("selectableCertifications", () => get<Array<SelectableItem>>("/certifications/selectableItems"), { enabled: isEnabled });
}

export function useComplianceAreaQuery(isEnabled: boolean = true) {
    return useQuery("selectableAreas", () => get<Array<SelectableAreaItem>>("/areas/selectableitems"), { enabled: isEnabled });
}

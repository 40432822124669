import { createElement, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../common/api/apiShared";
import { InternalAssessmentApiRow, InternalAssessmentApiModel, InternalAssessmentViewRow } from "./InternalAssessments.types";
import { useStateUrlParamsArray } from "../common/hooks/useStateUrlParams";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../common/stringOperations";
import { SelectableItem } from "../processingActivity/ProcessingActivity.types";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { getSettings } from "../common/settingsProvider";
import { createPdfUrl } from "../common/pdfUrlHelper";
import { downloadFile } from "../common/api/downloadFile";
import { Trans } from "react-i18next";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { getQuestionnaireResponseScore } from "../common/questionnaireResponseScore.ts";
import { LegalEntityAuditStatus } from "../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import { EvaluationStatus } from "../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";

export default function useInternalAssessmentsHooks() {
    const snackbar = useDotLegalSnackbar();
    const { language } = useUserContext();
    const { translateLegalEntityAuditStatus, translateDate, translateString } = useTranslation();
    const { getInternalAssessmentQuestionnaireResponseReport, getQuestionnaireResponseExternalreport } = useUrlProvider();

    const [selectedInternalAssessment, setSelectedInternalAssessment] = useState<InternalAssessmentApiRow | undefined>(undefined);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [showChangeAssigneeDialog, setShowChangeAssigneeDialog] = useState(false);
    const [showChangeResponsibleDialog, setShowChangeResponsibleDialog] = useState(false);
    const [showReminderDialog, setShowReminderDialog] = useState(false);
    const [showToggleRemindersDialog, setShowToggleRemindersDialog] = useState(false);
    const [showEvaluationDialog, setShowEvaluationDialog] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState("");
    const [selectedTemplates, setSelectedTemplates] = useStateUrlParamsArray("templates", []);
    const [selectedAreas, setSelectedAreas] = useStateUrlParamsArray("areas", []);
    const [selectedStatus, setSelectedStatus] = useStateUrlParamsArray("status", []);
    const [selectedResponsibles, setSelectedResponsibles] = useStateUrlParamsArray("responsibles", []);
    const [selectedEvaluations, setSelectedEvaluations] = useStateUrlParamsArray("evaluations", []);

    const url = `/internalAssessments`;
    let { isLoading, data, refetch } = useQuery(url, () => get<InternalAssessmentApiModel>(url));

    let audits;
    let selectableStatus: Array<SelectableItem> = [];
    let selectableEvaluationStatus: Array<SelectableItem> = [];
    if (data) {
        selectableStatus = getSelectableStatus();
        selectableEvaluationStatus = getSelectableEvaluationStatus();
        audits = getFilteredRows(data.rows);
    }

    const onInternalAssessmentSend = () => {
        setShowDialog(false);
        refetch();
    };

    const onInternalAssessmentDeleted = () => {
        refetch();
        setShowDeleteDialog(false);
    };

    const onInternalAssessmentClosed = () => {
        refetch();
        setShowCloseDialog(false);
    };

    const onInternalAssessmentReopened = () => {
        refetch();
        setShowReopenDialog(false);
    };

    const onAssigneeChanged = () => {
        refetch();
        setShowChangeAssigneeDialog(false);
    };

    const onResponsibleChanged = () => {
        refetch();
        setShowChangeResponsibleDialog(false);
    };

    const onReminderSent = () => {
        refetch();
        setShowReminderDialog(false);
    };

    const onEvaluated = () => {
        refetch();
        setShowEvaluationDialog(false);
    };

    const onRemindersToggled = () => {
        refetch();
        setShowToggleRemindersDialog(false);
    };

    const onDownloadReportAsPdf = async (viewModel: InternalAssessmentApiRow, isSimpleReport: boolean, showRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = isSimpleReport ? getExternalPdfUrl(viewModel, true, showRecommendations) : getReportPdfUrl(viewModel, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);

        const fileName = `${viewModel.name} - ${translateDate(viewModel.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf("");
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getReportPdfUrl(row: InternalAssessmentApiRow, pdf: boolean, showRecommendations: boolean) {
        const respondent = translateString("theRespondent").toLowerCase();
        return getInternalAssessmentQuestionnaireResponseReport(
            row.questionnaireResponseId,
            row.deadline,
            row.sender,
            row.name,
            pdf,
            showRecommendations,
            respondent
        );
    }

    function getExternalPdfUrl(row: InternalAssessmentApiRow, pdf: boolean, showRecommendations: boolean) {
        const respondent = translateString("theRespondent").toLowerCase();
        return getQuestionnaireResponseExternalreport(row.responseExternalId, row.deadline, row.sender, respondent, row.name, pdf, showRecommendations, false);
    }

    const saveNoteMutation = useMutation(submitSaveNote);
    function submitSaveNote(note: string) {
        return put(`/internalAssessments/${selectedInternalAssessment!.id}/note`, note);
    }

    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNote(false);
                refetch();
            },
        });
    };

    const auditsViewModelRows: undefined | Array<InternalAssessmentViewRow> = audits?.map((r) => {
        return {
            ...r,
            responsibleName: r.responsible.name,
            assigneeName: r.assignee.name,
            complianceAreasString: r.complianceAreas.join(", "),
            questionnaireName: r.assessmentTemplate.name,
            statusString: translateLegalEntityAuditStatus(r.status.toString()),
            scoreName: getQuestionnaireResponseScore(
                r.status === LegalEntityAuditStatus.Submitted,
                r.scoreEnabled,
                r.score,
                translateString("scoreNotEnabled")
            ),
            evaluationName: r.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[r.evaluationStatus].toString())) : "",
        };
    });

    return {
        audits: auditsViewModelRows,
        hasData: isLoading || (data && data.rows.length > 0),
        isLoading,
        showDialog,
        setShowDialog,
        onInternalAssessmentSend,
        selectableTemplates: data?.selectableAssessmentTemplate,
        selectedTemplates,
        setSelectedTemplates,
        selectableAreas: data?.selectableComplianceAreas,
        selectedAreas,
        setSelectedAreas,
        selectableResponsibles: data?.selectableResponsibles,
        selectedResponsibles,
        setSelectedResponsibles,
        selectableStatus,
        selectedStatus,
        setSelectedStatus,
        showDeleteDialog,
        setShowDeleteDialog,
        onInternalAssessmentDeleted,
        selectedInternalAssessment,
        setSelectedInternalAssessment,
        showCloseDialog,
        setShowCloseDialog,
        onInternalAssessmentClosed,
        showReopenDialog,
        setShowReopenDialog,
        onInternalAssessmentReopened,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showNote,
        setShowNote,
        saveNote,
        savingNote: saveNoteMutation.isLoading,
        showChangeAssigneeDialog,
        setShowChangeAssigneeDialog,
        onAssigneeChanged,
        showChangeResponsibleDialog,
        setShowChangeResponsibleDialog,
        onResponsibleChanged,
        showReminderDialog,
        setShowReminderDialog,
        onReminderSent,
        onEvaluated,
        showEvaluationDialog,
        setShowEvaluationDialog,
        selectableEvaluationStatus,
        selectedEvaluations,
        setSelectedEvaluations,
        setShowToggleRemindersDialog,
        showToggleRemindersDialog,
        onRemindersToggled,
    };

    function getSelectableStatus() {
        var temp = data!.rows.map((x) => {
            return { id: x.status.toString(), name: translateLegalEntityAuditStatus(x.status.toString()) };
        });

        return temp?.filter((item, index) => temp?.findIndex((f) => f.id === item.id) === index);
    }

    function getSelectableEvaluationStatus() {
        var temp = data!.rows
            .filter((x) => x.evaluationStatus)
            .map((x) => {
                return {
                    id: x.evaluationStatus!.toString(),
                    name: translateString(setFirstLetterToLowerCase(EvaluationStatus[x.evaluationStatus!].toString())),
                };
            });

        return temp?.filter((item, index) => temp?.findIndex((f) => f.id === item.id) === index);
    }

    function getFilteredRows(rows: Array<InternalAssessmentApiRow>) {
        let tempData = [...rows];

        if (hasFilterData(selectedTemplates)) {
            tempData = tempData.filter((row) => selectedTemplates.includes(row.questionnaireId));
        }

        if (hasFilterData(selectedAreas)) {
            tempData = tempData.filter((activity) => activity.complianceAreas.filter((x) => selectedAreas.includes(x.id)).length > 0);
        }

        if (hasFilterData(selectedStatus)) {
            tempData = tempData.filter((row) => selectedStatus.includes(row.status.toString()));
        }

        if (hasFilterData(selectedEvaluations)) {
            tempData = tempData.filter((row) => row.evaluationStatus && selectedEvaluations.includes(row.evaluationStatus.toString()));
        }

        if (hasFilterData(selectedResponsibles)) {
            tempData = tempData.filter((row) => selectedResponsibles.includes(row.responsible.id));
        }

        return tempData;
    }

    function hasFilterData(filter: Array<string>) {
        return filter.length > 0 && !isNullOrWhitespace(filter[0]);
    }
}

import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import StepSegment from "../stepSegment/StepSegment";
import { useDataSubjectStepHook } from "./DataSubjectStep.hooks";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { ProcessingActivitySecurityMeasureModel } from "./DataSubjectStep.types";
import { ValidationError } from "../../common/validationError";
import DotLegalMultiSelectWithButton from "../../common/components/dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import AddEditSecurityMeasureDialog from "../../masterData/securityMeasure/addEditSecurityMeasureDialog/AddEditSecurityMeasureDialog";
import { Guid } from "../../common/guid";
import AddEditDataSubjectDialog from "../../masterData/dataSubject/addEditDataSubjectDialog/AddEditDataSubjectDialog";
import { DataSourceEnum, DataSubjectDataCategory } from "../ProcessingActivity.types";
import DotLegalSecurityMeasure from "../../common/components/dotLegalSecurityMeasure/DotLegalSecurityMeasure";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { dataControllerRecordIcon } from "../../common/icons";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { isNullOrWhitespace } from "../../common/stringOperations";

export interface IDataSubjectStep {
    name?: string;
    processingActivityId: string;
    dataSubjectDataCategories: Array<DataSubjectDataCategory>;
    dataSubjects: Array<string>;
    subjects?: number;
    processors?: number;
    dataSources: Array<DataSourceEnum>;
    validationErrors: Array<ValidationError>;
    securityMeasures: Array<ProcessingActivitySecurityMeasureModel>;
    tooltip?: string;
    onDataSubjectsChange: (dataSubjects: Array<string>) => void;
    onSubjectsChange: (subjects: number | undefined) => void;
    onProccessorsChange: (processors: number | undefined) => void;
    onSecurityMeasureChange: (securityMeasures: Array<ProcessingActivitySecurityMeasureModel>) => void;
    onDataSourceChange: (dataSources: Array<DataSourceEnum>) => void;
    readOnly?: boolean;
    hasDataCreationPermission: boolean;
}

function DataSubjectStep(props: IDataSubjectStep) {
    const { translateString, translateDataSubjectsEnum, translateProcessorsEnum } = useTranslation();
    const { permissions } = useUserContext();
    const { planType } = usePlanContext();
    const {
        dataSubjecstData,
        dataSubjectsLoading,
        securityMeasuresData,
        securityMeasuresLoading,
        showAddNewSecurityMeasure,
        setShowAddNewSecurityMeasure,
        typedSecurityMeasure,
        setTypedSecurityMeasure,
        onSaveSecurityMeasure,
        showAddNewDataSubject,
        setShowAddNewDataSubject,
        typedDataSubject,
        setTypedDataSubject,
        onSaveDataSubject,
        handleOnChangeDataSubject,
        getNoOfDataSubjectSelectOptions,
        getNoOfProcessorsSelectOptions,
    } = useDataSubjectStepHook(props);

    return (
        <StepSegment size="small">
            <DotLegalMultiSelectWithButton
                label={translateString("dataSubjects")}
                placeholder={translateString("dataSubjects")}
                isLoading={dataSubjectsLoading}
                options={dataSubjecstData as Array<DotLegalSelectOption>}
                chosenOptions={props.dataSubjects!}
                onChange={async (dataSubjects) => await handleOnChangeDataSubject(dataSubjects)}
                errorText={props.validationErrors.find((v) => v.field === "dataSubjects")?.error}
                toolTipText={translateString("categoryHelperText")}
                disabled={props.readOnly}
                buttonLabel={translateString("createNew")}
                onButtonClick={() => setShowAddNewDataSubject(true)}
                hasPermission={props.hasDataCreationPermission}
                onSearchValueChange={setTypedDataSubject}
                searchValue={typedDataSubject}
                noOptionsLabel={translateString("noOptions")}
                additionalIcon={{ icon: dataControllerRecordIcon, tooltipText: translateString("fieldIncludedInRecord") }}
            />
            {permissions.processingActivityDataSubjectPermissions.hasDataSubjectsPermission && (
                <DotLegalSelect
                    options={getNoOfDataSubjectSelectOptions(translateDataSubjectsEnum)}
                    onChange={(subjects) => props.onSubjectsChange(isNullOrWhitespace(subjects ?? undefined) ? undefined : Number(subjects))}
                    selectedItem={props.subjects?.toString() ?? ""}
                    label={translateString("noOfSubjects")}
                    noOptionsLabel={translateString("noOptions")}
                    disabled={props.readOnly}
                    toolTipText={translateString("noDataSubjectsHelperText")}
                />
            )}

            {permissions.processingActivityDataSubjectPermissions?.hasProcessorsPermission && (
                <DotLegalSelect
                    options={getNoOfProcessorsSelectOptions(translateProcessorsEnum)}
                    onChange={(processors) => props.onProccessorsChange(isNullOrWhitespace(processors ?? undefined) ? undefined : Number(processors))}
                    selectedItem={props.processors?.toString() ?? ""}
                    label={translateString("processors")}
                    noOptionsLabel={translateString("noOptions")}
                    disabled={props.readOnly}
                    toolTipText={translateString("noEmployeesHelperText")}
                />
            )}

            {planType !== PlanType.GdprResearch && permissions.processingActivityDataSubjectPermissions.hasDataSourcePermission && (
                <DotLegalMultiSelect
                    options={[
                        { id: DataSourceEnum.TheDataSubject.toString(), name: translateString("theDataSubject") },
                        { id: DataSourceEnum.ExternalDataSource.toString(), name: translateString("externalDataSource") },
                    ]}
                    onChange={(values) => {
                        props.onDataSourceChange(values.map((v) => Number(v)));
                    }}
                    label={translateString("dataSource")}
                    chosenOptions={props.dataSources.map((x) => x.toString())}
                    noOptionsLabel={""}
                    disabled={props.readOnly}
                />
            )}
            {permissions.processingActivityDataSubjectPermissions?.hasSecurityMeasurePermission && (
                <DotLegalSecurityMeasure
                    canManageMasterData={props.hasDataCreationPermission}
                    isLoading={securityMeasuresLoading}
                    onSecurityMeasureChange={(securityMeasure) => props.onSecurityMeasureChange(securityMeasure)}
                    securityMeasureOptions={securityMeasuresData!}
                    securityMeasures={props.securityMeasures}
                    typedSecurityMeasure={typedSecurityMeasure}
                    setTypedSecurityMeasure={setTypedSecurityMeasure}
                    setShowAddNewSecurityMeasure={setShowAddNewSecurityMeasure}
                    disabled={props.readOnly}
                />
            )}

            {showAddNewSecurityMeasure && (
                <AddEditSecurityMeasureDialog
                    open
                    isAdd
                    selectSecurityMeasure={{
                        id: Guid.newGuid(),
                        name: typedSecurityMeasure,
                        description: "",
                        securityMeasureType: undefined,
                        isGlobal: false,
                        active: true,
                        isCustom: true,
                        businessAreaIds: [],
                        groupEntitiesIds: [],
                    }}
                    onDialogClose={() => setShowAddNewSecurityMeasure(false)}
                    onOkClick={onSaveSecurityMeasure}
                    refetch={() => {}}
                />
            )}
            {showAddNewDataSubject && (
                <AddEditDataSubjectDialog
                    isAdd
                    onHideDialog={() => setShowAddNewDataSubject(false)}
                    onSave={onSaveDataSubject}
                    selectedDataSubject={{ id: Guid.newGuid(), name: typedDataSubject, description: "", active: true, isCustom: true }}
                />
            )}
        </StepSegment>
    );
}

export default DataSubjectStep;

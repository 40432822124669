import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../localization/useTranslation";
import { DocumentStatus, DotLegalDocumentType, caseManagementDocumentTypes } from "./Documents.types";

export function useDocumentHook() {
    const { translateString } = useTranslation();
    const { processingActivityAndRecordsPlan } = usePlanContext();
    const documentTypes = Object.entries(DotLegalDocumentType).filter((q) => isNaN(Number(q[0])) && filterCaseManagementDocumentTypes(Number(q[1])));
    const documentStatus = Object.entries(DocumentStatus).filter((q) => isNaN(Number(q[0])));

    const selecableDocumentStatus: Array<DotLegalSelectOption> = documentStatus.map((status) => {
        return {
            name: translateString(status[0].toString()),
            id: status[1].toString(),
        };
    });

    return { documentTypes, selecableDocumentStatus };

    function filterCaseManagementDocumentTypes(value: number) {
        if (!processingActivityAndRecordsPlan.caseHandlerModule) {
            return caseManagementDocumentTypes.indexOf(value) === -1;
        }
        return true;
    }
}

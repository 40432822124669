import { Badge, Box, Grid, styled, useTheme } from "@mui/material";
import React from "react";
import { DotLegalChip, DotLegalHeader, DotLegalMultiSelect, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useMasterDataSection } from "./MasterDataSection.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import { AnnualWheelMonthEnum, ApprovalOption, TaskRawStateEnum } from "../../../annualWheel/AnnualWheel.types";
import { TrackingEvent, useTrackAIEvent } from "../../../processingActivity/hooks/useTracking";
import { useDotLegalFileUploadDataMapping } from "../../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { TaskMasterDataStaticDataViewModel } from "./MasterDataSection.types";
import DotLegalWarningTriangle from "../../../common/components/dotLegalWarningTriangle/DotLegalWarningTriangle";
import { getTaskColor } from "../../task/Task.styles";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { isResponsibleRequired } from "../../../annualWheel/ApprovalOptions";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";

export interface MasterDataSectionProps {
    taskId?: string;
    masterData?: TaskMasterDataStaticDataViewModel;
    isLoading: boolean;
    passIsDoneLoading?: (newValue: boolean) => void;
}

const SelectWrapper = styled("div")({
    "& .MuiBox-root:first-child": {
        marginTop: 0,
        marginBottom: 0,
    },
});

function MasterDataSection(props: MasterDataSectionProps) {
    const bottomMargin = 4;
    const leftGridSize = 5;
    const rightGridSize = 7;
    const trackEvent = useTrackAIEvent();
    const theme = useTheme();
    const { permissions } = useUserContext();
    const { translateString } = useTranslation();
    const { addTaskToCalendar } = useDotLegalFileUploadDataMapping();
    const {
        options,
        completedAllowed,
        businessAreaData,
        assigneesData,
        responsiblesData,
        isSectionLoading,
        showAddToCalenderLink,
        priorityOptions,
        data,
        onResponsibleChange,
        onBusinessAreaChange,
        onPriorityChange,
        onProgressChange,
        onAssigneesChange,
    } = useMasterDataSection(props);
    const { getRiskIcon } = useRiskIndicator();

    const convertDate = () => {
        return new Date(props.masterData!.deadline);
    };

    const getValue = (value: string) => {
        return <div>{value}</div>;
    };

    const getLoadingBox = () => {
        return (
            <SelectWrapper>
                <DotLegalSelect
                    disabled={!permissions.taskPermissions.edit}
                    options={[]}
                    selectedItem={undefined}
                    disableClearable
                    onChange={() => {}}
                    placeholder={""}
                    variant={"standard"}
                    label={"Loading..."}
                    noOptionsLabel={"Loading..."}
                    isLoading={isSectionLoading}
                />
            </SelectWrapper>
        );
    };

    const getBagdeColorForTaskState = (id: string) => {
        switch (id) {
            case "1":
                return getTaskColor(TaskRawStateEnum.Ready, theme);
            case "2":
                return getTaskColor(TaskRawStateEnum.InProgress, theme);
            case "5":
                return getTaskColor(TaskRawStateEnum.ReadyForApproval, theme);
            default:
                return getTaskColor(TaskRawStateEnum.Completed, theme);
        }
    };

    const getReadOnlyResponsible = () => {
        if (isSectionLoading) return getLoadingBox();

        const responsible = responsiblesData?.find((x) => x.id === data?.responsible);
        return (
            <DotLegalHeader color={responsible ? "primary" : "lightgrey"} fontWeight={400} headerStyle={"small"}>
                {responsible ? responsible.name : translateString("noResponsible")}
            </DotLegalHeader>
        );
    };

    const getReadOnlyPriority = () => {
        if (isSectionLoading) return getLoadingBox();

        const name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[data!.priority].toString()));
        return (
            <DotLegalHeader color={"primary"} fontWeight={400} headerStyle={"small"}>
                <Box sx={{ "& svg": { marginRight: "12px" } }}>
                    {getRiskIcon(Number(data?.priority))}
                    {name}
                </Box>
            </DotLegalHeader>
        );
    };

    const getResponsibleLockedTooltip = () => {
        if (data?.approval === ApprovalOption.ApprovalNotRequiredResponsibleGetNotified) {
            return translateString("responsibleLockedRequiredForCompletionNotification");
        } else if (data?.approval === ApprovalOption.ApprovalRequiredByResponsible) {
            return translateString("responsibleLockedRequiredForCompletion");
        } else return "";
    };

    const responsibleNotChosen = data && (data.responsible === undefined || data.responsible === null || data.responsible === "");
    let assigneesPlaceholder = "";
    if (responsiblesData) {
        assigneesPlaceholder = data && data.responsible && data.assignees.length === 0 ? responsiblesData.find((x) => x.id === data.responsible)!.name : "";
    }

    return (
        <Box>
            <DotLegalSelect
                disabled={!permissions.taskPermissions.edit}
                width={"40%"}
                disableClearable
                noMargin
                label=""
                options={options}
                selectedItem={data?.taskState.toString()}
                onChange={async (state) => await onProgressChange(state)}
                placeholder=""
                warningText={completedAllowed}
                noOptionsLabel={translateString("noOptions")}
                isLoading={isSectionLoading}
                renderOptions={(option) => (
                    <Box>
                        <Badge
                            sx={{
                                marginRight: 1.5,
                                "& .MuiBadge-badge": {
                                    backgroundColor: getBagdeColorForTaskState(option.id),
                                },
                            }}
                            variant="dot"
                        />
                        {option.name}
                    </Box>
                )}
                inputStartAdornment={
                    data ? (
                        <Badge
                            sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: getTaskColor(data!.taskState, theme),
                                },
                            }}
                            variant="dot"
                        />
                    ) : undefined
                }
                disableSearch
            />

            <Box
                sx={(theme) => ({
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    marginTop: 1.5,
                    padding: 3,
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                    "& .MuiInputLabel-root": {
                        display: "none",
                    },
                })}
            >
                <Grid container>
                    <Grid item xs={leftGridSize}>
                        <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                            {translateString("deadline")}
                        </DotLegalHeader>
                    </Grid>
                    <Grid item xs={rightGridSize}>
                        {isSectionLoading ? (
                            getLoadingBox()
                        ) : (
                            <>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <DotLegalHeader marginBottom={0} headerStyle={"small"} fontWeight={500}>
                                        {getValue(
                                            props.masterData?.isSeparateTask
                                                ? translateString("None")
                                                : `${translateString(
                                                      AnnualWheelMonthEnum[convertDate().getMonth() + 1].toLocaleLowerCase()
                                                  )} ${convertDate().getFullYear()}`
                                        )}
                                    </DotLegalHeader>
                                    {props.masterData?.isOverdue && <DotLegalWarningTriangle warningText={translateString("deadlineOverdue")} />}
                                </Box>
                                {showAddToCalenderLink() && (
                                    <Box
                                        sx={{
                                            "& .MuiTypography-root:hover": {
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        <DotLegalHeader
                                            marginBottom={0}
                                            headerStyle={"extraSmall"}
                                            color="secondary"
                                            fontWeight={400}
                                            onClick={() => {
                                                trackEvent(TrackingEvent.TaskAddedToOutlook, { task: data!.id });
                                                addTaskToCalendar(data!.id, props.masterData!.name);
                                            }}
                                        >
                                            {translateString("addToOutLook")}
                                        </DotLegalHeader>
                                    </Box>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

                {props.masterData?.isSeparateTask === false && (
                    <>
                        <Grid container>
                            <Grid item xs={leftGridSize}>
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                    {translateString("priority")}
                                </DotLegalHeader>
                            </Grid>
                            <Grid item xs={rightGridSize}>
                                {!permissions.canManageTaskMasterData ? (
                                    getReadOnlyPriority()
                                ) : (
                                    <SelectWrapper>
                                        <DotLegalSelect
                                            disabled={!permissions.canManageTaskMasterData}
                                            options={priorityOptions()}
                                            selectedItem={data?.priority.toString()}
                                            disableClearable
                                            onChange={async (priority) => await onPriorityChange(priority)}
                                            placeholder={""}
                                            variant={"standard"}
                                            label={translateString("priority")}
                                            noOptionsLabel={translateString("noOptions")}
                                            isLoading={isSectionLoading}
                                            renderOptions={(option) => (
                                                <Box sx={{ "& svg": { marginRight: "12px" } }}>
                                                    {getRiskIcon(Number(option.id))}
                                                    {option.name}
                                                </Box>
                                            )}
                                            inputStartAdornment={data ? <Box sx={{ marginLeft: "-12px" }}>{getRiskIcon(data.priority)}</Box> : undefined}
                                        />
                                    </SelectWrapper>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={leftGridSize}>
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"} toolTip={getResponsibleLockedTooltip()}>
                                    {translateString("responsible")}
                                </DotLegalHeader>
                            </Grid>
                            <Grid item xs={rightGridSize}>
                                {props.masterData.type === AnnualWheelActivityType.ProcessingActivityValidation || !permissions.canManageTaskMasterData ? (
                                    getReadOnlyResponsible()
                                ) : (
                                    <SelectWrapper>
                                        <DotLegalSelect
                                            disableClearable={isResponsibleRequired(data?.approval)}
                                            options={responsiblesData}
                                            isLoading={isSectionLoading}
                                            selectedItem={data?.responsible}
                                            placeholder={translateString("noResponsible")}
                                            variant={"standard"}
                                            label={translateString("responsible")}
                                            onChange={async (responsible) => await onResponsibleChange(responsible)}
                                            noOptionsLabel={translateString("noOptions")}
                                        />
                                    </SelectWrapper>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={leftGridSize}>
                                <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                    {translateString("assign")}
                                </DotLegalHeader>
                            </Grid>
                            <Grid item xs={rightGridSize}>
                                {isSectionLoading ? (
                                    getLoadingBox()
                                ) : (
                                    <SelectWrapper>
                                        <DotLegalMultiSelect
                                            disabled={!permissions.canManageTaskMasterData}
                                            options={assigneesData}
                                            isLoading={isSectionLoading}
                                            chosenOptions={data?.assignees ?? []}
                                            placeholder={responsibleNotChosen ? translateString("assignTo") : assigneesPlaceholder}
                                            variant={"standard"}
                                            label={""}
                                            onChange={async (assigness) => await onAssigneesChange(assigness)}
                                            noOptionsLabel={translateString("noOptions")}
                                        />
                                    </SelectWrapper>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}

                {props.masterData?.isSeparateTask === false && (
                    <Grid container>
                        <Grid item xs={leftGridSize}>
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                                {translateString("businessAreas")}
                            </DotLegalHeader>
                        </Grid>
                        <Grid item xs={rightGridSize}>
                            {props.masterData!.type === AnnualWheelActivityType.ProcessingActivityValidation || !permissions.canManageTaskMasterData ? (
                                isSectionLoading ? (
                                    getLoadingBox()
                                ) : (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                                        {!isSectionLoading &&
                                            businessAreaData
                                                ?.filter((x) => data!.businessAreas.indexOf(x.id) > -1)
                                                .map((x) => {
                                                    return (
                                                        <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={x.id}>
                                                            <DotLegalChip isSelected size="small" key={x.id} color={x.color} value={x.name}></DotLegalChip>
                                                        </Box>
                                                    );
                                                })}
                                    </Box>
                                )
                            ) : (
                                <SelectWrapper>
                                    <DotLegalMultiSelect
                                        label={translateString("businessAreas")}
                                        options={businessAreaData}
                                        isLoading={isSectionLoading}
                                        chosenOptions={data?.businessAreas ?? []}
                                        placeholder={translateString("noBusinessAreas")}
                                        variant={"standard"}
                                        onChange={async (businessAreas) => await onBusinessAreaChange(businessAreas)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </SelectWrapper>
                            )}
                        </Grid>
                    </Grid>
                )}

                <Grid container>
                    <Grid item xs={leftGridSize}>
                        <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"}>
                            ID
                        </DotLegalHeader>
                    </Grid>
                    <Grid item xs={rightGridSize}>
                        {isSectionLoading ? (
                            getLoadingBox()
                        ) : (
                            <DotLegalHeader marginBottom={bottomMargin} headerStyle={"small"} fontWeight={500}>
                                {props.masterData?.customId}
                            </DotLegalHeader>
                        )}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={leftGridSize}>
                        <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                            {translateString("groupCompaniesParenthesis")}
                        </DotLegalHeader>
                    </Grid>
                    <Grid item xs={rightGridSize}>
                        {isSectionLoading ? (
                            getLoadingBox()
                        ) : (
                            <DotLegalHeader marginBottom={0} headerStyle={"small"} fontWeight={500}>
                                {props.masterData?.legalEntityName ?? translateString("entireGroup")}
                            </DotLegalHeader>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default MasterDataSection;

import React from "react";
import { Alert, AlertTitle, Box, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useCustomListViewHooks } from "./CustomListView.hooks";
import { useTranslation } from "../../localization/useTranslation";
import { ContentType, CustomListType } from "../CustomLists.types";
import {
    DotLegalButton,
    DotLegalPageHeader,
    DotLegalPagination,
    DotLegalPrettyScrollbar,
    DotLegalSearchField,
    useDotLegalTableStyles,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalFullScreenSpinner from "../../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import EmptyStateIcon from "../noCustomLists.svg?react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { useCustomListViewStyles } from "./CustomListView.styles";
import EditCustomListDialog from "../editCustomListDialog/EditCustomListDialog";
import { getTranslationKey } from "../customListOperations";
import { useUrlProvider } from "../../useUrlProvider";
import { CustomListDataRow } from "./CustomListView.types";
import ExportSvg from "../../common/components/dotLegalRecordHeader/export.svg?react";
import WhiteLockSvg from "../../common/svgs/whiteLock.svg?react";
import { PropertyType } from "../../common/PropertyTypes";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";

export interface ICustomListViewProps {}

function CustomListView(props: ICustomListViewProps) {
    const { processingActivityAndRecordsPlan, systemPlan, unitsPlan } = usePlanContext();
    const { translateString, translatePropertyName } = useTranslation();
    const classes = useDotLegalTableStyles(2, false, false);
    const customListViewStyles = useCustomListViewStyles();
    const { customerName } = useUserContext();
    const { getProcessingActivityOverviewUrl, getEditProcessingActivityUrl, getSystemOverviewUrl, getGroupEntityUrl, getLegalEntityUrl } = useUrlProvider();

    const {
        isLoading,
        isLoadingExport,
        columns,
        setSearchString,
        rows,
        onSortClick,
        orderBy,
        order,
        searchString,
        getBreadCrumbs,
        showEditDialog,
        setOpenEditDialog,
        customListId,
        refetch,
        listType,
        hasData,
        onClickExport,
        calculatedPage,
        count,
        from,
        noOfPages,
        to,
        setPage,
        isReadOnly,
        processingActivityLimitReached,
        processingActivityLimit,
    } = useCustomListViewHooks();

    const onClick = (row: CustomListDataRow) => {
        switch (listType) {
            case CustomListType.ProcessingActivity: {
                return getProcessingActivityOverviewUrl(row.id);
            }
            case CustomListType.Sharing: {
                return getEditProcessingActivityUrl(row.processingActivityId, 6, row.subStep, row.id);
            }
            case CustomListType.System: {
                return getSystemOverviewUrl(row.id);
            }
            case CustomListType.Company: {
                return row.isGroupCompany ? getGroupEntityUrl(row.id) : getLegalEntityUrl(row.id);
            }
            default: {
                new Error("ListType needs to be implemented: " + listType);
            }
        }
    };

    const getLinkStyle = (cell: any, index: number) => {
        const type = columns[index].columnType;

        const style = { fontWeight: "bold", fontStyle: "" };

        if (cell === translateString("unNamedSharing") && type === PropertyType.Sharings) {
            style.fontStyle = "italic";
        }

        return style;
    };

    const exportBtnIsLocked = () => {
        switch (listType) {
            case CustomListType.ProcessingActivity: {
                return (
                    (isReadOnly && !processingActivityAndRecordsPlan.standardListExport) || (!isReadOnly && !processingActivityAndRecordsPlan.customListExport)
                );
            }
            case CustomListType.Sharing: {
                return (
                    (isReadOnly && !processingActivityAndRecordsPlan.standardListExport) || (!isReadOnly && !processingActivityAndRecordsPlan.customListExport)
                );
            }
            case CustomListType.System: {
                return (isReadOnly && !systemPlan.standardListsExport) || (!isReadOnly && !systemPlan.customListExport);
            }
            case CustomListType.Company: {
                return (isReadOnly && !unitsPlan.standardListExport) || (!isReadOnly && !unitsPlan.customListExport);
            }
            default: {
                new Error("ListType needs to be implemented: " + listType);
            }
        }
    };

    const getWarningKey = () => {
        switch (listType) {
            case CustomListType.ProcessingActivity: {
                return "customListProcessingActivityLimitReached";
            }
            case CustomListType.Sharing: {
                return "customListSharingLimitReached";
            }
            case CustomListType.System: {
                return "customListLimitReached";
            }
            case CustomListType.Company: {
                return "customListLimitReached";
            }
            default: {
                new Error("ListType needs to be implemented: " + listType);
            }
        }
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} isLoading={isLoading} breadCrumbs={getBreadCrumbs()} />
            <DotLegalPaper background="blueWave" backgroundCover sx={customListViewStyles.paper}>
                <Grid container>
                    <Grid xs={6} item>
                        {isLoading ? (
                            <Skeleton width={"120px"} height={"80px"}></Skeleton>
                        ) : (
                            !isReadOnly && (
                                <DotLegalButton buttonType="primary" size="medium" onClick={() => setOpenEditDialog("true")}>
                                    {translateString("updateCustomList")}
                                </DotLegalButton>
                            )
                        )}
                    </Grid>
                    <Grid xs={6} display={"flex"} alignItems={"center"} justifyContent={"flex-end"} item>
                        {isLoading ? (
                            <Skeleton width={"120px"} height={"70px"}></Skeleton>
                        ) : (
                            <PlanDialogButtonWrapper enabled={exportBtnIsLocked() ?? false}>
                                <DotLegalButton
                                    btnLength={120}
                                    isLoading={isLoadingExport}
                                    onClick={onClickExport}
                                    buttonType={"primary"}
                                    disabled={exportBtnIsLocked() || (columns.length === 0 && processingActivityLimitReached === false)}
                                    endIcon={exportBtnIsLocked() ? <WhiteLockSvg /> : <ExportSvg />}
                                    toolTip={exportBtnIsLocked() ? translateString("upgradePrivacyPlanToday") : undefined}
                                >
                                    {translateString("export")}
                                </DotLegalButton>
                            </PlanDialogButtonWrapper>
                        )}
                    </Grid>
                </Grid>
            </DotLegalPaper>

            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : (
                <>
                    <DotLegalPaper>
                        {hasData || processingActivityLimitReached ? (
                            <React.Fragment>
                                {processingActivityLimitReached && (
                                    <Box marginBottom={3}>
                                        <Alert
                                            severity="warning"
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fill: "#ff9800",
                                                },
                                            }}
                                        >
                                            <AlertTitle>{translateString("warning")}</AlertTitle>
                                            {translateString(getWarningKey()!, { limit: processingActivityLimit })}
                                        </Alert>
                                    </Box>
                                )}

                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <DotLegalSearchField labelSearch={translateString("search")} value={searchString} onChange={(e) => setSearchString(e)} />
                                </Box>
                                <TableContainer sx={classes.root}>
                                    <DotLegalPrettyScrollbar width={14}>
                                        <Table className={`${classes.dpaTable} ${classes.sticky}`} size={"small"}>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((c, i) => (
                                                        <TableCell
                                                            key={c.columnType}
                                                            align={
                                                                c.contentType === ContentType.Number && c.columnType !== PropertyType.Status ? "right" : "left"
                                                            }
                                                        >
                                                            <TableSortLabel
                                                                sx={classes.headerText}
                                                                active={i === orderBy}
                                                                onClick={(_) => onSortClick(i)}
                                                                direction={i === orderBy ? order : "asc"}
                                                            >
                                                                {translatePropertyName(getTranslationKey(c.columnType))}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, i) => (
                                                    <TableRow key={i}>
                                                        {row.data.map((cell: any, j: number) => {
                                                            return (
                                                                <TableCell key={j} align={Number.isInteger(cell) ? "right" : "left"}>
                                                                    {j === 0 ? (
                                                                        <DotLegalLink to={onClick(row)!}>
                                                                            <Box sx={getLinkStyle(cell, j)}>{cell}</Box>
                                                                        </DotLegalLink>
                                                                    ) : (
                                                                        cell
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </DotLegalPrettyScrollbar>
                                    <DotLegalPagination
                                        calculatedPage={calculatedPage}
                                        count={count}
                                        from={from}
                                        noOfPages={noOfPages}
                                        setPage={setPage}
                                        to={to}
                                        hidePagination={false}
                                        labelOf={translateString("labelOf")}
                                    />
                                </TableContainer>
                            </React.Fragment>
                        ) : (
                            <DotLegalEmptyState icon={<EmptyStateIcon />} text={translateString("noListsFound")} />
                        )}
                    </DotLegalPaper>
                </>
            )}

            {!isLoading && showEditDialog && (
                <EditCustomListDialog
                    onSaveClick={() => {
                        setOpenEditDialog("");
                        refetch();
                    }}
                    onCloseClick={() => {
                        setOpenEditDialog("");
                        refetch();
                    }}
                    type={listType!}
                    listId={customListId}
                />
            )}
        </React.Fragment>
    );
}

export default CustomListView;

import { Box, IconButton, List, ListItem } from "@mui/material";
import React from "react";
import { useSubTasksStyles } from "./SubTasks.styles";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "../../localization/useTranslation";
import { useSubTasksHook } from "./SubTasks.hooks";
import { DotLegalCheckbox, DotLegalCompletedPercentage, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import DotLegalPlayer from "../../common/components/dotLegalPlayer/DotLegalPlayer";
import { playIcon } from "../../common/icons";
import AddIcon from "@mui/icons-material/Add";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";

export interface ISubTasksProps {
    taskId: string;
    updateTaskState: () => void;
    isSeparateTask: boolean;
    disabled: boolean;
    allDataIsDoneLoading: boolean;
    shouldAddTask?: boolean;
    onSuccessAddTask?: () => void;
    passIsDoneLoading?: (newValue: boolean) => void;
}

function SubTasks(props: ISubTasksProps) {
    const { translateString } = useTranslation();
    const { data, isLoading, onSubTaskChange, removeSubTask, hoveredSubTask, setHoveredSubTask, addSubTask, videoUrl, setVideoUrl } = useSubTasksHook(props);
    const styles = useSubTasksStyles(!isLoading && data?.subTasks !== undefined && data.subTasks!.length > 0);
    async function handleToggle(isComplete: boolean, id: string) {
        let tempData = { ...data! };

        const foundTask = tempData.subTasks.find((x) => x.id === id);
        foundTask!.isComplete = isComplete;

        await onSubTaskChange(tempData, foundTask!);
        props.updateTaskState();
    }

    async function updateDescription(newName: string | null, id: string) {
        let tempData = { ...data! };

        const foundTask = tempData.subTasks.find((x) => x.id === id);
        foundTask!.description = newName!;

        await onSubTaskChange(tempData, foundTask!);
        props.updateTaskState();
    }

    async function removeTask(id: string) {
        await removeSubTask(id);
        props.updateTaskState();
    }

    const handleMouseOver = (taskId: string) => {
        setHoveredSubTask(taskId);
    };

    const handleMouseOut = () => {
        setHoveredSubTask("");
    };

    return (
        <>
            {props.allDataIsDoneLoading && data?.subTasks !== undefined && data.subTasks!.length > 0 && (
                <React.Fragment>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2, marginBottom: 1 }}>
                        <DotLegalHeader marginBottom={0} marginTop={0} headerStyle="small" toolTip={translateString("SubTaskToolTip")}>
                            {translateString("subTasks")}
                        </DotLegalHeader>
                        {!props.isSeparateTask && !props.disabled && (
                            <DotLegalTooltip text={translateString("addSubTask")}>
                                <IconButton
                                    sx={{ padding: 0.5 }}
                                    onClick={async () => {
                                        await addSubTask();
                                        props.updateTaskState();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </DotLegalTooltip>
                        )}
                    </Box>

                    <DotLegalCompletedPercentage completed={data?.percentageComplate ?? 0} fullWidth oneLiner isLoading={isLoading} />

                    <List sx={styles.root}>
                        {!isLoading &&
                            data?.subTasks?.map((task, index) => {
                                const labelId = `checkbox-list-label-${index}`;

                                return (
                                    <Box key={task.id} onMouseEnter={() => handleMouseOver(task.id)} onMouseLeave={handleMouseOut}>
                                        <ListItem sx={styles.listItem} key={task.id} role={undefined} dense>
                                            <DotLegalCheckbox
                                                disabled={props.disabled || isNullOrWhitespace(task.description)}
                                                key={labelId}
                                                onChange={async (e) => {
                                                    await handleToggle(e, task.id);
                                                }}
                                                checked={task.isComplete}
                                            />

                                            <Box
                                                sx={[
                                                    styles.subTaskDescription,
                                                    // Apply styles to ReactMarkDown component
                                                    props.isSeparateTask
                                                        ? { "& :first-child": { ...styles.markDown, ...(task.isComplete ? styles.taskCompleted : {}) } }
                                                        : {},
                                                ]}
                                            >
                                                {props.isSeparateTask ? (
                                                    <ReactMarkdown>{task.description}</ReactMarkdown>
                                                ) : (
                                                    <DotLegalTextField
                                                        disabled={props.disabled}
                                                        noMargin
                                                        variant="standard"
                                                        label=""
                                                        debounce
                                                        multiline
                                                        placeholder={translateString("subTaskPlaceHolder")}
                                                        value={task.description}
                                                        onChange={async (value) => updateDescription(value, task.id)}
                                                        id={task.id}
                                                        alwaysShowUnderline
                                                        textDecoration={task.isComplete ? "line-through" : undefined}
                                                    />
                                                )}
                                            </Box>

                                            {props.isSeparateTask ? (
                                                <IconButton
                                                    sx={[styles.icon, styles.videoIcon]}
                                                    size="small"
                                                    onClick={async () => {
                                                        setVideoUrl(task.link);
                                                    }}
                                                >
                                                    {playIcon}
                                                </IconButton>
                                            ) : (
                                                !props.disabled && (
                                                    <IconButton
                                                        disabled={props.disabled}
                                                        sx={hoveredSubTask === task.id ? undefined : { visibility: "hidden" }}
                                                        size="small"
                                                        onClick={async () => await removeTask(task.id)}
                                                    >
                                                        <CloseIcon sx={styles.remove} />
                                                    </IconButton>
                                                )
                                            )}
                                        </ListItem>
                                    </Box>
                                );
                            })}
                    </List>

                    {videoUrl && (
                        <DotLegalPlayer
                            onCloseDialog={() => {
                                setVideoUrl("");
                            }}
                            url={videoUrl}
                        />
                    )}
                </React.Fragment>
            )}
        </>
    );
}

export default SubTasks;

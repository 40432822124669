import { DataPrivacyClassification } from "./DataCategoriesStep/DataCategoriesStep.types";
import { ProcessingActivitySecurityMeasureModel } from "./dataSubjectStep/DataSubjectStep.types";
import { Guid } from "../common/guid";
import { backgroundWaves } from "../common/components/dotLegalPaper/DotLegalPaper";
import { LevelOfProtectionType } from "../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { ProcessingActivityStatus } from "./processingActivities/ProcessingActivities.types";
import { DocumentStatus, DotLegalDocumentType } from "../documents/Documents.types";
import { Color } from "@dotlegal/dotlegal-ui-components";

export interface SelectableItem {
    name: string;
    id: string;
    description?: string;
}

export interface SelectableTransferBasisItem extends SelectableItem {
    showIfCountryCodeIs?: string;
}

export interface SelectableColoredItem {
    name: string;
    id: string;
    color: Color | undefined;
}

export interface SelectableContractItem {
    name: string;
    id: string;
    contractPartyArray: Array<string>;
}

export interface SelectableDocumentItem {
    name: string;
    id: string;
    legalEntityId: string;
    type: DotLegalDocumentType;
    fileDownloadName: string;
    link: string;
    status?: DocumentStatus;
}

export interface SelectableProcessingActivityItem {
    id: string;
    name: string;
    description: string;
}

export interface SelectableSecurityMeasure {
    name: string;
    description: string;
    id: string;
    isGlobal: boolean;
}

export interface SelectableDataCategoryItem {
    name: string;
    id: string;
    description: string;
    classification: DataPrivacyClassification;
}

export interface SelectableLegalUnitsItem {
    name: string;
    id: string;
    levelOfProtection: LevelOfProtectionType;
    isCustomerOwned: boolean;
    isTIAUnnecessary: boolean;
    isTransferBasisUnnecessary: boolean;
    countryCode: string;
}

export interface StepperChild {
    childElement: React.ReactNode;
    graphicType?: StepperGraphicType;
}

export interface StepperGraphicType {
    wave?: backgroundWaves;
    graphic?: "stepOne" | "stepTwo" | "stepFive" | "stepEight" | "systemInfomation" | "systemEdit";
    position?: "left" | "right";
}

export interface CreateProcessingActivityViewModel {
    name: string;
    industryAreas: Array<string>;
    mostPopular: boolean;
    processingActivityStatus: ProcessingActivityStatus;
}

export class CreateProcessingActivityModel {
    name: string;
    industryAreas: Array<ProcessingActivityIndustryAreaEnum>;
    mostPopular: boolean;
    processingActivityStatus: ProcessingActivityStatus;

    constructor(
        name: string,
        industryArea: Array<ProcessingActivityIndustryAreaEnum>,
        mostPopular: boolean,
        processingActivityStatus: ProcessingActivityStatus
    ) {
        this.name = name;
        this.industryAreas = industryArea;
        this.mostPopular = mostPopular;
        this.processingActivityStatus = processingActivityStatus;
    }
}

export class ProcessingActivityModel {
    id: string;
    name: string | undefined;
    description: string | undefined;
    purposes: Array<string> = [];
    dataSubjects: Array<string> = [];
    subjects?: NumberOfDataSubjectsEnum;
    processors?: number;
    securityMeasures: Array<ProcessingActivitySecurityMeasureModel> = [];
    legalEntities: Array<string> = [];
    owner: string | null;
    processingAreas: Array<string> = [];
    systems: Array<ProcessingActivitySystem> = [];
    standardDataCategories: Array<string> = [];
    legalBasesViewModel!: ProcessingActivityLegalBases;
    dataSubjectDataCategories: Array<DataSubjectDataCategory> = [];
    dataProcessors: Array<DataProcessor> = [];
    transfers: Array<Transfer> = [];
    sources: Array<Source> = [];
    jointDataControllers: Array<JointDataController> = [];
    policies: Array<string> = [];
    disableSystems: boolean = false;
    disableDataProcessors: boolean = false;
    disableDisclosures: boolean = false;
    disableSources: boolean = false;
    disableDataControllers: boolean = false;
    disableJointDataControllers: boolean = false;
    hasDataProcessors: boolean = false;
    hasSources: boolean = false;
    hasDisclosures: boolean = false;
    hasDataControllers: boolean = false;
    hasJointDataControllers: boolean = false;
    status: ProcessingActivityStatus = ProcessingActivityStatus.Active;
    sharedResponsibles: Array<string> = [];
    hasMultipleGroupCompanies: boolean = false;
    dataSources: Array<DataSourceEnum> = [];

    constructor() {
        this.owner = "";
        this.id = "";
    }
}

export interface ProcessingActivityUpdateModel {
    id: string;
    name: string | undefined;
    description: string | undefined;
    purposes: Array<string>;
    dataSubjects: Array<string>;
    subjects?: number;
    processors?: number;
    securityMeasures: Array<ProcessingActivitySecurityMeasureModel>;
    owner: string | null;
    processingAreas: Array<string>;
    policies: Array<string>;
    disableSystems: boolean;
    disableDataProcessors: boolean;
    disableDisclosures: boolean;
    disableSources: boolean;
    disableDataControllers: boolean;
    disableJointDataControllers: boolean;
    sharedResponsibles: Array<string>;
    dataSources: Array<DataSourceEnum>;
}

export class DataSubjectDataCategory {
    dataCategoryId: string;
    dataSubjectId: string | null;

    constructor(dataCategoryId: string, dataSubjectId: string | null) {
        this.dataCategoryId = dataCategoryId;
        this.dataSubjectId = dataSubjectId;
    }
}

export class DataProcessor {
    id: string;
    name?: string;
    description?: string;
    systemId?: string;
    dataProcessor?: string | null;
    dataProcessAgreements: Array<string> = [];
    hasDataProcessorAgreement: boolean = false;
    containsNotAccessibleDocuments: boolean = false;
    responsible?: string | null;
    dataCategories: Array<string> = [];
    legalEntities: Array<string> = [];
    subProcessors: Array<SubProcessor> = [];
    isSystemGenerated: boolean;
    isGeneratedFromGlobalSystem: boolean;
    transferBasisId?: string | null;
    readOnly: boolean = false;
    createdAsGroupEntityIsDataProcessor: boolean = false;
    hasDataProcessorAccess: boolean = false;
    hasDataControllerAccess: boolean = false;
    hasTransferImpactAssessment: boolean = false;
    transferImpactAssessmentId?: string | null;
    containsNotAccessibleTia: boolean = false;
    dataStorageLocations: Array<string> = [];
    supportLocations: Array<string> = [];

    constructor() {
        this.isSystemGenerated = false;
        this.isGeneratedFromGlobalSystem = false;
        this.id = Guid.newGuid();
    }
}

export class SubProcessor {
    id?: string;
    processorId?: string | null;
    subProcessHasAgreement: boolean = false;
    transferBasisId?: string | null;
    dataCategories: Array<string> = [];
    hasTransferImpactAssessment: boolean = false;
    transferImpactAssessmentId?: string | null;
    containsNotAccessibleTia: boolean = false;
    dataStorageLocations: Array<string> = [];
    supportLocations: Array<string> = [];
    isLink: boolean = false;
    link?: string;
}

export class Transfer {
    id?: string;
    name?: string;
    description?: string;
    legalUnitId?: string;
    transferAgreements: Array<string> = [];
    hasDataProcessingAgreement: boolean = false;
    containsNotAccessibleDocuments: boolean = false;
    responsible?: string;
    dataCategories: Array<DisclosureDataCategory> = [];
    legalEntities: Array<string> = [];
    transferBasis?: string | null;
    readOnly: boolean = false;
    generatedFromSourceId?: string;
}

export class DisclosureDataCategory {
    id?: string;
    dataCategoryId: string;
    directiveId?: string;
    doubleLegalBasisId?: string;
    nationalLawId?: string;
    additionalDetails?: string;

    constructor(dataCategoryId: string) {
        this.dataCategoryId = dataCategoryId;
    }
}

export class JointDataController {
    id?: string;
    name?: string;
    description?: string;
    dataControllerId?: string;
    hasAgreement: boolean = false;
    containsNotAccessibleDocuments: boolean = false;
    responsible?: string;
    dataCategories: Array<string> = [];
    legalEntities: Array<string> = [];
    readOnly: boolean = false;
    documents: Array<string> = [];
}

export class JointDataControllerUpdateModel {
    name?: string;
    description?: string;
    dataControllerId?: string;
    hasAgreement: boolean = false;
    responsible?: string;
    dataCategories: Array<string> = [];
    legalEntities: Array<string> = [];
    documents: Array<string> = [];
}

export class Source {
    id?: string;
    name?: string;
    description?: string;
    dataControllerId?: string;
    agreementIds: Array<string> = [];
    hasDataProcessingAgreement: boolean = false;
    containsNotAccessibleDocuments: boolean = false;
    responsible?: string;
    dataCategories: Array<string> = [];
    groupEntities: Array<string> = [];
    readOnly: boolean = false;
    generatedFromTransferId?: string;
}

export class DataCategory {
    dataCategoryId: string;
    directiveId?: string;
    doubleLegalBasisId?: string;
    nationalLawId?: string;
    balanceOfInterest?: string;
    deletionPeriodId?: string;
    deletionPeriodReason?: string;

    constructor(dataCategoryId: string) {
        this.dataCategoryId = dataCategoryId;
    }
}

export class ProcessingActivityLegalBases {
    allLegalBases: Array<LegalBasisDataCategoryViewModel> = [];
    purposeSpecificLegalBases: Array<ProcessingActivityPurposeSpecificLegalBasis> = [];

    constructor() {
        this.allLegalBases = [];
        this.purposeSpecificLegalBases = [];
    }
}

export class LegalBasisBaseClass {
    dataCategoryId: string;
    directiveId?: string;
    doubleLegalBasisId?: string;
    nationalLawId?: string;
    balanceOfInterest?: string;
    deletionPeriodId?: string;
    deletionPeriodReason?: string;

    constructor(dataCategoryId: string) {
        this.dataCategoryId = dataCategoryId;
    }
}

export class LegalBasisDataCategoryViewModel extends LegalBasisBaseClass {
    canEdit: boolean | undefined;
}

export class ProcessingActivityPurposeSpecificLegalBasis extends LegalBasisBaseClass {
    purposeId: string | undefined;
}

export interface ProcessingActivitySystem {
    processingActivitySystemId: string;
    systemId: string;
    dataCategoryIds: Array<string>;
    legalEntityIds: Array<string>;
    subDataProcessors: Array<ProcessingActivitySystemSubDataProcessor>;
}

export interface ProcessingActivitySystemSubDataProcessor {
    systemSubDataProcessorId: string;
    dataCategoryIds: Array<string>;
}

export interface ProcessingActivityValidationViewModel {
    id: string;
    isValidated: boolean;
    validatedSteps: Array<StepTypeEnum>;
    isAllStepsValid: boolean;
}

export enum RoleTypeEnum {
    processingActivityMasterData,
    processingActivityGroupEntityDetails,
}

export enum StepTypeEnum {
    masterData,
    dataSubject,
    association,
    dataCategories,
    legalBases,
    systems,
    sharings,
    documentation,
    dataprocessor,
    disclosure,
    sources,
    systemMaster,
    systemAffiliation,
    systemDataProcessor,
    systemSecurity,
    systemDocumentation,
    dataController,
    policies,
    jointDataControllers,
}

export enum ProcessingActivityIndustryAreaEnum {
    Utility = 1,
    MediaAndCommunication = 2,
    Logistics = 3,
    Retail = 4,
    ECommerce = 5,
    Medicinal = 6,
    MemberOrganizations = 7,
    Municipalities = 8,
    Production = 9,
    Finance = 10,
    Business = 11,
}

export enum DataSourceEnum {
    TheDataSubject = 1,
    ExternalDataSource = 2,
}

export enum NumberOfProcessorsEnum {
    OneToTen = 1,
    ElevenToFifty = 2,
    FiftyOneToOneHundred = 3,
    OneHundredAndOneToOneThousand = 4,
    OneThousandAndOneToInfinite = 5,
}

export enum NumberOfDataSubjectsEnum {
    OneToOneHundred = 1,
    OneHundredAndOneToOneThousand = 2,
    OneThousandAndOneToTenThousand = 3,
    TenThousandAndOneToOneHundredThousand = 4,
    OneHundredThousandAndOneToOneMillion = 5,
    OneMillionAndOneToInfinite = 6,
}

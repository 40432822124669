import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { NotificationType, NotificationViewModel } from "../../notification/Notification.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";

export function useNavMenuDataMapping() {
    const url = "/notification/notifications";
    const { planType } = usePlanContext();
    const [upgradeCardDismissed, setUpgradeCardDismissed] = useStateLocalStorage<number | undefined>("upgrade-plan-card-dismissed", undefined);
    let { isLoading, data, refetch } = useQuery(url, () => get<Array<NotificationViewModel>>(url));
    const { data: trialData } = useTrialInformationHook();

    let showNotification = (data && data?.filter((not) => not.isRead === false).length > 0) ?? false;
    let showOnBoardingNotification =
        (data && data?.filter((not) => not.isRead === false && not.notificationType === NotificationType.OnBoardingTask).length > 0) ?? false;

    let showUpgradeBox = trialData !== undefined && !trialData.isFullyUpgraded;

    if (upgradeCardDismissed) {
        showUpgradeBox = getDaysSinceLastShown(upgradeCardDismissed) >= 30 && showUpgradeBox;
    }

    return {
        showNotification,
        notifications: data,
        isLoading,
        showOnBoardingNotification,
        refetchMenuNotifications: refetch,
        hideUpgradeBox,
        showUpgradeBox,
        planType,
    };

    function hideUpgradeBox() {
        setUpgradeCardDismissed(new Date().getTime());
    }

    function getDaysSinceLastShown(savedTimeAsString: number) {
        const diff = new Date().getTime() - new Date(savedTimeAsString).getTime();
        return diff / 1000 / 60 / 60 / 24; // Convert milliseconds to days
    }
}

import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { QuestionCategory, QuestionCategoryQuestions, QuestionType, QuestionViewModel, RiskAssessmentType } from "../../RiskAssessment.types";
import RiskBusinessAreas from "../riskBusinessAreas/RiskBusinessAreas";
import RiskDialogHeader from "../rIskDialogHeader/RIskDIalogHeader";
import RiskBarometer from "../riskBarometer/RiskBarometer";
import { useRiskQuestionDataMapping } from "../../riskAssessmentQuestionDialog/RiskAssessmentQuestionDialog.hooks";
import QuestionsCollapse from "../questionContainer/QuestionsCollapse";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { RiskAssessmentArea, RiskComplianceArea } from "../../riskAssessments/RiskAssessments.types";
import { useParams } from "react-router-dom";

export interface IProcessingActivityRiskPDFProps {}

export default function ProcessingActivityRiskPDF(props: IProcessingActivityRiskPDFProps) {
    const { riskAssessmentAreaString, riskComplianceAreaString, riskAssessmentVersionId } = useParams<{
        riskAssessmentAreaString: string;
        riskComplianceAreaString: string;
        riskAssessmentVersionId: string;
    }>();
    const riskAssessmentArea: RiskAssessmentArea = Number(riskAssessmentAreaString);
    const riskComplianceArea: RiskComplianceArea = Number(riskComplianceAreaString);

    const { translateString } = useTranslation();
    const {
        score,
        averageScore,
        questions,
        name: processingActivityName,
        questionData,
        businessAreas,
        isLoading,
        isSaving,
        saveQuestion,
        id,
        questionCategories,
        getCustomQuestionDisplayText,
        currentRiskAssessmentVersionName,
    } = useRiskQuestionDataMapping(riskAssessmentArea, riskComplianceArea, riskAssessmentVersionId);

    const getQuestionCollapse = (questions: Array<QuestionViewModel>, questionCategories?: QuestionCategoryQuestions) => {
        const questionList =
            questionCategories === undefined ? questions : questions?.filter((q) => questionCategories.questionTypes.some((qt) => qt === q.questionType));

        return (
            <QuestionsCollapse
                questions={questionList}
                riskAssessmentType={RiskAssessmentType.Probability}
                saveQuestion={saveQuestion}
                disableResetButton={isSaving}
                resetQuestionClick={() => {}}
                questionData={questionData}
                id={id}
                isPDF
                securityMeasues={[]}
                getCustomQuestionDisplayValue={getCustomQuestionDisplayText}
                isLocked={false}
            />
        );
    };

    return (
        <div>
            <RiskDialogHeader label={currentRiskAssessmentVersionName ?? ""} name={processingActivityName ?? ""} href="" />

            {(businessAreas?.length ?? 0) > 0 && <RiskBusinessAreas businessAreas={businessAreas!} />}

            <RiskBarometer score={score} averageScore={averageScore} isLoading={isLoading} riskAssessmentType={RiskAssessmentType.Probability} />

            {riskAssessmentArea === RiskAssessmentArea.ProcessingActivities && riskComplianceArea === RiskComplianceArea.GDPR ? (
                questionCategories.map((obj, index) => {
                    return (
                        <React.Fragment key={index}>
                            {questions && questions.filter((q) => obj.questionTypes.some((qt) => qt === q.questionType)).length > 0 && (
                                <div className={"avoid-page-break"}>
                                    <DotLegalHeader headerStyle={"small"}>
                                        {translateString(`questionCategory${QuestionCategory[obj.questionCategory]}`)}
                                    </DotLegalHeader>
                                    {getQuestionCollapse(questions, obj)}
                                </div>
                            )}
                        </React.Fragment>
                    );
                })
            ) : (
                <>
                    {getQuestionCollapse(questions.filter((x) => x.questionType !== QuestionType.CustomQuestion))}

                    {questions.filter((x) => x.questionType === QuestionType.CustomQuestion).length > 0 && (
                        <>
                            <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                                {translateString("questionCategoryCustomQuestion")}
                            </DotLegalHeader>
                            {getQuestionCollapse(questions.filter((x) => x.questionType === QuestionType.CustomQuestion))}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { DataCategorySaveModel } from "../dataCategories/DataCategories.types";
import { IAddEditDataCategoryDialog } from "./AddEditDataCategoryDialog";

export function useAddEditDataCategoryDialogHooks(props: IAddEditDataCategoryDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [errors, setErrors] = useState({ nameError: "", classificationError: "" });
    const [viewModel, setViewModel] = useState<DataCategorySaveModel | undefined>(props.dataCategory);

    function save() {
        return post<string>("/DataCategories/", viewModel);
    }

    function update(id: string) {
        return put<string>(`/DataCategories/${id}/`, viewModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.refetch();
                props.onOkClick(response.value(), viewModel!.name);
                snackbar.show(createElement(Trans, { i18nKey: "dataCategoryCreated", values: { name: viewModel?.name } }));
            } else {
                snackbar.show(translateString("dataCategoryExist"), "warning");
            }
        },
    });

    const createDataCategory = async () => {
        await saveMutation.mutateAsync();
    };

    const editMutation = useMutation(update);
    const updateDataCategory = () => {
        editMutation.mutateAsync(viewModel!.id, {
            onSuccess: (response: Result<string>) => {
                if (response.success()) {
                    snackbar.show(translateString("dataCategoryUpdated", { name: viewModel!.name }));
                    props.onDialogClose();
                    props.refetch();
                } else {
                    snackbar.show(translateString("dataCategoryExist"), "warning");
                }
            },
        });
    };

    return {
        viewModel,
        setViewModel,
        getDataPrivacyClassificationOptions,
        validateDataCategoryName,
        validateDataCategoryType,
        validateDataCategoryForm,
        errors,
        isSaving: editMutation.isLoading || saveMutation.isLoading,
    };

    function getDataPrivacyClassificationOptions() {
        return [
            { name: translateString("none"), id: DataPrivacyClassification.None.toString() },
            { name: translateString("ordinary"), id: DataPrivacyClassification.Ordinary.toString() },
            { name: translateString("sensitive"), id: DataPrivacyClassification.Sensitive.toString() },
        ];
    }

    function validateDataCategoryName(name: string) {
        let tempErrors = { ...errors };

        tempErrors.nameError = isNullOrWhitespace(name) ? translateString("fieldMandatory") : "";

        setErrors(tempErrors);
    }

    function validateDataCategoryType(type: DataPrivacyClassification | undefined) {
        let tempErrors = { ...errors };

        tempErrors.classificationError = type ? "" : translateString("fieldMandatory");

        setErrors(tempErrors);
    }

    function validateDataCategoryForm() {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(viewModel?.name)) {
            tempErrors.nameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (viewModel?.classification === undefined) {
            tempErrors.classificationError = translateString("fieldMandatory");
            formValid = false;
        }

        if (formValid) {
            if (props.isAdd) createDataCategory();
            else updateDataCategory();
        } else {
            setErrors(tempErrors);
        }
    }
}

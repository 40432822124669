import React from "react";
import { useContentStyles } from "./Content.styles";
import DotLegalPlayer from "../../common/components/dotLegalPlayer/DotLegalPlayer";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useContentDataMapping } from "./Content.hooks";
import { Box } from "@mui/system";
import TrialSoonToExpireDialog from "../../plan/trialSoonToExpireDialog/TrialSoonToExpireDialog";
import TrialExpiredDialog from "../../plan/TrialExpiredDialog/TrialExpiredDialog";
import TrialRibbon from "../../trial/trialRibbon/TrialRibbon";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";

export interface IContentProps {
    isExpandedBigScreen: boolean;
    isExpandedSmallScreen: boolean;
    isOnSmallScreen: boolean;
    children: React.ReactElement;
}

const pdfToken = new URLSearchParams(window.location.search).get("pdfToken");

function Content(props: IContentProps) {
    const styles = useContentStyles(props, !!pdfToken);
    const userContext = useUserContext();
    const { videoLink, showIntroVideo, setShowIntroVideo } = useContentDataMapping(userContext.isFirstLogin);
    const { isLoading, data } = useTrialInformationHook();
    return (
        <Box sx={styles.wrapper}>
            <>
                {!isLoading && data?.showTrialDialogs && (
                    <React.Fragment>
                        <TrialSoonToExpireDialog data={data} />
                        <TrialExpiredDialog data={data} />
                        <TrialRibbon data={data} />
                    </React.Fragment>
                )}
            </>

            <Box sx={styles.content}>{props.children}</Box>
            {showIntroVideo && <DotLegalPlayer url={videoLink!} onCloseDialog={() => setShowIntroVideo(false)} />}
        </Box>
    );
}

export default Content;

import { QuestionType } from "./RiskAssessment.types";
import { useTranslation } from "../localization/useTranslation";

export function useQuestionToolTip() {
    const { translateString } = useTranslation();

    const getToolTip = (questionType: QuestionType) => {
        let translation = translateString(`${QuestionType[questionType]}Info`);
        if (translation === QuestionType[questionType].toString()) return "";

        return translation;
    };

    return {
        getQuestionToolTip: getToolTip,
    };
}

import { Box, Divider, Grid, GridSize, IconButton, MenuItem, Popover } from "@mui/material";
import React from "react";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { editIcon, NoteSVG } from "../common/icons";
import { useTranslation } from "../localization/useTranslation";
import { useLegalEntityHook } from "./LegalEntity.hooks";
import { useLegalEntityStyles } from "./LegalEntity.styles";
import { useParams } from "react-router";
import DotLegalDeleteDialogWrapper from "../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import DotLegalNoteDialog from "../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import Skeleton from "@mui/material/Skeleton";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { ChangeLegalEntityStatusDialog } from "./changeLegalEntityStatusDialog/ChangeLegalEntityStatusDialog";
import LegalEntityRiskAssessmentDialog from "./legalEntityRiskAssessmentDialog/LegalEntityRiskAssessmentDialog";
import { TabContent } from "../common/components/dotLegalTabs/DotLegalTabs.types";
import DotLegalTabs from "../common/components/dotLegalTabs/DotLegalTabs";
import LegalEntityDocumentTab from "./legalEntityDocumentTab/LegalEntityDocumentTab";
import SharingsBaseTable from "../processingActivity/components/sharingsTable/SharingsTable";
import { ChangeCustomerOwnedDialog } from "./changeCustomerOwnedDialog/ChangeCustomerOwnedDialog";
import { setFirstLetterToLowerCase } from "../common/stringOperations";
import { LegalEntityType, LevelOfProtectionType } from "./addLegalEntityDialog/AddLegalEntityDialog.types";
import { DotLegalPageHeader, DotLegalTooltip, DotLegalHeading, DotLegalFixedHeightText } from "@dotlegal/dotlegal-ui-components";
import PurpleLockSvg from "../common/svgs/purpleLock.svg?react";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import LegalEntityContactTab from "./legalEntityContactTab/LegalEntityContactTab";
import LegalEntityAuditTab from "./legalEntityAuditTab/LegalEntityAuditTab";
import LegalEntityThirdCountryDialog from "./legalEntityThirdCountryDialog/LegalEntityThirdCountryDialog";
import ComplianceAreaWrapper from "../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import { LegalEntityPageEnum } from "./legalEntities/LegalEntities.types";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../useUrlProvider";
import LegalEntitySystemTab from "./legalEntitySystemTab/LegalEntitySystemTab";
import LegalEntityClassificationTab from "./legalEntityClassificationTab/LegalEntityClassificationTab";
import { useLegalEntityBreadCrumbs } from "./useLegalEntityBreadCrumbs.ts";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "./legalEntityManagement/LegalEntityManagement.types.ts";

export interface ILegalEntityProps {
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntity(props: ILegalEntityProps) {
    let isGroupEntity = props.legalEntityPage === LegalEntityPageEnum.GroupEntity;
    const styles = useLegalEntityStyles();
    const navigate = useNavigate();
    const { translateString } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { permissions, customerName } = useUserContext();
    const { unitsPlan, vendorManagementPlan } = usePlanContext();
    const { getLegalEntityManagementUrl } = useUrlProvider();

    const {
        inProgress,
        legalEntityLoading,
        legalEntityData,
        sharingsLoading,
        sharingsData,
        anchorEl,
        setAnchorEl,
        open,
        popoverId,
        refecthLegalEntity,
        deleteLegalEntity,
        setDeleteShowDialog,
        showDeleteDialog,
        showNoteDialog,
        setShowNoteDialog,
        updateNote,
        noteSaving,
        setShowChangeCompanyStatusDialog,
        showChangeCompanyStatusDialog,
        showLegalEntityRiskQuestionnaireDialog,
        setShowLegalEntityRiskQuestionnaireDialog,
        selectedTab,
        setSelectedTab,
        showChangeCustomerOwnedDialog,
        setShowChangeCustomerOwnedDialog,
        setShowThirdCountryDialog,
        showThirdCountryDialog,
        legalEntityId,
    } = useLegalEntityHook(props);

    const breadCrumbs = useLegalEntityBreadCrumbs(props.legalEntityPage, { name: legalEntityData?.name ?? "", id: id ?? "" });
    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
    };

    const getTabContent = () => {
        const upgradeIcon = (
            <>
                <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                    <Box sx={{ marginLeft: 0.5 }}>
                        <PurpleLockSvg />
                    </Box>
                </DotLegalTooltip>
            </>
        );

        const content: Array<TabContent> = [];
        let index = 0;

        content.push({
            label: translateString("classifications"),
            content: (
                <LegalEntityClassificationTab
                    legalEntityId={id!}
                    legalEntityName={legalEntityData?.name}
                    isGroupEntity={isGroupEntity}
                    legalEntityPage={props.legalEntityPage}
                />
            ),
            index: index,
            disabled: !vendorManagementPlan.legalEntityClassification,
            icon: !vendorManagementPlan.legalEntityClassification ? upgradeIcon : undefined,
            tooltip: !vendorManagementPlan.legalEntityClassification ? translateString("upgradePrivacyPlanToday") : undefined,
        });

        content.push({
            label: translateString("assets"),
            content: <LegalEntitySystemTab legalEntityId={id!} />,
            index: ++index,
        });

        content.push({
            label: translateString("sharings"),
            content: getSharingsTab(),
            index: ++index,
        });

        if (!isGroupEntity) {
            content.push({
                label: translateString("audits"),
                disabled: !vendorManagementPlan.hasAccess,
                content: getAuditsTab(),
                index: ++index,
                icon: !vendorManagementPlan.hasAccess ? upgradeIcon : undefined,
                tooltip: !vendorManagementPlan.hasAccess ? translateString("upgradePrivacyPlanToday") : undefined,
            });
        }

        const isDocumentsDisabled = !unitsPlan.retentionOfDataProcessingAgreement && !vendorManagementPlan.hasAccess;
        content.push({
            label: translateString("documents"),
            content: getDocumentsTab(),
            index: ++index,
            disabled: isDocumentsDisabled,
            icon: isDocumentsDisabled ? upgradeIcon : undefined,
            tooltip: isDocumentsDisabled ? translateString("upgradePrivacyPlanToday") : undefined,
        });

        const isContactsDisabled = !unitsPlan.contacts && !vendorManagementPlan.hasAccess;
        content.push({
            label: translateString("contacts"),
            content: getContactsTab(),
            index: ++index,
            disabled: isContactsDisabled,
            icon: isContactsDisabled ? upgradeIcon : undefined,
            tooltip: isContactsDisabled ? translateString("upgradePrivacyPlanToday") : undefined,
        });

        return content;
    };

    const getSharingsTab = () => {
        return (
            <DotLegalPaper>
                <SharingsBaseTable
                    data={sharingsData}
                    isLoading={sharingsLoading}
                    emptyStateText={isGroupEntity ? translateString("noSharingsOnGroupEntity") : translateString("noSharingsOnLegalEntity")}
                />
            </DotLegalPaper>
        );
    };

    const getDocumentsTab = () => {
        return <LegalEntityDocumentTab isGroupEntity={isGroupEntity} legalEntityId={id} />;
    };

    const getContactsTab = () => {
        return <LegalEntityContactTab isGroupEntity={isGroupEntity} legalEntityId={id} />;
    };

    const getAuditsTab = () => {
        return <LegalEntityAuditTab legalEntityId={id} legalEntityName={legalEntityData?.name} />;
    };

    const getLockedMenuItem = () => {
        return (
            <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                <span>
                    <MenuItem sx={styles.menuItem} onClick={async () => {}} disabled>
                        <Box
                            sx={{
                                cursor: "default",
                                display: "flex",
                                alignContent: "center",
                            }}
                        >
                            <span>{translateString("changeToGroupCompany")}</span>{" "}
                            <Box component={"span"} sx={{ marginLeft: 0.5, height: 0 }}>
                                <PurpleLockSvg />
                            </Box>
                        </Box>
                    </MenuItem>
                </span>
            </DotLegalTooltip>
        );
    };

    const getTypesGrid = () => {
        return (
            <Grid {...gridProps} lg>
                <DotLegalHeading name={translateString("type")}>
                    <DotLegalFixedHeightText
                        isLoading={legalEntityLoading}
                        content={legalEntityData?.types.map((type) => translateString(setFirstLetterToLowerCase(LegalEntityType[type].toString()))).join(", ")}
                    />
                </DotLegalHeading>
            </Grid>
        );
    };

    const getResponsibleGrid = () => {
        return (
            <Grid {...gridProps} lg>
                <DotLegalHeading name={translateString("responsible")}>
                    <DotLegalFixedHeightText isLoading={legalEntityLoading} content={legalEntityData?.responsible} />{" "}
                </DotLegalHeading>
            </Grid>
        );
    };

    const getBusinessAreasGrid = () => {
        return (
            <Grid {...gridProps} lg>
                <DotLegalHeading name={translateString("businessAreas")}>
                    <DotLegalFixedHeightText isLoading={legalEntityLoading} content={legalEntityData?.businessAreas.join(", ")} />
                </DotLegalHeading>
            </Grid>
        );
    };

    const getNotesGrid = () => {
        return (
            <Grid {...gridProps} lg>
                <DotLegalHeading name={translateString("note")}>
                    {legalEntityLoading ? (
                        <Skeleton variant="text" width={"100%"} />
                    ) : (
                        <IconButton onClick={() => setShowNoteDialog(true)}>{NoteSVG}</IconButton>
                    )}
                </DotLegalHeading>
            </Grid>
        );
    };

    const getAuditGrid = () => {
        return (
            <Grid {...gridProps} lg>
                <DotLegalHeading name={translateString("legalEntityAudit")}>
                    <DotLegalFixedHeightText
                        isLoading={legalEntityLoading}
                        content={
                            <React.Fragment>
                                <Box>
                                    {`${translateString("legalEntityAuditType")}: ${legalEntityData?.auditType ? translateString(setFirstLetterToLowerCase(LegalEntityAuditType[legalEntityData.auditType].toString())) : ""}`}
                                </Box>
                                <Box>{`${translateString("legalEntityFrequency")}: ${legalEntityData?.auditFrequency ? translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[legalEntityData.auditFrequency].toString())) : ""}`}</Box>
                            </React.Fragment>
                        }
                    />
                </DotLegalHeading>
            </Grid>
        );
    };

    const getEmptyGrid = () => {
        return <Grid {...gridProps} lg></Grid>;
    };

    const getTopRow = () => {
        let grids: Array<JSX.Element> = [
            <React.Fragment key={0}>
                <Grid {...gridProps} lg>
                    <DotLegalHeading name={translateString("address")}>
                        <DotLegalFixedHeightText
                            isLoading={legalEntityLoading}
                            content={
                                <React.Fragment>
                                    <Box>{legalEntityData?.address}</Box>
                                    <Box>{legalEntityData?.zipCodeAndCity}</Box>
                                    <Box>{legalEntityData?.country}</Box>
                                </React.Fragment>
                            }
                        />
                    </DotLegalHeading>
                </Grid>
                <Grid {...gridProps} lg>
                    <DotLegalHeading name={translateString("companyRegistrationNumber")}>
                        <DotLegalFixedHeightText isLoading={legalEntityLoading} content={legalEntityData?.companyRegistrationNumber} />
                    </DotLegalHeading>
                </Grid>
                <Grid {...gridProps} lg>
                    <DotLegalHeading name={translateString("website")}>
                        <DotLegalFixedHeightText
                            isLoading={legalEntityLoading}
                            content={
                                legalEntityData?.website ? (
                                    <Box
                                        component={"a"}
                                        href={legalEntityData?.website ?? ""}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            color: "primary.main",
                                            fontWeight: 700,
                                            textDecoration: "none",
                                            "&:hover": {
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            },
                                        }}
                                    >
                                        {legalEntityData?.website}
                                    </Box>
                                ) : undefined
                            }
                        />
                    </DotLegalHeading>
                </Grid>
            </React.Fragment>,
        ];

        if (!vendorManagementPlan.hasAccess) {
            if (isGroupEntity) {
                grids.push(getResponsibleGrid());
                grids.push(getBusinessAreasGrid());
                grids.push(getNotesGrid());
            } else {
                grids.push(getTypesGrid());
                grids.push(getResponsibleGrid());
            }
        } else {
            grids.push(
                <React.Fragment>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("complianceAreas")}>
                            <DotLegalFixedHeightText
                                isLoading={legalEntityLoading}
                                content={
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                                        {legalEntityData?.areas.map((complianceArea, index) => {
                                            return <ComplianceAreaWrapper key={"complianceArea" + index} complianceArea={complianceArea} />;
                                        })}
                                    </Box>
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps} lg>
                        <DotLegalHeading name={translateString("certifications")}>
                            <DotLegalFixedHeightText isLoading={legalEntityLoading} content={legalEntityData?.certifications.join(", ")} />
                        </DotLegalHeading>
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <Grid sx={styles.gridRow} container spacing={3}>
                {grids.map((x, i) => {
                    return <React.Fragment key={`topGrid${i}`}>{x}</React.Fragment>;
                })}
            </Grid>
        );
    };

    const getBottomRow = () => {
        let grids = new Array<JSX.Element>();

        if (!vendorManagementPlan.hasAccess) {
            if (!isGroupEntity) {
                grids.push(getBusinessAreasGrid());
                grids.push(getNotesGrid());
                grids.push(getEmptyGrid());
                grids.push(getEmptyGrid());
                grids.push(getEmptyGrid());
            }
        } else {
            if (isGroupEntity) {
                grids.push(getResponsibleGrid());
                grids.push(getBusinessAreasGrid());
                grids.push(getAuditGrid());
                grids.push(getNotesGrid());
                grids.push(getEmptyGrid());
            } else {
                grids.push(getTypesGrid());
                grids.push(getResponsibleGrid());
                grids.push(getBusinessAreasGrid());
                grids.push(getAuditGrid());
                grids.push(getNotesGrid());
            }
        }

        return (
            <Grid sx={styles.gridRow} container spacing={3}>
                {grids.map((x, i) => {
                    return <React.Fragment key={`bottomGrid${i}`}>{x}</React.Fragment>;
                })}
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Box sx={styles.container}>
                <DotLegalPageHeader
                    userContext={{ customerName }}
                    breadCrumbs={breadCrumbs}
                    rightComponent={
                        <Box sx={styles.editButton}>
                            {((isGroupEntity && permissions.canManageGroupEntities) || (!isGroupEntity && permissions.companyPermissions.edit)) && (
                                <React.Fragment>
                                    <IconButton
                                        color={"primary"}
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                        aria-controls={open ? "menu-list-grow" : undefined}
                                        aria-haspopup="true"
                                    >
                                        {editIcon}
                                    </IconButton>
                                    <Popover
                                        id={popoverId}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{ vertical: "center", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    >
                                        <React.Fragment>
                                            {((isGroupEntity && permissions.canManageGroupEntities) ||
                                                (!isGroupEntity && permissions.companyPermissions.edit)) && (
                                                <React.Fragment>
                                                    <MenuItem
                                                        sx={styles.menuItem}
                                                        onClick={() => {
                                                            navigate(getLegalEntityManagementUrl(props.legalEntityPage, id!));
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {translateString("editCompany")}
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={styles.menuItem}
                                                        onClick={() => {
                                                            setShowChangeCompanyStatusDialog(true);
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {legalEntityData?.isActive ? translateString("deactivateCompany") : translateString("activateCompany")}
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}

                                            {((isGroupEntity && permissions.canManageGroupEntities) ||
                                                (!isGroupEntity && permissions.companyPermissions.delete)) && (
                                                <MenuItem sx={styles.menuItem} onClick={() => setDeleteShowDialog(true)}>
                                                    {translateString("deleteCompany")}
                                                </MenuItem>
                                            )}

                                            {permissions.canManageGroupEntities &&
                                                !isGroupEntity &&
                                                (unitsPlan.maxNumberOfGroupEntities ? (
                                                    getLockedMenuItem()
                                                ) : (
                                                    <MenuItem
                                                        sx={styles.menuItem}
                                                        onClick={() => {
                                                            setShowChangeCustomerOwnedDialog(true);
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {translateString("changeToGroupCompany")}
                                                    </MenuItem>
                                                ))}
                                            {legalEntityData?.isThirdCountry &&
                                                ((isGroupEntity && permissions.canManageGroupEntities) ||
                                                    (!isGroupEntity && permissions.companyPermissions.edit)) && (
                                                    <MenuItem
                                                        sx={styles.menuItem}
                                                        onClick={() => {
                                                            setShowThirdCountryDialog(true);
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {translateString("thirdCountrySettings")}
                                                    </MenuItem>
                                                )}
                                        </React.Fragment>
                                    </Popover>
                                </React.Fragment>
                            )}
                        </Box>
                    }
                />

                <DotLegalPaper background={!vendorManagementPlan.hasAccess && isGroupEntity ? "smallPurpleWave" : "purpleWave1"} backgroundCover>
                    {getTopRow()}
                    {!vendorManagementPlan.hasAccess && isGroupEntity ? undefined : (
                        <React.Fragment>
                            <Divider sx={styles.divider} />
                            {getBottomRow()}
                        </React.Fragment>
                    )}
                </DotLegalPaper>

                <Box sx={styles.tableContainer}>
                    <DotLegalTabs
                        fullWidth={false}
                        tabStyle="secondary"
                        selectedTab={Number(selectedTab[0])}
                        setSelectedTab={(val) => setSelectedTab([val.toString()])}
                        content={getTabContent()}
                        noPadding
                    />
                </Box>

                {legalEntityData && (
                    <DotLegalDeleteDialogWrapper
                        onOkClick={async () => {
                            deleteLegalEntity();
                        }}
                        inProgress={inProgress}
                        onDialogClose={() => setDeleteShowDialog(false)}
                        itemForDeletion={legalEntityData.name}
                        open={showDeleteDialog}
                    />
                )}

                {showChangeCompanyStatusDialog && (
                    <ChangeLegalEntityStatusDialog
                        legalEntityId={id}
                        onCloseDialog={() => setShowChangeCompanyStatusDialog(false)}
                        onSaveCorrect={() => refecthLegalEntity()}
                        companyName={legalEntityData!.name}
                        activeStatus={legalEntityData!.isActive}
                    />
                )}

                {showChangeCustomerOwnedDialog && (
                    <ChangeCustomerOwnedDialog
                        isCustomerOwned={legalEntityData!.isCustomerOwned}
                        legalEntityId={id}
                        onCloseDialog={() => setShowChangeCustomerOwnedDialog(false)}
                        companyName={legalEntityData!.name}
                        onUpdate={refecthLegalEntity}
                    />
                )}

                {showThirdCountryDialog && (
                    <LegalEntityThirdCountryDialog
                        thirdCountrySettings={{
                            country: legalEntityData?.country ?? "",
                            countryCode: legalEntityData?.countryCode ?? "",
                            isTIAUnnecessary: legalEntityData?.isTIAUnnecessary ?? false,
                            isTransferBasisUnnecessary: legalEntityData?.isTransferBasisUnnecessary ?? false,
                            levelOfProtection: legalEntityData!.levelOfProtection!,
                            addDataPrivacyFrameworkToSharings: false,
                            showCheckBoxIfLevelOfProtectionIsDataPrivacyFramework:
                                legalEntityData?.countryCode === "US" && legalEntityData.levelOfProtection !== LevelOfProtectionType.DataPrivacyFramework,
                        }}
                        legalEntityId={legalEntityId!}
                        onDialogClose={() => setShowThirdCountryDialog(false)}
                        onSaved={() => {
                            refecthLegalEntity();
                            setShowThirdCountryDialog(false);
                        }}
                    />
                )}
            </Box>

            <DotLegalNoteDialog
                showDialog={showNoteDialog}
                noteText={legalEntityData?.note}
                onDialogClose={() => setShowNoteDialog(false)}
                onOkClick={(text) => updateNote(text)}
                isSaving={noteSaving}
            />

            <LegalEntityRiskAssessmentDialog
                show={showLegalEntityRiskQuestionnaireDialog}
                setShowDialog={() => setShowLegalEntityRiskQuestionnaireDialog(false)}
                riskAssessment={legalEntityData?.riskAssessment ?? null}
                refetchEntity={() => {
                    setShowLegalEntityRiskQuestionnaireDialog(false);
                    refecthLegalEntity();
                }}
            />
        </React.Fragment>
    );
}

export default LegalEntity;

import React from "react";
import { reactPlugin } from "./AppInsights";
import privacyTheme from "./privacyTheme";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import QueryClientProviderPrivacy from "./QueryClientProviderPrivacy";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BreadCrumbsType, DotLegalBreadCrumbProvider, DotLegalSnackBarProvider } from "@dotlegal/dotlegal-ui-components";
import VersionProvider from "./VersionProvider";

export interface IProvidersProps {
    children: any;
    breadCrumbsCallback?: (breadCrumbs: BreadCrumbsType) => void;
}

function Providers(props: IProvidersProps) {
    return (
        <StyledEngineProvider injectFirst>
            <VersionProvider>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <ThemeProvider theme={privacyTheme}>
                        <DotLegalBreadCrumbProvider breadCrumbsCallback={props.breadCrumbsCallback}>
                            <CssBaseline />
                            <QueryClientProviderPrivacy>
                                <DotLegalSnackBarProvider>{props.children}</DotLegalSnackBarProvider>
                            </QueryClientProviderPrivacy>
                        </DotLegalBreadCrumbProvider>
                    </ThemeProvider>
                </AppInsightsContext.Provider>
            </VersionProvider>
        </StyledEngineProvider>
    );
}

export default Providers;

import { DotLegalHeader, DotLegalMultiSelect, DotLegalTableWithControls, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import { LegalEntityAuditGroupAuditModel, LegalEntityAuditGroupAuditTableModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types";
import SystemLinks from "../../../system/SystemLinks";
import QuestionnaireStatus from "../../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import QuestionnaireReportOverflowButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import QuestionnaireReportButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import { useManagementReportInProgressAudits } from "./ManagementReportInProgressAudits.hooks";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useUrlProvider } from "../../../useUrlProvider";
import { IDownloadAuditReportModel } from "../../DowloadLegalEntityAuditReport.hooks";

export interface ManagementReportInProgressAuditsProps {
    isLoading: boolean;
    audits: Array<LegalEntityAuditGroupAuditModel>;
}

function ManagementReportInProgressAudits(props: ManagementReportInProgressAuditsProps) {
    const { translateString, translateLegalEntityAuditStatus, translateDate } = useTranslation();
    const {
        rows,
        isDownloadingPdf,
        statusFilterOptions,
        searchedStatus,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        setSearchedStatus,
        searchedRemindersSent,
        setSearchedRemindersSent,
        remindersSentFilterOptions,
    } = useManagementReportInProgressAudits(props);
    const { getLegalEntityUrl } = useUrlProvider();

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupAuditTableModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntities"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "contact", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "status", text: translateString("status"), align: "left", showOnMobile: true });
        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });
        headers.push({ property: "report", text: translateString("legalEntityAuditReport"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        // Hidden headers - allowing search
        headers.push({ property: "assetsString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "contactTypesString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "auditStatusString", text: "", align: "left", showOnMobile: true, hideHeader: true });

        return headers;
    }

    function getSearchFields() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3,
        };

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedStatus}
                        chosenOptions={searchedStatus}
                        label={translateString("status")}
                        placeholder={translateString("status")}
                        isLoading={props.isLoading}
                        options={statusFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRemindersSent}
                        chosenOptions={searchedRemindersSent}
                        label={translateString("headerReminderSent")}
                        placeholder={translateString("headerReminderSent")}
                        isLoading={props.isLoading}
                        options={remindersSentFilterOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    function getReportButtons(row: LegalEntityAuditGroupAuditTableModel) {
        const downloadModel: IDownloadAuditReportModel = {
            legalEntityAuditId: row.id,
            deadline: row.deadline,
            legalEntityName: row.legalEntityName,
            questionnaireName: row.questionnaireName,
            questionnaireResponseId: row.questionnaireResponseId,
            responseExternalId: row.responseExternalId,
            sender: row.sender,
            hasRecommendations: row.hasRecommendations,
        };
        const reportUrl = getReportPdfUrl(row.questionnaireResponseId, row.deadline, row.sender, row.legalEntityName, false, row.hasRecommendations);
        const showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.closed;
        const downloadingPdf = isDownloadingPdf === row.id;

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row.scoreEnabled, downloadModel)}
                    showButtons={showButtons}
                    isDownloadingPdf={downloadingPdf}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={downloadingPdf}
                onDownloadReportPdf={() => onDownloadReportAsPdf(downloadModel, true, row.hasRecommendations)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(isScoreEnabled: boolean, row: IDownloadAuditReportModel) {
        if (isScoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(row, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(row, true, false),
                },
            ];
        } else {
            return [
                { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, false, true) },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(row, false, false),
                },
            ];
        }
    }

    return rows.length === 0 ? undefined : (
        <Box sx={{ marginTop: 3 }} className={"avoid-page-break"}>
            <DotLegalHeader headerStyle={"medium"}>
                {translateLegalEntityAuditStatus(LegalEntityAuditStatus.InProgress.toString()).toUpperCase()}{" "}
                <Box sx={(theme) => ({ color: theme.palette.grey[500] })} component="span">
                    ({props.audits.length})
                </Box>
            </DotLegalHeader>
            <DotLegalTableWithControls
                getUserSpecificPageLength={() => 1000}
                extraControls={getSearchFields()}
                labelRowsPerPage={""}
                labelSearch={translateString("search")}
                noOptionsLabel={translateString("noOptions")}
                headers={getHeaders()}
                defaultOrderBy={"legalEntityName"}
                defaultOrder={"asc"}
                paginationLabelOf={""}
                hidePagination
                hideRowsPerPage
                data={mapToTableData(rows)}
                emptyText={translateString("noData")}
                renderRow={(row) => {
                    return (
                        <TableRow key={row.legalEntityId}>
                            <TableCell>
                                <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                    <DotLegalLink linkColor={"primary"} to={`${getLegalEntityUrl(row.legalEntityId)}?tab=3`}>
                                        {row.legalEntityName}
                                    </DotLegalLink>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <SystemLinks systems={row.assets} />
                            </TableCell>
                            <TableCell>
                                <DotLegalTooltip text={row.contactEmail}>
                                    <Box>
                                        {row.contact} {row.contactTypes.length > 0 && <>({row.contactTypesString})</>}
                                    </Box>
                                </DotLegalTooltip>
                            </TableCell>
                            <TableCell>
                                <QuestionnaireStatus
                                    showQuestionsStatistics={row.showQuestionsStatistics}
                                    numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                                    totalNumberOfQuestions={row.totalNumberOfQuestions}
                                    status={row.status}
                                    lastestRecipientUpdate={row.lastestResponseChange}
                                />
                            </TableCell>
                            <TableCell>{row.reminderSentString}</TableCell>
                            <TableCell>{getReportButtons(row)}</TableCell>
                            <TableCell>{row.note}</TableCell>
                        </TableRow>
                    );
                }}
            />
        </Box>
    );

    function mapToTableData(audits: Array<LegalEntityAuditGroupAuditModel> | undefined): Array<LegalEntityAuditGroupAuditTableModel> {
        if (audits) {
            return audits.map((x) => {
                return {
                    ...x,
                    assetsString: x.assets.flatMap((x) => x.name).join(", "),
                    contactTypesString: x.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", "),
                    auditStatusString: translateLegalEntityAuditStatus(x.status.toString()),
                    scoreString: getQuestionnaireResponseScore(
                        x.status === LegalEntityAuditStatus.Submitted,
                        x.scoreEnabled,
                        x.score,
                        translateString("scoreNotEnabled")
                    ),
                    legalEntityTypeString: x.legalEntityTypes
                        ?.map((type) => translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())))
                        .join(", "),
                    reminderSentString: x.reminderSent ? translateDate(x.reminderSent) : "",
                };
            });
        }

        return [];
    }
}

export default ManagementReportInProgressAudits;

import { useMutation, useQuery } from "react-query";
import { LegalEntityClassificationApiRow, LegalEntityClassificationApiModel, LegalEntityClassificationViewRow } from "./LegalEntityClassificationTab.types";
import { get, put } from "../../common/api/apiShared";
import { ILegalEntityClassificationTab } from "./LegalEntityClassificationTab";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { getSettings } from "../../common/settingsProvider";
import { createPdfUrl } from "../../common/pdfUrlHelper";
import { downloadFile } from "../../common/api/downloadFile";
import { Trans } from "react-i18next";
import { getQuestionnaireResponseScore } from "../../common/questionnaireResponseScore.ts";
import { setFirstLetterToLowerCase } from "../../common/stringOperations.ts";
import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";

export default function useLegalEntityClassificationTabHooks(props: ILegalEntityClassificationTab) {
    const snackbar = useDotLegalSnackbar();
    const { language } = useUserContext();
    const { translateString } = useTranslation();
    const { getClassificationQuestionnaireResponseReport } = useUrlProvider();
    const url = `/LegalEntityClassification/${props.legalEntityId}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntityClassificationApiModel>(url));

    const [showEvaluateDialog, setShowEvaluateDialog] = useState(false);
    const [showClassificationDialog, setShowClassificationDialog] = useState(false);
    const [selectedSystems, setSelectedSystems] = useState<Array<string>>([]);
    const [selectedClassification, setSelectedClassification] = useState<LegalEntityClassificationApiRow | undefined>(undefined);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState("");

    let classifications: Array<LegalEntityClassificationApiRow> = [];
    let hasData = isLoading ? true : data!.rows.length > 0;

    if (data) {
        classifications = getFilteredRows(data.rows);
        classifications = classifications.map((c) => {
            return {
                ...c,
                submittedBy: c.submittedBy === "deleted" ? translateString("deletedUser") : c.submittedBy,
            };
        });
    }

    function onClassificationCreated() {
        refetch();
        setShowClassificationDialog(false);
    }

    const onClassificationDeleted = () => {
        refetch();
        setShowDeleteDialog(false);
    };

    function onClassificationEvaluated() {
        refetch();
        setSelectedClassification(undefined);
        setShowEvaluateDialog(false);
    }

    const saveNoteMutation = useMutation(submitSaveNote);

    function submitSaveNote(note: string) {
        return put(`/legalentityclassification/${selectedClassification!.id}/note`, {
            note: note,
        });
    }

    const saveNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: () => {
                snackbar.show(translateString("noteUpdated"));
                setShowNoteDialog(false);
                refetch();
            },
        });
    };

    function getReportPdfUrl(responseId: string, pdf: boolean, showRecommendations: boolean) {
        return getClassificationQuestionnaireResponseReport(responseId, props.legalEntityName!, pdf, showRecommendations);
    }

    const onDownloadReportAsPdf = async (viewModel: LegalEntityClassificationApiRow, showRecommendations: boolean) => {
        setIsDownloadingPdf(viewModel.id);
        let url = getReportPdfUrl(viewModel.questionnaireResponseId, true, showRecommendations);
        url = getSettings().mainSiteBaseUrl + url;
        url = createPdfUrl(url, language);
        const fileName = `${viewModel.questionnaireName}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingPdf("");
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    function getScore(row: LegalEntityClassificationApiRow) {
        return getQuestionnaireResponseScore(row.submitted !== undefined, row.scoreEnabled, row.score, translateString("scoreNotEnabled"));
    }

    const classificationViewRows: Array<LegalEntityClassificationViewRow> = classifications.map((c) => {
        return {
            ...c,
            systemName: c.system?.name,
            scoreName: getScore(c),
            evaluationName: c.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[c.evaluationStatus].toString())) : "",
        };
    });

    return {
        isLoading,
        hasData,
        showClassificationDialog,
        setShowClassificationDialog,
        selectedSystems,
        setSelectedSystems,
        classifications: classificationViewRows,
        selectableSystems: data?.selectableSystems,
        onClassificationCreated,
        selectedClassification,
        setSelectedClassification,
        showNoteDialog,
        setShowNoteDialog,
        saveNote,
        isSavingNote: saveNoteMutation.isLoading,
        showDeleteDialog,
        setShowDeleteDialog,
        onClassificationDeleted,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        showEvaluateDialog,
        setShowEvaluateDialog,
        onClassificationEvaluated,
        showReopenDialog,
        setShowReopenDialog,
    };

    function getFilteredRows(rows: Array<LegalEntityClassificationApiRow>) {
        let tempData = [...rows];

        if (selectedSystems.length > 0) {
            tempData = tempData.filter((row) => row.system && selectedSystems.includes(row.system.id));
        }

        return tempData;
    }
}

import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { convertEnumToStringOrNull } from "../../common/enumOperations";
import { useTranslation } from "../../localization/useTranslation";
import { PolicySaveModel } from "../policies/Policies.types";
import { usePolicyDialogDataMapping } from "./PolicyDialog.hooks";
import { UserSelectableItem } from "../../user/User.types";

export interface IPolicyDialog {
    onDialogClose: () => void;
    onSave: () => void;
    selectedPolicy: PolicySaveModel;
    selectableResponsibles: (responsible: string | null | undefined) => UserSelectableItem[] | undefined;
    isResponsiblesLoading: boolean;
}

function PolicyDialog(props: IPolicyDialog) {
    const { translateString } = useTranslation();
    const { typeOptions, saveModel, setSaveModel, savePolicy, validation, isSaving, isEdit } = usePolicyDialogDataMapping(props);

    validation?.check(saveModel);
    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            buttonOkText={isEdit ? translateString("save") : translateString("create")}
            header={isEdit ? translateString("editPolicy") : translateString("createPolicy")}
            onOkClick={async () => {
                savePolicy();
            }}
            onDialogClose={props.onDialogClose}
            open
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <React.Fragment>
                <DotLegalTextField
                    debounce={false}
                    size="small"
                    label={translateString("name")}
                    value={saveModel?.name ?? ""}
                    errorText={validation?.getErrorsForDisplay("name")}
                    onChange={(name) => {
                        let tempViewModel = { ...saveModel };
                        tempViewModel.name = name;
                        setSaveModel(tempViewModel);
                    }}
                />
                <DotLegalSelect
                    label={translateString("type")}
                    options={typeOptions}
                    placeholder={""}
                    disableClearable
                    errorText={validation?.getErrorsForDisplay("type")}
                    selectedItem={convertEnumToStringOrNull(saveModel.type)}
                    onChange={(type) => {
                        let tempViewModel = { ...saveModel };
                        tempViewModel.type = Number(type);
                        setSaveModel(tempViewModel);
                    }}
                    noOptionsLabel={translateString("noOptions")}
                />
                <DotLegalSelect
                    options={props.selectableResponsibles(saveModel.responsibleId)}
                    isLoading={props.isResponsiblesLoading}
                    selectedItem={saveModel.responsibleId}
                    placeholder={""}
                    label={translateString("responsible")}
                    onChange={(responsible) => {
                        setSaveModel({ ...saveModel, responsibleId: responsible });
                    }}
                    noOptionsLabel={translateString("noOptions")}
                />
            </React.Fragment>
        </DotLegalDialog>
    );
}

export default PolicyDialog;

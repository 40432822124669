import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { useCallback } from "react";
import { DotLegalBreadCrumbModel } from "@dotlegal/dotlegal-ui-components";

export function useMasterDataBreadCrumbProvider() {
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();

    const getBreadCrumbList = useCallback(() => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        breadCrumbs.push({ name: translateString("settings"), link: urlProvider.getSettingsUrl() });
        breadCrumbs.push({ name: translateString("masterData"), link: urlProvider.getMasterDataUrl() });

        return breadCrumbs;
    }, [translateString, urlProvider]);

    const getBreadCrumbs = (pageName: string) => {
        let breadCrumbs = getBreadCrumbList();

        breadCrumbs.push({ name: pageName });

        return breadCrumbs;
    };

    const getLegislationsBreadCrump = (pageName: string) => {
        let breadCrumbs = getBreadCrumbList();

        breadCrumbs.push({ name: translateString("nationalLaws"), link: urlProvider.getNationalLawsUrl() });
        breadCrumbs.push({ name: pageName });

        return breadCrumbs;
    };
    return {
        getBreadCrumbs,
        getLegislationsBreadCrump,
    };
}

import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { deleteHttp, get, post, put } from "../common/api/apiShared";
import { ProcessingActivityModel, ProcessingActivityUpdateModel, ProcessingActivityValidationViewModel, StepTypeEnum } from "./ProcessingActivity.types";
import { useProcessingActivitySteps } from "./hooks/useProcessingActivitySteps";
import { useTranslation } from "../localization/useTranslation";
import { useValidateMasterDataStep, useValidationDataSubjectStep } from "./masterDataStep/MasterDataStep.hooks";
import { useValidateDataSharingStep } from "./sharingOfDataStep/SharingOfDataStep.hooks";
import { useValidateDataCategoriesStep } from "./DataCategoriesStep/DataCategoriesStep.hooks";
import { useValidateLegalBasesStep } from "./legalBasesStep/LegalBasesStep.hooks";
import { useSelectableDataCategoriesQuery } from "./hooks/useSelectableDataCategoriesQuery";
import { DataPrivacyClassification } from "./DataCategoriesStep/DataCategoriesStep.types";
import { useValidateSystemStep } from "./systemsStep/SystemStep.hooks";
import { useOptimisticUpdate } from "../common/hooks/useOptimisticUpdate";
import { useValidateDataProcessorsStep } from "./sharingOfDataStep/dataProcessorsStep/DataProcessorsStep.hooks";
import { useValidateTransfersStep as useValidateDisclosuresStep } from "./sharingOfDataStep/transfersStep/TransfersStep.hooks";
import { useValidateSourceStep } from "./sharingOfDataStep/sourceStep/SourceStep.hooks";
import { StepModel } from "../common/components/dotLegalStepper/DotLegalCoreStepper.types";
import { useResponsibleQuery } from "../user/hooks/useResponsibleQuery";
import { useValidationAssociationStep } from "./associationStep/AssociationStep.hooks";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useSelectableLegalEntityQuery } from "../legalEntity/useSelectableLegalEntityQuery";
import { useLegalBasesQuery } from "./hooks/useLegalBasesQuery";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { HelpTextViewModel } from "../masterData/helpText/helpTexts/HelpTexts.types";
import { useValidateJointDataControllerStep } from "./sharingOfDataStep/jointDataControllerStep/JointDataControllerStep.hooks";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import useCountryHook from "../common/hooks/useCountryList";
import { PlanType } from "../auth/planProvider/PlanProvider.types";
import { ProcessingActivityStatus } from "./processingActivities/ProcessingActivities.types";

export function useProcessingActivityHook(processingActivityValidation: boolean) {
    const { id } = useParams<{ id: string }>();
    const optimisticUpdate = useOptimisticUpdate();
    const snackbar = useDotLegalSnackbar();
    const { permissions } = useUserContext();
    const { processingActivityAndRecordsPlan } = usePlanContext();

    const [expanded, setExpanded] = useState(true);
    const [completeValidationDialog, setCompleteValidationDialog] = useState(false);
    const [showProcessingActivityReportDialog, setShowProcessingActivityReportDialog] = useState(false);

    const { translateString } = useTranslation();
    const validateMasterDataStep = useValidateMasterDataStep(permissions.processingActivityMasterDataPermissions?.hasPurposePermission);
    const validateSubjectStep = useValidationDataSubjectStep();
    const validateAssociationStep = useValidationAssociationStep(
        permissions.processingActivityAssociationPermissions?.hasGroupEntitiesPermission,
        permissions.processingActivityAssociationPermissions?.hasOwnershipPermission
    );
    const validateDataSharingStep = useValidateDataSharingStep(permissions, processingActivityAndRecordsPlan.registerSelfAsDataProcessor);
    const validateDataCategoriesStep = useValidateDataCategoriesStep();
    const validateLegalBasesStep = useValidateLegalBasesStep(
        permissions.processingActivityLegalBasesPermissions?.hasDeletionPeriodPermission,
        permissions.processingActivityLegalBasesPermissions?.hasLegalBasisPermission
    );
    const validateSystemStep = useValidateSystemStep();

    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    let dataCategoriesQuery = useSelectableDataCategoriesQuery(permissions.processingActivityDisclosuresPermissions?.hasBasisOfDisclosurePermission === true);
    let responsibleQuery = useResponsibleQuery(
        (permissions.processingActivityAssociationPermissions?.permissions.read ||
            permissions.processingActivityDisclosuresPermissions?.hasResponsiblePermission ||
            permissions.processingActivityDataProcessorsPermissions?.hasResponsiblePermission ||
            permissions.processingActivityDataControllersPermissions?.hasResponsiblePermission ||
            permissions.processingActivitySourcesPermissions?.hasResponsiblePermission) === true
    );

    const { thirdCountries } = useCountryHook();
    const legalEntityData = legalEntitiesQuery.data;

    const { legalObligationId } = useLegalBasesQuery();
    const classificationDataCategoryIds = dataCategoriesQuery.allDataCategories
        ?.filter((d) => d.classification === DataPrivacyClassification.Sensitive)
        .map((d) => d.id);

    const url = "/helptexts";
    const helpTextQuery = useQuery(url, () => get<Array<HelpTextViewModel>>(url));
    let helpTexts = helpTextQuery.data ?? [];

    let queryKey = "processingActivity" + id;
    let query = useQuery(queryKey, () => get<ProcessingActivityModel>("/processingactivity/" + id));

    let validationData: ProcessingActivityValidationViewModel | undefined = undefined;
    let validationQuery = useQuery(queryKey + "validation", () => get<ProcessingActivityValidationViewModel>("/ProcessingActivityValidations/" + id), {
        enabled: processingActivityValidation,
    });

    if (processingActivityValidation && validationQuery.data) {
        validationData = {
            id: validationQuery.data.id,
            isValidated: validationQuery.data.isValidated,
            validatedSteps: validationQuery.data.validatedSteps,
            isAllStepsValid: validationQuery.data.isAllStepsValid,
        };
    }

    const defaultSteps = getDefaultSteps();
    const steps = useProcessingActivitySteps(query.data, defaultSteps, "step", validationData, helpTexts);

    const validateDataProcessorsStep = useValidateDataProcessorsStep(permissions, processingActivityAndRecordsPlan.registerSelfAsDataProcessor);
    const validateDisclosuresStep = useValidateDisclosuresStep(permissions.processingActivityDisclosuresPermissions);
    const validateSourceStep = useValidateSourceStep(permissions.processingActivitySourcesPermissions);
    const validateJointDataControllerStep = useValidateJointDataControllerStep(permissions.processingActivityJointDataControllerPermissions);

    const defaultDataSharingSteps = getSharingSteps();

    const subSteps = useProcessingActivitySteps(query.data, defaultDataSharingSteps, "substep", validationData);

    const addLegalEntitiesMutation = useMutation(addLegalEntities);

    const removeLegalEntitiesMutation = useMutation(removeLegalEntities);

    const validateStepMutation = useMutation(updateValidation);
    const completeProcessingActivityValidationMutation = useMutation(completeProcessingActivityValidation);

    async function onProcessingActivityChange(processingActivity: ProcessingActivityModel) {
        // THE 2021-08-11 Her bruges ikke en mutation fra react-query, da sådan en trigger en re-render og dermed overskriver
        // fx input text i en selectbox ved auto save
        await optimisticUpdate.updateWithProcessingActivityResponse(processingActivity, saveProcessingActivity, processingActivity);
    }

    async function onSaveProcessingActivity() {
        await saveProcessingActivity(query.data!);
    }

    async function onLegalEntityChange(legalEntities: Array<string>) {
        const newProcessingActivityModel = { ...query.data! };

        const added = legalEntities.filter((l) => !newProcessingActivityModel.legalEntities.includes(l));
        const removed = newProcessingActivityModel.legalEntities.filter((val) => !legalEntities.includes(val));

        newProcessingActivityModel.legalEntities = legalEntities;

        if (added.length > 0) {
            await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addLegalEntitiesMutation.mutateAsync, {
                processingActivityId: query.data!.id,
                legalEntities: added,
            });
        }
        for (const removedEntry of removed) {
            // Must not be awaited. We want to add all requests to the queue before executing any of the requests
            optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeLegalEntitiesMutation.mutateAsync, {
                processingActivityId: query.data!.id,
                legalEntityId: removedEntry,
            });
        }
    }

    function changeStep(step: StepTypeEnum) {
        steps.changeStep(step);
    }

    async function validateStep(step: StepTypeEnum, isValidated: boolean, currentStepIndex: number) {
        const newValidationViewModel = { ...validationQuery.data! };

        if (isValidated) {
            newValidationViewModel.validatedSteps.push(step);

            if (newValidationViewModel.validatedSteps.length === steps.getSteps().length) newValidationViewModel.isValidated = true;
        } else {
            const removed = newValidationViewModel.validatedSteps.filter((val) => val !== step);
            newValidationViewModel.validatedSteps = removed;

            if (newValidationViewModel.validatedSteps.length === steps.getSteps().length) newValidationViewModel.isValidated = false;
        }

        var allStepsValid = await optimisticUpdate.updateWithProcessingActivityValidationReponse(newValidationViewModel, id, validateStepMutation.mutateAsync, {
            step: step,
            processingActvityId: id,
            isValidated: isValidated,
        });

        await validationQuery.refetch();

        setCompleteValidationDialog(allStepsValid);

        if (!allStepsValid) {
            const tempsteps = steps.getSteps();

            if (isValidated && currentStepIndex !== tempsteps.length - 1) {
                changeStep(tempsteps[currentStepIndex + 1].stepType);
            }
        }
    }

    async function completeValidation(processingActivityName: string) {
        await completeProcessingActivityValidationMutation.mutateAsync(id, {
            onSuccess: () => {
                snackbar.show(createElement(Trans, { i18nKey: "processingActivityValidated", values: { name: processingActivityName } }));
            },
        });
    }

    const handleCollapseClick = () => {
        if (expanded) setExpanded(false);
        else setExpanded(true);
    };

    function getDefaultSteps() {
        const defaultSteps: Array<StepModel> = [];

        if (permissions.processingActivityMasterDataPermissions) {
            defaultSteps.push(new StepModel(translateString("processingactivtiyHeader"), StepTypeEnum.masterData, validateMasterDataStep));
        }

        if (permissions.processingActivityDataSubjectPermissions) {
            defaultSteps.push(new StepModel(translateString("dataSubjectHeader"), StepTypeEnum.dataSubject, validateSubjectStep));
        }

        if (permissions.processingActivityAssociationPermissions && permissions.processingActivityAssociationPermissions.hasAnySubPermissionAccess) {
            defaultSteps.push(
                new StepModel(translateString("affiliationHeader"), StepTypeEnum.association, (model) =>
                    validateAssociationStep(model, responsibleQuery.userData)
                )
            );
        }

        if (permissions.processingActivityDataCategoryPermissions) {
            defaultSteps.push(new StepModel(translateString("dataCategoryHeader"), StepTypeEnum.dataCategories, validateDataCategoriesStep));
        }

        if (
            permissions.processingActivityLegalBasesPermissions &&
            (permissions.processingActivityLegalBasesPermissions.hasDeletionPeriodPermission ||
                permissions.processingActivityLegalBasesPermissions.hasLegalBasisPermission)
        ) {
            defaultSteps.push(
                new StepModel(translateString("legalBasisHeader"), StepTypeEnum.legalBases, (model) =>
                    validateLegalBasesStep(model, classificationDataCategoryIds, legalObligationId)
                )
            );
        }

        if (permissions.processingActivitySystemPermissions)
            defaultSteps.push(new StepModel(translateString("systemsHeader"), StepTypeEnum.systems, validateSystemStep));

        if (
            permissions.processingActivityDataProcessorsPermissions ||
            permissions.processingActivityDisclosuresPermissions ||
            permissions.processingActivitySourcesPermissions ||
            permissions.processingActivityDataControllersPermissions
        )
            defaultSteps.push(
                new StepModel(translateString("sharingOfDataStepHeader"), StepTypeEnum.sharings, (model) =>
                    validateDataSharingStep(model, legalEntityData, thirdCountries, classificationDataCategoryIds, responsibleQuery.userData, legalObligationId)
                )
            );

        if (permissions.processingActivityPolicyPermissions) {
            defaultSteps.push(new StepModel(translateString("policies"), StepTypeEnum.policies, undefined));
        }

        return defaultSteps;
    }

    function getSharingSteps() {
        let steps: Array<StepModel> = [];

        if (permissions.processingActivityDataProcessorsPermissions) {
            steps.push(
                new StepModel(translateString("dataProcessorsStep"), StepTypeEnum.dataprocessor, (model) =>
                    validateDataProcessorsStep(model, legalEntityData, thirdCountries, false, responsibleQuery.userData)
                )
            );
        }

        if (permissions.processingActivityDisclosuresPermissions) {
            steps.push(
                new StepModel(translateString("disclosuresStep"), StepTypeEnum.disclosure, (model) =>
                    validateDisclosuresStep(
                        model,
                        legalEntityData,
                        dataCategoriesQuery.allDataCategories?.filter((d) => d.classification === DataPrivacyClassification.Sensitive).map((d) => d.id),
                        responsibleQuery.userData,
                        legalObligationId
                    )
                )
            );
        }

        if (permissions.processingActivityJointDataControllerPermissions) {
            steps.push(
                new StepModel(translateString("jointDataControllersStep"), StepTypeEnum.jointDataControllers, (model) =>
                    validateJointDataControllerStep(model, responsibleQuery.userData)
                )
            );
        }

        if (permissions.processingActivitySourcesPermissions) {
            steps.push(new StepModel(translateString("sourcesStep"), StepTypeEnum.sources, (model) => validateSourceStep(model, responsibleQuery.userData)));
        }

        if (permissions.processingActivityDataControllersPermissions) {
            steps.push(
                new StepModel(translateString("dataControllersStep"), StepTypeEnum.dataController, (model) =>
                    validateDataProcessorsStep(model, legalEntityData, thirdCountries, true, responsibleQuery.userData)
                )
            );
        }

        return steps;
    }

    return {
        isLoading: query.isLoading || validationQuery.isLoading,
        processingActivity: query.data!,
        setProcessingActivity: onProcessingActivityChange,
        setProcessingActivityWithoutSaving: async (processingActivity: ProcessingActivityModel) =>
            await optimisticUpdate.setQueryData(queryKey, processingActivity),
        onSave: onSaveProcessingActivity,
        onLegalEntityChange,
        getSteps: steps.getSteps,
        getActiveStep: steps.getActiveStep,
        changeStep: changeStep,
        validationErrors: steps.validationErrors,
        subSteps,
        expanded,
        completeValidationDialog,
        setCompleteValidationDialog,
        validateStep,
        completeValidation,
        completeInProgress: completeProcessingActivityValidationMutation.isLoading,
        handleCollapseClick,
        isAllStepsValid: validationData?.isAllStepsValid,
        showProcessingActivityReportDialog,
        setShowProcessingActivityReportDialog,
    };
}

async function addLegalEntities(data: { processingActivityId: string; legalEntities: Array<string> }) {
    return await post<ProcessingActivityModel>("/processingactivity/" + data.processingActivityId + "/legalentity", { legalEntityIds: data.legalEntities });
}

async function removeLegalEntities(data: { processingActivityId: string; legalEntityId: string }) {
    return await deleteHttp<ProcessingActivityModel>("/processingactivity/" + data.processingActivityId + "/legalentity/" + data.legalEntityId);
}

async function updateValidation(data: { step: StepTypeEnum; processingActvityId: string | undefined; isValidated: boolean }) {
    if (data.isValidated) {
        return await put<boolean>("/ProcessingActivityValidations/" + data.processingActvityId + "/step", data.step);
    } else {
        return await deleteHttp<boolean>("/ProcessingActivityValidations/" + data.processingActvityId + "/step/" + data.step);
    }
}

async function completeProcessingActivityValidation(processingActvityId: string | undefined) {
    return await put<{}>("/ProcessingActivityValidations/" + processingActvityId + "/complete", undefined);
}

async function saveProcessingActivity(viewModel: ProcessingActivityModel) {
    const updateModel: ProcessingActivityUpdateModel = {
        purposes: viewModel.purposes,
        name: viewModel.name,
        owner: viewModel.owner,
        dataSubjects: viewModel.dataSubjects,
        description: viewModel.description,
        disableDataProcessors: viewModel.disableDataProcessors,
        disableDisclosures: viewModel.disableDisclosures,
        disableSources: viewModel.disableSources,
        disableDataControllers: viewModel.disableDataControllers,
        disableJointDataControllers: viewModel.disableJointDataControllers,
        disableSystems: viewModel.disableSystems,
        policies: viewModel.policies,
        processingAreas: viewModel.processingAreas,
        id: viewModel.id,
        processors: viewModel.processors,
        securityMeasures: viewModel.securityMeasures,
        subjects: viewModel.subjects,
        sharedResponsibles: viewModel.sharedResponsibles,
        dataSources: viewModel.dataSources,
    };

    return await put<ProcessingActivityUpdateModel>("/processingactivity/" + viewModel.id, updateModel);
}

export function shouldDisableComments(userPlanType: PlanType, processingActivityStatus: ProcessingActivityStatus) {
    return (
        userPlanType === PlanType.GdprResearch &&
        (processingActivityStatus === ProcessingActivityStatus.Active ||
            processingActivityStatus === ProcessingActivityStatus.Archived ||
            processingActivityStatus === ProcessingActivityStatus.Cancelled)
    );
}

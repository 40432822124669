import React from "react";
import DotLegalLink from "../common/components/dotLegalLink/DotLegalLink.tsx";
import { useUrlProvider } from "../useUrlProvider.ts";

export interface ISystemLinksProps {
    systems: Array<{ id: string; name: string }>;
}

function SystemLinks(props: ISystemLinksProps) {
    const urlProvider = useUrlProvider();
    return props.systems.map((x, i) => (
        <React.Fragment key={x.id}>
            <DotLegalLink linkColor={"primary"} to={urlProvider.getSystemOverviewUrl(x.id)} bold stopPropagation>
                {x.name}
            </DotLegalLink>
            {i !== props.systems.length - 1 && <span style={{ marginRight: "4px" }}>,</span>}
        </React.Fragment>
    ));
}

export default SystemLinks;

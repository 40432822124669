import { DotLegalDialog, DotLegalFileUpload, DotLegalHeader, DotLegalTable } from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../localization/useTranslation";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import useCustomizeEmailLogoDialogHooks from "./CustomizeEmailLogoDialog.hooks";
import CloseIcon from "@mui/icons-material/Close";
import { downloadFile } from "../../common/api/downloadFile";
import { getSettings } from "../../common/settingsProvider";
import { isNullOrWhitespace } from "../../common/stringOperations";

export interface ICustomizeEmailLogoDialog {
    onDialogClose: () => void;
}

function CustomizeEmailLogoDialog(props: ICustomizeEmailLogoDialog) {
    const { emailAddress } = useUserContext();
    const { translateString } = useTranslation();
    const { onFileUpload, error, onSendEmailClick, onSaveLogoClick, isSendingEmail, isSavingLogo, isLoading, viewModel, onRemoveFile } =
        useCustomizeEmailLogoDialogHooks(props);

    return (
        <DotLegalDialog
            size="md"
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSaveLogoClick}
            buttonOkText={translateString("save")}
            header={translateString("addLogoToMails")}
            inProgress={isSavingLogo}
            secondaryButtonText={translateString("sendTestEmail")}
            onSecondaryButtonClick={onSendEmailClick}
            secondaryButtonLoading={isSendingEmail}
            secondaryButtonHoverText={emailAddress}
            isLoading={isLoading}
        >
            <>
                <Box mr={4} ml={4} sx={{ display: "flex", justifyContent: "center" }}>
                    <DotLegalHeader fontWeight={400} headerStyle={"small"} center>
                        {translateString("addLogoToMailsInfo")}
                    </DotLegalHeader>
                </Box>
                <DotLegalFileUpload
                    dropZoneText={translateString("dragDrop")}
                    choseFileText={translateString("chooseFile")}
                    numberOfFilesChosenText={translateString("numberOfFilesChosen")}
                    dropFileText={translateString("dropFile")}
                    onFilesChanged={(files) => onFileUpload(files)}
                    files={[]}
                    errorText={error}
                />
                <Box>
                    <DotLegalHeader headerStyle="extraSmall" fontWeight={400} marginBottom={0} marginTop={1}>
                        {translateString("addLogoToMailsRules")}
                    </DotLegalHeader>
                </Box>

                {viewModel !== undefined && !isNullOrWhitespace(viewModel.logoId) && (
                    <DotLegalTable
                        headers={[{ text: "test", property: "fileName", hideHeader: true }]}
                        emptyText={translateString("noDocuments")}
                        paginationLabelOf={translateString("labelOf")}
                        defaultOrderBy={"fileName"}
                        defaultOrder={"asc"}
                        isLoading={false}
                        hidePagination
                        textStyle={"NoCapsHeaders"}
                        data={viewModel ? [{ fileName: viewModel.fileName }] : []}
                        marginTop={1}
                        renderRow={(row) => (
                            <TableRow
                                key={1}
                                onClick={() =>
                                    downloadFile(
                                        getSettings().apiBaseUrl + `/QuestionnaireEmailCustomization/dowloadLogo?logoId=${viewModel.logoId}`,
                                        "Logo.png"
                                    )
                                }
                            >
                                <TableCell sx={{ wordBreak: "break-word" }}>{row.fileName}</TableCell>

                                <TableCell width={40}>
                                    <IconButton
                                        size="small"
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            onRemoveFile();
                                        }}
                                    >
                                        <CloseIcon sx={(theme) => ({ fill: theme.palette.error.main })} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    />
                )}
            </>
        </DotLegalDialog>
    );
}

export default CustomizeEmailLogoDialog;

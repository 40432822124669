import { useState } from "react";
import { useQuery } from "react-query";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { get } from "../../common/api/apiShared";
import { ValidationError } from "../../common/validationError";
import { LegalEntitySelectorViewModel } from "../../legalEntity/LegalEntity.types";
import { useTranslation } from "../../localization/useTranslation";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../user/User.types";
import { ProcessingActivityModel, SelectableColoredItem } from "../ProcessingActivity.types";
import { IAssociationStepProps } from "./AssociationStep";

export function useAssociationStepDataMapping(props: IAssociationStepProps) {
    const [showBusinessAreaDialog, setShowBusinessAreaDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const { permissions } = useUserContext();

    const [transferDialogContent, setTransferDialogContent] = useState({ showDialog: false, userFrom: "", userTo: "" });

    let { data, isLoading } = useQuery("groupentities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities"), {
        enabled: permissions.processingActivityAssociationPermissions.hasGroupEntitiesPermission,
    });

    let areaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    let responsiblesQuery = useResponsibleQuery();

    let areasLoading = areaQuery.isLoading;
    let ownersLoading = responsiblesQuery.isLoading;

    let areasData;
    if (areaQuery.data) {
        areasData = areaQuery.data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
                color: d.color,
            };
        });
    }

    let ownersData;
    let sharedResponsiblesData;
    if (responsiblesQuery.userData) {
        ownersData = responsiblesQuery
            .data(props.owner)
            ?.filter((x) => !props.selectedSharedResponsibles.includes(x.id))
            .map((d) => {
                return d;
            });

        sharedResponsiblesData = responsiblesQuery
            .dataForMultiSelect(props.selectedSharedResponsibles)
            ?.filter((x) => x.id !== props.owner)
            .map((d) => {
                return d;
            });
    }

    const onSaveBusinessArea = (response: any) => {
        var temp = [...props.processingAreas!];
        temp.push(response.id);
        props.onProcessingAreaChange(temp);

        areaQuery.refetch();
        setSearchValue("");
    };

    return {
        isLoading,
        data,
        areasData,
        ownersData,
        sharedResponsiblesData,
        areasLoading,
        ownersLoading,
        onSaveBusinessArea,
        searchValue,
        setSearchValue,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        transferDialogContent,
        setTransferDialogContent,
    };
}

export function useValidationAssociationStep(hasGroupEntityAccess: boolean, hasOwnershipAccess: boolean) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel, responsibles: Array<UserSelectableItem> | undefined): Array<ValidationError> => {
        const result = [];

        if (hasGroupEntityAccess && (!model.legalEntities || model.legalEntities.length === 0)) {
            result.push(new ValidationError("legalEntities", translateString("affiliationRequired")));
        }

        if (hasOwnershipAccess) {
            let user = responsibles?.find((x) => x.id === model.owner);
            if (user && !user.active) {
                result.push(new ValidationError("inActiveUserAffiliation", translateString("userDeactivated")));
            }
        }

        return result;
    };
}

import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHttp, get, put } from "../../../common/api/apiShared";
import { LegalEntitySelectorViewModel } from "../../../legalEntity/LegalEntity.types";
import { ProcessingActivityModel, SelectableDataCategoryItem, SelectableLegalUnitsItem, Transfer } from "../../ProcessingActivity.types";
import { useTranslation } from "../../../localization/useTranslation";
import { ValidationError } from "../../../common/validationError";
import { useSelectableLegalEntityQuery } from "../../../legalEntity/useSelectableLegalEntityQuery";
import { useSelectableDataCategoriesQuery } from "../../hooks/useSelectableDataCategoriesQuery";
import { useSelectableDocumentsQuery } from "../../hooks/useSelectableDocumentQuery";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import { LevelOfProtectionType } from "../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { ProcessingActivityDisclosuresPermissions } from "../../../auth/userContextProvider/UserContextProvider.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useSelectableAllLegalBasesAllQuery, useTransferBasesQuery } from "../../../common/hooks/useSelectableItemQueries";
import { useState } from "react";
import { AgreementSaveModel, DataProcessorAgreementSaveModel } from "../agreementDialog/DataProcessorAgreementDialog.types";
import { SelectedLegalEntityDocument } from "../../../legalEntity/legalEntityDocumentTab/LegalEntityDocumentTab.types";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { ITransfersSubStep } from "./TransfersStep";

export function useTransfersSubStep(props: ITransfersSubStep) {
    const { permissions } = useUserContext();
    const [dataProcessorAgreementSaveModel, setDataProcessorAgreementSaveModel] = useState<DataProcessorAgreementSaveModel | undefined>(undefined);
    const [transferId, setTransferId] = useState("");
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);
    const { getExpandedId, setExpandedId } = useExpandedId();
    const optimisticUpdate = useOptimisticUpdate();
    const queryClient = useQueryClient();

    let queryKey = "processingActivity" + props.processingActivity.id;

    const disclosurePermissions = permissions.processingActivityDisclosuresPermissions;

    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    let responsibleQuery = useResponsibleQuery(disclosurePermissions.hasResponsiblePermission);
    let groupEntityQuery = useQuery("groupEntities", () => get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/groupentities/"));
    let transferBasisQuery = useTransferBasesQuery(disclosurePermissions.hasTransferBasisPermission);
    let legalbasisQuery = useSelectableAllLegalBasesAllQuery(disclosurePermissions.hasBasisOfDisclosurePermission);
    let documentsQuery = useSelectableDocumentsQuery(
        disclosurePermissions.hasAgreementPermission,
        props.transfers.find((x) => x.id === getExpandedId())?.legalUnitId ?? undefined,
        props.processingActivity.id
    );

    let dataCategoryLoading = dataCategoriesQuery.isLoading;
    let legalEntityLoading = legalEntitiesQuery.isLoading;
    let groupEntityLoading = groupEntityQuery.isLoading;
    let responsibleLoading = responsibleQuery.isLoading;
    let transferBasisLoading = transferBasisQuery.isLoading;
    let legalBasesLoading = legalbasisQuery.isLoading;
    let documentsLoading = documentsQuery.isLoading;

    let legalEntityData = legalEntitiesQuery.data;
    let groupEntityData = groupEntityQuery.data;
    let transferBasisData = transferBasisQuery.data;
    let legalBasesData = legalbasisQuery.data;
    let documentsData = documentsQuery.disclosureDocuments;

    let dataCategoriesData = Array<SelectableDataCategoryItem>();
    if (dataCategoriesQuery.allDataCategories) {
        dataCategoriesData = dataCategoriesQuery.allDataCategories!.filter(function (item) {
            return props.dataCategories
                .map((d) => {
                    return d!;
                })
                .includes(item.id);
        });
    }

    const removeAgreementMutation = useMutation(removeAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });
    const addAgreementMutation = useMutation(addAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const onAddAgreement = async (agreementId: string, disclosureId: string) => {
        const newProcessingActivityModel = { ...props.processingActivity };
        const source = newProcessingActivityModel.transfers.find((d) => d.id === disclosureId)!;

        source.transferAgreements.push(agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: props.processingActivity.id,
            disclosureId: disclosureId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onDeleteAgreement = async (agreementId: string, disclosureId: string) => {
        const newProcessingActivityModel = { ...props.processingActivity };
        const source = newProcessingActivityModel.transfers.find((d) => d.id === disclosureId)!;

        source.transferAgreements = source.transferAgreements.filter((x) => x !== agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeAgreementMutation.mutateAsync, {
            processingActivityId: props.processingActivity.id,
            disclosureId: disclosureId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onHasAgreementChange = async (hasAgreement: boolean, disclosureId: string) => {
        const newProcessingActivityModel = { ...props.processingActivity };
        const source = newProcessingActivityModel.transfers.find((d) => d.id === disclosureId)!;

        source.hasDataProcessingAgreement = hasAgreement;
        source.transferAgreements = [];

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: props.processingActivity.id,
            disclosureId: disclosureId,
            saveModel: { hasAgreement: hasAgreement },
        });
    };

    const onSaveNationalLaw = (disclosureId: string, response: any, dataCategoryId: string) => {
        const dc = props.transfers.find((x) => x.id! === disclosureId)!.dataCategories.find((dc) => dc.dataCategoryId === dataCategoryId)!;
        dc.nationalLawId = response.id;
        props.onDisclosureUpdate(dc, disclosureId);

        legalbasisQuery.refetch();
    };

    return {
        dataCategoryLoading,
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        groupEntityLoading,
        groupEntityData,
        transferBasisData,
        transferBasisLoading,
        legalBasesLoading,
        legalBasesData,
        documentsLoading,
        documentsData,
        getResponsibles: responsibleQuery.data,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        transferId,
        setTransferId,
        refetchDocuments: documentsQuery.refetch,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        getExpandedId,
        setExpandedId,
        onAddAgreement,
        onDeleteAgreement,
        onHasAgreementChange,
        onSaveNationalLaw,
    };

    async function addAgreementAPI(data: { processingActivityId: string; disclosureId: string; saveModel: AgreementSaveModel }) {
        return await put("/processingactivity/" + data.processingActivityId + "/disclosure/" + data.disclosureId + "/agreement", data.saveModel);
    }

    async function removeAgreementAPI(data: { processingActivityId: string; disclosureId: string; saveModel: AgreementSaveModel }) {
        return await deleteHttp(
            `/processingactivity/${data.processingActivityId}/disclosure/${data.disclosureId}/agreement?agreementId=${data.saveModel.agreementId}`
        );
    }
}

export function useValidateTransfersStep(permissions: ProcessingActivityDisclosuresPermissions) {
    const { translateString } = useTranslation();
    return (
        model: ProcessingActivityModel,
        legalEntities: Array<SelectableLegalUnitsItem> | undefined,
        sensitiveDataCategories: Array<string> | undefined,
        responsibles: Array<UserSelectableItem> | undefined,
        legalObligationId: string
    ): Array<ValidationError> => {
        return getTransfersErrors(
            model,
            translateString,
            legalEntities ?? [],
            sensitiveDataCategories ?? [],
            responsibles ?? [],
            legalObligationId,
            permissions
        );
    };
}

function getTransfersErrors(
    model: ProcessingActivityModel,
    translateString: any,
    legalEntities: Array<SelectableLegalUnitsItem>,
    sensitiveDataCategories: Array<string> | undefined,
    responsibles: Array<UserSelectableItem> | undefined,
    legalObligationId: string,
    permissions: ProcessingActivityDisclosuresPermissions
) {
    const result: Array<ValidationError> = [];

    if (permissions) {
        if (!model.disableDisclosures && !model.hasDisclosures) {
            result.push(new ValidationError("noDataSharingsDisclosures", translateString("atLeastOneSharingRequired")));
        }

        model.transfers.forEach((transfer, index) => {
            if (!transfer.legalUnitId) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("noLegalUnitForTransferError")));
            }
            if (transfer.legalEntities.length === 0) {
                result.push(new ValidationError(`${index}companies`, translateString("noCompaniesForTransferChosenError")));
            }
            if (permissions.hasDataCategoriesPermission && transfer.dataCategories.length === 0) {
                result.push(new ValidationError(`${index}dataCategories`, translateString("dataCategoriesRequired")));
            }
            if (
                permissions.hasTransferBasisPermission &&
                legalEntities.some(
                    (t) =>
                        t.id === transfer.legalUnitId &&
                        (t.levelOfProtection === LevelOfProtectionType.ThirdCountry || t.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework)
                ) &&
                !transfer.transferBasis
            ) {
                result.push(new ValidationError(`${index}transferBasisId`, translateString("transferBasisRequiredController")));
            }
            if (transfer.legalEntities.some((l) => l === transfer.legalUnitId)) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("legalUnitCantBeInCompanies")));
            }

            if (permissions.hasBasisOfDisclosurePermission) {
                transfer.dataCategories.forEach((d) => {
                    if (d.directiveId === legalObligationId && !d.nationalLawId) {
                        result.push(new ValidationError(`${index},${d.dataCategoryId},nationalLawId`, translateString("nationalLawRequired")));
                    }

                    if (!d.nationalLawId && !d.directiveId) {
                        result.push(new ValidationError(`${index},${d.dataCategoryId},directiveId`, translateString("legalBasisRequired")));
                    }
                    if (sensitiveDataCategories?.some((s) => s === d.dataCategoryId) && !d.doubleLegalBasisId) {
                        result.push(new ValidationError(`${index},${d.dataCategoryId},doubleLegalBasisId`, translateString("doubleLegalBasisRequired")));
                    }
                });
            }

            if (permissions.hasResponsiblePermission) {
                let user = responsibles?.find((x) => x.id === transfer.responsible);
                if (user && !user.active) {
                    result.push(new ValidationError(`${index}inactiveUser`, translateString("userDeactivated")));
                }
            }
        });
    }

    return result;
}

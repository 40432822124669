import { Box, Grid, GridSize, Skeleton, TableCell } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { ITableHeader } from "../../common/components/dotLegalTable/DotLegalTable.types";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalSwitch,
    DotLegalChip,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useLegalEntitiesHook } from "./LegalEntities.hooks";
import { useLegalEntitiesStyles } from "./LegalEntities.styles";
import { LegalEntitiesRowViewModel, LegalEntityPageEnum } from "./LegalEntities.types";
import { greenArrowRight, warning, WhiteLockIcon } from "../../common/icons";
import VendorsIcon from "../vendors.svg?react";
import NoData from "../legalEntityTable/noData.svg?react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import CustomListSelector from "../../customLists/customListSelector/CustomListSelector";
import { CustomListSelectorType } from "../../customLists/customListSelector/CustomListSelector.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { CallPage } from "../../customLists/CustomLists.types";
import AddLegalEntityDialog from "../legalEntityManagement/addLegalEntityDialog/AddLegalEntityDialog";
import { VendorManagementPlanViewModel } from "../../auth/planProvider/PlanProvider.types";
import ComplianceAreaWrapper from "../../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import QuestionnaireStatus from "../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import ClassificationRisk from "../../common/components/questionnaireComponents/classiificationRisk/ClassificationRisk";
import SystemLinks from "../../system/SystemLinks.tsx";

export interface ILegalEntitiesProps {
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntities(props: ILegalEntitiesProps) {
    const isGroupEntities = props.legalEntityPage === LegalEntityPageEnum.GroupEntity;
    const styles = useLegalEntitiesStyles();
    const pagelength = 30;

    const { translateString, translateCountry } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { unitsPlan, vendorManagementPlan } = usePlanContext();

    const {
        isLoading,
        auditIsLoading,
        data,
        showAllLegalEntities,
        setShowAllLegalEntities,
        searchedCountry,
        setSearchedCountry,
        countrySelectOptions,
        setShowAddLegalEntityDialog,
        showAddLegalEntityDialog,
        onGoToLegalEntityClick,
        getBreadCrumbs,
        hasCompanies,
        searchedRiskLevel,
        setSearchedRisklevel,
        riskLevelSelectOptions,
        searchedClassifications,
        setSearchedClassifications,
        searchedComplianceAreas,
        setSearchedComplianceAreas,
        searchedResponsibles,
        setSearchedResponsibles,
        searchedLatestAudits,
        setSearchedLatestAudits,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedLatestAuditStatus,
        setSearchedLatestAuditStatus,
        businessAreaSelectOptions,
        responsibleSelectOptions,
        complianceAreaSelectOptions,
        latestAuditFilterOptions,
        classificationFilterOptions,
        latestAuditStatusFilterOptions,
    } = useLegalEntitiesHook(props);

    const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize | boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: vendorManagementPlan.hasAccess ? true : 2,
    };

    const getSearchFields = () => {
        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedCountry}
                        chosenOptions={searchedCountry}
                        label={translateString("countriesPlural")}
                        placeholder={translateString("countriesPlural")}
                        isLoading={isLoading}
                        options={countrySelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedBusinessAreas}
                        chosenOptions={searchedBusinessAreas}
                        label={translateString("businessAreas")}
                        placeholder={translateString("businessAreas")}
                        isLoading={isLoading}
                        options={businessAreaSelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                {vendorManagementPlan.hasAccess && (
                    <>
                        {vendorManagementPlan.legalEntityClassification && (
                            <>
                                <Grid {...tableGridProps}>
                                    <DotLegalMultiSelect
                                        onChange={setSearchedClassifications}
                                        chosenOptions={searchedClassifications}
                                        label={translateString("classifications")}
                                        placeholder={translateString("classifications")}
                                        isLoading={isLoading}
                                        options={classificationFilterOptions}
                                        noMargin
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </Grid>
                                <Grid {...tableGridProps}>
                                    <DotLegalMultiSelect
                                        onChange={setSearchedRisklevel}
                                        chosenOptions={searchedRiskLevel}
                                        label={translateString("classificationRiskLevel")}
                                        placeholder={translateString("classificationRiskLevel")}
                                        isLoading={isLoading}
                                        options={riskLevelSelectOptions}
                                        noMargin
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid {...tableGridProps}>
                            <DotLegalMultiSelect
                                onChange={setSearchedComplianceAreas}
                                chosenOptions={searchedComplianceAreas}
                                label={translateString("complianceAreas")}
                                placeholder={translateString("complianceAreas")}
                                isLoading={isLoading}
                                options={complianceAreaSelectOptions}
                                noMargin
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Grid>
                    </>
                )}
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedResponsibles}
                        chosenOptions={searchedResponsibles}
                        label={translateString("responsibles")}
                        placeholder={translateString("responsibles")}
                        isLoading={isLoading}
                        options={responsibleSelectOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                {vendorManagementPlan.hasAccess && !isGroupEntities && (
                    <>
                        <Grid {...tableGridProps}>
                            <DotLegalMultiSelect
                                onChange={setSearchedLatestAudits}
                                chosenOptions={searchedLatestAudits}
                                label={translateString("legalEntitiesLatestAuditHeader")}
                                placeholder={translateString("legalEntitiesLatestAuditHeader")}
                                isLoading={isLoading}
                                options={latestAuditFilterOptions}
                                noMargin
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Grid>
                        <Grid {...tableGridProps}>
                            <DotLegalMultiSelect
                                onChange={setSearchedLatestAuditStatus}
                                chosenOptions={searchedLatestAuditStatus}
                                label={translateString("latestAuditStatus")}
                                placeholder={translateString("latestAuditStatus")}
                                isLoading={isLoading}
                                options={latestAuditStatusFilterOptions}
                                noMargin
                                noOptionsLabel={translateString("noOptions")}
                            />
                        </Grid>
                    </>
                )}
            </React.Fragment>
        );
    };

    const getCustomListBtn = () => {
        if (permissions.customListPermissions.read) {
            return [<CustomListSelector key={0} type={CustomListSelectorType.Company} callPage={isGroupEntities ? CallPage.GroupCompanies : undefined} />];
        }

        return undefined;
    };

    function getLoadingState() {
        return <Skeleton width={"100%"} height={18} />;
    }

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs()}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllLegalEntities}
                        enabled={showAllLegalEntities}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <Box
                sx={{
                    "& img": {
                        height: 100,
                    },
                }}
            >
                <DotLegalActionBar
                    canCreate={(isGroupEntities && permissions.canManageGroupEntities) || (!isGroupEntities && permissions.companyPermissions.create)}
                    showDialog={showAddLegalEntityDialog}
                    icon={<VendorsIcon />}
                    additionalButtons={getCustomListBtn()}
                    primaryButton={
                        <PlanDialogButtonWrapper enabled={isGroupEntities && unitsPlan.maxNumberOfGroupEntities ? true : false}>
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowAddLegalEntityDialog(true)}
                                disabled={isGroupEntities && unitsPlan.maxNumberOfGroupEntities ? true : false}
                                endIcon={isGroupEntities && unitsPlan.maxNumberOfGroupEntities ? WhiteLockIcon : undefined}
                                toolTip={isGroupEntities && unitsPlan.maxNumberOfGroupEntities ? translateString("upgradePrivacyPlanToday") : undefined}
                            >
                                {translateString("createLegalEntity")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    }
                >
                    <AddLegalEntityDialog legalEntityPage={props.legalEntityPage} onDialogClose={() => setShowAddLegalEntityDialog(false)} />
                </DotLegalActionBar>
            </Box>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    {hasCompanies ? (
                        <DotLegalTableWithControls
                            extraControls={getSearchFields()}
                            headers={getHeaders(translateString, isGroupEntities === false, vendorManagementPlan)}
                            getUserSpecificPageLength={() => pagelength}
                            hideRowsPerPage
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            emptyText={translateString("noCompaniesMatchesQuery")}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            data={data?.legalEntityRows}
                            renderRow={(row, i) => {
                                const lastElement = data!.legalEntityRows[data!.legalEntityRows.length - 1];
                                return (
                                    <TableRow onClick={() => onGoToLegalEntityClick(row.legalEntityId)} key={i} sx={styles.tableRow}>
                                        <TableCell sx={styles.bold}>{row.name}</TableCell>
                                        <TableCell>{row.countryCode ? translateCountry(row.countryCode) : ""}</TableCell>
                                        <TableCell>
                                            {row.businessAreas.map((x, index) => {
                                                return (
                                                    <Box sx={styles.chip} key={x.name + index}>
                                                        <DotLegalChip
                                                            value={x.name}
                                                            color={x.color}
                                                            size="small"
                                                            isSelected
                                                            collapse={row.businessAreas.length > 2 && index > 0}
                                                        />
                                                    </Box>
                                                );
                                            })}
                                        </TableCell>
                                        {vendorManagementPlan.hasAccess && (
                                            <>
                                                <TableCell>
                                                    <Box
                                                        sx={(theme) => ({
                                                            display: "flex",
                                                            gap: 1,
                                                            "& .MuiTypography-root": { fontSize: theme.typography.pxToRem(12) },
                                                        })}
                                                    >
                                                        {row.complianceAreas.map((area, index) => {
                                                            return <ComplianceAreaWrapper key={area.id} complianceArea={area.name} />;
                                                        })}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {row.certifications
                                                        .map((certification) => {
                                                            return certification.name;
                                                        })
                                                        .join(", ")}
                                                </TableCell>
                                            </>
                                        )}

                                        <TableCell>{row.responsible?.name}</TableCell>
                                        <TableCell>
                                            <SystemLinks systems={row.assets} />
                                        </TableCell>
                                        <TableCell>{row.isApproved && <CheckIcon sx={{ height: "20px !important" }} />}</TableCell>

                                        {vendorManagementPlan.hasAccess && (
                                            <>
                                                {vendorManagementPlan.legalEntityClassification && (
                                                    <TableCell sx={{ minWidth: 350 }}>
                                                        {auditIsLoading
                                                            ? getLoadingState()
                                                            : row.classifications &&
                                                              row.classifications.length > 0 && (
                                                                  <ClassificationRisk
                                                                      legalEntityId={row.legalEntityId}
                                                                      legalEntityPage={props.legalEntityPage}
                                                                      classifications={row.classifications}
                                                                  />
                                                              )}
                                                    </TableCell>
                                                )}

                                                {!isGroupEntities && (
                                                    <>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: 150,
                                                                paddingLeft: "16px !important",
                                                                borderTopLeftRadius: i === 0 ? "20px" : "unset",
                                                                borderBottomLeftRadius:
                                                                    i === data!.legalEntityRows.length - 1 ||
                                                                    i === pagelength - 1 ||
                                                                    row.legalEntityId === lastElement.legalEntityId
                                                                        ? "20px"
                                                                        : "unset",
                                                                backgroundColor: "#F5F5FC",
                                                            }}
                                                        >
                                                            {auditIsLoading ? getLoadingState() : row.latestAudit?.name}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: 100,
                                                                paddingRight: "16px !important",
                                                                borderTopRightRadius: i === 0 ? "20px" : "unset",
                                                                borderBottomRightRadius:
                                                                    i === data!.legalEntityRows.length - 1 ||
                                                                    i === pagelength - 1 ||
                                                                    row.legalEntityId === lastElement.legalEntityId
                                                                        ? "20px"
                                                                        : "unset",
                                                                backgroundColor: "#F5F5FC",
                                                            }}
                                                        >
                                                            {auditIsLoading
                                                                ? getLoadingState()
                                                                : row.latestAudit && (
                                                                      <QuestionnaireStatus
                                                                          showQuestionsStatistics={row.latestAudit.showQuestionsStatistics}
                                                                          numberOfQuestionsAnswered={row.latestAudit.numberOfQuestionsAnswered}
                                                                          totalNumberOfQuestions={row.latestAudit.totalNumberOfQuestions}
                                                                          status={row.latestAudit.status}
                                                                          lastestRecipientUpdate={row.latestAudit.lastestRecipientUpdate || undefined}
                                                                      />
                                                                  )}
                                                        </TableCell>
                                                    </>
                                                )}
                                                <TableCell>
                                                    <Box sx={styles.greenArrowContainer}>{greenArrowRight}</Box>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            }}
                        />
                    ) : (
                        <DotLegalEmptyState
                            icon={<NoData />}
                            text={isGroupEntities ? translateString("noGroupEntities") : translateString("noLegalEntities")}
                        />
                    )}
                </DotLegalPaper>
            </Box>
        </Box>
    );
}

export default LegalEntities;

function getHeaders(translateString: any, isExternalEntities: boolean, vendorManagementPlan: VendorManagementPlanViewModel) {
    let headers = Array<ITableHeader<LegalEntitiesRowViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "countryCode", text: translateString("country"), align: "left", showOnMobile: true });
    headers.push({ property: "businessAreas", text: translateString("businessAreas"), align: "left", showOnMobile: true });

    if (vendorManagementPlan.hasAccess) {
        headers.push({ property: "complianceAreas", text: translateString("complianceAreas"), align: "left", showOnMobile: true });
        headers.push({ property: "certifications", text: translateString("certifications"), align: "left", showOnMobile: true });
    }

    headers.push({ property: "responsible", text: translateString("responsible"), align: "left", showOnMobile: true });
    headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
    headers.push({ property: "isApproved", text: translateString("approved"), align: "left", showOnMobile: true });

    if (vendorManagementPlan.hasAccess) {
        if (vendorManagementPlan.legalEntityClassification) {
            headers.push({ property: "classifications", text: translateString("classifications"), align: "left", showOnMobile: true });
        }

        if (isExternalEntities) {
            headers.push({ property: "latestAudit", text: translateString("legalEntitiesLatestAuditHeader"), align: "left", showOnMobile: true });
            headers.push({ property: "latestAudit", text: translateString("latestAuditStatus"), align: "left", showOnMobile: true });
        }
    }

    return headers;
}

import { createElement, useState } from "react";
import { useParams } from "react-router-dom";
import { useUrlProvider } from "../../../useUrlProvider";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { getSettings } from "../../../common/settingsProvider";
import { createPdfUrl } from "../../../common/pdfUrlHelper";
import { AuditGroupHeaderProps } from "./AuditGroupHeader";
import { downloadFile } from "../../../common/api/downloadFile";
import { Trans } from "react-i18next";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export function useAuditGroupHeader(props: AuditGroupHeaderProps) {
    const { id } = useParams<{ id: string }>();
    const { language } = useUserContext();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isDownloadingManagementReport, setIsDownloadingManagementReport] = useState(false);

    const urlProvider = useUrlProvider();
    const snackbar = useDotLegalSnackbar();

    const { translateDate } = useTranslation();

    const onDownloadManagementReport = async () => {
        setIsDownloadingManagementReport(true);
        let url = getSettings().mainSiteBaseUrl + urlProvider.getAuditGroupManagementReportUrl(id!) + "?isPdf=true";
        url = createPdfUrl(url, language);

        const fileName = `${props.auditGroupModel?.name} - ${translateDate(props.auditGroupModel!.deadline)}.pdf`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?noMargin=true&url=" + url, fileName);
        setIsDownloadingManagementReport(false);
        snackbar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
    };

    return {
        anchorEl,
        setAnchorEl,
        showEditDialog,
        setShowEditDialog,
        openMenu: Boolean(anchorEl),
        popoverId: Boolean(anchorEl) ? "simple-popover" : undefined,
        id,
        showDeleteDialog,
        setShowDeleteDialog,
        onDownloadManagementReport,
        isDownloadingManagementReport,
    };
}

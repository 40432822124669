import React from "react";
import { AnnualWheelActivityTemplateItem, NIS2TemplateContainer, NIS2Templates } from "../../AnnualWheelDialog.types";
import { Box, Divider, Grid } from "@mui/material";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { NIS2Type } from "../../../AnnualWheel.types";
import { useTranslation } from "../../../../localization/useTranslation";
import { SelectableActivityTemplate } from "../selectableActivityTemplate/SelectableActivityTemplate";
import useNis2TemplateHooks from "./Nis2Templates.hooks";

export interface INis2Templates {
    templateContainer: NIS2TemplateContainer;
    searchField: JSX.Element;
    additionalFilterField?: JSX.Element;
    onSelectActivity: (activity: AnnualWheelActivityTemplateItem) => void;
    selectedActivity?: string;
    onCopyClick: (activity: AnnualWheelActivityTemplateItem) => void;
}

function Nis2Templates(props: INis2Templates) {
    const { translateString, translateNIS2Category } = useTranslation();
    const { selectedNIS2Type, setSelectedNIS2Type } = useNis2TemplateHooks();

    const getNIS2Templates = (templates: Array<NIS2Templates>) => {
        const filteredTemplates = templates.filter((x) => x.hasTemplates());

        return (
            <Box>
                {filteredTemplates.map((x, index) => {
                    return (
                        <React.Fragment key={x.categoryType}>
                            <Box
                                key={x.categoryType}
                                sx={(theme) => ({
                                    marginTop: theme.spacing(2),
                                    marginLeft: theme.spacing(1),
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                {translateNIS2Category(x.categoryType.toString())}
                            </Box>
                            <Grid container columnSpacing={1}>
                                {x.templates.map((activity) => {
                                    return (
                                        <SelectableActivityTemplate
                                            key={activity.id}
                                            activity={activity}
                                            isActivityLocked={false}
                                            selectedActivity={props.selectedActivity}
                                            onClick={props.onSelectActivity}
                                            onCopyClick={() => props.onCopyClick(activity)}
                                        />
                                    );
                                })}
                            </Grid>
                            {++index !== filteredTemplates.length && <Divider sx={{ marginTop: 1, marginBottom: 3, color: "#D9DAEE" }} />}
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    };

    const getNIS2OngoingOperationTemplates = () => {
        return getNIS2Templates(props.templateContainer.ongoingOperationTemplates);
    };

    const getNIS2ImplementationTemplates = () => {
        return getNIS2Templates(props.templateContainer.implementationTemplates);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <DotLegalSelect
                        selectedItem={selectedNIS2Type}
                        options={[
                            { id: NIS2Type.Implementation.toString(), name: translateString("implementation") },
                            { id: NIS2Type.OngoingOperation.toString(), name: translateString("ongoingOperation") },
                        ]}
                        onChange={(item) => setSelectedNIS2Type(item!)}
                        placeholder={""}
                        label={""}
                        noOptionsLabel={""}
                        disableClearable
                        noMargin
                        width={"200px"}
                    />
                    {props.additionalFilterField}
                </Box>
                {props.searchField}
            </Box>
            <Box>{selectedNIS2Type === NIS2Type.Implementation.toString() ? getNIS2ImplementationTemplates() : getNIS2OngoingOperationTemplates()}</Box>
        </React.Fragment>
    );
}

export default Nis2Templates;

import { useMutation } from "react-query";
import { put } from "../../common/api/apiShared";
import { LegalEntityContactViewModel } from "./LegalEntityContactTab.types";
import { ILegalEntityContactTab } from "./LegalEntityContactTab";
import { useTranslation } from "../../localization/useTranslation";
import { useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useLegalEntityContactsQuery } from "../useLegalEntityContactsQuery";

export function useLegalEntityContactTabDataMapping(props: ILegalEntityContactTab) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    let { isLoading, data, refetch } = useLegalEntityContactsQuery(props.legalEntityId);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedContact, setSelectedContact] = useState<LegalEntityContactViewModel | undefined>(undefined);

    const editMutation = useMutation(setPrimaryContactMutation);

    function setPrimaryContactMutation(doc: LegalEntityContactViewModel) {
        return put(`/LegalEntityContacts/${props.legalEntityId}/primaryContact/${doc.id}`, null);
    }

    const setPrimaryContact = (doc: LegalEntityContactViewModel) => {
        editMutation.mutateAsync(doc!, {
            onSuccess: (response: any) => {
                refetch();
                snackbar.show(translateString("primaryContactUpdated"));
            },
        });
    };

    function getMenuItems(model: LegalEntityContactViewModel) {
        let menuItems = [];

        if (!model.primaryContact) {
            menuItems.push({
                menuItem: translateString("setAsPrimaryContact"),
                onClick: () => {
                    setPrimaryContact(model);
                },
            });
        }

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedContact(model);
                setShowDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedContact(model);
                setShowDeleteDialog(true);
            },
        });

        return menuItems;
    }

    return {
        isLoading,
        data,
        getMenuItems,
        setShowDialog,
        showDialog,
        selectedContact,
        setSelectedContact,
        refetch,
        showDeleteDialog,
        setShowDeleteDialog,
        hasContacts: isLoading || (data && data.length > 0),
    };
}
